import { styled, Stack, Button } from '@mui/material';

export const StackStyleHeader = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const ButtonAddFileStyle = styled(Button)(({ theme }) => ({
  fontWeight: '500',
  marginTop: '12px',
  textTransform: 'initial',
  '&:hover': {
    backgroundColor: theme.palette.neutral200.main,
    color: theme.palette.textPrimary.main,
  },
}));
