import { Stack, Typography, useTheme } from '@mui/material';
import { StackHeaderStyle, TypographyDotStyle, StackUserStyle } from './style';

import { MessageChatType } from '../../../../types/types';
import { userNameCalc, isElementInView } from '../../../../utils/index';

import { ActionCommentButtons } from './ActionCommentButtons/ActionCommentButtons';
import { EmojiSelect } from './EmojiSelect/EmojiSelect';
import { Files } from './Files/Files';
import { Reply } from './Reply/Reply';
import { Content } from './Content/Content';
import { useEffect, useRef, useState, memo } from 'react';
import { useChatContext } from '../../context/ChatContext';
import { TimeMessageMemo } from '../../TimeMessage/TimeMessage';
import { AvatarCustom } from '../../../AvatarCustom/AvatarCustom';
import { User } from '../../../../graphql/generated/graphql';

type CommentItemType = {
  message?: MessageChatType;
  wrapperHeight: number;
};

const CommentItemLocal = ({ message, wrapperHeight }: CommentItemType) => {
  const targetRef = useRef<HTMLDivElement>(null);

  const [isEditContent, setIsEditContent] = useState(false);
  const { replayId, setReplayId, setReply } = useChatContext();

  if (!message) return <></>;

  const { author, id: messageId, content, reactions, attachments } = message;

  const [changeBackground, setChangeBackground] = useState(false);

  useEffect(() => {
    const element = targetRef.current;
    if (element && replayId === message.id) {
      const checkVisibilityAndExecute = () => {
        if (!replayId || replayId !== message?.id) {
          return;
        }
        setChangeBackground(true);
        if (isElementInView(element, wrapperHeight)) {
          setTimeout(() => {
            setChangeBackground(false);
            setReplayId(null);
          }, 300);
        } else {
          setTimeout(checkVisibilityAndExecute, 100);
        }
      };

      checkVisibilityAndExecute();
    }
  }, [replayId, message?.id]);

  const theme = useTheme();

  const [isHovering, setIsHovering] = useState(false);
  const hanldeOnMouseEnter = () => {
    setIsHovering(true);
  };

  const hanldeOnMouseLeave = () => {
    setIsHovering(false);
  };

  const handleReplay = () => {
    setReply(message);
  };

  return (
    <Stack
      ref={targetRef}
      sx={{
        padding: ' 8px 8px 16px 8px',
        backgroundColor: changeBackground
          ? `${theme.palette.neutralPlainHoverBg.main} !important`
          : '',
      }}
      direction={'row'}
      id={`${message.id}`}
      onMouseEnter={hanldeOnMouseEnter}
      onMouseLeave={hanldeOnMouseLeave}
    >
      <Stack sx={{ marginRight: '14px' }}>
        <AvatarCustom
          user={author as User}
          sx={{ height: '32px', width: '32px', marginTop: '6px', marginRight: '0px !important' }}
        />
      </Stack>
      <StackUserStyle>
        <StackHeaderStyle>
          <Stack direction={'row'}>
            <Typography color='textPrimary.main' variant='h5'>
              {userNameCalc({
                email: author.email,
                firstName: author.firstName,
                lastName: author.lastName,
              })}
            </Typography>
            <TypographyDotStyle>&middot;</TypographyDotStyle>
            <Typography variant='h5' fontWeight={500} color='textTertiary.main'>
              {author.karmaStatistics?.karma} кармы
            </Typography>
            <TypographyDotStyle>&middot;</TypographyDotStyle>
            <TimeMessageMemo time={message.updatedAt} />
          </Stack>
          <ActionCommentButtons
            messageId={messageId}
            authorId={author.id}
            isHovering={isHovering}
            handleSetIsEditContent={() => {
              setIsEditContent(true);
            }}
            handleReplay={handleReplay}
            hanldeOnMouseLeave={hanldeOnMouseLeave}
          />
        </StackHeaderStyle>
        {message?.repliedToMessage && <Reply reply={message?.repliedToMessage} />}

        <Content
          content={content}
          isEditContent={isEditContent}
          setIsEditContent={setIsEditContent}
          messageId={messageId}
        />
        <Files attachments={attachments} authorId={message.author.id} />
        <EmojiSelect reactions={reactions} messageId={messageId} />
      </StackUserStyle>
    </Stack>
  );
};

export const CommentItem = memo(CommentItemLocal);
