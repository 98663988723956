import { gql } from '@apollo/client';

export const THREAD_HISTORY = gql`
  query threadHistory($id: Int!) {
    thread(id: $id) {
      id
      history {
        logs {
          id
          title
        }
        maxCreatedAt
        user {
          id
          email
          firstName
          lastName
          avatar {
            fileName
            path
          }
          karmaStatistics {
            karma
          }
        }
      }
    }
  }
`;
