import { Stack } from '@mui/material';
import { StyledTableCell } from '../../../../style';
import { TextFieldRHF } from '../../../../../uiComponents';
import { useForm, FormProvider, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThreadSchema } from '../../../../../../validation';
import { SizeOptions } from '../../../../../../types/enum';
import { BoxOutherStyle, StackInnerStyle, TypographyStyle } from './style';
import { useClickOutside } from '../../../../../../hooks';
import {
  useCreateGoalMutation,
  useUpdateGoalMutation,
} from '../../../../../../graphql/generated/graphql';
import { useWorkspaceContext } from '../../../../../../context';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FOLDER } from '../../../../../../graphql/queries/folder.gql';

type NameCellType = {
  setCreateGoal: (value: boolean) => void;
  update?: boolean;
  goalId?: number;
  goalTitle?: string;
};

export const NameCreateCell = ({
  setCreateGoal,
  update = false,
  goalId,
  goalTitle = '',
}: NameCellType) => {
  const { selectFolderId } = useWorkspaceContext();

  const [createGoalMutation] = useCreateGoalMutation({
    refetchQueries: [FOLDER],
  });
  const [updateGoalMutation] = useUpdateGoalMutation({ refetchQueries: [FOLDER] });

  const { refClickOutside } = useClickOutside({
    callBack: () => {
      const validateAndSubmit = async () => {
        const isValid = await trigger();
        if (isValid) {
          const currentValues = getValues();
          if (update) {
            updateGoalMutation({
              variables: {
                updateGoalInput: {
                  id: Number(goalId),
                  title: currentValues.title,
                  folderId: Number(selectFolderId),
                },
              },
            });
          } else {
            createGoalMutation({
              variables: {
                createGoalInput: {
                  title: currentValues.title,
                  folderId: Number(selectFolderId),
                },
              },
            });
          }
        } else {
          reset({ title: '' });
        }
      };

      validateAndSubmit();
      setCreateGoal(false);
    },
  });

  type FormData = {
    title: string;
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(ThreadSchema) as Resolver<FormData>,
    defaultValues: {
      title: goalTitle,
    },
  });
  const { getValues, trigger, reset } = methods;

  return (
    <StyledTableCell>
      <FormProvider {...methods}>
        <Stack direction={'row'} alignItems={'center'} ref={refClickOutside}>
          <BoxOutherStyle>
            <StackInnerStyle direction={'row'}>
              <ArrowDropDownIcon />
              <TextFieldRHF
                fullWidth
                placeholder='Введите название'
                minRows={1}
                name='title'
                multiline
                size={SizeOptions.small}
              />
            </StackInnerStyle>
          </BoxOutherStyle>
          <TypographyStyle sx={{ opacity: '0' }}>fix</TypographyStyle>
        </Stack>
      </FormProvider>
    </StyledTableCell>
  );
};
