import { useWorkspaceContext } from '../context';
import { useWorkspaceQuery, WorkspaceMemberRole } from '../graphql/generated/graphql';

export const useWorkspace = () => {
  const { workspaceId, setWorkspaceId, workspaces } = useWorkspaceContext();
  const getWorkspaceById = (id?: number) => {
    return workspaces?.find((workspace) => workspace.id === id);
  };
  const { data, loading, refetch } = useWorkspaceQuery({
    variables: {
      workspaceId: Number(workspaceId),
    },
  });
  const currentWorkspace = data?.workspace;
  const handleWorkspace = (id?: number) => {
    setWorkspaceId(id);
  };
  const myRole = currentWorkspace?.myRole as WorkspaceMemberRole;
  return {
    getWorkspaceById,
    currentWorkspace,
    workspaces,
    handleWorkspace,
    myRole,
    loading,
    refetch,
  };
};
