import { Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useClickOutside } from '../../../hooks';
import { TextFieldRHF } from '../../index';
import { useForm, FormProvider } from 'react-hook-form';
import {
  StackWrapperStyle,
  StackResultStyle,
  StackWrapperInnerStyle,
  FlagRoundedIconStyle,
} from './style';
import { SizeOptions } from '../../../types/enum';
import { UpdateThreadMutationFn } from '../../../graphql/generated/graphql';

type EditResultType = {
  updateThreadMutation: UpdateThreadMutationFn;
  threadId?: number;
  result?: string | null;
};

export const EditResult = ({ updateThreadMutation, threadId, result }: EditResultType) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isInitDisable, setIsInitDisable] = useState(true);

  type FormDataType = {
    result: string | null;
  };
  const methods = useForm<FormDataType>({
    defaultValues: {
      result: '',
    },
  });

  const { refClickOutside } = useClickOutside({
    callBack: () => {
      setIsEdit(false);
    },
  });

  const { getValues, reset, watch } = methods;

  const resultWatch = watch('result');

  useEffect(() => {
    setIsInitDisable(false);

    if (result === resultWatch) return;
    if (isInitDisable || !threadId) return;
    const currentValues = getValues();

    updateThreadMutation({
      variables: {
        data: {
          id: threadId,
          result: currentValues.result,
        },
      },
    });
  }, [isEdit]);

  useEffect(() => {
    if (result) {
      reset({
        result: result,
      });
    }
  }, [result]);

  const theme = useTheme();

  return (
    <FormProvider {...methods}>
      <StackWrapperStyle
        onClick={() => {
          setIsEdit(true);
        }}
        ref={refClickOutside}
        sx={{
          '&:hover': {
            backgroundColor: isEdit ? `${theme.palette.white.main} !important` : '',
          },
        }}
      >
        {isEdit ? (
          <StackWrapperInnerStyle>
            <FlagRoundedIconStyle fill={theme.palette.textIcon.main} />
            <TextFieldRHF
              name={'result'}
              placeholder='Добавьте результат...'
              multiline
              size={SizeOptions.small}
              sx={{
                color: theme.palette.textTertiary.main,
                '& textarea': {
                  lineHeight: '142%',
                },
                '& .MuiInputBase-input::placeholder': {
                  color: theme.palette.textTertiary.main,
                  opacity: '1',
                },
              }}
            />
          </StackWrapperInnerStyle>
        ) : (
          <StackResultStyle>
            <FlagRoundedIconStyle fill={theme.palette.neutralPlainDisabledColor.main} />
            <Typography sx={{ color: theme.palette.textTertiary.main, whiteSpace: 'pre-wrap' }}>
              {resultWatch ? resultWatch : 'Добавьте результат...'}
            </Typography>
          </StackResultStyle>
        )}
      </StackWrapperStyle>
    </FormProvider>
  );
};
