import { Stack, Typography, useTheme } from '@mui/material';
import { useWorkspaceContext } from '../../context';
import { PositionedPopper, CustomSwitch, AutocompliteUsers } from '../index';
import LockIcon from '@mui/icons-material/Lock';
import { useForm, FormProvider } from 'react-hook-form';
import { MemberTypes, OutsideClickFlagType } from '../../types/enum';
import { useInitDate, useUpdateVisabilityStatus } from './hook/index';
import { memo, useEffect, useState } from 'react';
import {
  StackWrapperOuterStyle,
  StackWrapperSelectStyle,
  StackMemberWrapperStyle,
  ButtonStyle,
} from './style';
import {
  useAddMembersToFolderMutation,
  useAddMembersToProjectMutation,
  UserOrUserGroupInput,
  WorkspaceMemberRole,
} from '../../graphql/generated/graphql';
import { MemberItem } from './MemberItem/MemberItem';

export type UserType = {
  title: string;
  value: number;
  type: MemberTypes;
  avatar?: {
    path: string;
  } | null;
};

const EditAccessMemo = () => {
  const { selectFolderId, selectProjectId, currentWorkspace } = useWorkspaceContext();

  const theme = useTheme();
  const [isOpen, isSetOpen] = useState(false);

  const { options, initValue, visibilityStatus, title } = useInitDate();

  const isShowButton =
    currentWorkspace?.myRole === WorkspaceMemberRole.Admin ||
    currentWorkspace?.myRole === WorkspaceMemberRole.Creator;

  type FormDataType = {
    title: string;
    visibilityStatus: boolean;
    users?: Array<UserType> | null;
  };

  const methods = useForm<FormDataType>({
    defaultValues: {
      users: [],
    },
  });

  const { watch, reset, getValues } = methods;

  const visibilityStatusWatch = watch('visibilityStatus');
  const usersWatch = watch('users');

  const [addMembersToFolderMutation] = useAddMembersToFolderMutation({});

  const [addMembersToProjectMutation] = useAddMembersToProjectMutation({});

  useEffect(() => {
    reset({ users: [], visibilityStatus });
  }, [isOpen, visibilityStatus]);

  useUpdateVisabilityStatus({ visibilityStatus, visibilityStatusWatch });

  const onSubmit = () => {
    const currentValues = getValues();

    const transformedArray =
      ((currentValues?.users as Array<UserType>) || [])
        .map((item) => {
          if (item?.type === MemberTypes.user) {
            return { userId: item?.value, userGroupId: null };
          } else if (item?.type === MemberTypes.groups) {
            return { userGroupId: item?.value, userId: null };
          }
        })
        .filter((item) => item) || [];
    if (selectFolderId) {
      addMembersToFolderMutation({
        variables: {
          data: {
            folderId: Number(selectFolderId),
            members: transformedArray as UserOrUserGroupInput[],
          },
        },
      });
    } else {
      addMembersToProjectMutation({
        variables: {
          data: {
            members: transformedArray as UserOrUserGroupInput[],
            projectId: Number(selectProjectId),
          },
        },
      });
    }

    reset({ users: [], visibilityStatus: visibilityStatusWatch });
  };

  const isAccess = watch('visibilityStatus');
  if ((!selectFolderId && !selectProjectId) || !isShowButton) return null;

  return (
    <PositionedPopper
      setOutOpen={isSetOpen}
      ClickComponent={
        <StackWrapperOuterStyle>
          <Typography fontWeight={500} sx={{ color: 'black600' }}>
            Доступ
          </Typography>
        </StackWrapperOuterStyle>
      }
      PopperContent={
        <Stack className={OutsideClickFlagType.outsideClickFlagDefault}>
          <FormProvider {...methods}>
            <Stack sx={{ padding: '16px' }}>
              <Typography variant='h3'>Доступ к {selectFolderId ? 'папке' : 'проекту'}</Typography>
              <StackWrapperSelectStyle>
                <Stack direction='row' alignItems={'center'}>
                  <LockIcon
                    sx={{
                      margin: '0px 8px 0px 0px',
                      color: theme.palette.neutral500.main,
                    }}
                  />
                  <Typography sx={{ color: 'black600' }}>
                    Полный доступ для участников {title}
                  </Typography>
                </Stack>

                <CustomSwitch name='visibilityStatus' sx={{ marginLeft: '16px' }} />
              </StackWrapperSelectStyle>
              {!isAccess && (
                <StackWrapperSelectStyle>
                  <AutocompliteUsers options={options} name='users' placeholder='Поиск по имени' />
                  <ButtonStyle variant='confirm' disabled={!usersWatch?.length} onClick={onSubmit}>
                    Пригласить
                  </ButtonStyle>
                </StackWrapperSelectStyle>
              )}
            </Stack>

            {!isAccess && !!initValue.length && (
              <StackMemberWrapperStyle>
                {initValue?.map((e, index) => {
                  const isLastItem = initValue.length - 1 === index;
                  return <MemberItem key={e.title} member={e} isLastItem={isLastItem} />;
                })}
              </StackMemberWrapperStyle>
            )}
          </FormProvider>
        </Stack>
      }
      sxPopper={{
        marginTop: '10px',
        borderRadius: '16px',
        width: '500px',
      }}
    />
  );
};

export const EditAccess = memo(EditAccessMemo);
