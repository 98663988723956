import { StyledTableCell } from '../../../style';
import { Stack } from '@mui/material';
import { LinearProgressStyle, TypographyStyle } from './style';

type ProgressCellType = {
  threadsStatistics: {
    completedThreadsCount: number;
    completedThreadsPercent: number;
    threadsCount: number;
  };
};

export const ProgressCell = ({ threadsStatistics }: ProgressCellType) => {
  return (
    <StyledTableCell
      width={320}
      sx={{
        maxWidth: '320px',
      }}
    >
      <Stack direction={'row'} alignItems={'center'}>
        <LinearProgressStyle
          variant='determinate'
          value={threadsStatistics.completedThreadsPercent}
        />
        <TypographyStyle sx={{ fontWeight: '500', marginLeft: '16px' }}>
          {threadsStatistics.completedThreadsCount}/{threadsStatistics.threadsCount}
        </TypographyStyle>
      </Stack>
    </StyledTableCell>
  );
};
