import { Stack, styled } from '@mui/material';

export const StackStyleLeft = styled(Stack)(({ theme }) => ({
  width: '50%',
  borderRight: `1px solid ${theme.palette.neutral200.main}`,
  padding: '0px 32px',
  maxHeight: 'calc(100vh - 72px)',
  overflowY: 'auto',
}));
export const StackEditItemStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  height: '40px',
  borderBottom: `1px solid ${theme.palette.backgroundLevel1.main}`,
}));
export const StackEditItemDescriptionStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.backgroundLevel1.main}`,
  padding: '32px 0px',
}));

export const StackEditableNameStyle = styled(Stack)(() => ({
  minWidth: '140px',
  flexDirection: 'row',
  alignItems: 'center',
}));
export const StackEditableContentStyle = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  borderRadius: '6px',
  padding: '6px 8px',
  '&:hover': {
    backgroundColor: theme.palette.tableHover.main,
  },
}));

export const StackBreadcrumbsStyle = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: '16px 32px',
}));

export const StackEditableResultStyle = styled(StackEditableNameStyle)(() => ({
  marginTop: '10px',
  marginBottom: 'auto',
  padding: '0px',
}));
