import { gql } from '@apollo/client';

export const PROJECTS = gql`
  query projects($options: FindAllOptionsEntity!) {
    projects(options: $options) {
      id
      title
      authorId
      visibilityStatus
      folders {
        id
        title
        authorId
        visibilityStatus
        goals {
          id
          title
          threads {
            id
            title
          }
        }
      }
    }
  }
`;
