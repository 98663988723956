import { Box, Stack, styled } from '@mui/material';

export const StackStyle = styled(Stack)({
  margin: '2px 3px 3px',
});

export const BoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '28px',
  borderRadius: '6px',
  cursor: 'pointer',
  paddingLeft: '8px',
  '&:hover': {
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
  },
}));
