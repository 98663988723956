import {
  useCreateUserGroupMutation,
  useUserGroupsQuery,
  WorkspaceMemberRole,
} from '../../../../../../../../../graphql/generated/graphql';
import { useWorkspace, useClickOutside } from '../../../../../../../../../hooks';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react';
import { SizeOptions } from '../../../../../../../../../types/enum';
import CancelIcon from '@mui/icons-material/Cancel';
import { TextFieldStyle } from './style';
import { FormProvider, useForm } from 'react-hook-form';
import { GroupsColumnItem } from './components/GroupsColumnItem/GroupsColumnItem';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateUserGroupSchema } from '../../../../../../../../../validation';
import { USER_GROUPS } from '../../../../../../../../../graphql/queries/userGroups.gql';

interface IFormData {
  title: string;
}

type GroupColumnType = {
  handleSelectUserGroup: (id: number) => void;
  hasPermission: WorkspaceMemberRole[];
  selectUserGroupId: number | null;
};
export const GroupsColumn = ({
  handleSelectUserGroup,
  selectUserGroupId,
  hasPermission,
}: GroupColumnType) => {
  const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
  const theme = useTheme();
  const { currentWorkspace, myRole } = useWorkspace();
  const { data } = useUserGroupsQuery({
    variables: {
      data: { workspaceId: currentWorkspace!.id },
    },
  });
  const handleCreateMode = () => setIsCreateMode((prevState) => !prevState);
  const userGroups = data?.userGroups;
  const isHasUserGroups = !!userGroups?.length;

  const methods = useForm<IFormData>({
    resolver: yupResolver(CreateUserGroupSchema),
    defaultValues: {
      title: '',
    },
  });
  const { getValues, trigger, reset } = methods;
  const [useCreateUserGroup] = useCreateUserGroupMutation({
    refetchQueries: [USER_GROUPS],
  });

  const { refClickOutside } = useClickOutside({
    callBack: () => {
      const currentValues = getValues();
      const validateAndSubmit = async () => {
        const isValid = await trigger();
        if (isValid) {
          useCreateUserGroup({
            variables: {
              createUserGroupInput: {
                title: currentValues.title,
                workspaceId: currentWorkspace!.id,
              },
            },
          });
          reset();
        }
        handleCreateMode();
      };

      validateAndSubmit();
    },
  });
  const isHasPermission = hasPermission.includes(myRole);
  return (
    <Stack flex={1} mr='16px'>
      {!isHasUserGroups && !isCreateMode && (
        <Typography variant='h4' mb='16px'>
          Группы еще не созданы
        </Typography>
      )}
      {isHasUserGroups &&
        userGroups?.map((userGroup, index) => (
          <GroupsColumnItem
            isHasPermission={isHasPermission}
            title={userGroup.title}
            id={userGroup.id}
            select={userGroup.id === selectUserGroupId}
            handleSelectUserGroup={handleSelectUserGroup}
            key={index}
          />
        ))}
      {isCreateMode && (
        <Box ref={refClickOutside}>
          <FormProvider {...methods}>
            <TextFieldStyle
              name='title'
              type='text'
              autoFocus
              placeholder='Новая группа'
              size={SizeOptions.small}
              InputProps={{
                endAdornment: (
                  <CancelIcon
                    onClick={handleCreateMode}
                    sx={{
                      cursor: 'pointer',
                      color: theme.palette.textIcon.main,
                    }}
                  />
                ),
              }}
            />
          </FormProvider>
        </Box>
      )}
      {isHasPermission && (
        <Button
          size='small'
          variant='outlined'
          onClick={handleCreateMode}
          sx={{ minWidth: '135px', marginTop: '16px' }}
        >
          <AddIcon />
          <Typography variant='h4' fontWeight='500' color='black600' ml='6px'>
            Создать группу
          </Typography>
        </Button>
      )}
    </Stack>
  );
};
