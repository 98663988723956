import { createTheme } from '@mui/material/styles';

export const Theme = createTheme({
  palette: {
    neutralSoftBg: {
      main: '#0E0E0E',
    },
    white: {
      main: '#FFF',
    },
    lightHover: { main: '#fdfdfd' },
    orangeColor: {
      main: '#EA9A3E',
    },
    red: '#C41C1C',
    green: '#51BC51',
    redHover: '#C62828',
    error: {
      main: '#C41C1C',
    },
    neutralPlainDisabledColor: {
      main: '#9FA6AD',
    },
    textTertiary: {
      main: '#555E68',
    },
    boxShadowPopper: {
      main: '5.3px 13.3px 18.8px -1.2px rgba(187, 187, 187, 0.29), 2.4px 6.1px 8.6px -0.8px rgba(187, 187, 187, 0.23), 1.1px 2.8px 3.9px -0.4px rgba(187, 187, 187, 0.17), 0.3px 0.8px 1.1px 0px rgba(187, 187, 187, 0.12)',
    },
    neutralOutlinedBorder: { main: '#CDD7E1' },
    underlineColor: {
      main: 'rgba(99, 107, 116, 0.6)',
    },
    neutralLightChannel: {
      main: 'rgba(221, 231, 238, 0.6)',
    },
    tableHover: {
      main: 'rgba(221, 231, 238, 0.60)',
    },
    borderDark: 'rgba(50, 56, 62, 0.60)',
    imageFill: {
      main: '#D9D9D9',
    },
    backgroundSurface: {
      main: '#FBFCFE',
    },
    neutralPlainHoverBg: {
      main: '#F0F4F8',
    },
    neutral200: {
      main: '#DDE7EE',
    },
    neutral500: {
      main: '#636B74',
    },
    backgroundLevel1: {
      main: '#F0F4F8',
    },
    backgroundBody: {
      main: '#FFFFFF',
    },
    textPrimary: {
      main: '#171A1C',
    },
    black600: '#32383E',
    black900: '#0B0D0E',
    avatarBlue: {
      main: '#0B6BCB',
    },
    thumbColor: {
      main: '#BDBDBD',
    },
    joyShadow: {
      main: '0px 1px 2px 0px rgba(187, 187, 187, 0.12)',
    },
    joyShadoSm: {
      main: '1.1px 2.7px 3.8px -1.2px rgba(187, 187, 187, 0.26), 0.5px 1.3px 1.8px -0.6px rgba(187, 187, 187, 0.18), 0.3px 0.8px 1.1px 0px rgba(187, 187, 187, 0.11)',
    },
    textIcon: { main: '#636B74' },
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '20px',
          height: '20px',
          margin: '2px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          '::-webkit-scrollbar': {
            width: '7px',
          },
          '::-webkit-scrollbar:horizontal': {
            height: '7px',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#BDBDBD',
            borderRadius: '10px',
            border: 'none',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          '& .MuiOutlinedInput-root': {
            padding: '0px',
          },
        },
      },
    },

    MuiSelect: {
      variants: [
        {
          props: { variant: 'outlined', size: 'small' },
          style: ({ theme }) => ({
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '142%',
            width: 'fit-content',

            '& .MuiSelect-select[aria-expanded="true"]': {
              backgroundColor: theme.palette.backgroundLevel1.main,
            },
            '&[aria-expanded="true"]': {
              color: theme.palette.red,
            },
            '& .MuiSelect-select': {
              paddingRight: '8px',
              border: `1px solid  ${theme.palette.neutralOutlinedBorder.main}`,
            },
            '& .MuiSelect-select.MuiInputBase-input': {
              paddingRight: '8px !important',
              border: `1px solid  ${theme.palette.neutralOutlinedBorder.main}`,
            },
            '& .MuiSelect-iconOutlined': {
              display: 'none',
            },
            '& .MuiInputBase-input': {
              padding: '6px  8px',
            },
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
          }),
        },
        {
          props: { variant: 'outlined', size: 'medium' },
          style: ({ theme }) => ({
            width: '140px',
            height: '36px',
            padding: '6px 8px',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
            cursor: 'pointer',
            '& .MuiSelect-select': {
              padding: '0px',
            },
            '& fieldset': {
              border: 'none',
            },
          }),
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        root: (props) => ({
          '& .MuiTab-root.Mui-selected': { color: props.theme.palette.black600 },
          '& .MuiTabs-indicator': {
            borderRadius: '0px',
            borderBottom: `2px solid ${props.theme.palette.black600}`,
          },
          minHeight: '32px',
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: (props) => ({
          padding: '0px',
          marginRight: '16px',
          color: props.theme.palette.neutralPlainDisabledColor.main,
          textTransform: 'capitalize',
          minHeight: '32px',
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: (props) => ({
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '142%',
          color: props.theme.palette.textTertiary.main,
        }),
      },
      variants: [
        {
          props: { variant: 'h5' },
          style: (props) => ({
            fontSize: '12px',
            fontWeight: 600,
            color: props.theme.palette.black600,
          }),
        },
        {
          props: { variant: 'h3' },
          style: (props) => ({
            fontSize: '16px',
            fontWeight: 600,
            color: props.theme.palette.black600,
          }),
        },
        {
          props: { variant: 'customDefault' },
          style: {
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '142%',
          },
        },
        {
          props: { variant: 'h1' },
          style: (props) => ({
            fontWeight: '600',
            fontSize: '24px',
            marginTop: '24px',
            marginBottom: '8px',
            color: props.theme.palette.textPrimary.main,
          }),
        },
      ],
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 40,
          height: 20,
          padding: 0,
          display: 'flex',
          '&:active': {
            '& .MuiSwitch-thumb': {
              width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              transform: 'translateX(9px)',
            },
          },
        },
        switchBase: ({ theme }) => ({
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(24px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.neutral500.main,
            },
            '& .MuiSwitch-thumb': {
              opacity: 1,
              backgroundColor: theme.palette.white.main,
            },
          },
        }),
        thumb: ({ theme }) => ({
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 12,
          height: 12,
          borderRadius: 6,
          marginTop: 2,
          backgroundColor: theme.palette.neutralPlainDisabledColor.main,
          transition: theme.transitions.create(['width'], { duration: 200 }),
        }),
        track: ({ theme }) => ({
          borderRadius: 20,
          opacity: 1,
          backgroundColor: theme.palette.backgroundLevel1.main,
          boxSizing: 'border-box',
        }),
      },
    },
    MuiButtonGroup: {
      variants: [
        {
          props: { variant: 'table', size: 'medium' },
          style: (props) => ({
            boxSizing: 'border-box',
            padding: '2px',
            height: '36px',
            borderRadius: '6px',
            backgroundColor: props.theme.palette.backgroundLevel1.main,
          }),
        },
      ],
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
    MuiTextField: {
      defaultProps: { variant: 'outlined', size: 'large' },
      variants: [
        {
          props: { variant: 'outlined', size: 'large', type: 'password' },
          style: ({ theme }) => ({
            '& .MuiOutlinedInput-root': {
              fontFamily: 'Inter',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '155%',
              '&.Mui-error, &.Mui-focused.Mui-error': {
                color: theme.palette.red,
                borderColor: theme.palette.red,
              },
              paddingLeft: 0,
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
          }),
        },
        {
          props: { variant: 'outlined', size: 'medium', type: 'text' },
          style: ({ theme }) => ({
            '& .MuiOutlinedInput-root': {
              fontFamily: 'Inter',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '155%',
              paddingLeft: 0,
              '&.Mui-error, &.Mui-focused.Mui-error': {
                color: theme.palette.red,
                borderColor: theme.palette.red,
              },
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
          }),
        },
        {
          props: { variant: 'outlined', size: 'small', type: 'text' },
          style: ({ theme }) => ({
            '& .MuiOutlinedInput-root': {
              padding: '0px',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '155%',
              paddingLeft: 0,
              '&.Mui-error, &.Mui-focused.Mui-error': {
                color: theme.palette.red,
                borderColor: theme.palette.red,
              },
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
          }),
        },
        {
          props: { variant: 'outlined', size: 'large', type: 'text' },
          style: (props) => ({
            '& .MuiOutlinedInput-root': {
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '142%',
              borderRadius: '8px',
              border: `1px solid ${props.theme.palette.neutralOutlinedBorder.main}`,
              boxShadow: props.theme.palette.joyShadow.main,
              background: '#FBFCFE',
              padding: '6px  8px',
              justifyContent: 'center',
              alignItems: 'center',
              '&.Mui-error, &.Mui-focused.Mui-error': {
                color: props.theme.palette.red,
                borderColor: props.theme.palette.red,
              },
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },

              '& .MuiOutlinedInput-input': {
                padding: 0,
              },
            },
          }),
        },
        // conflict default style input
        {
          props: { variant: 'outlined', size: 'extraLarge' },
          style: (props) => ({
            '& .MuiOutlinedInput-root': {
              '& input::placeholder': {
                fontSize: '16px',
              },
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '155%',
              border: `1px solid ${props.theme.palette.neutralOutlinedBorder.main}`,
              height: '40px',
              background: props.theme.palette.backgroundSurface.main,
              borderRadius: '6px',
              justifyContent: 'center',
              alignItems: 'center',
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused': {
                border: `2px solid ${props.theme.palette.black600}`,
              },
              '&.Mui-error, &.Mui-focused.Mui-error': {
                color: props.theme.palette.red,
                borderColor: props.theme.palette.red,
              },
              '& .MuiOutlinedInput-input': {
                padding: 0,
                height: '100%',
                paddingLeft: '12px',
              },
            },
          }),
        },
        {
          props: { variant: 'outlined', size: 'medium', type: 'password' },
          style: (props) => ({
            '& .MuiOutlinedInput-root': {
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '155%',
              border: `1px solid ${props.theme.palette.neutralOutlinedBorder.main}`,
              height: '40px',
              background: props.theme.palette.backgroundSurface.main,
              borderRadius: '4px',
              justifyContent: 'center',
              alignItems: 'center',
              '&.Mui-error, &.Mui-focused.Mui-error': {
                color: props.theme.palette.red,
                borderColor: props.theme.palette.red,
              },
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                padding: '0px',
                height: '45px',
                border: `2px solid ${props.theme.palette.black600}`,
              },
              '& .MuiOutlinedInput-input': {
                padding: 0,
                height: '100%',
                paddingLeft: '12px',
              },
            },
          }),
        },
      ],
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'popper' },
          style: (props) => ({
            width: 'fit-content',
            border: `1px solid ${props.theme.palette.neutralOutlinedBorder.main}`,
            height: '34px',
            fontWeight: '400',
            color: props.theme.palette.textTertiary.main,
            whiteSpace: 'nowrap ',
            fontSize: '14px',
            textTransform: 'capitalize',
            borderRadius: '6px',
            '&.MuiButton-root': {
              padding: '6px 8px',
            },
          }),
        },
        {
          props: { variant: 'outlined' },
          style: (props) => ({
            width: 'fit-content',
            border: `1px solid ${props.theme.palette.neutralOutlinedBorder.main}`,
            height: '32px',
            fontWeight: '400',
            color: props.theme.palette.black600,
            whiteSpace: 'nowrap ',
            fontSize: '14px',
            textTransform: 'none',
            borderRadius: '6px',
            '&.MuiButton-root': {
              padding: '6px 12px',
            },
            '&:hover': {
              border: `1px solid ${props.theme.palette.neutralOutlinedBorder.main}`,
            },
          }),
        },
        {
          props: { variant: 'confirm', size: 'medium' },
          style: (props) => ({
            minWidth: '105px',
            maxHeight: '28px',
            textTransform: 'initial',
            fontWeight: '500',
            borderRadius: '6px',
            boxSizing: 'border-box',
            backgroundColor: props.theme.palette.black600,
            color: props.theme.palette.white.main,
            padding: '2px 12px',
            '&.MuiButton-root': {
              '&:hover': {
                color: props.theme.palette.black900,
              },
            },
            '&.Mui-disabled': {
              backgroundColor: props.theme.palette.neutralPlainHoverBg.main,
              color: props.theme.palette.neutralPlainDisabledColor.main,
            },
          }),
        },
        {
          props: { variant: 'cancel', size: 'medium' },
          style: (props) => ({
            maxHeight: '28px',
            textTransform: 'initial',
            fontWeight: '500',
            borderRadius: '6px',
            boxSizing: 'border-box',
            padding: '2px 12px',
            backgroundColor: props.theme.palette.white.main,
            color: props.theme.palette.black600,
          }),
        },
        {
          props: { variant: 'default', size: 'medium' },
          style: (props) => ({
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '14px',
            color: props.theme.palette.black600,
            borderRadius: '6px',
            background: props.theme.palette.neutralPlainHoverBg.main,
            textTransform: 'capitalize',
          }),
        },
        {
          props: { variant: 'text', size: 'medium' },
          style: (props) => ({
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            lineHeight: '14px',
            color: props.theme.palette.black600,
            padding: 0,
            fontWeight: '500',
            textTransform: 'none',
            width: 'fit-content',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
              textDecorationColor: props.theme.palette.underlineColor.main,
            },
          }),
        },
        {
          props: { variant: 'drawer', size: 'medium' },
          style: () => ({
            width: '108px',
            height: '32px',
            borderRadius: '16px',
            background:
              'linear-gradient(69deg,rgba(0, 0, 0, 0.8) 27.94%,rgba(43, 43, 43, 0.8) 50.25%,rgba(0, 0, 0, 0.8) 74.13%)',
          }),
        },
        {
          props: { variant: 'tableFilter', size: 'medium' },
          style: (props) => ({
            textTransform: 'none',
            height: '32px',
            boxSizing: 'border-box',
            backgroundColor: props.theme.palette.backgroundLevel1.main,
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '142%',
            marginRight: '1px',
            borderRadius: '6px !important',
            '&:hover': {
              borderRadius: '6px',
              backgroundColor: props.theme.palette.backgroundBody.main,
            },
          }),
        },
        {
          props: { variant: 'white', size: 'medium' },
          style: (props) => ({
            textTransform: 'none',
            height: '32px',
            boxSizing: 'border-box',
            backgroundColor: props.theme.palette.white.main,

            '&:hover': {
              borderRadius: '6px',
              backgroundColor: props.theme.palette.backgroundLevel1.main,
            },
          }),
        },
        {
          props: { variant: 'icons' },
          style: (props) => ({
            minWidth: '32px',
            height: '32px',
            paddingLeft: '2px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0',
            '&:hover': {
              backgroundColor: props.theme.palette.neutralPlainHoverBg.main,
            },
          }),
        },
        {
          props: { variant: 'contained', size: 'small' },
          style: (props) => ({
            width: 'fit-content',
            padding: '6px 12px',
            height: '32px',
            background: props.theme.palette.black600,
            color: props.theme.palette.white.main,
            fontWeight: '500',
            fontSize: 14,
            borderRadius: '4px',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'none',
            '&:hover': {
              background: props.theme.palette.black900,
            },
          }),
        },
        {
          props: { variant: 'contained', size: 'medium' },
          style: (props) => ({
            width: 'fit-content',
            height: '40px',
            background: props.theme.palette.black600,
            color: props.theme.palette.white.main,
            fontWeight: '500',
            fontSize: 14,
            borderRadius: '6px',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'none',
            '&:hover': {
              background: props.theme.palette.black900,
            },
          }),
        },
      ],
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: `250px`,
          flexShrink: 0,
          boxSizing: 'border-box',
        },
        paper: {
          width: `250px`,
          boxSizing: 'border-box',
          padding: '20px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: (props) => ({
          width: '20px',
          height: '20px',
          fontSize: '12px',
          backgroundColor: props.theme.palette.avatarBlue.main,
          alignItems: 'center',
          justifyContent: 'center',
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: (props) => ({
          '.MuiFormControlLabel-label': {
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '142%',
            marginLeft: '8px',
            color: props.theme.palette.black900,
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: 'inherit',
          width: '100%',
        },
        input: (props) => ({
          padding: props.theme.spacing(1, 1, 1, 0),
          paddingLeft: `calc(1em + ${props.theme.spacing(3)})`,
          transition: props.theme.transitions.create('width'),
        }),
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: (props) => ({
          justifyContent: 'center',
          alignItems: 'center',
          background: props.theme.palette.black600,
          border: `1px solid ${props.theme.palette.neutralOutlinedBorder.main}`,
          borderRadius: '6px',
          height: '6px',
          '& .MuiLinearProgress-bar': {
            background: props.theme.palette.neutralPlainHoverBg.main,
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: (props) => ({
          fontWeight: '500',
          fontSize: '14px',
          color: props.theme.palette.black600,
          '&:hover': {
            textDecorationColor: props.theme.palette.underlineColor.main,
          },
        }),
      },
    },
  },
});

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    customDefault: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    small: true;
    medium: true;
    large: true;
    extraLarge: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    drawer: true;
    default: true;
    tableFilter: true;
    confirm: true;
    popper: true;
    white: true;
    cancel: true;
    icons: true;
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsVariantOverrides {
    table: true;
  }
}
declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    neutralSoftBg?: {
      main: string;
    };
    white?: { main: string };
    backgroundSurface?: { main: string };
    neutralOutlinedBorder?: { main: string };
    neutralPlainHoverBg?: { main: string };
    avatarBlue?: { main: string };
    neutral200?: {
      main: string;
    };
    orangeColor?: {
      main: string;
    };
    borderDark?: string;
    red?: string;
    green?: string;
    redHover?: string;
    lightHover?: {
      main: string;
    };
    tableHover?: {
      main: string;
    };
    textIcon?: {
      main: string;
    };
    textTertiary?: {
      main: string;
    };
    boxShadowPopper?: {
      main: string;
    };
    thumbColor?: {
      main: string;
    };
    black600?: string;
    black900?: string;
    textPrimary?: {
      main: string;
    };
    neutral500?: {
      main: string;
    };
    backgroundLevel1?: {
      main: string;
    };
    backgroundBody?: {
      main: string;
    };
    neutralPlainDisabledColor?: {
      main: string;
    };
    neutralLightChannel?: {
      main: string;
    };
    joyShadow?: {
      main: string;
    };
    underlineColor?: {
      main: string;
    };
    joyShadoSm?: {
      main: string;
    };
    imageFill?: {
      main: string;
    };
  }
  interface Palette {
    neutralSoftBg: Palette['primary'];
    neutralOutlinedBorder: Palette['primary'];
    neutralPlainHoverBg: Palette['primary'];
    neutral200: Palette['primary'];
    neutral500: Palette['primary'];
    textIcon: Palette['primary'];
    textPrimary: Palette['primary'];
    backgroundLevel1: Palette['primary'];
    backgroundBody: Palette['primary'];
    neutralPlainDisabledColor: Palette['primary'];
    backgroundSurface: Palette['primary'];
    white: Palette['primary'];
    black600: string;
    black900: string;
    borderDark: string;
    avatarBlue: Palette['primary'];
    boxShadowPopper: Palette['primary'];
    textTertiary: Palette['primary'];
    red: string;
    green: string;
    redHover: string;
    orangeColor: Palette['primary'];
    neutralLightChannel: Palette['primary'];
    underlineColor: Palette['primary'];
    lightHover: Palette['primary'];
    tableHover: Palette['primary'];
    joyShadow: Palette['primary'];
    joyShadoSm: Palette['primary'];
    imageFill: Palette['primary'];
  }
}
