import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { useQueryParams } from '../../hooks';
import { RequestHandler } from '../../components/RequestHandler/RequestHandler';
import {
  useJoinToWorkspaceMutation,
  useWorkspaceAndUserDataByAccessTokenQuery,
} from '../../graphql/generated/graphql';
import { LOCAL_STORAGE_KEY, QUERY_KEY, ROUTES } from '../../constants/constants';
import { useRegistration } from '../../hooks/useRegistration';
import { REGISTRATION_STEP } from '../Registration/const';
import { useAuthContext, useWorkspaceContext } from '../../context';
import { WORKSPACES } from '../../graphql/queries/workspaces';

enum ServerError {
  CURRENT_USER_IS_NOT_INVITED = 'CurrentUserIsNotInvited',
}
export const GuestInvite = () => {
  const queryParams = useQueryParams();
  const { signOut } = useAuthContext();
  const { setWorkspaceId } = useWorkspaceContext();
  const { isAuth } = useAuthContext();
  const navigate = useNavigate();
  const { updateRegistrationData } = useRegistration();
  const token = queryParams.get(QUERY_KEY.INVITE_TOKEN);
  const { data, loading } = useWorkspaceAndUserDataByAccessTokenQuery({
    variables: {
      workspaceAccessToken: token as string,
    },
    onError: (error) => {
      if (error.message === ServerError.CURRENT_USER_IS_NOT_INVITED) {
        signOut();
        navigate(`${ROUTES.guestInvite}?t=${token}`);
      } else {
        navigate(ROUTES.invalidLink);
      }
    },
  });

  const [joinToWorkspaceMutation] = useJoinToWorkspaceMutation({
    variables: {
      token: String(token),
    },
    onCompleted: (data) => {
      setWorkspaceId(data.joinToWorkspace.id);
      localStorage.removeItem(LOCAL_STORAGE_KEY.WORKSPACE_INVITE_TOKEN);
    },
    onError: () => {
      navigate(ROUTES.invalidLink);
    },
    refetchQueries: [WORKSPACES],
  });

  const workspaceData = data?.workspaceAndUserDataByAccessToken;

  const userIsRegister = workspaceData?.isRegister;
  const btnTitle = !userIsRegister && workspaceData?.email ? 'Создать аккаунт' : 'Присоединиться';
  const handleNavigateNextStep = () => {
    if (isAuth) {
      joinToWorkspaceMutation();
      navigate(ROUTES.home);
    }
    if (!workspaceData) return;
    localStorage.setItem(LOCAL_STORAGE_KEY.WORKSPACE_INVITE_TOKEN, String(token));
    if (userIsRegister || (!userIsRegister && !workspaceData?.email)) {
      navigate(ROUTES.login);
    } else {
      updateRegistrationData({
        email: String(workspaceData.email),
        stepName: workspaceData.email
          ? REGISTRATION_STEP.personalDetails
          : REGISTRATION_STEP.atTheBeginning,
        isInvitedUserAccessToken: workspaceData.isInvitedUserAccessToken,
      });
      navigate(ROUTES.registration);
    }
  };

  return (
    <RequestHandler loading={loading}>
      <Stack width='448px' textAlign='center'>
        <Typography variant='h1' mb='8px'>
          {`Вы приглашены в рабочую область ${workspaceData?.workspaceTitle}`}
        </Typography>
        {workspaceData?.email && (
          <Typography variant='h4' textAlign='center'>
            {`Вы принимаете приглашение, высланное на ${workspaceData?.email}`}
          </Typography>
        )}
        <Button
          variant='contained'
          type='submit'
          sx={{ marginTop: '32px', width: '100%' }}
          onClick={handleNavigateNextStep}
        >
          {btnTitle}
        </Button>
      </Stack>
    </RequestHandler>
  );
};
