import { Stack, Typography } from '@mui/material';
import { TabType } from '../../../const';
import { MyDataForm } from './components/MyDataForm/MyDataForm';
import { ResetPassword } from './components/ResetPassword/ResetPassword';

export const MyProfile = ({ handleCloseModal }: TabType) => {
  return (
    <Stack p='24px' flex={1}>
      <Typography variant='h3' mb='24px'>
        Мой профиль
      </Typography>
      <MyDataForm />
      <ResetPassword />
    </Stack>
  );
};
