import {
  useNewChatActivitySubscription,
  useMessagesWithCountQuery,
} from '../../../../graphql/generated/graphql';
import { useApolloClient, ApolloClient } from '@apollo/client';
import { MESSAGES_WITH_COUNT } from '../../../../graphql/queries/messagesWithCount.gql';
import { MessageChatType } from '../../../../types/types';
import { useChatContext } from '../../context/ChatContext';
import { TypeMessageChat } from '../../../../types/enum';
import { useAuthContext } from '../../../../context';

type UseQueryChatType = {
  chatId?: number;
};

type UpdateChatMessagesCacheType = {
  client: ApolloClient<object>;
  newMessage?: MessageChatType | null;
  chatId?: number;
  typeMessage?: string;
};

const updateChatMessagesCache = ({
  client,
  newMessage,
  chatId,
  typeMessage,
}: UpdateChatMessagesCacheType) => {
  const query = {
    query: MESSAGES_WITH_COUNT,
    variables: {
      data: {
        chatId: chatId,
      },
    },
  };

  const data = client.readQuery(query);

  if (data) {
    const existingMessages = data.messagesWithCount.rows;
    const count = data.messagesWithCount.count;
    const existingMessageIndex = existingMessages.findIndex(
      (message: MessageChatType) => message.id === newMessage?.id,
    );

    let updatedMessages;
    if (existingMessageIndex > -1) {
      updatedMessages = existingMessages.map((message: MessageChatType, index: number) =>
        index === existingMessageIndex ? newMessage : message,
      );
    } else if (TypeMessageChat.DELETE_MESSAGE !== typeMessage) {
      updatedMessages = [...existingMessages, newMessage];
    }

    client.writeQuery({
      ...query,
      data: {
        messagesWithCount: {
          count: count,
          __typename: data.messagesWithCount.__typename,
          rows: updatedMessages,
        },
      },
    });
  }
};

export const useQueryChat = ({ chatId }: UseQueryChatType) => {
  const client = useApolloClient();

  const { setTypeMessage } = useChatContext();
  const { currentUser } = useAuthContext();

  useNewChatActivitySubscription({
    variables: {
      chatId: Number(chatId),
    },
    onSubscriptionData: (e) => {
      const newMessage = e.subscriptionData.data?.newChatActivity.message;
      const typeMessage = e.subscriptionData.data?.newChatActivity.type;
      const findMyMessage = () => {
        if (!typeMessage) return '';

        if (
          typeMessage === TypeMessageChat.NEW_MESSAGE &&
          currentUser?.id === newMessage?.author.id
        ) {
          return TypeMessageChat.MY_NEW_MESSAGE;
        }
        return typeMessage;
      };

      setTypeMessage(findMyMessage());
      updateChatMessagesCache({ client, newMessage, chatId, typeMessage });
    },
  });

  const { data } = useMessagesWithCountQuery({
    variables: {
      data: {
        chatId: chatId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const commentList = data?.messagesWithCount?.rows;

  return { commentList };
};
