import { ModalStatuses } from '../../types/enum';
import {
  DefaultModal,
  ProjectModal,
  ProjectDeleteModal,
  FolderDeleteModal,
  ThreadModal,
  GoalDeleteModal,
  ThreadDeleteModal,
  RenameFileModal,
  SingleImageModal,
  CreateWorkspaceModal,
  UniversalModal,
  ListImageModal,
} from './Modals';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { ModalParametersTypes } from '../../types/types';
import { InviteWorkspaceModal } from './Modals/InviteWorkspaceModal/InviteWorkspaceModal';
import { WorkspaceSettings } from './Modals/WorkspaceSettings/WorkspaceSettings';

type ModalLayoutType = {
  children: ReactNode;
  modalParameters: ModalParametersTypes;
  handleCloseModal: () => void;
};

export const ModalLayout = ({ children, modalParameters, handleCloseModal }: ModalLayoutType) => {
  return (
    <Box>
      {children}
      <ModalController modalParameters={modalParameters} handleCloseModal={handleCloseModal} />
    </Box>
  );
};

type ModalControllerType = {
  modalParameters: ModalParametersTypes;
  handleCloseModal: () => void;
};

export const ModalController = ({ modalParameters, handleCloseModal }: ModalControllerType) => {
  switch (modalParameters.type) {
    case ModalStatuses.THREAD_DELETE_MODAl:
      return (
        <DefaultModal handleCloseModal={handleCloseModal} sx={{ width: 400 }}>
          <ThreadDeleteModal
            modalParameters={modalParameters}
            handleCloseModal={handleCloseModal}
          />
        </DefaultModal>
      );
    case ModalStatuses.THREAD_MODAl:
      return (
        <DefaultModal handleCloseModal={handleCloseModal} sx={{ width: 635 }}>
          <ThreadModal modalParameters={modalParameters} handleCloseModal={handleCloseModal} />
        </DefaultModal>
      );
    case ModalStatuses.GOAL_DELETE_MODAL:
      return (
        <DefaultModal handleCloseModal={handleCloseModal} sx={{ width: 360 }}>
          <GoalDeleteModal modalParameters={modalParameters} handleCloseModal={handleCloseModal} />
        </DefaultModal>
      );
    case ModalStatuses.PROJECT_MODAL:
      return (
        <DefaultModal handleCloseModal={handleCloseModal}>
          <ProjectModal modalParameters={modalParameters} handleCloseModal={handleCloseModal} />
        </DefaultModal>
      );
    case ModalStatuses.PROJECT_DELETE_MODAL:
      return (
        <DefaultModal handleCloseModal={handleCloseModal} sx={{ width: 360 }}>
          <ProjectDeleteModal
            modalParameters={modalParameters}
            handleCloseModal={handleCloseModal}
          />
        </DefaultModal>
      );
    case ModalStatuses.FOLDER_DELETE_MODAL:
      return (
        <DefaultModal handleCloseModal={handleCloseModal} sx={{ width: 360 }}>
          <FolderDeleteModal
            modalParameters={modalParameters}
            handleCloseModal={handleCloseModal}
          />
        </DefaultModal>
      );
    case ModalStatuses.RENAME_FILE_MODAL:
      return (
        <DefaultModal handleCloseModal={handleCloseModal} sx={{ width: 360 }}>
          <RenameFileModal
            editindnameData={modalParameters?.editindnameData}
            setEditindnameData={modalParameters?.setEditindnameData}
            handleCloseModal={handleCloseModal}
            type={modalParameters?.renameFileModalType}
          />
        </DefaultModal>
      );
    case ModalStatuses.SINGLE_IMAGE_MODAL:
      return (
        <DefaultModal
          handleCloseModal={handleCloseModal}
          sx={{
            padding: '0px',
            border: 'none',
            borderRadius: '0px',
            backgroundColor: 'transparent',
          }}
        >
          <SingleImageModal singleImagePath={modalParameters.singleImagePath as string} />
        </DefaultModal>
      );
    case ModalStatuses.CREATE_WORKSPACE_MODAl:
      return (
        <UniversalModal handleCloseModal={handleCloseModal} sx={{ width: 560, padding: '24px' }}>
          <CreateWorkspaceModal
            modalParameters={modalParameters}
            handleCloseModal={handleCloseModal}
          />
        </UniversalModal>
      );
    case ModalStatuses.INVITE_WORKSPACE_MODAl:
      return (
        <UniversalModal handleCloseModal={handleCloseModal} sx={{ width: 560, padding: '24px' }}>
          <InviteWorkspaceModal
            modalParameters={modalParameters}
            handleCloseModal={handleCloseModal}
          />
        </UniversalModal>
      );
    case ModalStatuses.SETTINGS_WORKSPACE_MODAl:
      return (
        <UniversalModal handleCloseModal={handleCloseModal} sx={{ width: 900 }}>
          <WorkspaceSettings
            modalParameters={modalParameters}
            handleCloseModal={handleCloseModal}
          />
        </UniversalModal>
      );
    case ModalStatuses.LIST_IMAGE_MODAL:
      return (
        <DefaultModal
          handleCloseModal={handleCloseModal}
          onClick={handleCloseModal}
          sx={{
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
            border: 'none',
          }}
        >
          <ListImageModal modalParameters={modalParameters} handleCloseModal={handleCloseModal} />
        </DefaultModal>
      );
    default:
      return <Box></Box>;
  }
};
