import { TextField, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { SxProps } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import { SizeOptions } from '../../../types/enum';
import React from 'react';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type TextFieldMultilineProps = {
  name: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  sx?: SxProps;
  fullWidth?: boolean;
  placeholder?: string;
  minRows?: number;
  multiline?: boolean;
  size?: OverridableStringUnion<SizeOptions>;
  InputProps?: Partial<OutlinedInputProps>;
  autoFocus?: boolean;
  value?: number | string;
  isDisabled?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export const TextFieldRHF = ({
  name,
  type = 'text',
  sx,
  fullWidth = true,
  isDisabled = false,
  placeholder,
  minRows,
  multiline,
  size = SizeOptions.medium,
  autoFocus,
  InputProps,
  value,
  onKeyDown,
  onChange,
  ...props
}: TextFieldMultilineProps) => {
  const methods = useFormContext();
  const {
    field: { value: valueRHF, ref, onChange: onChangeRHF, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control: methods.control,
  });

  return (
    <TextField
      {...props}
      {...inputProps}
      sx={sx}
      disabled={isDisabled}
      onKeyDown={onKeyDown}
      onChange={onChange ? onChange : onChangeRHF}
      value={value ? value : valueRHF}
      fullWidth={fullWidth}
      inputRef={ref}
      type={type}
      error={!!error}
      helperText={
        error?.message ? (
          <Stack flexDirection='row' alignItems='center'>
            <ErrorOutlineIcon style={{ marginRight: '2px', marginLeft: '-14px' }} />
            {error.message}
          </Stack>
        ) : null
      }
      placeholder={placeholder}
      minRows={minRows}
      multiline={multiline}
      variant='outlined'
      size={size}
      InputProps={InputProps}
      autoFocus={autoFocus}
    />
  );
};
