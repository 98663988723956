import { FolderRow } from './FolderRow/FolderRow';
import { AddRows } from '../AddRows/AddRows';
import { FolderTableType } from '../../../types/types';
import { useState } from 'react';
import { CreateFolderRow } from './FolderRowAdd/FolderRowAdd';
import { TypeTableRow } from '../../../types/enum';

type BodyFolderContentType = {
  emptyCellWidth: string;
  titleCellWidth: string;
  projectFoldersList?: Array<FolderTableType>;
  responsibleCellWidth: string;
  isDraggingOccurs: boolean;
};

export const BodyFolderContent = ({
  emptyCellWidth,
  titleCellWidth,
  projectFoldersList,
  responsibleCellWidth,
  isDraggingOccurs,
}: BodyFolderContentType) => {
  const [isAddFolder, setIsAddFolder] = useState(false);

  const handleAddFolder = () => {
    setIsAddFolder(true);
  };

  return (
    <>
      {projectFoldersList?.map((item, index) => (
        <FolderRow
          key={index}
          emptyWipth={emptyCellWidth}
          nameWidth={titleCellWidth}
          index={index}
          item={item}
          responsibleWidth={responsibleCellWidth}
        />
      ))}
      {isAddFolder && (
        <CreateFolderRow
          setIsAddFolder={setIsAddFolder}
          emptyCellWidth={emptyCellWidth}
          titleCellWidth={titleCellWidth}
        />
      )}
      <AddRows
        emptyCellWidth={emptyCellWidth}
        text='Добавить папку'
        onCallback={handleAddFolder}
        onCallbackOuther={handleAddFolder}
        isDraggingOccurs={isDraggingOccurs}
        type={TypeTableRow.folder}
      />
    </>
  );
};
