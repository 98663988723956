import { gql } from '@apollo/client';

export const LINKS_AND_COUNT = gql`
  query linksAndCount($data: FindLinksInput!) {
    linksAndCount(data: $data) {
      count
      rows {
        id
        title
        description
        URL
        messageId
        createdAt
        updatedAt
      }
    }
  }
`;
