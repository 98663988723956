import Box from '@mui/material/Box';
import { PermanentDrawerLeft } from '../index';

type LayoutDefaultType = {
  children: React.ReactNode;
};

export const LayoutDefault = ({ children }: LayoutDefaultType) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <PermanentDrawerLeft />
      <Box component='main' width='100%' sx={{ overflow: 'hidden' }}>
        {children}
      </Box>
    </Box>
  );
};
