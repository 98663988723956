import { BoxLogoWrapper } from './style';
import { SideburLogo } from '../icons';
import { Box, Stack, Drawer } from '@mui/material';
import { SearchInput, NavigationList, DrawerBottom, ProjectList } from '../index';
import { WorkspaceHub } from './WorkspaceHub/WorkspaceHub';

export const PermanentDrawerLeft = () => {
  return (
    <Drawer variant='permanent' anchor='left' sx={{ '& .MuiPaper-root': { padding: '16px' } }}>
      <Stack justifyContent='space-between' height='100%'>
        <Box>
          <BoxLogoWrapper>
            <SideburLogo />
          </BoxLogoWrapper>
          <WorkspaceHub />
          <SearchInput />
          <NavigationList />
          <ProjectList />
        </Box>
        <DrawerBottom />
      </Stack>
    </Drawer>
  );
};
