import { Stack, Button } from '@mui/material';
import {
  ButtonGroupCustom,
  TableTypeControll,
  StatusThreadTable,
  HomeEditPopup,
  BreadcrumbsWrapper,
  EditAccess,
} from '../../components';
import { StackStyle, LinearProgressStyle, TypographyStyle } from './style';
import { AuthorSelectFilter, StatusThreadTypes } from '../../types/enum';
import { useWorkspaceContext } from '../../context';
import { useEffect, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useFolderQuery } from '../../graphql/generated/graphql';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';

export const Home = () => {
  const filterAuthorOptions = [
    { text: 'Я автор', value: AuthorSelectFilter.isAuthor },
    { text: 'Я отвественный', value: AuthorSelectFilter.isResponsible },
    { text: 'Я подписан', value: AuthorSelectFilter.isSubscriber },
  ];

  const [filterStatus, setFilterStatus] = useState(StatusThreadTypes.none);

  const [selectAuthorUser, setSelectAuthorUser] = useState<string>(filterAuthorOptions[0].value);

  const {
    projects,
    selectProjectId,
    setSelectProjectId,
    selectFolderId,
    setSelectFolderId,
    workspaces,
    workspaceId,
    setWorkspaceId,
  } = useWorkspaceContext();

  const navigation = useNavigate();
  const handleClearFilter = () => {
    setFilterStatus(StatusThreadTypes.none);
    setSelectAuthorUser(filterAuthorOptions[0].value);
  };

  const createBreadcrumbsOptions = () => {
    const project = projects?.find((e) => e.id === selectProjectId);
    const folder = project?.folders?.find((e) => e.id === selectFolderId);
    const workspace = workspaces?.find((e) => e.id === workspaceId);

    const setParams = ({
      workspaceId,
      folderId,
      projectId,
    }: {
      workspaceId: number | null;
      folderId: number | null;
      projectId: number | null;
    }) => {
      setWorkspaceId(workspaceId as number);
      setSelectFolderId(folderId);
      setSelectProjectId(projectId);
    };

    return [
      {
        text: workspace?.title,
        id: workspace?.id,
        onClallback: () => {
          setParams({ folderId: null, projectId: null, workspaceId: workspace?.id || null });
        },
      },
      {
        text: project?.title || 'Мои треды',
        id: project?.id,
        onClallback: () => {
          setParams({
            folderId: null,
            projectId: project?.id || null,
            workspaceId: workspace?.id || null,
          });
        },
      },
      {
        text: folder?.title,
        id: folder?.id,
        onClallback: () => {
          setParams({
            folderId: folder?.id || null,
            projectId: project?.id || null,
            workspaceId: workspace?.id || null,
          });
        },
      },
    ]?.filter((e) => e.text);
  };
  const options = createBreadcrumbsOptions();

  useEffect(() => {
    if (!workspaces?.length) {
      navigation(ROUTES.createWorkspace);
    }
  }, []);

  return (
    <Stack>
      <StackStyle>
        <BreadcrumbsWrapper options={options} />
        <EditAccess />
        <HomeEditPopup />
      </StackStyle>
      <HeaderFilter
        setFilterStatus={setFilterStatus}
        filterStatus={filterStatus}
        handleClearFilter={handleClearFilter}
        selectAuthorUser={selectAuthorUser}
        setSelectAuthorUser={setSelectAuthorUser}
        filterAuthorOptions={filterAuthorOptions}
      />
      <TableTypeControll selectAuthorUser={selectAuthorUser} filterStatus={filterStatus} />
    </Stack>
  );
};

type HeaderFilterType = {
  setFilterStatus: (value: StatusThreadTypes) => void;
  filterStatus: StatusThreadTypes;
  handleClearFilter: () => void;
  filterAuthorOptions: Array<{ text: string; value: AuthorSelectFilter }>;
  selectAuthorUser: string;
  setSelectAuthorUser: (value: string) => void;
};

const HeaderFilter = ({
  setFilterStatus,
  filterStatus,
  handleClearFilter,
  filterAuthorOptions,
  selectAuthorUser,
  setSelectAuthorUser,
}: HeaderFilterType) => {
  const { selectProjectId, selectFolderId } = useWorkspaceContext();

  const { data } = useFolderQuery({
    variables: {
      id: Number(selectFolderId),
    },
    skip: !selectFolderId,
  });

  const statistic = data?.folder?.threadsStatistics;

  if (!selectProjectId && !selectFolderId) {
    return (
      <StackStyle>
        <ButtonGroupCustom
          groupOptions={filterAuthorOptions}
          selectAuthorUser={selectAuthorUser}
          setSelectAuthorUser={setSelectAuthorUser}
        />
        <ButtonFilter
          setFilterStatus={setFilterStatus}
          filterStatus={filterStatus}
          handleClearFilter={handleClearFilter}
        />
      </StackStyle>
    );
  }
  if (selectProjectId && selectFolderId) {
    return (
      <StackStyle>
        <Stack direction={'row'}>
          <TypographyStyle sx={{ marginRight: '16px' }}>Треды</TypographyStyle>
          <Stack direction={'row'} alignItems={'center'}>
            <LinearProgressStyle variant='determinate' value={statistic?.completedThreadsPercent} />
            <TypographyStyle sx={{ fontWeight: '500', marginLeft: '16px' }}>
              {statistic?.completedThreadsCount}/{statistic?.threadsCount}
            </TypographyStyle>
          </Stack>
        </Stack>
        <ButtonFilter
          setFilterStatus={setFilterStatus}
          filterStatus={filterStatus}
          handleClearFilter={handleClearFilter}
        />
      </StackStyle>
    );
  }

  return <></>;
};

type ButtonFilterType = {
  setFilterStatus: (value: StatusThreadTypes) => void;
  filterStatus: StatusThreadTypes;
  handleClearFilter: () => void;
};

const ButtonFilter = ({ filterStatus, handleClearFilter, setFilterStatus }: ButtonFilterType) => {
  return (
    <Stack direction='row'>
      {!!filterStatus && (
        <Button
          variant='white'
          sx={{
            marginRight: '6px',
          }}
          onClick={handleClearFilter}
        >
          <CloseRoundedIcon fontSize='small' /> Сбросить все
        </Button>
      )}
      <StatusThreadTable onChange={setFilterStatus} value={filterStatus} />
    </Stack>
  );
};
