import {
  useUpdateProjectMutation,
  VisibilityStatusEnum,
  useUpdateFolderMutation,
} from '../../../graphql/generated/graphql';
import { useWorkspaceContext } from '../../../context';
import { useEffect, useRef } from 'react';

type UpdateVisabilityStatusType = {
  visibilityStatusWatch: boolean;
  visibilityStatus: boolean;
};

export const useUpdateVisabilityStatus = ({
  visibilityStatusWatch,
  visibilityStatus,
}: UpdateVisabilityStatusType) => {
  const [updateProjectMutation] = useUpdateProjectMutation({});
  const [updateFolderMutation] = useUpdateFolderMutation({});
  const { selectFolderId, selectProjectId } = useWorkspaceContext();

  const isInit = useRef(true);

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      return;
    }
    if (visibilityStatus === visibilityStatusWatch || !selectProjectId) return;
    if (selectFolderId) {
      updateFolderMutation({
        variables: {
          updateFolderInput: {
            id: Number(selectFolderId),
            visibilityStatus: visibilityStatusWatch
              ? VisibilityStatusEnum.Public
              : VisibilityStatusEnum.Team,
          },
        },
      });
    } else {
      updateProjectMutation({
        variables: {
          updateProjectInput: {
            id: Number(selectProjectId),
            visibilityStatus: visibilityStatusWatch
              ? VisibilityStatusEnum.Public
              : VisibilityStatusEnum.Team,
          },
        },
      });
    }
  }, [visibilityStatusWatch]);
};
