import { Stack, Divider, Typography, Button, Box } from '@mui/material';
import {
  TextFieldRHF,
  FormFlex,
  StatusThreadRHF,
  BasicDatePickerRHF,
  MemberThredModal,
  Attachments,
  PathThreadSelectModalRHF,
  CloseButton,
} from '../../../index';
import { StackStyle } from './style';
import { useForm, SubmitHandler, FormProvider, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThreadSchema } from '../../../../validation/index';
import { ModalParametersTypes, PathThreadSelectType } from '../../../../types/types';
import { SizeOptions, MemberTypes, StatusThreadTypes } from '../../../../types/enum';
import { useCreateThreadMutation } from '../../../../graphql/generated/graphql';
import { THREADS_AND_COUNT } from '../../../../graphql/queries/threadsAndCount.gql';
import { FOLDER } from '../../../../graphql/queries/folder.gql';

type UserType = { title: string; value: number; type: MemberTypes };

type FormData = {
  title: string;
  description: string;
  status: number;
  startDate: string;
  endDate: string;
  user?: UserType | null;
  files: Array<File | null>;
  path?: PathThreadSelectType;
};

type ThreadModalType = {
  modalParameters: ModalParametersTypes;
  handleCloseModal: () => void;
};

export const ThreadModal = ({ modalParameters, handleCloseModal }: ThreadModalType) => {
  const workspaceId = modalParameters?.workspaceId;

  const [createThreadMutation] = useCreateThreadMutation({
    refetchQueries: [THREADS_AND_COUNT, FOLDER],
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(ThreadSchema) as Resolver<FormData>,
    defaultValues: {
      title: '',
      description: '',
      startDate: '',
      endDate: '',
      status: StatusThreadTypes.open,
      files: [],
      user: { title: '', value: 0, type: MemberTypes.user },
      path: modalParameters?.path || {
        project: null,
        folder: null,
        goal: null,
      },
    },
  });

  const { handleSubmit, watch } = methods;
  const pathWatch = watch('path');

  const onSubmit: SubmitHandler<FormData> = (data) => {
    createThreadMutation({
      variables: {
        data: {
          workspaceId: workspaceId,
          title: data.title,
          description: data.description,
          startDate: data.startDate ? data.startDate : undefined,
          endDate: data.endDate ? data.endDate : undefined,
          attachments: data.files,
          responsibleId:
            data.user?.value !== 0 && data.user?.type === MemberTypes.user
              ? data.user?.value
              : null,
          responsibleUserGroupId:
            data.user?.value !== 0 && data.user?.type === MemberTypes.groups
              ? data.user?.value
              : null,
          statusId: data.status,
          goalId: data?.path?.goal?.id,
        },
      },
    });
    handleCloseModal();
  };

  return (
    <FormProvider {...methods}>
      <FormFlex onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant='h3'>Создание треда</Typography>
            <CloseButton onClick={handleCloseModal} />
          </Stack>
          <Divider />
        </Stack>
        <Stack sx={{ maxHeight: '350px', overflowY: 'auto' }}>
          <TextFieldRHF
            fullWidth
            placeholder='Введите название'
            minRows={1}
            name='title'
            type='text'
            multiline
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingBottom: '8px',
              },
            }}
          />
          <TextFieldRHF
            fullWidth
            placeholder='Добавьте описание...'
            minRows={2}
            name='description'
            type='text'
            multiline
            size={SizeOptions.small}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingBottom: '16px',
              },
            }}
          />
        </Stack>
        <Stack>
          <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction='row' alignItems={'center'} sx={{ width: '100%' }} flexWrap={'wrap'}>
              <StatusThreadRHF
                sx={{
                  marginRight: '8px',
                  marginBottom: '8px',
                }}
                name='status'
              />
              <Box
                sx={{
                  marginRight: '8px',
                  marginBottom: '8px',
                }}
              >
                <BasicDatePickerRHF startDateName={'startDate'} endDateName={'endDate'} />
              </Box>

              <Box
                sx={{
                  marginRight: '8px',
                  marginBottom: '8px',
                }}
              >
                <MemberThredModal
                  isOpen={true}
                  workspaceId={Number(workspaceId)}
                  folderId={pathWatch?.folder?.id}
                  projectId={pathWatch?.project?.id}
                  name={'user'}
                />
              </Box>
              <Box
                sx={{
                  marginRight: '8px',
                  marginBottom: '8px',
                }}
              >
                <PathThreadSelectModalRHF workspaceId={workspaceId} name={'path'} />
              </Box>
              <Box
                sx={{
                  marginRight: '8px',
                  marginBottom: '8px',
                }}
              >
                <Attachments name='files' />
              </Box>
            </Stack>
          </Stack>

          <Divider sx={{ paddingTop: '8px' }} />
          <StackStyle>
            <Button variant='cancel' sx={{ marginLeft: '8px' }} onClick={handleCloseModal}>
              Отменить
            </Button>
            <Button
              variant='confirm'
              type='submit'
              sx={{
                marginLeft: '8px',
                fontWeight: '500',
              }}
            >
              Создать тред
            </Button>
          </StackStyle>
        </Stack>
      </FormFlex>
    </FormProvider>
  );
};
