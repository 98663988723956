import { Button, Stack, Typography } from '@mui/material';
import { ROUTES } from '../../../../constants/constants';
import { AtTheBeginningForm } from './Form';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CONFIRM_EMAIL_QUERY_KEY, REGISTRATION_STEP } from '../../const';
import { useRegistrationContext } from '../../context/context';
import { useQueryParams } from '../../../../hooks/useQueryParams';

export const AtTheBeginning = () => {
  const navigate = useNavigate();
  const { handleGoToStep } = useRegistrationContext();
  const queryParams = useQueryParams();
  const handleNavigateToLogin = () => navigate(ROUTES.login);
  useEffect(() => {
    const token = queryParams.get(CONFIRM_EMAIL_QUERY_KEY);
    token && handleGoToStep(REGISTRATION_STEP.personalDetails);
  }, []);
  return (
    <>
      <Typography variant='h1' mb='8px'>
        Создать аккаунт
      </Typography>
      <Typography variant='h4' component='h4'>
        Введите почту для регистрации
      </Typography>
      <AtTheBeginningForm />
      <Stack flexDirection='row' mt='24px'>
        <Typography variant='h4' mr='4px'>
          Уже есть аккаунт?
        </Typography>
        <Button onClick={handleNavigateToLogin} variant='text'>
          Войти
        </Button>
      </Stack>
    </>
  );
};
