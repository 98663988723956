import { Box, Stack, styled } from '@mui/material';

export const BoxLogoWrapper = styled(Box)`
  padding: 0px 8px 8px 8px;
`;

export const StackWorkspaceStyle = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  margin: '16px 0px',
  padding: '8px',
  height: '36px',
}));
