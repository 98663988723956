import { TypographyCSS, ErrorMessageWrapperCSS } from './style';
import { Loader } from '../Loader/Loader';
import { ApolloError } from '@apollo/client';
import { ReactNode } from 'react';

type RequestHandlerType = {
  children: ReactNode;
  error?: ApolloError;
  loading: boolean;
  onCloseError?: () => void;
};

export const RequestHandler = ({ loading, error, children, onCloseError }: RequestHandlerType) => {
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <ErrorMessageWrapperCSS onClick={onCloseError}>
        <TypographyCSS variant='h2' fontWeight='700'>
          {error.message}
        </TypographyCSS>
      </ErrorMessageWrapperCSS>
    );
  }
  return <>{children}</>;
};
