import { styled, Stack, Box } from '@mui/material';

export const BoxProjectStyle = styled(Box)(({ theme }) => ({
  boxShadow: theme.palette.boxShadowPopper.main,
  borderRadius: '6px ',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
}));

export const StackStyle = styled(Stack)(() => ({
  whiteSpace: 'nowrap',
  flexDirection: 'row',
  alignItems: 'center',
}));
export const StackWrapperStyle = styled(Stack)(() => ({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  padding: '6px 8px',
}));
