import { styled, Stack, Popper } from '@mui/material';

export const StackStylePopper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
  },
  padding: '4px 8px',
  cursor: 'pointer',
  borderRadius: '6px',
}));

export const PopperStyle = styled(Popper)(({ theme }) => ({
  zIndex: '1500',
  borderRadius: '6px',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  background: theme.palette.backgroundSurface.main,
  boxShadow: theme.palette.boxShadowPopper.main,
}));
