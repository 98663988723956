import { Stack, Typography, useTheme } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { StackStyle } from './style';
import { useDeleteMessageMutation } from '../../../../../../graphql/generated/graphql';

type CommentEditPopperType = {
  handleSetIsEditContent: () => void;
  messageId?: number;
  authorId?: number;
};

export const CommentEditPopper = ({ handleSetIsEditContent, messageId }: CommentEditPopperType) => {
  const theme = useTheme();

  const [deleteMessageMutation] = useDeleteMessageMutation({
    variables: {
      id: Number(messageId),
    },
  });

  const options = [
    {
      title: 'Редактировать',
      handleClick: () => {
        handleSetIsEditContent();
      },
      Icon: EditOutlinedIcon,
    },
    {
      title: 'Удалить',
      handleClick: () => {
        deleteMessageMutation();
      },
      Icon: DeleteOutlineOutlinedIcon,
    },
  ];

  return (
    <Stack sx={{ borderRadius: '8px', padding: '4px 0px' }}>
      {options.map((e) => {
        const { title, handleClick, Icon } = e;
        return (
          <StackStyle onClick={handleClick} key={title}>
            <Icon sx={{ color: theme.palette.textIcon.main }} />
            <Typography color='black600' pl={1}>
              {title}
            </Typography>
          </StackStyle>
        );
      })}
    </Stack>
  );
};
