import { styled, Typography, Stack } from '@mui/material';

export const TypographyStyle = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const ImageStyle = styled('img')(({ theme }) => ({
  width: '36px',
  height: '36px',
  minHeight: '36px',
  minWidth: '36px',
  objectFit: 'cover',
  borderRadius: '8px',
  background: theme.palette.backgroundLevel1.main,
  marginRight: '12px',
}));

export const StackStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.neutralPlainHoverBg.main,
  borderRadius: '3px 3px 0px 0px',
  borderLeft: `3px solid ${theme.palette.borderDark}`,
  padding: '4px 8px',
}));
