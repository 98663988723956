import { InputBase, styled } from '@mui/material';

export const InputBaseStyle = styled(InputBase)(({ theme }) => ({
  borderRadius: '8px',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  boxShadow: theme.palette.joyShadow.main,
  height: '32px',
  '& .MuiInputBase-input': {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
}));
export const Search = styled('div')(() => ({
  position: 'relative',
  marginLeft: 0,
  width: '100%',
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
