import { Stack, Typography, Button } from '@mui/material';
import { CloseButton } from '../../../index';
import { StackStyle, StackMainStyle } from './style';
import { ModalParametersTypes } from '../../../../types/types';

type ProjectModalType = {
  modalParameters: ModalParametersTypes;
  handleCloseModal: () => void;
};

export const ProjectDeleteModal = ({ modalParameters, handleCloseModal }: ProjectModalType) => {
  const handleRemove = () => {
    const removeProject = modalParameters?.removeProject;
    removeProject?.();
    handleCloseModal();
  };

  return (
    <Stack>
      <Stack>
        <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant='h3'>Удаление проекта</Typography>
          <CloseButton onClick={handleCloseModal} />
        </Stack>
      </Stack>

      <Stack>
        <StackMainStyle>
          <Stack direction='row' alignItems={'center'}>
            <Typography variant='customDefault' fontWeight={400}>
              Вы действительно хотите удалить проект со всем содержимым?
            </Typography>
          </Stack>
        </StackMainStyle>

        <StackStyle>
          <Button variant='cancel' onClick={handleCloseModal} sx={{ marginLeft: '8px' }}>
            Отмена
          </Button>
          <Button
            variant='confirm'
            type='submit'
            onClick={handleRemove}
            sx={{ marginLeft: '8px', fontWeight: '500' }}
          >
            Удалить
          </Button>
        </StackStyle>
      </Stack>
    </Stack>
  );
};
