import { Button, Stack, Typography, useTheme } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { PositionedPopper } from '../../../uiComponents';
import { ModalStatuses } from '../../../../types/enum';
import { useModalContext } from '../../../../context';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { StackPopperStyle } from '../style';
import { ROUTES } from '../../../../constants/constants';
import { useNavigateSearch } from '../../../../hooks';

type DeleteThreadPopperType = {
  threadId?: number;
};

export const DeleteThreadPopper = ({ threadId }: DeleteThreadPopperType) => {
  const theme = useTheme();

  const { navigateSearchParams } = useNavigateSearch();

  const { handleChangeModalParameters } = useModalContext();

  const hanldeDeleteThread = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.THREAD_DELETE_MODAl,
      },
      {
        key: 'threadId',
        value: threadId,
      },
      {
        key: 'removeThreadCallback',
        value: () => {
          navigateSearchParams({ path: ROUTES.home });
        },
      },
    ]);
  };

  return (
    <PositionedPopper
      sxPopper={{
        borderRadius: '6px',
        border: 'none',
      }}
      ClickComponent={
        <Button variant='icons'>
          <MoreHorizIcon sx={{ color: theme.palette.black600 }} />
        </Button>
      }
      PopperContent={
        <Stack sx={{ padding: '4px 0px' }}>
          <StackPopperStyle onClick={hanldeDeleteThread}>
            <DeleteOutlineOutlinedIcon sx={{ color: theme.palette.textIcon.main }} />
            <Typography pl={1} color='black600'>
              Удалить
            </Typography>
          </StackPopperStyle>
        </Stack>
      }
    />
  );
};
