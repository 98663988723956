import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SizeOptions } from '../../../../../../../../types/enum';
import {
  useRemoveFileMutation,
  useUpdateWorkspaceMutation,
} from '../../../../../../../../graphql/generated/graphql';
import EditAvatar from '../../../EditAvatar/EditAvatar';
import { Button } from '@mui/material';
import { FormWrapper } from '../../../../../../../AuthFormElements/form.style';
import { RequestHandler } from '../../../../../../../RequestHandler/RequestHandler';
import { useWorkspace } from '../../../../../../../../hooks';
import { TextFieldRHFStyle } from '../../../../../../../WorkspaceHubElements/style';
import { WorkspaceSchema } from '../../../../../../../../validation/workspace.schem';
import { WORKSPACE } from '../../../../../../../../graphql/queries/workspace';

interface IFormData {
  title: string;
}

export const UpdateWorkspaceForm = () => {
  const { currentWorkspace, loading: loadingWorkspace } = useWorkspace();
  const [updateWorkspaceMutation, { loading }] = useUpdateWorkspaceMutation({
    refetchQueries: [WORKSPACE],
  });

  const methods = useForm<IFormData>({
    resolver: yupResolver(WorkspaceSchema),
    defaultValues: {
      title: String(currentWorkspace?.title),
    },
  });
  const {
    formState: { isValid },
  } = methods;
  const [removeFileMutation] = useRemoveFileMutation({
    update(cache) {
      cache.evict({
        id: `File:${currentWorkspace?.avatar?.id as number}`,
      });
    },
  });
  const handleUpdateAvatar = (image: File) => {
    updateWorkspaceMutation({
      variables: {
        avatar: image,
        updateWorkspaceInput: {
          id: currentWorkspace!.id,
        },
      },
    });
  };
  const handleRemoveAvatar = () => {
    currentWorkspace?.avatar &&
      removeFileMutation({
        variables: {
          id: currentWorkspace?.avatar.id,
        },
      });
  };
  const onSubmit: SubmitHandler<IFormData> = (data) => {
    updateWorkspaceMutation({
      variables: {
        updateWorkspaceInput: {
          id: currentWorkspace!.id,
          title: data.title,
        },
      },
    });
  };
  return (
    <RequestHandler loading={loading && loadingWorkspace}>
      <FormProvider {...methods}>
        <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
          <EditAvatar
            title={currentWorkspace?.title}
            imageUrl={currentWorkspace?.avatar?.path}
            handleUpdateAvatar={handleUpdateAvatar}
            handleRemoveAvatar={handleRemoveAvatar}
          />
          <TextFieldRHFStyle
            name='title'
            type='text'
            placeholder='Workspace Name'
            size={SizeOptions.small}
            sx={{
              marginTop: '24px',
            }}
          />
          <Button
            disabled={!isValid}
            variant='contained'
            type='submit'
            sx={{ marginTop: '24px', height: '32px', width: 'fit-content' }}
          >
            Сохранить
          </Button>
        </FormWrapper>
      </FormProvider>
    </RequestHandler>
  );
};
