export const isElementInView = (element: HTMLDivElement, wrapperHeight: number): boolean => {
  const rect = element.getBoundingClientRect();
  const windowHeight = wrapperHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  const isTooBigForView = rect.height > windowHeight || rect.width > windowWidth;

  if (isTooBigForView) {
    return rect.top < windowHeight && rect.bottom > 0 && rect.left < windowWidth && rect.right > 0;
  } else {
    return (
      rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth
    );
  }
};
