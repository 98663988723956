import { Stack, Typography, useTheme } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { StackStyle } from './style';

type FolderPopperContentType = {
  handleSetIsEditable: (value: boolean) => void;
  handleDeleteFolder: () => void;
};

export const FolderPopperContent = ({
  handleSetIsEditable,
  handleDeleteFolder,
}: FolderPopperContentType) => {
  const theme = useTheme();

  return (
    <Stack sx={{ borderRadius: '8px', padding: '4px 0px' }}>
      <StackStyle onClick={() => handleSetIsEditable(true)}>
        <EditOutlinedIcon sx={{ color: theme.palette.textIcon.main }} />
        <Typography color='black600' pl={1}>
          Переименовать
        </Typography>
      </StackStyle>

      <StackStyle onClick={handleDeleteFolder}>
        <DeleteOutlineOutlinedIcon sx={{ color: theme.palette.textIcon.main }} />
        <Typography color='black600' pl={1}>
          Удалить
        </Typography>
      </StackStyle>
    </Stack>
  );
};
