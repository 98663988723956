import { Button, Stack, Typography } from '@mui/material';
import { useRegistrationContext } from '../../context/context';
import React, { useEffect, useState } from 'react';
import {
  CONFIRM_EMAIL_QUERY_KEY,
  REGISTRATION_STEP,
  REGISTRATION_TOKEN_QUERY_KEY,
} from '../../const';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import {
  useConfirmEmailMutation,
  useSendConfirmationEmailMutation,
} from '../../../../graphql/generated/graphql';
import { ROUTES } from '../../../../constants/constants';
import { useNavigate } from 'react-router-dom';

type FirstStepConfirmByEmailPropsType = {
  sendAgain: () => void;
  handleGoToAtTheBeginning: () => void;
};

const FirstStepConfirmByEmail = ({
  sendAgain,
  handleGoToAtTheBeginning,
}: FirstStepConfirmByEmailPropsType) => {
  const navigate = useNavigate();

  return (
    <>
      <Typography variant='h4' component='h4'>
        Перейдите по ссылке внутри, чтобы начать регистрацию.
      </Typography>
      <Stack flexDirection='row' mt='32px'>
        <Typography variant='h4' component='h4'>
          Не получили ссылку?
        </Typography>
        <Button onClick={sendAgain} variant='text' sx={{ marginLeft: '4px' }}>
          Отправить еще раз
        </Button>
      </Stack>
      <Button
        variant='contained'
        type='submit'
        onClick={() => {
          navigate(ROUTES.login);
          handleGoToAtTheBeginning();
        }}
        sx={{ marginTop: '24px', width: '448px' }}
      >
        Вернуться на главную
      </Button>
    </>
  );
};
type SecondStepConfirmByEmailPropsType = {
  handleGoToAtTheBeginning: () => void;
};
const SecondStepConfirmByEmail = ({
  handleGoToAtTheBeginning,
}: SecondStepConfirmByEmailPropsType) => {
  return (
    <>
      <Typography variant='h4' component='h4'>
        Перейдите по ссылке внутри, чтобы начать регистрацию.
      </Typography>
      <Typography variant='h4' component='h4' mt='32px'>
        Все еще не получили ссылку?
      </Typography>
      <Stack flexDirection='row'>
        <Typography variant='h4' component='h4'>
          Проверьте папку спам или
        </Typography>
        <Button onClick={handleGoToAtTheBeginning} variant='text' sx={{ marginLeft: '4px' }}>
          введите другую почту
        </Button>
      </Stack>
      <Button
        variant='contained'
        type='submit'
        onClick={handleGoToAtTheBeginning}
        sx={{ marginTop: '24px', width: '448px' }}
      >
        Ввести другую почту
      </Button>
    </>
  );
};

export const ConfirmByEmail = () => {
  const [isFirstSend, setIsFirstSend] = useState<boolean>(true);
  const { handleGoToStep, registrationData } = useRegistrationContext();
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const [sendConfirmationEmailMutation] = useSendConfirmationEmailMutation({
    onCompleted: () => {
      setIsFirstSend(false);
    },
  });
  const [confirmEmailMutation] = useConfirmEmailMutation({
    onCompleted: (data) => {
      navigate(
        `${ROUTES.registration}?${REGISTRATION_TOKEN_QUERY_KEY}=${data.confirmEmail.registrationToken}`,
      );
      handleGoToStep(REGISTRATION_STEP.personalDetails);
    },
  });
  const handleSendAgain = () => {
    sendConfirmationEmailMutation({
      variables: {
        email: registrationData.email,
      },
    });
  };
  const handleGoToAtTheBeginning = () => {
    handleGoToStep(REGISTRATION_STEP.atTheBeginning);
  };

  useEffect(() => {
    const token = queryParams.get(CONFIRM_EMAIL_QUERY_KEY);
    if (token) {
      confirmEmailMutation({
        variables: {
          token: token as string,
        },
      });
    }
  }, []);
  return (
    <>
      <Typography variant='h1' mb='8px'>
        Подтвердите вашу почту
      </Typography>
      <Typography variant='h4' component='h4'>
        Мы отправили письмо с подтверждением на адрес
      </Typography>
      <Typography variant='h4' component='h4'>
        {registrationData.email}.
      </Typography>
      {isFirstSend ? (
        <FirstStepConfirmByEmail
          sendAgain={handleSendAgain}
          handleGoToAtTheBeginning={handleGoToAtTheBeginning}
        />
      ) : (
        <SecondStepConfirmByEmail handleGoToAtTheBeginning={handleGoToAtTheBeginning} />
      )}
    </>
  );
};
