import { Breadcrumbs, Box, useTheme, SxProps } from '@mui/material';
import { PathThreadSelectType, PathType } from '../../../types/types';
import { LimitedWidthBox } from './style';
import { useRef, useEffect, useState } from 'react';
import { measureTextSize } from '../../../utils/index';

type PathThreadBreadCrumbsType = {
  value?: PathThreadSelectType | PathType;
  sxText?: SxProps;
  isDisable?: boolean;
};

export const PathThreadBreadCrumbs = ({
  value,
  sxText,
  isDisable = false,
}: PathThreadBreadCrumbsType) => {
  const createOptions = () => {
    return [value?.project?.title, value?.folder?.title, value?.goal?.title].filter((e) => e);
  };

  const [maxWidths, setMaxWidths] = useState<Array<number | null>>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const option = createOptions();

  useEffect(() => {
    if (wrapperRef.current) {
      const shiftWidth = 28;
      const totalWidth = wrapperRef.current.offsetWidth - shiftWidth;
      const optionWidths = option.map((text) => measureTextSize(String(text)).width);
      const totalOptionWidth = optionWidths.reduce((acc, width) => acc + width, 0);
      if (totalOptionWidth > totalWidth) {
        const ratio = totalWidth / totalOptionWidth;
        setMaxWidths(optionWidths.map((width) => width * ratio));
      } else {
        setMaxWidths([]);
      }
    }
  }, [wrapperRef.current, value]);

  const isEmpty = option.length === 0;

  const theme = useTheme();

  return (
    <Box component={'span'} ref={wrapperRef} width={'100%'}>
      <Breadcrumbs
        sx={{
          padding: '0px',
          '& .MuiBreadcrumbs-separator': {
            marginLeft: '4px',
            marginRight: '4px',
          },
        }}
      >
        {isEmpty ? (
          <Box
            sx={{
              color: isDisable
                ? theme.palette.neutralPlainDisabledColor.main
                : theme.palette.textTertiary.main,
            }}
          >
            Проект
          </Box>
        ) : null}
        {option.map((item, index) => {
          return (
            <LimitedWidthBox key={index} sx={{ maxWidth: `${maxWidths[index]}px`, ...sxText }}>
              {item}
            </LimitedWidthBox>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};
