import { AuthLayoutWrapper, AuthLayoutStack } from './style';
import { AuthLogo } from '../icons';

type LayoutDefaultType = {
  children: React.ReactNode;
};

export const AuthLayout = ({ children }: LayoutDefaultType) => {
  return (
    <AuthLayoutStack>
      <AuthLogo />
      <AuthLayoutWrapper width='100%'>{children}</AuthLayoutWrapper>
    </AuthLayoutStack>
  );
};
