import { IconButton, styled, Stack } from '@mui/material';

export const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  width: '48px',
  height: '48px',
  borderRadius: '6px',
  backgroundColor: theme.palette.neutralPlainHoverBg.main,
  '&:hover': { backgroundColor: theme.palette.neutral200.main },
}));

export const IconButtonControllStyle = styled(IconButtonStyle)(() => ({
  position: 'absolute',
  top: '50%',
  transform: 'translate(0%, -50%)',
}));

export const StackButtonStyle = styled(Stack)(() => ({
  position: 'absolute',
  top: '0px',
  right: '0px',
  zIndex: '10',
  flexDirection: 'row',
}));

export const ImageStyle = styled('img')(({ theme }) => ({
  backgroundColor: theme.palette.backgroundSurface.main,
  maxWidth: '550px',
  maxHeight: '600px',
  width: 'auto',
  height: 'auto',
  objectFit: 'contain',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}));
