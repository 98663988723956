import React from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useResetPasswordMutation } from '../../../../../../../../../graphql/generated/graphql';
import { SizeOptions } from '../../../../../../../../../types/enum';
import { Button, Typography } from '@mui/material';
import { FormWrapper } from '../style';
import { PasswordTextFieldRHFStyle } from '../../../../../../../../WorkspaceHubElements/style';
import { ResetPasswordSchema } from '../../../../../../../../../validation/editUser.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { PasswordTextFieldRHF } from '../../../../../../../../PasswordTextFieldRHF/PasswordTextFieldRHF';

interface IFormData {
  newPassword: string;
  oldPassword: string;
}
enum ServerError {
  WRONG_DATA = 'Wrong data',
}
export const ResetPassword = () => {
  const methods = useForm<IFormData>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: {
      newPassword: '',
      oldPassword: '',
    },
  });

  const {
    formState: { isValid },
    setError,
    reset,
  } = methods;
  const [resetPasswordMutation] = useResetPasswordMutation({
    onCompleted: () => {
      reset();
    },
    onError: (error) => {
      if (error.message === ServerError.WRONG_DATA) {
        setError('oldPassword', {
          type: 'server',
          message: 'Неверный старый пароль',
        });
      }
    },
  });
  const onSubmit: SubmitHandler<IFormData> = (data) => {
    resetPasswordMutation({
      variables: {
        data: {
          newPassword: data.newPassword,
          oldPassword: data.oldPassword,
        },
      },
    });
  };
  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
        <Typography variant='h3' mb='24px'>
          Изменить пароль
        </Typography>
        <PasswordTextFieldRHFStyle
          name='oldPassword'
          type='text'
          placeholder='Старый пароль'
          size={SizeOptions.extraLarge}
        />
        <PasswordTextFieldRHFStyle
          name='newPassword'
          type='text'
          placeholder='Новый пароль'
          size={SizeOptions.extraLarge}
          sx={{
            marginTop: '4px',
          }}
        />
        <Button
          disabled={!isValid}
          variant='contained'
          type='submit'
          sx={{ marginTop: '24px', height: '32px' }}
        >
          Изменить
        </Button>
      </FormWrapper>
    </FormProvider>
  );
};
