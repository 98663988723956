import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useFormContext, useController } from 'react-hook-form';
import { SxProps } from '@mui/system';
import { Tag } from './Tag/Tag';
import { RenderOptionsMembers } from '../RenderOptionsMembers/RenderOptionsMembers';
import { useTheme } from '@mui/material';
import { AutocompliteUsersOptionType } from '../../../types/types';

type AutocompliteUsersType = {
  name: string;
  options: Array<AutocompliteUsersOptionType>;
  sx?: SxProps;
  multiple?: boolean;
  popupIcon?: boolean;
  clearIcon?: boolean;
  placeholder?: string;
};

export const AutocompliteUsers = ({
  name,
  options,
  sx,
  multiple = true,
  popupIcon = undefined,
  clearIcon = undefined,
  placeholder,
}: AutocompliteUsersType) => {
  const methods = useFormContext();

  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control: methods.control,
  });

  const theme = useTheme();

  const filteredOptions = options?.filter(
    (option) =>
      !value.some((selected: AutocompliteUsersOptionType) => selected.value === option.value),
  );

  return (
    <Autocomplete
      {...inputProps}
      value={value || []}
      disablePortal
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      fullWidth
      options={filteredOptions || []}
      sx={{
        '& .MuiInputBase-root': {
          background: theme.palette.backgroundSurface.main,
          borderRadius: '8px',
          padding: '0px',
          paddingLeft: '8px',
        },
        ...sx,
      }}
      getOptionLabel={(option) => option?.title}
      multiple={multiple}
      clearIcon={clearIcon}
      popupIcon={popupIcon}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return <Tag key={key} label={option?.title} path={option?.avatar?.path} {...tagProps} />;
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error ? error.message : null}
          inputRef={ref}
          placeholder={placeholder}
        />
      )}
      renderOption={(props, option) => {
        return <RenderOptionsMembers props={props} option={option} />;
      }}
    />
  );
};
