import { Checkbox, Stack, styled } from '@mui/material';

export const StackStyle = styled(Stack)`
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
`;

export const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    padding: '0px',
    width: '15px',
    height: '15px',
    overflow: 'hidden',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
    color: theme.palette.white.main,
    svg: {
      display: 'none',
    },
  },
  '&.Mui-checked': {
    border: `none`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    svg: {
      background: theme.palette.white.main,
      display: 'block',
    },
  },
}));
