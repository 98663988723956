import { Box, IconButton, Stack } from '@mui/material';
import { StackWrapperIconsStyle } from '../style';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { PositionedPopper } from '../../../../index';
import { CommentEditPopper } from './CommentEditPopper/CommentEditPopper';
import { useHandleReactionMutation } from '../../../../../graphql/generated/graphql';
import { REACTION_EMOJI } from '../../../../../constants/constants';
import { useAuthContext } from '../../../../../context';

type ActionCommentButtonsTypes = {
  messageId?: number;
  handleReplay: () => void;
  handleSetIsEditContent: () => void;
  authorId?: number;
  isHovering: boolean;
  hanldeOnMouseLeave: () => void;
};

export const ActionCommentButtons = ({
  messageId,
  handleReplay,
  handleSetIsEditContent,
  authorId,
  isHovering,
  hanldeOnMouseLeave,
}: ActionCommentButtonsTypes) => {
  const visibility = isHovering ? 'visible' : 'hidden';

  const [handleReactionMutation] = useHandleReactionMutation();

  const handleClickReaction = ({ emojiId }: { emojiId: number }) => {
    handleReactionMutation({
      variables: {
        data: { emojiId: emojiId, messageId: Number(messageId) },
      },
    });
  };

  const { currentUser } = useAuthContext();

  const isCurrentUser = authorId === currentUser?.id;

  return (
    <Stack
      direction={'row'}
      sx={{
        marginLeft: 'auto',
      }}
    >
      <PositionedPopper
        placement='bottom-end'
        ClickComponent={
          <IconButton sx={{ height: '32px', width: '32px', visibility }}>
            <AddReactionOutlinedIcon sx={{ color: 'black600' }} />
          </IconButton>
        }
        PopperContent={
          <StackWrapperIconsStyle>
            {REACTION_EMOJI.map((e) => {
              const { src, id } = e;
              return (
                <IconButton
                  key={id}
                  sx={{ height: '32px', width: '32px' }}
                  onClick={() => {
                    handleClickReaction({ emojiId: id });
                  }}
                >
                  <img alt='a' width={20} src={src} />
                </IconButton>
              );
            })}
          </StackWrapperIconsStyle>
        }
      />
      <IconButton sx={{ height: '32px', width: '32px', visibility }} onClick={handleReplay}>
        <ReplyOutlinedIcon sx={{ color: 'black600' }} />
      </IconButton>
      {isCurrentUser && (
        <PositionedPopper
          placement='bottom-end'
          ClickComponent={
            <IconButton sx={{ height: '32px', width: '32px', visibility }}>
              <MoreHorizOutlinedIcon sx={{ color: 'black600' }} />
            </IconButton>
          }
          PopperContent={
            <Box component='span' onClick={hanldeOnMouseLeave}>
              <CommentEditPopper
                handleSetIsEditContent={handleSetIsEditContent}
                messageId={messageId}
                authorId={authorId}
              />
            </Box>
          }
        />
      )}
    </Stack>
  );
};
