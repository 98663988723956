import { Typography } from '@mui/material';
import { calcTime } from '../../../utils/index';
import { memo, useEffect, useState } from 'react';

type TimeMessageType = {
  time?: string;
};

const TimeMessage = ({ time }: TimeMessageType) => {
  const [displayTime, setDisplayTime] = useState('');

  useEffect(() => {
    const updateDisplayTime = () => {
      setDisplayTime(calcTime({ time }));
    };
    updateDisplayTime();

    const interval = setInterval(() => {
      updateDisplayTime();
    }, 60000);

    return () => clearInterval(interval);
  }, [time]);

  return (
    <Typography variant='h5' fontWeight={500} color='textTertiary.main'>
      {displayTime}
    </Typography>
  );
};

export const TimeMessageMemo = memo(TimeMessage);
