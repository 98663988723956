import React, { createContext, ReactElement, useContext } from 'react';
import { FORGOT_PASSWORD_STEP } from '../const';
import { IForgotPasswordData } from '../../../hooks/useForgotPassword';

type Props = {
  forgotPasswordData: IForgotPasswordData;
  updateData: (changes: Partial<IForgotPasswordData>) => void;
  handleGoToStep: (step: FORGOT_PASSWORD_STEP) => void;
  children: ReactElement;
};

type ForgotPasswordContextTypes = {
  forgotPasswordData: IForgotPasswordData;
  updateData: (changes: Partial<IForgotPasswordData>) => void;
  handleGoToStep: (step: FORGOT_PASSWORD_STEP) => void;
};

const ForgotPasswordContext = createContext<ForgotPasswordContextTypes | null>(null);

export function ForgotPasswordProvider(props: Props) {
  return (
    <ForgotPasswordContext.Provider
      value={{
        forgotPasswordData: props.forgotPasswordData,
        updateData: props.updateData,
        handleGoToStep: props.handleGoToStep,
      }}
    >
      {props.children}
    </ForgotPasswordContext.Provider>
  );
}

export const useForgotPasswordContext = () => {
  const context = useContext(ForgotPasswordContext);
  if (!context) {
    throw new Error('sorry man.');
  }
  return context;
};
