import { styled, Stack, IconButton, Typography } from '@mui/material';

export const StackWrapperIconsStyle = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}));

export const StackWrapperItemStyle = styled(Stack)(({ theme }) => ({
  padding: '6px',
  width: '137px',
  minWidth: '137px',
  borderRadius: '12px',
  cursor: 'pointer',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
    '& .hidden': {
      visibility: 'visible',
    },
  },
}));
export const StackWrapperImageStyle = styled(Stack)(({ theme }) => ({
  borderRadius: '8px',
  background: theme.palette.imageFill.main,
  width: '125px',
  height: '80px',
  padding: '4px',
  justifyContent: 'space-between',
  marginBottom: '6px',
  backgroundSize: 'cover',
}));

export const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.neutralPlainHoverBg.main,
  width: '32px',
  height: '32px',
  visibility: 'hidden',
  '&:hover': {
    backgroundColor: theme.palette.neutral200.main,
  },
}));

export const TypographyStyle = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '133%',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
