import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { CloseButton } from '../../../index';
import { ModalParametersTypes } from '../../../../types/types';
import { InviteLinkForm, InviteByEmailForm } from './components';
import { StackStyle } from './style';
import { useWorkspaceContext } from '../../../../context';

type ThreadModalType = {
  modalParameters: ModalParametersTypes;
  handleCloseModal: () => void;
};

export const InviteWorkspaceModal = ({ handleCloseModal }: ThreadModalType) => {
  const theme = useTheme();
  const { currentWorkspace } = useWorkspaceContext();

  return (
    <Stack width='100%'>
      <StackStyle>
        <Typography variant='h3'>{`Пригласить людей в ${currentWorkspace?.title}`}</Typography>
        <CloseButton onClick={handleCloseModal} />
      </StackStyle>
      <Stack>
        <InviteByEmailForm />
        <Divider
          sx={{
            backgroundColor: theme.palette.neutralOutlinedBorder.main,
            margin: '24px 0',
          }}
        />
        <InviteLinkForm />
      </Stack>
    </Stack>
  );
};
