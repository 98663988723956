import { Tab, Tabs } from '@mui/material';
import { SyntheticEvent } from 'react';

type TabsCustomType = {
  tabIndex: number;
  handleTabChange: (event: SyntheticEvent, newValue: number) => void;
  options: Array<{ title: string }>;
};

export const TabsCustom = ({ tabIndex, handleTabChange, options }: TabsCustomType) => {
  return (
    <Tabs value={tabIndex} onChange={handleTabChange}>
      {options?.map((e) => {
        return <Tab label={e.title} key={e.title} />;
      })}
    </Tabs>
  );
};
