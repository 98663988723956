import { Button, Stack, Typography } from '@mui/material';
import { useForgotPasswordContext } from '../../context/context';
import React, { useEffect, useState } from 'react';
import { FORGOT_PASSWORD_STEP, RECOVERY_INSTRUCTIONS_QUERY_KEY } from '../../const';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { useSendPasswordResetMutation } from '../../../../graphql/generated/graphql';
import { StackStyle } from './style';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/constants';

type FirstStepConfirmByEmailPropsType = {
  sendAgain: () => void;
  handleGoToAtTheBeginning: () => void;
};

const FirstStepRestorationInstruction = ({ sendAgain }: FirstStepConfirmByEmailPropsType) => {
  const navigation = useNavigate();

  return (
    <>
      <StackStyle>
        <Typography variant='h4' component='h4'>
          Не получили ссылку?
        </Typography>
        <Button onClick={sendAgain} variant='text' sx={{ marginLeft: '4px' }}>
          Отправить еще раз
        </Button>
      </StackStyle>
      <Button
        variant='contained'
        type='submit'
        onClick={() => {
          navigation(ROUTES.login);
        }}
        sx={{ marginTop: '24px', width: '448px' }}
      >
        Войти
      </Button>
    </>
  );
};
type SecondStepConfirmByEmailPropsType = {
  handleGoToAtTheBeginning: () => void;
};
const SecondStepRestorationInstruction = ({
  handleGoToAtTheBeginning,
}: SecondStepConfirmByEmailPropsType) => {
  return (
    <>
      <Typography variant='h4' component='h4' mt='32px'>
        Все еще не получили ссылку?
      </Typography>
      <Stack flexDirection='row' justifyContent='center'>
        <Typography variant='h4' component='h4'>
          Проверьте папку спам или
        </Typography>
        <Button onClick={handleGoToAtTheBeginning} variant='text' sx={{ marginLeft: '4px' }}>
          введите другую почту
        </Button>
      </Stack>
      <Button
        variant='contained'
        type='submit'
        onClick={handleGoToAtTheBeginning}
        sx={{ marginTop: '24px', width: '448px' }}
      >
        Ввести другую почту
      </Button>
    </>
  );
};

export const RecoveryInstructions = () => {
  const navigation = useNavigate();
  const [isFirstSend, setIsFirstSend] = useState<boolean>(true);
  const { handleGoToStep, forgotPasswordData } = useForgotPasswordContext();
  const queryParams = useQueryParams();

  const [sendPasswordResetMutation] = useSendPasswordResetMutation({
    onCompleted: () => {
      setIsFirstSend(false);
    },
  });
  const handleSendAgain = () => {
    sendPasswordResetMutation({
      variables: {
        email: forgotPasswordData.email,
      },
    });
  };
  const handleGoToAtTheBeginning = () => {
    handleGoToStep(FORGOT_PASSWORD_STEP.atTheBeginning);
  };

  useEffect(() => {
    const token = queryParams.get(RECOVERY_INSTRUCTIONS_QUERY_KEY);
    if (token) {
      handleGoToStep(FORGOT_PASSWORD_STEP.createNewPassword);
    }
  }, []);
  return (
    <Stack maxWidth='448px' textAlign='center'>
      <Typography variant='h1' mb='8px'>
        Инструкции по восстановлению пароля отправлены
      </Typography>
      <Typography variant='h4' component='h4'>
        {`Пожалуйста, проверьте почту ${forgotPasswordData.email}, чтобы получить ссылку на
        восстановление пароля`}
      </Typography>

      {isFirstSend ? (
        <FirstStepRestorationInstruction
          sendAgain={handleSendAgain}
          handleGoToAtTheBeginning={handleGoToAtTheBeginning}
        />
      ) : (
        <SecondStepRestorationInstruction handleGoToAtTheBeginning={handleGoToAtTheBeginning} />
      )}
    </Stack>
  );
};
