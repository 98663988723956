import { gql } from '@apollo/client';

export const FOLDER = gql`
  query folder($id: Int!, $dataThread: FindThreadsOptions) {
    folder(id: $id) {
      id
      title
      authorId
      project {
        id
        title
      }
      visibilityStatus
      membersUsers {
        user {
          id
          email
          firstName
          lastName
          avatar {
            path
          }
        }
      }
      membersUserGroups {
        userGroup {
          avatar {
            path
            id
          }
          id
          title
          workspaceId
          users {
            id
            email
            firstName
            lastName
            avatar {
              path
            }
          }
        }
      }
      threadsStatistics {
        completedThreadsCount
        completedThreadsPercent
        threadsCount
      }
      goals {
        id
        title
        threads(data: $dataThread) {
          id
          title
          description
          startDate
          endDate
          statusId
          order
          path {
            folder {
              id
              title
            }
            project {
              id
              title
            }
            goal {
              id
              title
            }
          }
          responsible {
            id
            email
            firstName
            lastName
            avatar {
              path
            }
          }
          responsibleUserGroup {
            id
            title
            avatar {
              path
            }
          }
        }
      }
    }
  }
`;
