import { Stack, Typography } from '@mui/material';

import { SelectFile } from './SelectFile/SelectFile';
import { FilterFileType } from '../../../types/enum';
import { useState } from 'react';
import { Images } from './Images/Images';
import { Files } from './Files/Files';
import { LinkItems } from './LinkItems/LinkItems';
import { useAttachments } from './hook/useAttachments';
import { StackNodataStyle } from './style';

type AttachmentsType = {
  chatId?: number;
  wrapperHeight: number;
};

export const Attachments = ({ chatId, wrapperHeight }: AttachmentsType) => {
  const [filter, setFilter] = useState(FilterFileType.Image);

  const { data, links } = useAttachments({ chatId, filter });

  const isShowNodata =
    ((FilterFileType.Image === filter || FilterFileType.File === filter) && !data?.files.length) ||
    (FilterFileType.Link === filter && !links?.length);

  return (
    <Stack
      sx={{
        maxHeight: `calc(${wrapperHeight}px - 35px)`,
      }}
    >
      <Stack sx={{ padding: '8px' }}>
        <SelectFile filter={filter} setFilter={setFilter} />
      </Stack>
      {FilterFileType.Image === filter && <Images files={data?.files} />}
      {FilterFileType.File === filter && <Files files={data?.files} />}
      {FilterFileType.Link === filter && <LinkItems links={links} />}

      {isShowNodata && (
        <StackNodataStyle>
          <Typography sx={{ color: 'neutralPlainDisabledColor.main', marginBottom: '20px' }}>
            Нет вложений
          </Typography>
        </StackNodataStyle>
      )}
    </Stack>
  );
};
