import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTableCell, StyledTableCellDrag } from '../style';

type HeaderTableType = {
  options: Array<{ title: string; width?: string; isEmpty: boolean }>;
};

export const HeaderTable = ({ options }: HeaderTableType) => {
  return (
    <TableHead>
      <TableRow sx={{ height: '40px' }}>
        {options.map((e, index) => {
          if (e.isEmpty) {
            return (
              <StyledTableCellDrag
                padding='none'
                sx={{
                  borderBottom: 'none',
                }}
                width={e.width}
                key={index}
              ></StyledTableCellDrag>
            );
          }
          return (
            <StyledTableCell key={index} width={e.width || ''}>
              {e.title}
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
