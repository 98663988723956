import { StyledTableCell } from '../../../style';
import { UpdateThreadMutationFn } from '../../../../../graphql/generated/graphql';
import { useTheme, Popover } from '@mui/material';
import { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { TableDatePickerRHF } from '../../../../index';
import { showElementDate, getFormattedDateWiev } from '../../../../../utils/index';
import { CalendarIcons } from '../../../../icons';
import dayjs from 'dayjs';
import { useClickCellPopup } from '../../../../../hooks/index';
import { StackStyle, BoxPickerStyle } from './style';

type DealinesTypes = {
  startDate?: string | null;
  endDate?: string | null;
  threadId: number;
  updateThreadMutation: UpdateThreadMutationFn;
};

export const Dealines = ({ startDate, endDate, updateThreadMutation, threadId }: DealinesTypes) => {
  const theme = useTheme();

  const { anchorEl, open, handleClick, handleClose, refClickOutside } = useClickCellPopup({});

  type FormData = {
    endDate?: string | null;
    startDate?: string | null;
  };

  const methods = useForm<FormData>({
    defaultValues: {
      startDate: startDate,
      endDate: endDate,
    },
  });
  const { getValues } = methods;
  const [isInitDisable, setIsInitDisable] = useState(true);

  useEffect(() => {
    setIsInitDisable(false);
    if (isInitDisable || open) return;
    const currentValues = getValues();
    updateThreadMutation({
      variables: {
        data: {
          id: threadId,
          startDate: currentValues.startDate,
          endDate: currentValues.endDate,
        },
      },
    });
  }, [open]);

  const showIcons = !!startDate || !!endDate;

  return (
    <FormProvider {...methods}>
      <StyledTableCell
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: open ? 'transparent' : `${theme.palette.tableHover.main} !important`,
          },
        }}
      >
        <StackStyle ref={refClickOutside}>
          {!open && (
            <>
              {showIcons && (
                <CalendarIcons sx={{ marginRight: '8px' }} color={theme.palette.orangeColor.main} />
              )}
              {showElementDate({
                startValue: startDate ? getFormattedDateWiev(dayjs(startDate), 'DD MMM') : '',
                endValue: endDate ? getFormattedDateWiev(dayjs(endDate), 'DD MMM') : '',
                empty: true,
              })}
            </>
          )}
        </StackStyle>
      </StyledTableCell>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <BoxPickerStyle>
          <TableDatePickerRHF endDateName='endDate' startDateName='startDate' />
        </BoxPickerStyle>
      </Popover>
    </FormProvider>
  );
};
