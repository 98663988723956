import { styled, Stack, Typography, IconButton, LinearProgress } from '@mui/material';

export const StackWrapperStyle = styled(Stack)(() => ({
  padding: '4px',
  marginTop: '4px',
  flexDirection: 'row',
}));

export const TypographyStyleName = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const TypographyStyleSize = styled(Typography)(() => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
}));

export const IconButtonStyle = styled(IconButton)(() => ({
  width: '32px',
  height: '32px',
  paddingLeft: '2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0',
}));

export const ImageStyle = styled('img')(({ theme }) => ({
  width: '48px',
  height: '48px',
  minHeight: '48px',
  minWidth: '48px',
  objectFit: 'cover',
  borderRadius: '8px',
  background: theme.palette.backgroundLevel1.main,
  marginRight: '12px',
}));

export const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  borderRadius: '6px',
  marginTop: '8px',
  backgroundColor: theme.palette.backgroundLevel1.main,
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.black600,
  },
}));
