import * as yup from 'yup';

import { REQUIRE_MESSAGE } from './messages';

export const EditUserSchema = yup.object().shape({
  firstName: yup.string().required(REQUIRE_MESSAGE),
  lastName: yup.string().required(REQUIRE_MESSAGE),
});

export const ResetPasswordSchema = yup.object().shape({
  oldPassword: yup.string().required(REQUIRE_MESSAGE),
  newPassword: yup.string().required(REQUIRE_MESSAGE),
});
