import { SvgIcon, SxProps } from '@mui/material';

type TimelansOutlinedOrangeType = {
  sx: SxProps;
};

export const TimelansOutlinedOrange = ({ sx }: TimelansOutlinedOrangeType) => {
  return (
    <SvgIcon sx={{ ...sx, width: 20, height: 20 }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M13.5333 6.4582C12.5583 5.4832 11.2833 4.99154 9.99996 4.99154V9.99154L6.46663 13.5249C8.41663 15.4749 11.5833 15.4749 13.5416 13.5249C15.4916 11.5749 15.4916 8.4082 13.5333 6.4582ZM9.99996 1.6582C5.39996 1.6582 1.66663 5.39154 1.66663 9.99154C1.66663 14.5915 5.39996 18.3249 9.99996 18.3249C14.6 18.3249 18.3333 14.5915 18.3333 9.99154C18.3333 5.39154 14.6 1.6582 9.99996 1.6582ZM9.99996 16.6582C6.31663 16.6582 3.33329 13.6749 3.33329 9.99154C3.33329 6.3082 6.31663 3.32487 9.99996 3.32487C13.6833 3.32487 16.6666 6.3082 16.6666 9.99154C16.6666 13.6749 13.6833 16.6582 9.99996 16.6582Z'
          fill='#EA9A3E'
        />
      </svg>
    </SvgIcon>
  );
};
