import { Stack, useTheme, Tooltip } from '@mui/material';
import { LinksAndCountQuery } from '../../../../graphql/generated/graphql';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';
import { useChatContext } from '../../context/ChatContext';
import {
  StackItemStyle,
  StackImageStyle,
  StackWrapperStyle,
  TypographyStyle,
  IconButtonStyle,
} from './style';

type LinkType = { links?: LinksAndCountQuery['linksAndCount']['rows'] };

export const LinkItems = ({ links }: LinkType) => {
  const theme = useTheme();

  const { setReplayId, setTabIndex } = useChatContext();

  const handleLinkMessage = (value: number) => {
    setTabIndex(0);
    setTimeout(() => {
      setReplayId(value);
    }, 0);
  };

  const handleOpenLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <StackWrapperStyle>
      {links?.map((link) => {
        return (
          <StackItemStyle
            key={link.id}
            onClick={() => {
              handleOpenLink(link.URL);
            }}
          >
            <StackImageStyle>
              <InsertLinkRoundedIcon sx={{ color: 'textIcon.main' }} />
            </StackImageStyle>
            <Stack
              sx={{
                marginRight: 'auto',
                width: 'calc(100% - 105px)',
              }}
            >
              <TypographyStyle
                sx={{
                  color: 'textPrimary.main',
                  fontWeight: '600',
                }}
              >
                {link.title}
              </TypographyStyle>
              <TypographyStyle>{link.description}</TypographyStyle>
              <TypographyStyle
                sx={{
                  color: 'avatarBlue.main',
                }}
              >
                {link.URL}
              </TypographyStyle>
            </Stack>
            <Tooltip
              title={'Перейти к сообщению'}
              placement='bottom-start'
              componentsProps={{
                popper: {
                  sx: {
                    zIndex: 99999,
                    '& .MuiTooltip-tooltip': {
                      borderRadius: ' 6px',
                      background: theme.palette.neutral500.main,
                      marginTop: '7px !important',
                      boxShadow: theme.palette.joyShadoSm.main,
                    },
                  },
                },
              }}
            >
              <IconButtonStyle
                onClick={(event) => {
                  event.stopPropagation();
                  handleLinkMessage(Number(link.messageId));
                }}
              >
                <ForwardToInboxRoundedIcon sx={{ color: 'black600' }} />
              </IconButtonStyle>
            </Tooltip>
          </StackItemStyle>
        );
      })}
    </StackWrapperStyle>
  );
};
