import { AutocompleteGetTagProps } from '@mui/base/useAutocomplete';
import { Box } from '@mui/material';
import { StackStyle, CloseIconStyle } from './style';
import { AvatarCustom } from '../../../AvatarCustom/AvatarCustom';
import { User } from '../../../../graphql/generated/graphql';

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
  path?: string;
}

export const Tag = (props: TagProps) => {
  const { label, path, onDelete, ...other } = props;

  return (
    <StackStyle {...other}>
      <AvatarCustom
        user={{ email: label, avatar: { path } } as User}
        sx={{
          width: '24px',
          height: '24px',
          marginRight: '6px',
          fontSize: '12px',
          fontWeight: '500',
        }}
      />
      <Box component='span'>{label}</Box>
      <CloseIconStyle onClick={onDelete} fontSize='small' />
    </StackStyle>
  );
};
