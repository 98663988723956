import { ReactNode } from 'react';
import { Modal, SxProps } from '@mui/material';
import { BoxStyle } from './style';

type DefaultModalType = {
  children: ReactNode;
  handleCloseModal: () => void;
  sx?: SxProps;
  open?: boolean;
  calssName?: string;
  onClick?: () => void;
};

export const DefaultModal = ({
  children,
  handleCloseModal,
  sx,
  open = true,
  calssName,
  onClick,
}: DefaultModalType) => {
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={calssName}
      onClick={onClick}
    >
      <BoxStyle sx={sx}>{children}</BoxStyle>
    </Modal>
  );
};
