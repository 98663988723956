import { useEffect, useState } from 'react';
import { useClickOutside } from './useClickOutside';
import { useProjectsQuery } from '../graphql/generated/graphql';
import { useWorkspaceContext } from '../context';

type ClickCellPopupType = {
  isSetDisableProject?: boolean;
  isAcess?: boolean;
};

export const useClickCellPopup = ({
  isSetDisableProject = false,
  isAcess = true,
}: ClickCellPopupType) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [open, setOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const { workspaceId } = useWorkspaceContext();

  const { data } = useProjectsQuery({
    variables: {
      options: {
        workspaceId: Number(workspaceId),
      },
    },
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    isSetDisableProject && setIsDisable(!data?.projects.length);
  }, [data]);

  const handleClick: React.MouseEventHandler<HTMLTableCellElement> = (event) => {
    if (isDisable || !isAcess) return;
    setAnchorEl(event.currentTarget as Element);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const { refClickOutside } = useClickOutside({
    callBack: () => {
      handleClose();
    },
  });

  return {
    anchorEl,
    setAnchorEl,
    open,
    setOpen,
    handleClick,
    handleClose,
    refClickOutside,
    setIsDisable,
    isDisable,
  };
};
