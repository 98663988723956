import { Box, Typography, useTheme, Stack, SxProps } from '@mui/material';
import { PositionedPopper, AutocompliteMember, AvatarCustom } from '../index';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useCreateOptions } from './hooks/useCreateOptions';
import { OutsideClickFlagType } from '../../types/enum';
import { AccountCircleIconStyle } from './style';
import { User } from '../../graphql/generated/graphql';

type MemberThredModalType = {
  workspaceId: number;
  name: string;
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
  sxPopper?: SxProps;
  isOutControll?: boolean;
  folderId: number;
  projectId: number;
};

export const MemberThredItem = ({
  workspaceId,
  name,
  isOpen,
  setIsOpen,
  sxPopper,
  isOutControll,
  folderId,
  projectId,
}: MemberThredModalType) => {
  const [outOupen, setOutOpen] = useState(false);
  const options = useCreateOptions({ workspaceId, projectId, folderId });

  const theme = useTheme();
  const {
    field: { value },
  } = useController({
    name,
  });

  useEffect(() => {
    setOutOpen(false);
  }, [value]);

  useEffect(() => {
    setIsOpen?.(outOupen);
  }, [outOupen]);

  return (
    <PositionedPopper
      setOutOpen={setOutOpen}
      outOpen={outOupen}
      isOutControll={isOutControll}
      sxPopper={{ marginTop: '-30px', ...sxPopper }}
      placement='auto-start'
      ClickComponent={
        <Stack direction={'row'} alignItems={'center'} height={'100%'}>
          {value?.title ? (
            <>
              <AvatarCustom
                user={value as User}
                sx={{
                  marginRight: '6px',
                }}
              />
              <Typography
                variant='customDefault'
                fontWeight={400}
                color={theme.palette.black600}
                sx={{ textTransform: 'none' }}
              >
                {value?.title}
              </Typography>
            </>
          ) : (
            <>
              <AccountCircleIconStyle />
              <Typography
                variant='customDefault'
                fontWeight={400}
                color={theme.palette.textTertiary.main}
              >
                Указать
              </Typography>
            </>
          )}
        </Stack>
      }
      PopperContent={
        <Box className={OutsideClickFlagType.outsideClickFlagDefault}>
          {options && (
            <AutocompliteMember
              options={options}
              name={name}
              sx={{ width: '220px' }}
              isHasPopupIcon
              isHasClearIcon
              placeholder='Ответственный'
              isOpen={isOpen}
            />
          )}
        </Box>
      }
    />
  );
};
