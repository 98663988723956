import { styled, Stack, Typography } from '@mui/material';

export const StackWrapperStyle = styled(Stack)(() => ({
  width: '100%',
  padding: '8px 0px',
  boxSizing: 'content-box',
  justifyContent: 'center',
}));

export const TypographyStyle = styled(Typography)(() => ({
  fontSize: '16px',
  whiteSpace: 'pre-line',
  lineHeight: '150%',
}));
