import { styled, Stack } from '@mui/material';

export const StackItemStyle = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  width: '220px',
  padding: '3px 8px',
  height: '32px',
  color: theme.palette.neutral500.main,
}));
