import { PositionedPopper } from '../index';
import { Button, Typography, useTheme } from '@mui/material';
import { StackPopperStyle, StackPopperOutherStyle } from './style';
import { useWorkspaceContext, useModalContext } from '../../context';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ModalStatuses } from '../../types/enum';
import { useRemoveProjectMutation, useRemoveFolderMutation } from '../../graphql/generated/graphql';
import { PROJECTS } from '../../graphql/queries/projects.gql';
import { useDefineAcess } from '../../hooks';

export const HomeEditPopup = () => {
  const {
    selectFolderId,
    selectProjectId,
    setIsEditableFolder,
    setIsEditableProject,
    setSelectProjectId,
    setSelectFolderId,
    projects,
  } = useWorkspaceContext();
  const { handleChangeModalParameters } = useModalContext();

  const theme = useTheme();

  const [removeProjectMutation] = useRemoveProjectMutation({
    variables: {
      id: Number(selectProjectId),
    },
    refetchQueries: [PROJECTS],
    onCompleted: () => {
      setSelectProjectId(null);
    },
  });

  const [removeFolderMutation] = useRemoveFolderMutation({
    variables: {
      id: Number(selectFolderId),
    },
    refetchQueries: [PROJECTS],
    onCompleted: () => {
      setSelectFolderId(null);
    },
  });

  const handleDeleteProject = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.PROJECT_DELETE_MODAL,
      },
      {
        key: 'removeProject',
        value: removeProjectMutation,
      },
    ]);
  };
  const handleDeleteFolder = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.FOLDER_DELETE_MODAL,
      },
      {
        key: 'removeFolder',
        value: removeFolderMutation,
      },
    ]);
  };
  if (!selectFolderId && !selectProjectId) return null;

  const isProject = !selectFolderId && selectProjectId;
  const isFolder = selectFolderId && selectProjectId;

  const calcAuthorId = () => {
    if (!projects?.length) return;
    const selectedProject = projects.find((e) => e.id === selectProjectId);
    if (isFolder) {
      return selectedProject?.folders?.find((e) => e.id === selectFolderId)?.authorId;
    }
    if (isProject) {
      return selectedProject?.authorId;
    }
  };

  const { isProjectAcess, isEditFolderAcess } = useDefineAcess({ authorId: calcAuthorId() });

  if ((isProject && !isProjectAcess) || (!isEditFolderAcess && isFolder)) return null;

  return (
    <PositionedPopper
      ClickComponent={
        <Button variant='icons'>
          <MoreHorizIcon />
        </Button>
      }
      PopperContent={
        <StackPopperOutherStyle>
          <StackPopperStyle
            onClick={() => {
              !selectFolderId ? setIsEditableProject(true) : setIsEditableFolder(true);
            }}
          >
            <EditOutlinedIcon sx={{ color: theme.palette.textIcon.main }} />
            <Typography pl={1} color='black600'>
              Переименовать
            </Typography>
          </StackPopperStyle>

          <StackPopperStyle
            onClick={() => {
              !selectFolderId ? handleDeleteProject() : handleDeleteFolder();
            }}
          >
            <DeleteOutlineOutlinedIcon sx={{ color: theme.palette.textIcon.main }} />
            <Typography pl={1} color='black600'>
              Удалить
            </Typography>
          </StackPopperStyle>
        </StackPopperOutherStyle>
      }
    />
  );
};
