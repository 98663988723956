import { ListStyle } from './style';

import { useCreateProjectMutation, VisibilityStatusEnum } from '../../../graphql/generated/graphql';
import { Stack, Typography, useTheme } from '@mui/material';
import { useModalContext, useWorkspaceContext } from '../../../context/index';
import { ModalStatuses } from '../../../types/enum';
import { IconButtonStyle } from '../../index';
import { PROJECTS } from '../../../graphql/queries/projects.gql';
import { ListItemButtonWrapper } from './ListItemButtonWrapper/ListItemButtonWrapper';
import { MembersType } from '../../../types/types';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useDefineAcess } from '../../../hooks';

export const ProjectList = () => {
  const { workspaceId, projects, currentWorkspace } = useWorkspaceContext();

  const [createProjectMutation] = useCreateProjectMutation({
    refetchQueries: [PROJECTS],
  });

  const { handleChangeModalParameters } = useModalContext();

  const handleProjectMjdal = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.PROJECT_MODAL,
      },
      {
        key: 'workspaceId',
        value: workspaceId,
      },
      {
        key: 'workspaceName',
        value: currentWorkspace?.title,
      },
      {
        key: 'createProject',
        value: (value: { title: string; visibilityStatus: boolean; members: MembersType }) => {
          createProjectMutation({
            variables: {
              createProjectInput: {
                title: value.title,
                visibilityStatus: value.visibilityStatus
                  ? VisibilityStatusEnum.Public
                  : VisibilityStatusEnum.Team,
                workspaceId: Number(workspaceId),
                members: !value.visibilityStatus ? value.members : undefined,
              },
            },
          });
        },
      },
    ]);
  };

  const theme = useTheme();

  const { isProjectAcess } = useDefineAcess({});

  return (
    <Stack>
      <Stack
        sx={{
          color: theme.palette.textPrimary.main,
          padding: '4px 8px',
        }}
      >
        <Stack direction='row' alignItems='center'>
          <Typography color='textPrimary.main' fontWeight={500}>
            Проекты
          </Typography>
          {isProjectAcess && (
            <IconButtonStyle onClick={handleProjectMjdal}>
              <AddRoundedIcon
                sx={{
                  color: theme.palette.black600,
                }}
              />
            </IconButtonStyle>
          )}
        </Stack>
      </Stack>
      <ListStyle sx={{ padding: '0px' }}>
        {projects?.map((e) => {
          return (
            <ListItemButtonWrapper
              projectTitle={e?.title}
              key={e?.id}
              projectId={e.id}
              folders={e?.folders}
              visibilityStatus={e.visibilityStatus}
            />
          );
        })}
      </ListStyle>
    </Stack>
  );
};
