import { Stack, Typography, useTheme, Select } from '@mui/material';
import { useState } from 'react';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import { StackItemStyle } from './style';
import { FilterFileType } from '../../../../types/enum';

type SelectFileType = {
  filter: FilterFileType;
  setFilter: (value: FilterFileType) => void;
};

export const SelectFile = ({ filter, setFilter }: SelectFileType) => {
  const options = [
    { title: 'Фото', Icon: ImageOutlinedIcon, value: FilterFileType.Image },
    { title: 'Файлы', Icon: InsertDriveFileOutlinedIcon, value: FilterFileType.File },
    { title: 'Ссылки', Icon: InsertLinkRoundedIcon, value: FilterFileType.Link },
  ];

  const handleChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value as FilterFileType);
  };
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Select
      value={filter}
      onChange={handleChange}
      variant='outlined'
      size='medium'
      sx={{ backgroundColor: isOpen ? theme.palette.backgroundLevel1.main : '' }}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      renderValue={(renderValue) => {
        const select = options.find((e) => e.value === renderValue);
        if (!select) return <></>;
        const { title, Icon } = select;
        return (
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Icon
              sx={{
                width: '24px',
                height: '24px',
                margin: '0px',
                color: theme.palette.neutral500.main,
              }}
            />
            <Typography sx={{ padding: '0px 8px', color: theme.palette.textTertiary.main }}>
              {title}
            </Typography>
          </Stack>
        );
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          sx: {
            marginTop: '5px',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
            background: theme.palette.backgroundSurface.main,
            boxShadow: theme.palette.boxShadowPopper.main,
            '& .MuiList-root': {
              padding: '4px 0px',
              width: '220px',
            },
            '& .Mui-selected': {
              backgroundColor: theme.palette.neutral200.main,
            },
          },
        },
      }}
    >
      {options?.map((e, index: number) => {
        const { title, Icon, value } = e;
        return (
          <MenuItem
            value={value}
            key={index}
            sx={{
              padding: '0px',
              '&:hover': {
                backgroundColor: theme.palette.neutralPlainHoverBg.main,
              },
            }}
          >
            <StackItemStyle>
              <Icon sx={{ marginRight: '14px' }} />
              <Typography sx={{ color: theme.palette.black600 }}> {title}</Typography>
            </StackItemStyle>
          </MenuItem>
        );
      })}
    </Select>
  );
};
