import { styled, Popper, Paper } from '@mui/material';

export const PopperStyle = styled(Popper)(({ theme }) => ({
  zIndex: '9999',
  '&.MuiStack-root': {
    boxShadow: theme.palette.boxShadowPopper.main,

    border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
    borderRadius: '8px',
  },
}));

export const PaperStyle = styled(Paper)(({ theme }) => ({
  borderRadius: '8px',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  background: theme.palette.backgroundSurface.main,
  boxShadow: theme.palette.boxShadowPopper.main,
}));
