import { StatusThreadEditItemRHF } from '../../index';
import { useForm, FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { UpdateThreadMutationFn } from '../../../graphql/generated/graphql';

type EditThreadTitleType = {
  name: string;
  statusId?: number;
  updateThreadMutation: UpdateThreadMutationFn;
  threadId?: number;
};

export const EditThreadStatus = ({
  name,
  statusId,
  threadId,
  updateThreadMutation,
}: EditThreadTitleType) => {
  type FormData = {
    statusId: number;
  };

  const [isOpen, setIsOpen] = useState(false);

  const methods = useForm<FormData>({
    defaultValues: {
      statusId: statusId,
    },
  });
  const { getValues, trigger, reset, watch } = methods;

  const status = watch('statusId');

  useEffect(() => {
    if (isOpen && threadId) {
      const validateAndSubmit = async () => {
        const isValid = await trigger();
        if (isValid) {
          const currentValues = getValues();
          updateThreadMutation({
            variables: {
              data: {
                id: threadId,
                statusId: currentValues.statusId,
              },
            },
          });
        } else {
          reset({ statusId: statusId });
        }
      };

      validateAndSubmit();
    }
  }, [status]);

  useEffect(() => {
    if (statusId) {
      methods.reset({
        statusId: statusId,
      });
    }
  }, [statusId]);

  return (
    <FormProvider {...methods}>
      <Box
        sx={{ width: '100%', height: '100%' }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <StatusThreadEditItemRHF sx={{ width: '100%', height: '100%' }} name={name} />
      </Box>
    </FormProvider>
  );
};
