import { styled, Stack, Typography, Breadcrumbs } from '@mui/material';

export const BreadcrumbsStyle = styled(Breadcrumbs)(() => ({
  '& .MuiBreadcrumbs-separator': { color: 'black600' },
  padding: '8px',
  '& .MuiBox-root': {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

export const TypographyStyle = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: 'black600',
}));

export const StackStyle = styled(Stack)(({ theme }) => ({
  padding: '3px 8px',
  flexDirection: 'row',
  cursor: 'pointer',
  alignItems: 'center',
  width: '220px',
  '&:hover': {
    borderRadius: '6px',
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
  },
}));
