import { StyledTableCellDrag } from '../../style';
import { PositionedPopper } from '../../../index';
import { IconButton, useTheme, Typography } from '@mui/material';
import { StackStyle } from './style';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ModalStatuses } from '../../../../types/enum';
import { useModalContext } from '../../../../context';

type RemoveCellType = {
  emptyCellWidth: string;
  threadId?: number;
  handleDeleteFolder?: () => void;
  isAcess?: boolean;
};

export const RemoveCell = ({
  emptyCellWidth,
  threadId,
  handleDeleteFolder,
  isAcess = true,
}: RemoveCellType) => {
  const theme = useTheme();

  const { handleChangeModalParameters } = useModalContext();

  const handleDeleteThread = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.THREAD_DELETE_MODAl,
      },
      {
        key: 'threadId',
        value: threadId,
      },
    ]);
  };

  const handleRemove = () => {
    handleDeleteFolder ? handleDeleteFolder() : handleDeleteThread();
  };

  return (
    <StyledTableCellDrag padding='none' sx={{ minWidth: emptyCellWidth }}>
      <PositionedPopper
        sxPopper={{
          '&:hover': {
            backgroundColor: theme.palette.neutralPlainHoverBg.main,
          },
        }}
        ClickComponent={
          isAcess ? (
            <IconButton sx={{ padding: '0px', visibility: 'hidden' }}>
              <MoreHorizIcon />
            </IconButton>
          ) : null
        }
        PopperContent={
          <StackStyle onClick={handleRemove}>
            <DeleteOutlineOutlinedIcon
              sx={{
                marginRight: '8px',
                color: theme.palette.neutral500.main,
              }}
            />
            <Typography color='black600'>Удалить</Typography>
          </StackStyle>
        }
        placement='bottom-end'
      />
    </StyledTableCellDrag>
  );
};
