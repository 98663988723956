import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ProjectNameSchema } from '../../../../validation/index';
import {
  useUpdateProjectMutation,
  useRemoveProjectMutation,
} from '../../../../graphql/generated/graphql';
import { PROJECTS } from '../../../../graphql/queries/projects.gql';

type ProjectEditableType = {
  projectTitle: string;
  isEditable: boolean;
  projectId: number;
  setSelectProjectId: (value: number | null) => void;
};

export const useProjectEditable = ({
  projectTitle,
  isEditable,
  projectId,
  setSelectProjectId,
}: ProjectEditableType) => {
  interface FormData {
    title: string;
  }

  const [updateProjectMutation] = useUpdateProjectMutation({
    refetchQueries: [PROJECTS],
  });
  const [removeProjectMutation] = useRemoveProjectMutation({
    variables: {
      id: projectId,
    },
    refetchQueries: [PROJECTS],
    onCompleted: () => {
      setSelectProjectId(null);
    },
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(ProjectNameSchema),
    defaultValues: {
      title: projectTitle,
    },
  });
  const onSubmit: SubmitHandler<FormData> = (data) => {
    updateProjectMutation({
      variables: {
        updateProjectInput: { id: projectId, title: data.title },
      },
    });
  };
  const { getValues, trigger, reset } = methods;
  const currentValues = getValues();
  useEffect(() => {
    const validateAndSubmit = async () => {
      const isValid = await trigger();
      if (isValid) {
        const currentValues = getValues();
        onSubmit(currentValues);
      } else {
        reset({ title: projectTitle });
      }
    };

    if (currentValues?.title !== projectTitle) {
      validateAndSubmit();
    }
  }, [isEditable]);

  return { methods, onSubmit, removeProjectMutation };
};
