type TextStyleType = {
  fontSize?: string;
  fontFamily?: string;
  fontWeight?: string;
};
type TextSizeType = {
  width: number;
  height: number;
};

const DEFAULT_STYLES = { fontSize: '14px', fontFamily: 'Inter', fontWeight: '400' };

export const measureTextSize = (
  text: string,
  styles: TextStyleType = DEFAULT_STYLES,
): TextSizeType => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return { width: 0, height: 0 };
  context.font = `${styles.fontWeight || '400'} ${styles.fontSize || '14px'} ${
    styles.fontFamily || 'Inter'
  }`;

  const metrics = context.measureText(text);
  const height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

  return { width: metrics.width, height };
};
