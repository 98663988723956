import { gql } from '@apollo/client';

export const USER_GROUP = gql`
  query userGroup($userGroupId: Int!) {
    userGroup(id: $userGroupId) {
      avatar {
        path
        id
      }
      id
      users {
        id
        firstName
        lastName
        avatar {
          path
        }
        workspaces {
          myRole
        }
      }
      title
    }
  }
`;
