import React from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import {
  useEditUserMutation,
  useRemoveFileMutation,
} from '../../../../../../../../../graphql/generated/graphql';
import { SizeOptions } from '../../../../../../../../../types/enum';
import { FormWrapper } from '../style';
import { EditAvatar } from '../../../../EditAvatar/EditAvatar';
import { useAuthContext } from '../../../../../../../../../context';
import { TextFieldRHFStyle } from '../../../../../../../../WorkspaceHubElements/style';
import { EditUserSchema } from '../../../../../../../../../validation/editUser.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { CURRENT_USER } from '../../../../../../../../../graphql/queries/currentUser.gql';
import { LoadingButton } from '@mui/lab';
import { WORKSPACE } from '../../../../../../../../../graphql/queries/workspace';

interface IFormData {
  firstName: string;
  lastName: string;
}

export const MyDataForm = () => {
  const { currentUser } = useAuthContext();
  const [editUserMutation, { loading }] = useEditUserMutation({
    refetchQueries: [CURRENT_USER, WORKSPACE],
  });
  const [removeFileMutation] = useRemoveFileMutation({
    update(cache) {
      cache.evict({
        id: `File:${currentUser?.avatar?.id as number}`,
      });
    },
  });

  const methods = useForm<IFormData>({
    resolver: yupResolver(EditUserSchema),
    defaultValues: {
      firstName: String(currentUser?.firstName),
      lastName: String(currentUser?.lastName),
    },
  });
  const {
    formState: { isValid },
  } = methods;
  const handleUpdateAvatar = (image: File) => {
    editUserMutation({
      variables: {
        data: {
          image: image,
        },
      },
    });
  };
  const handleRemoveAvatar = () => {
    currentUser?.avatar?.id &&
      removeFileMutation({
        variables: {
          id: currentUser?.avatar?.id,
        },
      });
  };

  const onSubmit: SubmitHandler<IFormData> = (data) => {
    editUserMutation({
      variables: {
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
        },
      },
    });
  };
  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
        <EditAvatar
          title={`${currentUser?.firstName} ${currentUser?.lastName}` || currentUser?.email}
          handleUpdateAvatar={handleUpdateAvatar}
          imageUrl={currentUser?.avatar?.path}
          handleRemoveAvatar={handleRemoveAvatar}
        />
        <TextFieldRHFStyle
          name='firstName'
          type='text'
          placeholder='Имя'
          size={SizeOptions.small}
          sx={{
            marginTop: '24px',
          }}
        />
        <TextFieldRHFStyle
          name='lastName'
          type='text'
          placeholder='Фамилия'
          size={SizeOptions.small}
          sx={{
            marginTop: '4px',
          }}
        />
        <TextFieldRHFStyle
          isDisabled
          name='email'
          type='text'
          placeholder='mail@example.com'
          size={SizeOptions.small}
          value={String(currentUser?.email)}
          sx={{
            marginTop: '4px',
          }}
        />
        <LoadingButton
          loading={loading}
          disabled={!isValid}
          variant='contained'
          type='submit'
          sx={{ marginTop: '24px', height: '32px' }}
        >
          Сохранить
        </LoadingButton>
      </FormWrapper>
    </FormProvider>
  );
};
