import { FormControl, Button, Menu, useTheme } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import { PathThreadWrapper } from '../PathThreadWrapper/PathThreadWrapper';
import { useFormContext, useController } from 'react-hook-form';
import { PathThreadSelectType } from '../../../types/types';
import { PathThreadBreadCrumbs } from '../PathThreadBreadCrumbs/PathThreadBreadCrumbs';
import { useProjectsQuery } from '../../../graphql/generated/graphql';

type PathThreadSelectModalRHFType = {
  workspaceId?: number;
  name: string;
};

export const PathThreadSelectModalRHF = ({ workspaceId, name }: PathThreadSelectModalRHFType) => {
  const methods = useFormContext();

  const {
    field: { value, onChange },
  } = useController({
    name,
    control: methods.control,
  });

  return <PathThreadSelectModal workspaceId={workspaceId} onChange={onChange} value={value} />;
};

type PathThreadSelectModalType = {
  value?: PathThreadSelectType;
  workspaceId?: number;
  onChange: (value: PathThreadSelectType) => void;
};
export const PathThreadSelectModal = ({
  workspaceId,
  onChange,
  value,
}: PathThreadSelectModalType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDisable, setIsDisable] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (isDisable) return;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const { data } = useProjectsQuery({
    variables: {
      options: {
        workspaceId: Number(workspaceId),
      },
    },
    skip: !workspaceId,
  });

  useEffect(() => {
    setIsDisable(!data?.projects.length);
  }, [data]);

  const theme = useTheme();
  return (
    <FormControl>
      <Button
        sx={{
          background: open ? theme.palette.backgroundLevel1.main : theme.palette.white.main,
          borderColor: isDisable
            ? theme.palette.neutral200.main
            : theme.palette.neutralOutlinedBorder.main,
        }}
        variant='popper'
        ref={buttonRef}
        onClick={handleClick}
      >
        <BusinessCenterRoundedIcon
          fontSize='small'
          sx={{
            marginRight: '6px',
            color: isDisable
              ? theme.palette.neutralPlainDisabledColor.main
              : theme.palette.textTertiary.main,
          }}
        />
        <PathThreadBreadCrumbs value={value} isDisable />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiList-root': { paddingTop: '0px' } }}
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '5px',
              borderRadius: '6px',
              border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
              background: theme.palette.backgroundSurface.main,
              boxShadow: theme.palette.boxShadowPopper.main,
              '& .MuiList-root': {
                padding: '4px 0px',
              },
            },
          },
        }}
      >
        <PathThreadWrapper onChange={onChange} workspaceId={workspaceId} />
      </Menu>
    </FormControl>
  );
};
