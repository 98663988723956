import React, { useRef } from 'react';
import { Chip, Stack, useTheme, Button } from '@mui/material';
import { SizeOptions } from '../../../../../../types/enum';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TextFieldStyle } from './style';
import { SubTitle, FormWrapper } from '../../style';
import { useInviteToWorkspaceMutation } from '../../../../../../graphql/generated/graphql';
import { useWorkspace } from '../../../../../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { inviteToWorkspaceSchemaEmail } from '../../../../../../validation/inviteToWorkspace.schema';
import { useModalContext } from '../../../../../../context';
import { WORKSPACE } from '../../../../../../graphql/queries/workspace';
import { Maybe } from 'yup';

interface IFormData {
  email?: Maybe<string>;
  listEmails?: string[];
}

export const InviteByEmailForm = () => {
  const isFormValid = useRef(false);
  const theme = useTheme();
  const { currentWorkspace } = useWorkspace();
  const { handleAlertChangeParameter } = useModalContext();
  const methods = useForm<IFormData>({
    resolver: yupResolver(inviteToWorkspaceSchemaEmail),
    defaultValues: {
      email: '',
      listEmails: [],
    },
  });
  const {
    watch,
    setValue,
    trigger,
    formState: { isValid },
    setError,
    reset,
  } = methods;
  const [inviteToWorkspaceMutation] = useInviteToWorkspaceMutation({
    onCompleted: (data) => {
      reset();
      if (data.inviteToWorkspace.failures.length) {
        setValue('listEmails', data.inviteToWorkspace.failures);
        setError('email', {
          type: 'server',
          message:
            data.inviteToWorkspace.failures.length === 1
              ? `Пользователь ${data.inviteToWorkspace.failures[0]} уже участник или приглашен.`
              : `Пользователи с адресами электронной почты ${data.inviteToWorkspace.failures.join(
                  ', ',
                )} уже участники или приглашены.`,
        });
      }
      data.inviteToWorkspace.successes.length &&
        handleAlertChangeParameter({
          severity: 'success',
          state: true,
          text: 'Приглашения отправлены',
        });
    },
    refetchQueries: [WORKSPACE],
  });
  const listEmailWatch = watch('listEmails');
  const emailWatch = watch('email');
  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === 'v' && (event.metaKey || event.ctrlKey)) {
      await trigger('email');
    }
    if (event.ctrlKey || event.shiftKey || event.metaKey) {
      return;
    }
    const isValid = await trigger('email');
    isFormValid.current = isValid || !!listEmailWatch?.length;
    if (['Enter', ',', ' '].includes(event.key)) {
      event.preventDefault();
      if (emailWatch && isValid && !listEmailWatch?.includes(emailWatch)) {
        setValue('listEmails', [...(listEmailWatch || []), emailWatch]);
        setValue('email', '');
      }
    }
  };
  const onSubmit: SubmitHandler<IFormData> = (data, event) => {
    event?.preventDefault();
    const listOfEmail = data?.listEmails?.length
      ? (data?.listEmails as string[])
      : ([data?.email] as string[]);
    inviteToWorkspaceMutation({
      variables: {
        data: {
          emails: listOfEmail,
          workspaceId: currentWorkspace!.id,
        },
      },
    });
  };
  const handleDelete = (emailToDelete: string) => () => {
    setValue(
      'listEmails',
      listEmailWatch?.filter((email: string) => email !== emailToDelete),
    );
  };
  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack>
          <SubTitle mt='24px' mb='8px' variant='h4'>
            По почте
          </SubTitle>
          <Stack flexDirection='row' width='100%'>
            <TextFieldStyle
              name='email'
              multiline
              fullWidth
              value={String(emailWatch)}
              size={SizeOptions.small}
              onKeyDown={handleKeyDown}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setValue('email', e.target.value);
                trigger('email');
              }}
              placeholder={listEmailWatch?.length ? '' : 'email@example.com, email@example.com'}
              InputProps={{
                startAdornment: (
                  <>
                    {listEmailWatch?.map((email: string) => (
                      <Chip
                        deleteIcon={<CloseIcon sx={{ color: theme.palette.black600 }} />}
                        key={email}
                        label={email}
                        onDelete={handleDelete(email)}
                        style={{ marginRight: '5px', height: '24px' }}
                      />
                    ))}
                  </>
                ),
              }}
            />
            <Button
              disabled={!isValid || (!emailWatch && !listEmailWatch?.length)}
              size='small'
              variant='contained'
              type='submit'
              sx={{ marginLeft: '8px', minWidth: '105px', borderRadius: '6px' }}
            >
              Пригласить
            </Button>
          </Stack>
        </Stack>
      </FormWrapper>
    </FormProvider>
  );
};
