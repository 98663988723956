import { styled, Stack, Box, Typography } from '@mui/material';

export const BoxOutherStyle = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}));

export const StackInnerStyle = styled(Stack)(({ theme }) => ({
  borderRadius: '6px',
  border: `2px solid ${theme.palette.black600}`,
  background: theme.palette.white.main,
  position: 'absolute',
  top: '-18px',
  left: '-5px',
  zIndex: '9999',
  padding: '6px',
  width: '100%',
}));

export const StackDefaultStyle = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  '&:hover .hidden': {
    visibility: 'visible',
  },
}));

export const TypographyStyle = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
