import React, { createContext, useContext, useState } from 'react';
import { MessageChatType } from '../../../types/types';

type ChatContextType = {
  replayId: number | null;
  setReplayId: (value: number | null) => void;
  typeMessage?: string;
  setTypeMessage: (value: string) => void;
  reply: MessageChatType | null;
  setReply: (value: MessageChatType | null) => void;
  inputHeight: number;
  setInputHeight: (value: number) => void;
  tabIndex: number;
  setTabIndex: (value: number) => void;
};

const ChatContext = createContext<ChatContextType | null>(null);

const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};

type ChatProviderType = {
  children: React.ReactNode;
};

const ChatProvider = ({ children }: ChatProviderType) => {
  const [replayId, setReplayId] = useState<number | null>(null);
  const [typeMessage, setTypeMessage] = useState<string>();
  const [reply, setReply] = useState<MessageChatType | null>(null);
  const [inputHeight, setInputHeight] = useState(50);
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <ChatContext.Provider
      value={{
        setInputHeight,
        inputHeight,
        setReply,
        reply,
        replayId,
        setReplayId,
        typeMessage,
        setTypeMessage,
        tabIndex,
        setTabIndex,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export { ChatProvider, useChatContext };
