import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { StyledTableCellDrag, StyledTableRow } from '../../style';
import { Stack, useTheme } from '@mui/material';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { useModalContext, useWorkspaceContext } from '../../../../context';
import { ModalStatuses, ResponsibleCellTyle } from '../../../../types/enum';
import {
  useRemoveFolderMutation,
  useUpdateFolderMutation,
} from '../../../../graphql/generated/graphql';
import { PROJECT_FOLDER } from '../../../../graphql/queries/projectFolders.gql';
import { FolderTableType } from '../../../../types/types';
import { ResponsibleCell } from '../../Cell/ResponsibleCell/ResponsibleCell';
import { NameCell } from './NameCell/NameCell';
import { ProgressCell } from './ProgressCell/ProgressCell';
import { RemoveCell } from '../../Cell/RemoveCell/RemoveCell';
import { useDefineAcess } from '../../../../hooks';

type TredRowType = {
  emptyWipth: string;
  nameWidth: string;
  item: FolderTableType;
  index: number;
  responsibleWidth: string;
};

export const FolderRow = ({
  emptyWipth,
  nameWidth,
  item,
  index,
  responsibleWidth,
}: TredRowType) => {
  const theme = useTheme();
  const { selectProjectId } = useWorkspaceContext();
  const { handleChangeModalParameters } = useModalContext();

  const [updateFolderMutation] = useUpdateFolderMutation({
    refetchQueries: [PROJECT_FOLDER],
  });

  const [removeFolderMutation] = useRemoveFolderMutation({
    variables: {
      id: item.id,
    },
    refetchQueries: [PROJECT_FOLDER],
  });

  const handleDeleteFolder = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.FOLDER_DELETE_MODAL,
      },
      {
        key: 'removeFolder',
        value: removeFolderMutation,
      },
    ]);
  };

  const { isEditFolderAcess } = useDefineAcess({ authorId: item?.authorId });

  return (
    <Draggable key={`${item.id}`} draggableId={`${item.id}`} index={index}>
      {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        return (
          <StyledTableRow
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            sx={{
              ...draggableProvided.draggableProps.style,
              background: snapshot.isDragging ? theme.palette.white.main : '',
              width: '100%',
            }}
          >
            <StyledTableCellDrag padding='none' sx={{ minWidth: emptyWipth }}>
              <Stack justifyContent={'center'} alignItems={'center'}>
                <div {...draggableProvided.dragHandleProps}>
                  <DragIndicatorOutlinedIcon
                    className='hidden'
                    sx={{
                      visibility: 'hidden',
                    }}
                  />
                </div>
              </Stack>
            </StyledTableCellDrag>
            <NameCell
              nameWidth={nameWidth}
              title={item.title}
              updateFolderMutation={updateFolderMutation}
              folderId={item.id}
              isAcess={isEditFolderAcess}
            />
            <ResponsibleCell
              entityId={item.id}
              responsible={item.responsible}
              responsibleUserGroup={item.responsibleUserGroup}
              updateFolderMutation={updateFolderMutation}
              projectId={Number(selectProjectId)}
              folderId={item.id}
              type={ResponsibleCellTyle.folder}
              responsibleWidth={responsibleWidth}
              isAcess={isEditFolderAcess}
            />

            <ProgressCell threadsStatistics={item.threadsStatistics} />
            <RemoveCell
              emptyCellWidth={emptyWipth}
              handleDeleteFolder={handleDeleteFolder}
              isAcess={isEditFolderAcess}
            />
          </StyledTableRow>
        );
      }}
    </Draggable>
  );
};
