import { ThredRow } from '../BodyThredContent/ThredRow/ThredRow';
import { AddRows } from '../AddRows/AddRows';
import { BodyColapseItem } from './BodyColapseItem/BodyColapseItem';
import { useState } from 'react';
import { ThreadTableType, PathThreadSelectType } from '../../../types/types';
import { ThredRowAdd } from '../BodyThredContent/ThreadRowAdd/ThreadRowAdd';
import { TypeTableRow, ModalStatuses } from '../../../types/enum';
import { useModalContext, useWorkspaceContext } from '../../../context';

export interface DataItem {
  uuid: string;
  description: string;
  unitPrice: number;
  quantity: number;
}

type BodyGoalContentType = {
  emptyCellWidth: string;
  titleCellWidth: string;
  firstProjectThreаdList: Array<ThreadTableType>;
  goalTitle: string;
  goalId: number;
  isDraggingOccurs: boolean;
  path: PathThreadSelectType | null;
};

export const BodyGoalContent = ({
  emptyCellWidth,
  titleCellWidth,
  firstProjectThreаdList,
  goalTitle,
  goalId,
  isDraggingOccurs,
  path,
}: BodyGoalContentType) => {
  const [isOpen, setIsOpen] = useState(true);
  const [createThread, setCreateThread] = useState(false);

  const { workspaceId } = useWorkspaceContext();
  const { handleChangeModalParameters } = useModalContext();

  const hanldeCreateThread = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.THREAD_MODAl,
      },
      {
        key: 'workspaceId',
        value: workspaceId,
      },
      {
        key: 'path',
        value: path,
      },
      {
        key: 'typeTableRow',
        value: TypeTableRow.goal,
      },
    ]);
  };

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <BodyColapseItem
        goalTitle={goalTitle}
        emptyWipth={emptyCellWidth}
        open={isOpen}
        handleClick={handleClick}
        goalId={goalId}
        emptyCellWidth={emptyCellWidth}
      />
      {isOpen ? (
        <>
          {firstProjectThreаdList.map((item, index) => (
            <ThredRow
              key={index}
              emptyCellWidth={emptyCellWidth}
              titleCellWidth={titleCellWidth}
              index={index}
              item={item}
              type={TypeTableRow.goal}
            />
          ))}
          {createThread && (
            <ThredRowAdd
              emptyCellWidth={emptyCellWidth}
              titleCellWidth={titleCellWidth}
              setCreateThread={setCreateThread}
              goalId={goalId}
              type={TypeTableRow.goal}
            />
          )}
          <AddRows
            emptyCellWidth={emptyCellWidth}
            text='Добавить тред'
            onCallbackOuther={() => setCreateThread(true)}
            onCallback={hanldeCreateThread}
            isDraggingOccurs={isDraggingOccurs}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
