import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registrationSchemaWorkspace } from '../../validation';
import { FormWrapper } from '../../components/AuthFormElements/form.style';
import { TextFieldRHF } from '../../components';
import { SizeOptions } from '../../types/enum';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';
import { useCreateWorkspaceMutation } from '../../graphql/generated/graphql';
import { RequestHandler } from '../../components/RequestHandler/RequestHandler';
import { LoadingButton } from '@mui/lab';
import { WORKSPACE } from '../../graphql/queries/workspace';
import { useWorkspaceContext } from '../../context';
import { WORKSPACES } from '../../graphql/queries/workspaces';

interface IFormData {
  workspaceName: string;
}

export const CreateWorkspaceForm = () => {
  const navigate = useNavigate();
  const { setWorkspaceId } = useWorkspaceContext();
  const [createWorkspaceMutation, { loading }] = useCreateWorkspaceMutation({
    onCompleted: (data) => {
      setWorkspaceId(data.createWorkspace.id);
      navigate(ROUTES.home);
    },
    refetchQueries: [WORKSPACE, WORKSPACES],
  });

  const methods = useForm<IFormData>({
    resolver: yupResolver(registrationSchemaWorkspace),
    defaultValues: {
      workspaceName: '',
    },
  });

  const onSubmit: SubmitHandler<IFormData> = (data) => {
    createWorkspaceMutation({
      variables: {
        createWorkspaceInput: {
          title: data.workspaceName,
        },
      },
    });
  };

  return (
    <RequestHandler loading={loading}>
      <FormProvider {...methods}>
        <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
          <TextFieldRHF
            name='workspaceName'
            type='text'
            placeholder='Название рабочей области'
            size={SizeOptions.extraLarge}
            sx={{
              marginTop: '32px',
            }}
          />
          <LoadingButton
            loading={loading}
            variant='contained'
            type='submit'
            sx={{ marginTop: '24px', width: '100%' }}
          >
            Начать работу
          </LoadingButton>
        </FormWrapper>
      </FormProvider>
    </RequestHandler>
  );
};
