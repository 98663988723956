import Paper from '@mui/material/Paper';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DropResult,
  DragStart,
} from 'react-beautiful-dnd';
import { HeaderTable } from '../HeaderTable/HeaderTable';
import { BodyGoalContent } from '../BodyGoalContent/BodyGoalContent';
import { Table, TableBody, TableContainer } from '@mui/material';
import { useFolderQuery, useSortGoalThreadsMutation } from '../../../graphql/generated/graphql';
import { useWorkspaceContext } from '../../../context';
import { useEffect, useState } from 'react';
import { FoldersGoalsTableType, GoalTableType } from '../../../types/types';
import { EmptyRow } from '../BodyGoalContent/EmptyRow/EmptyRow';
import { GoalCreate } from '../BodyGoalContent/GoalCreate/GoalCreate';
import { FOLDER } from '../../../graphql/queries/folder.gql';
import { StatusThreadTypes } from '../../../types/enum';

export interface DataItem {
  uuid: string;
  description: string;
  unitPrice: number;
  quantity: number;
}

type FilterTablesType = {
  emptyCellWidth: string;
  titleCellWidth: string;
  filterStatus: StatusThreadTypes;
};

export const GoalTables = ({ emptyCellWidth, titleCellWidth, filterStatus }: FilterTablesType) => {
  const { selectFolderId } = useWorkspaceContext();

  const [isDraggingOccurs, setIsDraggingOccurs] = useState('');

  const [folderList, setFolderList] = useState<FoldersGoalsTableType>();

  const [sortGoalThreadsMutation] = useSortGoalThreadsMutation({
    refetchQueries: [FOLDER],
  });

  const { data } = useFolderQuery({
    variables: {
      id: Number(selectFolderId),
      dataThread: {
        statusId: filterStatus,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    data?.folder && setFolderList(data?.folder);
  }, [data]);

  const headerOptions = [
    { title: '', width: emptyCellWidth, isEmpty: true },
    { title: 'Название', width: titleCellWidth, isEmpty: false },
    { title: 'Ответственный', isEmpty: false },
    { title: 'Сроки', isEmpty: false },
    { title: 'Проект', isEmpty: false },
    { title: '', width: emptyCellWidth, isEmpty: true },
  ];

  const handleDragStart = (e: DragStart) => {
    setIsDraggingOccurs(e.source.droppableId);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !folderList) return;
    setIsDraggingOccurs('');

    const { source, destination } = result;

    const sourceGoalIndex = folderList.goals.findIndex(
      (goal) => goal.id === parseInt(source.droppableId),
    );
    const destinationGoalIndex = folderList.goals.findIndex(
      (goal) => goal.id === parseInt(destination.droppableId),
    );

    if (sourceGoalIndex < 0 || destinationGoalIndex < 0) return;

    const updatedGoals: GoalTableType[] = JSON.parse(JSON.stringify(folderList.goals));

    const [movedThread] = updatedGoals[sourceGoalIndex].threads.splice(source.index, 1);
    updatedGoals[destinationGoalIndex].threads.splice(destination.index, 0, movedThread);

    // Preparing data for mutations
    const threadSortOrders = updatedGoals.flatMap((goal, index) =>
      goal.threads.map((thread, threadIndex) => ({
        threadId: thread.id,
        order: threadIndex,
        goalId: goal.id,
        newGoalId: destinationGoalIndex === index ? goal.id : null,
      })),
    );

    // We filter, leaving newGoalId only for the moved thread
    const updatedThreadSortOrders = threadSortOrders.map((order) => {
      if (order.threadId === movedThread.id) {
        return { ...order, newGoalId: updatedGoals[destinationGoalIndex].id };
      }
      return { ...order, newGoalId: null };
    });

    sortGoalThreadsMutation({
      variables: {
        data: {
          sortedThreadsInGoals: updatedThreadSortOrders,
        },
      },
    });

    setFolderList((prev) => (prev ? { ...prev, goals: updatedGoals } : undefined));
  };

  const createPath = ({ id, title }: { id: number; title: string }) => {
    return data
      ? {
          project: { id: data.folder.project.id, title: data.folder.project.title },
          folder: {
            id: data.folder.id,
            title: data.folder.title,
          },
          goal: {
            id: id,
            title: title,
          },
        }
      : null;
  };

  return (
    <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 150px)' }}>
      <Table sx={{ minWidth: 700 }} aria-label='customized table' stickyHeader>
        <HeaderTable options={headerOptions} />
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          {folderList?.goals?.map((goal, index) => {
            const path = createPath({ id: goal.id, title: goal.title });
            return (
              <Droppable droppableId={`${goal.id}`} key={goal.id}>
                {(droppableProvided: DroppableProvided) => (
                  <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                    {index !== 0 && <EmptyRow />}
                    <BodyGoalContent
                      emptyCellWidth={emptyCellWidth}
                      titleCellWidth={titleCellWidth}
                      firstProjectThreаdList={goal.threads}
                      goalTitle={goal.title}
                      goalId={goal.id}
                      isDraggingOccurs={goal.id === Number(isDraggingOccurs)}
                      path={path}
                    />
                  </TableBody>
                )}
              </Droppable>
            );
          })}
        </DragDropContext>

        <GoalCreate titleCellWidth={titleCellWidth} emptyCellWidth={emptyCellWidth} />
      </Table>
    </TableContainer>
  );
};
