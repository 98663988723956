import { useForm, FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { UpdateThreadMutationFn } from '../../../graphql/generated/graphql';
import { TableDatePickerRHF } from '../../DatePicker';
import { useClickCellPopup } from '../../../hooks';
import { BoxPickerStyle, StackStyle } from './style';
import { useTheme, Popover, Stack, Typography } from '@mui/material';
import { showElementDate, getFormattedDateWiev } from '../../../utils';
import dayjs from 'dayjs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

type EditThreadResponsibleType = {
  updateThreadMutation: UpdateThreadMutationFn;
  threadId?: number;
  startDate?: string | null;
  endDate?: string | null;
};

export const EditDeadline = ({
  threadId,
  updateThreadMutation,
  startDate,
  endDate,
}: EditThreadResponsibleType) => {
  type FormData = {
    startDate: string;
    endDate: string;
  };

  const { anchorEl, open, handleClick, handleClose, refClickOutside } = useClickCellPopup({});
  const methods = useForm<FormData>({
    defaultValues: {
      startDate: '',
      endDate: '',
    },
  });

  const { getValues, reset } = methods;

  const [isInitDisable, setIsInitDisable] = useState(true);

  useEffect(() => {
    setIsInitDisable(false);
    if (open || isInitDisable || !threadId) return;
    const currentValues = getValues();
    updateThreadMutation({
      variables: {
        data: {
          id: threadId,
          startDate: currentValues.startDate || null,
          endDate: currentValues.endDate || null,
        },
      },
    });
  }, [open]);

  useEffect(() => {
    if (startDate || endDate) {
      reset({
        startDate: startDate || '',
        endDate: endDate || '',
      });
    }
  }, [startDate, endDate]);

  const theme = useTheme();

  return (
    <FormProvider {...methods}>
      <Stack
        sx={{ width: '100%', height: '100%', justifyContent: 'center', padding: '6px 8px' }}
        ref={refClickOutside}
        onClick={handleClick}
      >
        <StackStyle>
          {!open &&
            (startDate || endDate ? (
              <>
                <CalendarTodayIcon
                  sx={{
                    marginRight: '6px',

                    color: theme.palette.orangeColor.main,
                  }}
                />
                <Typography>
                  {showElementDate({
                    startValue: startDate ? getFormattedDateWiev(dayjs(startDate), 'DD MMM') : '',
                    endValue: endDate ? getFormattedDateWiev(dayjs(endDate), 'DD MMM') : '',
                    empty: true,
                  })}
                </Typography>
              </>
            ) : (
              <>
                <CalendarTodayIcon
                  sx={{ color: theme.palette.neutralPlainDisabledColor.main, marginRight: '6px' }}
                />
                <Typography color='textTertiary.main'>Указать</Typography>
              </>
            ))}
        </StackStyle>
      </Stack>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <BoxPickerStyle>
          <TableDatePickerRHF endDateName={'endDate'} startDateName={'startDate'} />
        </BoxPickerStyle>
      </Popover>
    </FormProvider>
  );
};
