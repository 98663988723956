import { gql } from '@apollo/client';

export const PROJECT_FOLDER = gql`
  query projectFolders($id: Int!) {
    project(id: $id) {
      id
      title
      authorId
      membersUserGroups {
        userGroup {
          avatar {
            path
            id
          }
          id
          title
          workspaceId
          users {
            id
            email
            firstName
            lastName
            avatar {
              path
            }
          }
        }
      }
      membersUsers {
        user {
          id
          email
          firstName
          lastName
          avatar {
            path
          }
        }
      }
      folders {
        id
        title
        authorId
        order
        responsible {
          id
          email
          firstName
          lastName
          avatar {
            path
          }
        }
        responsibleUserGroup {
          id
          title
          avatar {
            path
          }
        }
        threadsStatistics {
          completedThreadsCount
          completedThreadsPercent
          threadsCount
        }
      }
    }
  }
`;
