import { styled, ListItemButton, ListItemIcon, List, Typography, ListItem } from '@mui/material';

export const ListItemButtonStyle = styled(ListItemButton)(({ theme }) => ({
  padding: '4px 8px',
  borderRadius: '6px',
  '&:hover': {
    borderRadius: '6px',
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
  },
}));

export const TypographyMessageCount = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.textIcon.main,
  padding: '0px 6px',
}));

export const ListItemStyle = styled(ListItem)(() => ({
  borderRadius: '6px',
  height: '32px',
  marginBottom: '2px',
}));

export const ListItemIconStyle = styled(ListItemIcon)`
  min-width: 24px;
  padding-right: 8px;
`;
export const ListStyle = styled(List)`
  padding: 16px 0px;
`;
