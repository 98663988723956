import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayoutStack, AuthLayoutWrapper } from './style';
import { ROUTES } from '../../constants/constants';

export const InvalidLink = () => {
  const navigation = useNavigate();
  const navigateGoMain = () => {
    navigation(ROUTES.home);
  };
  return (
    <AuthLayoutStack>
      <AuthLayoutWrapper>
        <Stack width='448px'>
          <Typography variant='h1' mb='16px' textAlign='center'>
            Ссылка больше не действительна
          </Typography>
          <Button variant='contained' type='submit' sx={{ width: '100%' }} onClick={navigateGoMain}>
            Вернуться на главную
          </Button>
        </Stack>
      </AuthLayoutWrapper>
    </AuthLayoutStack>
  );
};
