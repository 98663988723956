import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: `1px solid ${theme.palette.backgroundLevel1.main}`,
    boxSizing: 'border-box',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '142%',
    padding: '6px 8px',
    color: theme.palette.neutralPlainDisabledColor.main,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.white.main,
    border: 'none',
    boxSizing: 'border-box',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '142%',
    padding: '6px 4px',
    borderBottom: `1px solid ${theme.palette.backgroundLevel1.main}`,
    '&:hover': {
      borderRadius: '6px',
    },
  },
}));

export const StyledTableCellDrag = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: 'none',
    backgroundColor: theme.palette.white.main,
    width: '32px',
  },
  [`&.${tableCellClasses.body}`]: {
    borderTop: `1px solid ${theme.palette.white.main}`,
    borderBottom: `1px solid ${theme.palette.white.main}`,
    backgroundColor: theme.palette.white.main,
    width: '32px',
    height: '40px',
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '40px',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },

  '&:hover': {
    '& .MuiSvgIcon-root': {
      visibility: 'visible',
    },
  },
  '&:hover td': { backgroundColor: theme.palette.lightHover.main },
}));
