import { styled, Stack, Typography } from '@mui/material';

export const StackStyle = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
export const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  color: theme.palette.black600,
}));

export const FormWrapper = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
