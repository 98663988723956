import { Stack, Button } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import { AddRounded } from '../../icons';
import { ModalStatuses } from '../../../types/enum';
import { useModalContext } from '../../../context/ModalContext';
import { useWorkspaceContext } from '../../../context';

export const DrawerBottom = () => {
  const { handleChangeModalParameters } = useModalContext();
  const { workspaceId } = useWorkspaceContext();

  const hanldeCreateThread = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.THREAD_MODAl,
      },
      {
        key: 'workspaceId',
        value: workspaceId,
      },
    ]);
  };

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <SettingsIcon />
      <Button variant='drawer' onClick={hanldeCreateThread}>
        <AddRounded />
      </Button>
      <NotificationsIcon />
    </Stack>
  );
};
