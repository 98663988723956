import { useEffect, RefObject } from 'react';

type AutoFocusLineEndType = {
  ref: RefObject<HTMLElement | HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>;
  trigger: boolean;
};

export const useAutoFocusLineEnd = ({ ref, trigger }: AutoFocusLineEndType) => {
  useEffect(() => {
    if (!trigger) return;
    const currentElement = ref.current;
    if (!currentElement) return;
    let inputElement: HTMLInputElement | HTMLTextAreaElement | null = null;
    if (
      currentElement instanceof HTMLInputElement ||
      currentElement instanceof HTMLTextAreaElement
    ) {
      inputElement = currentElement;
    } else {
      inputElement = currentElement.querySelector('input, textarea');
    }
    if (inputElement && 'value' in inputElement) {
      const valueLength = inputElement.value.length;
      inputElement.focus();
      inputElement.setSelectionRange(valueLength, valueLength);
    }
  }, [ref, trigger]);
};
