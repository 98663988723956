import { Stack } from '@mui/material';
import { FilesFilterItem } from '../../../../types/types';
import { createAttachmentImage } from '../../../../utils/index';
import { ImgStyle, StackStyle } from './style';
import { useModalContext } from '../../../../context';
import { ModalStatuses } from '../../../../types/enum';
import { useChatContext } from '../../context/ChatContext';

type ImagesType = { files?: Array<FilesFilterItem> };

export const Images = ({ files }: ImagesType) => {
  const { handleChangeModalParameters } = useModalContext();

  const { setReplayId, setTabIndex } = useChatContext();

  const handleClick = (id: number) => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.LIST_IMAGE_MODAL,
      },
      {
        key: 'imageList',
        value: files,
      },
      {
        key: 'selectImageListId',
        value: id,
      },
      {
        key: 'setChatLink',
        value: (value: number) => {
          setTabIndex(0);
          setTimeout(() => {
            setReplayId(value);
          }, 0);
        },
      },
    ]);
  };

  return (
    <StackStyle>
      {files?.map((e) => {
        const { imageUrl } = createAttachmentImage({ mimeType: e.mimeType, path: e.path });
        return (
          <Stack
            key={e.id}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              handleClick(e.id);
            }}
          >
            <ImgStyle src={imageUrl} alt={e.fileName} />
          </Stack>
        );
      })}
    </StackStyle>
  );
};
