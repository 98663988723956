import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registrationSchemaEmail } from '../../../../validation';
import { FormWrapper } from '../../../../components/AuthFormElements/form.style';
import { TextFieldRHF } from '../../../../components';
import { useRegistrationContext } from '../../context/context';
import { REGISTRATION_STEP } from '../../const';
import { SizeOptions } from '../../../../types/enum';
import { useSendConfirmationEmailMutation } from '../../../../graphql/generated/graphql';
import { LoadingButton } from '@mui/lab';

interface IFormData {
  email: string;
}
enum ServerError {
  USER_ALREADY_EXISTS = 'User already exists',
}
export const AtTheBeginningForm = () => {
  const { handleGoToStep, updateData } = useRegistrationContext();
  const methods = useForm<IFormData>({
    resolver: yupResolver(registrationSchemaEmail),
    defaultValues: {
      email: '',
    },
  });

  const { setError } = methods;

  const [sendConfirmationEmailMutation, { loading: isLoading }] = useSendConfirmationEmailMutation({
    onCompleted: () => {
      handleGoToStep(REGISTRATION_STEP.confirmByEmail);
    },
    onError: (error) => {
      if (error.message === ServerError.USER_ALREADY_EXISTS) {
        setError('email', {
          type: 'server',
          message: 'Этот адрес электронной почты уже используется',
        });
      }
    },
  });
  const onSubmit: SubmitHandler<IFormData> = (data) => {
    sendConfirmationEmailMutation({
      variables: {
        email: data.email,
      },
    });
    updateData({ email: data.email });
  };

  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
        <TextFieldRHF
          name='email'
          type='text'
          placeholder='Почта'
          size={SizeOptions.extraLarge}
          sx={{
            marginTop: '32px',
          }}
        />

        <LoadingButton
          loading={isLoading}
          variant='contained'
          type='submit'
          sx={{ marginTop: '24px', width: '100%' }}
        >
          Продолжить
        </LoadingButton>
      </FormWrapper>
    </FormProvider>
  );
};
