import { Stack, Typography, useTheme } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { StackStyle } from './style';
import { useDefineAcess } from '../../../../hooks';

type ProjectPopperContentType = {
  handleSetIsEditable: (value: boolean) => void;
  handleDeleteProject: () => void;
  handleAddNewFolder: () => void;
};

export const ProjectPopperContent = ({
  handleSetIsEditable,
  handleDeleteProject,
  handleAddNewFolder,
}: ProjectPopperContentType) => {
  const theme = useTheme();

  const { isProjectAcess } = useDefineAcess({});

  return (
    <Stack sx={{ borderRadius: '8px', padding: '4px 0px' }}>
      <StackStyle onClick={handleAddNewFolder}>
        <FolderOutlinedIcon sx={{ color: theme.palette.textIcon.main }} />
        <Typography color='black600' pl={1}>
          Добавить папку
        </Typography>
      </StackStyle>
      {isProjectAcess && (
        <StackStyle onClick={() => handleSetIsEditable(true)}>
          <EditOutlinedIcon sx={{ color: theme.palette.textIcon.main }} />
          <Typography color='black600' pl={1}>
            Переименовать
          </Typography>
        </StackStyle>
      )}
      {isProjectAcess && (
        <StackStyle onClick={handleDeleteProject}>
          <DeleteOutlineOutlinedIcon sx={{ color: theme.palette.textIcon.main }} />
          <Typography color='black600' pl={1}>
            Удалить
          </Typography>
        </StackStyle>
      )}
    </Stack>
  );
};
