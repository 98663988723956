import { useEffect, useRef, useReducer, useState } from 'react';
import { MessageChatType } from '../../../../types/types';
import { TypeMessageChat } from '../../../../types/enum';

import { useChatContext } from '../../context/ChatContext';

enum ScrollActionTypes {
  SCROLL_TO_BOTTOM = 'SCROLL_TO_BOTTOM',
  SCROLL_TO_MESSAGE = 'SCROLL_TO_MESSAGE',
  RESET_SCROLL = 'RESET_SCROLL',
  NEW_MESSAGE = 'NEW_MESSAGE',
}

type ScrollStateType = {
  actionType: null | ScrollActionTypes;
  messageId?: number | null;
  typeMessage?: string;
};

const initialState: ScrollStateType = {
  actionType: null,
  messageId: null,
  typeMessage: '',
};

type ScrollAction =
  | { type: ScrollActionTypes.SCROLL_TO_BOTTOM }
  | { type: ScrollActionTypes.SCROLL_TO_MESSAGE }
  | { type: ScrollActionTypes.RESET_SCROLL }
  | { type: ScrollActionTypes.NEW_MESSAGE };

type ScrollChatType = {
  commentList?: Array<MessageChatType>;
};

export const useScrollChat = ({ commentList }: ScrollChatType) => {
  const refBottom = useRef<HTMLDivElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);

  const scrollReducer = (state: ScrollStateType, action: ScrollAction) => {
    switch (action.type) {
      case ScrollActionTypes.SCROLL_TO_BOTTOM:
        return { actionType: ScrollActionTypes.SCROLL_TO_BOTTOM };
      case ScrollActionTypes.SCROLL_TO_MESSAGE:
        return { actionType: ScrollActionTypes.SCROLL_TO_MESSAGE };
      case ScrollActionTypes.NEW_MESSAGE:
        return {
          actionType: ScrollActionTypes.NEW_MESSAGE,
        };
      case ScrollActionTypes.RESET_SCROLL:
        return { ...initialState };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(scrollReducer, initialState);

  const { replayId, setReplayId, setTypeMessage, typeMessage, inputHeight } = useChatContext();

  const [isUserScrolling, setIsUserScrolling] = useState(false);

  useEffect(() => {
    const messageContainer = refWrapper.current;
    if (!messageContainer) return;

    let isThrottled = false;
    const delay = 100;

    const handleUserScroll = () => {
      if (!isThrottled) {
        isThrottled = true;
        setTimeout(() => {
          if (
            messageContainer.scrollTop + messageContainer.clientHeight >=
            messageContainer.scrollHeight
          ) {
            setIsUserScrolling(false);
          } else {
            setIsUserScrolling(true);
          }
          isThrottled = false;
        }, delay);
      }
    };

    messageContainer.addEventListener('scroll', handleUserScroll);

    return () => {
      messageContainer.removeEventListener('scroll', handleUserScroll);
    };
  }, [commentList, isUserScrolling]);

  const executeScroll = () => {
    switch (state.actionType) {
      case ScrollActionTypes.SCROLL_TO_BOTTOM:
        if (refBottom.current && commentList) {
          refBottom.current.scrollIntoView();
        }
        break;
      case ScrollActionTypes.SCROLL_TO_MESSAGE: {
        if (!replayId) return;
        const element = document.getElementById(replayId?.toString());
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          setReplayId(null);
        }
        break;
      }
      case ScrollActionTypes.NEW_MESSAGE: {
        if (!refBottom.current) return;
        refBottom.current.scrollIntoView();
        setTypeMessage(TypeMessageChat.CLEAN);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    executeScroll();
  }, [state]);

  const isInit = useRef(true);
  useEffect(() => {
    if (!commentList || !isInit.current) return;
    isInit.current = false;
    dispatch({ type: ScrollActionTypes.SCROLL_TO_BOTTOM });
  }, [refBottom.current, commentList]);

  useEffect(() => {
    if (!replayId) return;
    dispatch({ type: ScrollActionTypes.SCROLL_TO_MESSAGE });
  }, [replayId]);

  useEffect(() => {
    if (typeMessage === TypeMessageChat.NEW_MESSAGE && !isUserScrolling) {
      dispatch({ type: ScrollActionTypes.NEW_MESSAGE });
    }
    if (typeMessage === TypeMessageChat.MY_NEW_MESSAGE) {
      dispatch({ type: ScrollActionTypes.NEW_MESSAGE });
    }
  }, [typeMessage]);

  useEffect(() => {
    if (isUserScrolling) return;
    dispatch({ type: ScrollActionTypes.SCROLL_TO_BOTTOM });
  }, [inputHeight]);

  return { refBottom, dispatch, refWrapper };
};
