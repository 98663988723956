import React, { useRef } from 'react';
import { Button, Stack } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButtonStyle } from './style';
import { BASE_URL } from '../../../../../../constants/constants';
import { AvatarCustom } from '../../../../../AvatarCustom/AvatarCustom';
import { User } from '../../../../../../graphql/generated/graphql';

type EditAvatarType = {
  title?: string | null;
  handleUpdateAvatar?: (image: File) => void;
  handleRemoveAvatar?: () => void;
  imageUrl?: string;
};

export const EditAvatar = ({
  title,
  handleUpdateAvatar,
  imageUrl,
  handleRemoveAvatar,
}: EditAvatarType) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      handleUpdateAvatar?.(file);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemove = () => {
    handleRemoveAvatar && handleRemoveAvatar();
  };
  return (
    <Stack flexDirection='row' alignItems='center'>
      <input
        type='file'
        ref={fileInputRef}
        onChange={handleAvatarChange}
        style={{ display: 'none' }}
        accept='image/*'
      />
      <AvatarCustom
        user={{ email: title?.toUpperCase() || '' } as User}
        src={imageUrl ? `${BASE_URL}${imageUrl}` : undefined}
        isOneLether={false}
        sx={{ marginRight: '8px', width: '48px', height: '48px', fontSize: '18px' }}
      />
      <Button size='small' variant='outlined' onClick={handleClick}>
        Сменить аватар
      </Button>
      <IconButtonStyle disabled={!imageUrl} onClick={handleRemove}>
        <DeleteOutlineIcon sx={{ height: '20px', width: '20px' }} />
      </IconButtonStyle>
    </Stack>
  );
};

export default EditAvatar;
