import { Stack, styled, Box } from '@mui/material';

export const StackStyle = styled(Stack)(() => ({
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
}));

export const LimitedWidthBox = styled(Box)(() => ({
  maxWidth: '200px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
