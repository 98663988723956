import { useCallback, useEffect, useState } from 'react';
import { FORGOT_PASSWORD_STEP } from '../pages/ForgotPassword/const';
import { LOCAL_STORAGE_KEY } from '../constants/constants';

export interface IForgotPasswordData {
  stepName: FORGOT_PASSWORD_STEP;
  email: string;
}

export const useForgotPassword = () => {
  const savedData = localStorage.getItem(LOCAL_STORAGE_KEY.FORGOT_PASSWORD_DATA);

  const currentData = savedData
    ? JSON.parse(savedData)
    : { stepName: FORGOT_PASSWORD_STEP.atTheBeginning, email: '' };

  const [forgotPasswordData, setForgotPasswordData] = useState<IForgotPasswordData>(currentData);

  const updateForgotPasswordData = useCallback(
    (newData: Partial<IForgotPasswordData>) => {
      const updatedData = { ...forgotPasswordData, ...newData };
      setForgotPasswordData(updatedData);
      localStorage.setItem(LOCAL_STORAGE_KEY.FORGOT_PASSWORD_DATA, JSON.stringify(updatedData));
    },
    [forgotPasswordData],
  );

  useEffect(() => {
    return () => {
      localStorage.removeItem(LOCAL_STORAGE_KEY.FORGOT_PASSWORD_DATA);
    };
  }, []);

  return { forgotPasswordData, updateForgotPasswordData };
};
