import { BASE_URL } from '../../constants/constants';
import { User } from '../../graphql/generated/graphql';
import { userNameCalc } from '../../utils/index';
import { Avatar, SxProps } from '@mui/material';

type AvatarCustomType = {
  user: User & { title?: string };
  src?: string;
  sx?: SxProps;
  isOneLether?: boolean;
};

export const AvatarCustom = ({ user, src, sx, isOneLether }: AvatarCustomType) => {
  const { email, firstName, lastName, avatar, title } = user;
  const path = avatar?.path;
  const avatarText = userNameCalc({ email, firstName, lastName, title, isOneLether });
  const avatarColor = stringToColor(avatarText);

  if (path || src) {
    return <Avatar src={src ? src : `${BASE_URL}${path}`} sx={{ marginRight: '5px', ...sx }} />;
  }

  return (
    <Avatar
      {...stringAvatar({ text: avatarText, isOneLether })}
      sx={{ marginRight: '5px', backgroundColor: avatarColor, textTransform: 'uppercase', ...sx }}
    />
  );
};

type StringAvatarType = { text: string; isOneLether?: boolean };

export const stringAvatar = ({ text, isOneLether = false }: StringAvatarType) => {
  if (!text) return '';
  const initials = text.split(' ');
  const firstLetter = initials[0][0];
  const secondLetter = initials.length > 1 ? initials[1][0] : '';

  if (isOneLether)
    return {
      children: `${firstLetter}`,
    };

  return {
    children: `${firstLetter}${secondLetter}`,
  };
};

const stringToColor = (string: string) => {
  let hash = 0;
  let color = '#';
  const updateString = string.toLowerCase().trim();

  for (let i = 0; i < updateString.length; i++) {
    hash = updateString.charCodeAt(i) + ((hash << 5) - hash);
  }

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};
