import { styled, Stack } from '@mui/material';

export const StackStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',
  color: theme.palette.black600,
  fontWeight: '400',
  fontSize: '14px',
  '& svg': {
    marginRight: '6px',
  },
}));
