import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../fragments/userFragment.gql';

export const CURRENT_USER = gql`
  ${USER_FRAGMENT}
  query currentUser {
    currentUser {
      ...userFragment
    }
  }
`;
