import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

type ButtonGroupCustomType = {
  groupOptions: {
    text: string;
    value: string;
  }[];
  defaultValue?: string;
  selectAuthorUser: string;
  setSelectAuthorUser: (value: string) => void;
};

export const ButtonGroupCustom = ({
  groupOptions,
  selectAuthorUser,
  setSelectAuthorUser,
}: ButtonGroupCustomType) => {
  return (
    <ButtonGroup variant='table'>
      {groupOptions.map((e) => {
        return (
          <ButtonSelect
            key={e.value}
            value={e.value}
            text={e.text}
            select={selectAuthorUser}
            setSelect={setSelectAuthorUser}
          />
        );
      })}
    </ButtonGroup>
  );
};

type ButtonSelectType = {
  select: string;
  setSelect: (value: string) => void;
  text: string;
  value: string;
};

const ButtonSelect = ({ select, setSelect, text, value }: ButtonSelectType) => {
  const theme = useTheme();

  return (
    <Button
      variant='tableFilter'
      onClick={() => {
        setSelect(value);
      }}
      sx={select === value ? { backgroundColor: theme.palette.backgroundBody.main } : {}}
    >
      {text}
    </Button>
  );
};
