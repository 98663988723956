import { StyledTableCellDrag, StyledTableRow, StyledTableCell } from '../../style';
import { NameCreateCell } from '../FolderRow/NameCreateCell/NameCreateCell';

type CreateFolderRowType = {
  emptyCellWidth: string;
  titleCellWidth: string;
  setIsAddFolder: (value: boolean) => void;
};

export const CreateFolderRow = ({
  emptyCellWidth,
  titleCellWidth,
  setIsAddFolder,
}: CreateFolderRowType) => {
  return (
    <StyledTableRow>
      <StyledTableCellDrag sx={{ minWidth: emptyCellWidth }}></StyledTableCellDrag>
      <NameCreateCell nameWidth={titleCellWidth} setIsAddFolder={setIsAddFolder} />
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCellDrag sx={{ minWidth: emptyCellWidth }}></StyledTableCellDrag>
    </StyledTableRow>
  );
};
