import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import create from 'zustand';

import { API_HOST, LOCAL_STORAGE_KEY, SOCKET_HOST } from '../constants/constants';
import { WebSocketLink } from './ws-link';

type ErrorType = {
  hasError: boolean;
  error: any;
  date: number | undefined;
};

export const useErrorsStore = create<ErrorType>(() => ({
  hasError: false,
  error: undefined,
  date: undefined,
}));

const wsLink = new WebSocketLink({
  url: SOCKET_HOST,
  lazy: true,
  retryAttempts: 1,
  connectionParams: () => {
    const token =
      sessionStorage.getItem(LOCAL_STORAGE_KEY.TOKEN) ||
      localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN);

    return {
      authorization: `Bearer ${token}`,
    };
  },
});

const httpLink = createUploadLink({
  uri: API_HOST,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    for (const graphQLError of graphQLErrors) {
      console.info('graphQLError: ', graphQLError);

      if (graphQLError.message === 'Context creation failed: INVALID_TOKEN') {
        localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
        sessionStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
      }

      useErrorsStore.setState({
        hasError: true,
        error: graphQLError,
        date: Date.now(),
      });
    }
  } else if (networkError) {
    console.info('networkError: ', networkError);

    useErrorsStore.setState({
      hasError: true,
      error: networkError,
      date: Date.now(),
    });
  }
});

const authLink = setContext((_, { headers }) => {
  const token =
    sessionStorage.getItem(LOCAL_STORAGE_KEY.TOKEN) ||
    localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'apollo-require-preflight': '*',
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache(),
});
