import { Button, Stack, Typography } from '@mui/material';
import { useWorkspaceContext } from '../../../context';
import { PositionedPopper, AvatarCustom } from '../../index';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { MemberTypes, OutsideClickFlagType } from '../../../types/enum';
import { useState } from 'react';
import { TypographyStyle, StackMemberStyle } from '../style';
import {
  User,
  useRemoveMemberFromFolderMutation,
  useRemoveMemberFromProjectMutation,
} from '../../../graphql/generated/graphql';
import { useClickOutside } from '../../../hooks';
import { UserType } from '../EditAccess';

export const MemberItem = ({ member, isLastItem }: { member: UserType; isLastItem: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectFolderId, selectProjectId } = useWorkspaceContext();

  const [removeMemberFromFolderMutation] = useRemoveMemberFromFolderMutation({});

  const [removeMemberFromProjectMutation] = useRemoveMemberFromProjectMutation({});

  const handleRemove = () => {
    if (selectFolderId) {
      removeMemberFromFolderMutation({
        variables: {
          data: {
            folderId: Number(selectFolderId),
            member: {
              userGroupId: member.type === MemberTypes.groups ? member.value : null,
              userId: member.type === MemberTypes.user ? member.value : null,
            },
          },
        },
      });
    } else {
      removeMemberFromProjectMutation({
        variables: {
          data: {
            projectId: Number(selectProjectId),
            member: {
              userGroupId: member.type === MemberTypes.groups ? member.value : null,
              userId: member.type === MemberTypes.user ? member.value : null,
            },
          },
        },
      });
    }
  };

  const { refClickOutside } = useClickOutside({
    callBackAnyClick: () => {
      setIsOpen(false);
    },
    callBack: () => {},
  });

  return (
    <StackMemberStyle
      key={member.title}
      sx={{ marginBottom: isLastItem ? '' : '16px' }}
      ref={refClickOutside}
    >
      <Stack flexDirection={'row'} alignItems={'center'}>
        <AvatarCustom
          sx={{ width: '32px', height: '32px' }}
          user={{ email: member.title, avatar: member.avatar } as User}
        />
        <Typography sx={{ color: 'black600' }}>{member.title}</Typography>
      </Stack>
      <PositionedPopper
        placement='bottom'
        isOutControll
        outOpen={isOpen}
        setOutOpen={setIsOpen}
        ClickComponent={
          <Button variant='cancel' sx={{ backgroundColor: 'initial' }}>
            <Typography sx={{ fontWeight: '500' }}>Полный доступ</Typography>
            <KeyboardArrowDownRoundedIcon />
          </Button>
        }
        PopperContent={
          <Stack
            sx={{ width: '155px', padding: '4px 0px' }}
            className={OutsideClickFlagType.outsideClickFlagDefault}
            onClick={handleRemove}
          >
            <TypographyStyle>Удалить</TypographyStyle>
          </Stack>
        }
      />
    </StackMemberStyle>
  );
};
