import { IconButton, Stack, Typography } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useClickOutside } from '../../../../../hooks';
import { TextFieldRHF } from '../../../../uiComponents';
import { SizeOptions } from '../../../../../types/enum';
import { memo, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useUpdateMessageMutation } from '../../../../../graphql/generated/graphql';
import { StackStyle, StackButtonStyle } from './style';
import { urlRegex } from '../../../../../constants/constants';

type ContentType = {
  content?: string | null;
  setIsEditContent: (valud: boolean) => void;
  isEditContent: boolean;
  messageId?: number;
};

export const ContentMemo = ({
  content,
  isEditContent,
  setIsEditContent,
  messageId,
}: ContentType) => {
  type FormDataType = {
    content?: string | null;
  };
  const methods = useForm<FormDataType>({
    defaultValues: {
      content: content,
    },
  });

  const { reset, getValues } = methods;

  const { refClickOutside } = useClickOutside({
    callBack: () => {
      setIsEditContent(false);
      reset({
        content: content,
      });
    },
  });

  useEffect(() => {
    reset({
      content: content,
    });
  }, [content]);

  const handleClose = () => {
    reset({
      content: content,
    });
    setIsEditContent(false);
  };

  const [updateMessageMutation] = useUpdateMessageMutation();

  const handleSubmit = () => {
    const currenvValue = getValues();
    updateMessageMutation({
      variables: {
        id: Number(messageId),
        data: {
          content: currenvValue.content,
        },
      },
    });
    setIsEditContent(false);
  };

  const renderTextWithLinks = (text: string) => {
    const parts = text.split(urlRegex).filter((part) => part && !/^(https?|)$/.test(part));

    return parts.map((part, index) => {
      const isUrl = urlRegex.test(part);
      urlRegex.lastIndex = 0;
      return isUrl ? (
        <a href={part} key={index} target='_blank' rel='noopener noreferrer'>
          {part}
        </a>
      ) : (
        part
      );
    });
  };

  return (
    <Stack>
      {isEditContent ? (
        <StackStyle ref={refClickOutside}>
          <FormProvider {...methods}>
            <TextFieldRHF
              name='content'
              size={SizeOptions.small}
              multiline
              sx={{
                '& .MuiInputBase-input': { padding: '0px', lineHeight: '142%' },
              }}
            />
          </FormProvider>
          <StackButtonStyle>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <IconButton sx={{ marginLeft: '4px' }} onClick={handleSubmit}>
              <CheckIcon />
            </IconButton>
          </StackButtonStyle>
        </StackStyle>
      ) : (
        <Typography
          color='textPrimary.main'
          sx={{
            lineHeight: '142%',
            whiteSpace: 'pre-line',
          }}
        >
          {renderTextWithLinks(content || '')}
        </Typography>
      )}
    </Stack>
  );
};

export const Content = memo(ContentMemo);
