import { Box, styled } from '@mui/material';

export const NavigationItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '28px',
  borderRadius: '6px',
  cursor: 'pointer',
  paddingLeft: '8px',
  '&:hover': {
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
  },
  '&.isSelect': {
    backgroundColor: theme.palette.backgroundLevel1.main,
  },
}));
