import { styled, Stack, Checkbox, IconButton } from '@mui/material';

export const CheckboxStyle = styled(Checkbox)(() => ({
  padding: '0px',
  width: '20px',
  height: '20px',
  marginTop: '2px',
  marginBottom: 'auto',
  '& .MuiBox-root': { width: '20px', height: '20px' },
}));

export const StackWrapperStyle = styled(Stack)(() => ({
  padding: '6px 8px',
  alignItems: 'center',
  flexDirection: 'row',
  '&:hover': {
    '& .hidden': {
      visibility: 'visible',
    },
  },
}));

export const IconButtonStyle = styled(IconButton)(() => ({
  padding: '0px',
  visibility: 'hidden',
  marginBottom: 'auto',
}));
