import { styled, Stack } from '@mui/material';

export const StackStyle = styled(Stack)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  height: 'fit-content',
  maxWidth: '560px',
  maxHeight: '560px',
}));

export const ImageStyle = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
  width: 'auto',
  height: 'auto',
  objectFit: 'contain',
}));
