import { Button, Stack, Typography } from '@mui/material';
import { ROUTES } from '../../../../constants/constants';
import { PersonalDetailsForm } from './Form';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useRegistration } from '../../../../hooks/useRegistration';
import { useRegistrationContext } from '../../context/context';
import { REGISTRATION_STEP } from '../../const';

export const PersonalDetails = () => {
  const navigate = useNavigate();
  const { registrationData } = useRegistration();
  const { handleGoToStep } = useRegistrationContext();
  const handleNavigateToLogin = () => {
    navigate(ROUTES.login);
    handleGoToStep(REGISTRATION_STEP.atTheBeginning);
  };
  return (
    <>
      <Typography variant='h1' mb='8px'>
        Создать аккаунт
      </Typography>
      <Typography variant='h4' component='h4' mb='32px'>
        Введите данные для регистрации
      </Typography>
      <PersonalDetailsForm />
      {!registrationData.isInvitedUserAccessToken && (
        <Stack flexDirection='row' mt='24px'>
          <Typography variant='h4' mr='4px'>
            Уже есть аккаунт?
          </Typography>
          <Button onClick={handleNavigateToLogin} variant='text'>
            Войти
          </Button>
        </Stack>
      )}
    </>
  );
};
