import { IconButton, styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';

export const AvatarStyle = styled(Avatar)({
  marginRight: '8px',
  width: '48px',
  height: '48px',
  fontSize: '18px',
});
export const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  marginLeft: '8px',
  width: '32px',
  height: '32px',
  fontSize: '18px',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  '&:hover': {
    background: 'transparent',
  },
}));
