import { Stack, styled, LinearProgress, Typography } from '@mui/material';

export const StackStyle = styled(Stack)`
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 32px;
  align-items: center;
`;

export const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  width: '160px',
  borderRadius: '6px',
  backgroundColor: theme.palette.backgroundLevel1.main,
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.black600,
  },
}));

export const TypographyStyle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  marginLeft: '16px',
  color: theme.palette.black600,
}));
