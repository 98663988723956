type ShowElementDateType = {
  startValue?: string;
  endValue?: string;
  empty?: boolean;
};

export const showElementDate = ({ startValue, endValue, empty = false }: ShowElementDateType) => {
  if (startValue && endValue) {
    return `${startValue} - ${endValue}`;
  }
  if (!startValue && endValue) {
    return `до ${endValue}`;
  }
  if (startValue && !endValue) {
    return `с ${startValue}`;
  }
  if (empty) return '';
  return 'Сроки';
};
