import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

export const calcTime = ({ time }: { time?: string }) => {
  if (!time) return '';
  dayjs.extend(relativeTime);
  const now = dayjs();
  const date = dayjs(parseInt(time)).locale('ru');
  if (date.isAfter(now)) {
    return 'только что';
  }
  return date.fromNow();
};
