import { BASE_URL, SUPPORTED_IMAGE_FORMATS } from '../constants/constants';
import noImage from '../assets/noImage.png';

type CreateAttachmentImageType = {
  mimeType?: string | null;
  path?: string;
};

export const createAttachmentImage = ({ mimeType = '', path }: CreateAttachmentImageType) => {
  const isImage = SUPPORTED_IMAGE_FORMATS.includes(mimeType || '');

  const imageUrl = isImage ? `${BASE_URL}${path}` : noImage;

  return { isImage, imageUrl };
};
