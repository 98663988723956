import { styled, Stack } from '@mui/material';

export const StackStyle = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingTop: '16px',
}));

export const StackMainStyle = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '16px',
  paddingBottom: '16px',
}));
