import * as yup from 'yup';

import { INCORRECT_EMAIL, incorrectMaXLength, REQUIRE_MESSAGE } from './messages';
import { emailRegex } from '../constants/constants';

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required(REQUIRE_MESSAGE)
    .email(INCORRECT_EMAIL)
    .matches(emailRegex, INCORRECT_EMAIL)
    .max(100, incorrectMaXLength(100)),
  password: yup.string().required(REQUIRE_MESSAGE),
});
