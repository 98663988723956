import { ThredRow } from './ThredRow/ThredRow';
import { AddRows } from '../AddRows/AddRows';
import { ThreadTableType } from '../../../types/types';
import { ThredRowAdd } from './ThreadRowAdd/ThreadRowAdd';
import { useState } from 'react';

export interface DataItem {
  uuid: string;
  description: string;
  unitPrice: number;
  quantity: number;
}

type BodyTredContentType = {
  emptyCellWidth: string;
  titleCellWidth: string;
  firstProjectThreаdList?: Array<ThreadTableType>;
  hanldeCreateThread: () => void;
  isDraggingOccurs: boolean;
};

export const BodyThredContent = ({
  emptyCellWidth,
  titleCellWidth,
  firstProjectThreаdList,
  hanldeCreateThread,
  isDraggingOccurs,
}: BodyTredContentType) => {
  const [createThread, setCreateThread] = useState(false);

  return (
    <>
      {firstProjectThreаdList?.map((item, index) => (
        <ThredRow
          key={index}
          emptyCellWidth={emptyCellWidth}
          titleCellWidth={titleCellWidth}
          index={index}
          item={item}
        />
      ))}
      {createThread && (
        <ThredRowAdd
          emptyCellWidth={emptyCellWidth}
          titleCellWidth={titleCellWidth}
          setCreateThread={setCreateThread}
        />
      )}
      <AddRows
        emptyCellWidth={emptyCellWidth}
        text='Добавить тред'
        onCallbackOuther={() => {
          setCreateThread(true);
        }}
        onCallback={hanldeCreateThread}
        isDraggingOccurs={isDraggingOccurs}
      />
    </>
  );
};
