import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const LoaderWrapper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  background: theme.palette.neutralPlainHoverBg.main,
  justifyContent: 'center',
  alignItems: 'center',
}));
