import { Stack, Typography, Button } from '@mui/material';
import { CloseButton } from '../../../index';
import { StackStyle, HeaderStack } from './style';
import React from 'react';

type ResetInvitationLinkModalType = {
  handleCloseModal: () => void;
  handleSubmitModal: () => void;
};
export const ResetInvitationLinkModal = ({
  handleCloseModal,
  handleSubmitModal,
}: ResetInvitationLinkModalType) => {
  const handleSubmit = () => {
    handleSubmitModal();
    handleCloseModal();
  };
  return (
    <Stack>
      <HeaderStack>
        <Typography variant='h3'>Сброс ссылки-приглашения</Typography>
        <CloseButton onClick={handleCloseModal} />
      </HeaderStack>
      <Stack mt='20px'>
        <Stack direction='row' alignItems={'center'}>
          <Typography variant='customDefault' fontWeight={400}>
            Вы уверены, что хотите сбросить ссылку? Это приведет к истечению срока действия текущей
            ссылки и созданию новой.
          </Typography>
        </Stack>
        <StackStyle mt='32px'>
          <Button variant='cancel' onClick={handleCloseModal} sx={{ marginLeft: '8px' }}>
            Отмена
          </Button>
          <Button
            variant='confirm'
            onClick={handleSubmit}
            type='submit'
            sx={{ marginLeft: '8px', fontWeight: '500' }}
          >
            Сбросить
          </Button>
        </StackStyle>
      </Stack>
    </Stack>
  );
};
