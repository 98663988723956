import * as yup from 'yup';
import { INCORRECT_EMAIL, incorrectMaXLength } from './messages';
import { emailRegex } from '../constants/constants';

export const inviteToWorkspaceSchemaEmail = yup.object({
  email: yup
    .string()
    .email(INCORRECT_EMAIL)
    .matches(emailRegex, INCORRECT_EMAIL)
    .max(100, incorrectMaXLength(100))
    .oneOf([yup.ref('email'), ''], INCORRECT_EMAIL)
    .notRequired(),
  listEmails: yup.array().of(yup.string().required()),
});
