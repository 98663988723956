import { SvgIcon, SxProps } from '@mui/material';

type CalendarIconsType = {
  sx?: SxProps;
  color?: string;
};

export const CalendarIcons = ({ sx, color = '#EA9A3E' }: CalendarIconsType) => {
  return (
    <SvgIcon sx={{ width: '16px', height: '16px', ...sx }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 17 16'
        fill='none'
      >
        <path
          d='M13.8333 2.00033H13.1667V0.666992H11.8333V2.00033H5.16667V0.666992H3.83333V2.00033H3.16667C2.43333 2.00033 1.83333 2.60033 1.83333 3.33366V14.0003C1.83333 14.7337 2.43333 15.3337 3.16667 15.3337H13.8333C14.5667 15.3337 15.1667 14.7337 15.1667 14.0003V3.33366C15.1667 2.60033 14.5667 2.00033 13.8333 2.00033ZM13.8333 14.0003H3.16667V5.33366H13.8333V14.0003Z'
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
