import { TextFieldRHF } from '../../index';
import { useForm, FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useClickOutside, useAutoFocusLineEnd } from '../../../hooks';
import { Box, useTheme } from '@mui/material';
import { UpdateThreadMutationFn } from '../../../graphql/generated/graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThreadTitleSchema } from '../../../validation/index';
import { TypographyStyle } from './style';

type EditThreadTitleType = {
  name: string;
  title?: string;
  updateThreadMutation: UpdateThreadMutationFn;
  threadId?: number;
};

export const EditThreadTitle = ({
  name,
  title,
  threadId,
  updateThreadMutation,
}: EditThreadTitleType) => {
  type FormData = {
    title: string;
  };

  const [isOpen, setIsOpen] = useState(false);

  const methods = useForm<FormData>({
    resolver: yupResolver(ThreadTitleSchema),
    defaultValues: {
      title: '',
    },
  });
  const { getValues, trigger, reset, watch } = methods;

  const titleWatch = watch('title');

  const { refClickOutside } = useClickOutside({
    callBack: () => {
      setIsOpen(false);
      if (titleWatch === title) return;
      if (isOpen && threadId) {
        const validateAndSubmit = async () => {
          const isValid = await trigger();
          if (isValid) {
            const currentValues = getValues();
            updateThreadMutation({
              variables: {
                data: {
                  id: threadId,
                  title: currentValues.title,
                },
              },
            });
          } else {
            reset({ title: title });
          }
        };

        validateAndSubmit();
      }
    },
  });

  useEffect(() => {
    if (title) {
      methods.reset({
        title: title,
      });
    }
  }, [title]);

  const theme = useTheme();

  useAutoFocusLineEnd({
    ref: refClickOutside,
    trigger: isOpen,
  });

  return (
    <FormProvider {...methods}>
      {isOpen ? (
        <Box component={'span'} ref={refClickOutside}>
          <TextFieldRHF
            name={name}
            multiline
            autoFocus
            sx={{
              '& .MuiOutlinedInput-input': {
                fontSize: '24px',
                fontWeight: '600',
                color: theme.palette.textPrimary.main,
                padding: '0px 0px 16px 0px',
                lineHeight: '150%',
              },
            }}
          />
        </Box>
      ) : (
        <Box
          component={'span'}
          onClick={() => {
            setIsOpen(true);
          }}
          sx={{ padding: '17px 14px 17px 0px' }}
        >
          <TypographyStyle>{titleWatch}</TypographyStyle>
        </Box>
      )}
    </FormProvider>
  );
};
