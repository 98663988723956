import { useState, useCallback, useMemo } from 'react';
import {
  SubscriptionStatusEnum,
  useUnsubscribeFromThreadMutation,
  useSubscribeToThreadMutation,
} from '../../../../graphql/generated/graphql';
import { THREAD } from '../../../../graphql/queries/thread.gql';
import { Button, Tooltip, useTheme } from '@mui/material';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsIcon from '@mui/icons-material/Notifications';

type NotificationIconsWrapperType = {
  threadId?: number;
  subscriptionStatus?: SubscriptionStatusEnum | undefined;
};

export const NotificationIconsWrapper = ({
  threadId,
  subscriptionStatus,
}: NotificationIconsWrapperType) => {
  const [isHovered, setIsHovered] = useState(false);

  const [unsubscribeFromThreadMutation] = useUnsubscribeFromThreadMutation({
    variables: {
      threadId: Number(threadId),
    },
    refetchQueries: [THREAD],
  });

  const [subscribeToThreadMutation] = useSubscribeToThreadMutation({
    variables: {
      threadId: Number(threadId),
    },
    refetchQueries: [THREAD],
  });

  const getCalcToolTipTitle = () => {
    if (subscriptionStatus === SubscriptionStatusEnum.Subscribed) {
      return 'Отписаться от уведомлений';
    }
    if (subscriptionStatus === SubscriptionStatusEnum.GroupSubscribed) {
      return 'В группе нельзя отписать от уведомлений';
    }
    return 'Подписаться на уведомления';
  };

  const handleNotificationSubscription = () => {
    if (subscriptionStatus === SubscriptionStatusEnum.Unsubscribed) {
      subscribeToThreadMutation();
    }
    if (subscriptionStatus === SubscriptionStatusEnum.Subscribed) {
      unsubscribeFromThreadMutation();
    }
    return;
  };
  const theme = useTheme();

  const getIcon = useCallback(() => {
    const isSubscribed =
      subscriptionStatus === SubscriptionStatusEnum.Subscribed ||
      subscriptionStatus === SubscriptionStatusEnum.GroupSubscribed;

    if (isHovered) {
      return isSubscribed ? (
        <NotificationsOffIcon sx={{ color: theme.palette.black600 }} />
      ) : (
        <NotificationsIcon sx={{ color: theme.palette.black600 }} />
      );
    } else {
      return isSubscribed ? (
        <NotificationsIcon sx={{ color: theme.palette.black600 }} />
      ) : (
        <NotificationsOffIcon sx={{ color: theme.palette.black600 }} />
      );
    }
  }, [isHovered, subscriptionStatus]);

  const activeNotificationsIcon = useMemo(() => getIcon(), [getIcon]);

  const newTipTitle = getCalcToolTipTitle();

  return (
    <Button
      variant='icons'
      onClick={handleNotificationSubscription}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Tooltip
        title={newTipTitle}
        placement='bottom-start'
        componentsProps={{
          popper: {
            sx: {
              zIndex: 99999,
              '& .MuiTooltip-tooltip': {
                boxShadow: theme.palette.joyShadoSm.main,
                backgroundColor: theme.palette.neutral500.main,
              },
            },
          },
        }}
      >
        {activeNotificationsIcon}
      </Tooltip>
    </Button>
  );
};
