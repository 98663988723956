import { Button, Stack, Typography } from '@mui/material';
import { CreateWorkspaceForm } from './Form';
import React, { useEffect } from 'react';
import { useAuthContext, useWorkspaceContext } from '../../context';
import { ROUTES } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';

export const CreateWorkspace = () => {
  const { signOut } = useAuthContext();
  const { workspaces } = useWorkspaceContext();
  const navigation = useNavigate();
  useEffect(() => {
    if (workspaces?.length) {
      navigation(ROUTES.home);
    }
  }, []);
  return (
    <>
      <Typography variant='h1' mb='8px'>
        Создайте рабочую область
      </Typography>
      <Typography variant='h4' component='h4'>
        В ней будут находится ваши проекты
      </Typography>
      <CreateWorkspaceForm />
      <Stack flexDirection='row' mt='32px'>
        <Typography variant='h4' mr='4px'>
          Сменить аккаунт?
        </Typography>
        <Button sx={{ width: 'fit-content' }} onClick={signOut} variant='text' fullWidth>
          Выйти
        </Button>
      </Stack>
    </>
  );
};
