import { gql } from '@apollo/client';

export const THREAD = gql`
  query thread($id: Int!) {
    thread(id: $id) {
      id
      title
      description
      startDate
      endDate
      statusId
      order
      result
      attachments {
        id
        fileName
        path
        size
        mimeType
      }
      path {
        folder {
          id
          title
        }
        project {
          id
          title
        }
        goal {
          id
          title
        }
      }
      responsible {
        id
        email
        firstName
        lastName
        avatar {
          path
        }
      }
      responsibleUserGroup {
        id
        title
        avatar {
          path
        }
      }
      subscribedUsers {
        id
        email
        firstName
        lastName
        avatar {
          path
        }
      }
      subscribedUserGroups {
        id
        title
        avatar {
          path
        }
      }
      checklistItems {
        id
        isChecked
        threadId
        title
      }
      chat {
        id
      }
      subscriptionStatus
    }
  }
`;
