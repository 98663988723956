import SearchIcon from '@mui/icons-material/Search';
import { Search, SearchIconWrapper, InputBaseStyle } from './style';
import HistoryIcon from '@mui/icons-material/History';
import { Stack, useTheme } from '@mui/material';

export const SearchInput = () => {
  const theme = useTheme();

  return (
    <Stack direction='row' alignItems='center'>
      <Search>
        <SearchIconWrapper>
          <SearchIcon
            sx={{
              color: theme.palette.textIcon.main,
            }}
          />
        </SearchIconWrapper>
        <InputBaseStyle placeholder='Поиск' inputProps={{ 'aria-label': 'search' }} />
      </Search>
      <Stack ml={1} alignItems={'center'}>
        <HistoryIcon sx={{ color: theme.palette.textIcon.main }} />
      </Stack>
    </Stack>
  );
};
