/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type AddMembersToFolderInput = {
  folderId: Scalars['Int'];
  members: Array<FolderMemberInput>;
};

export type AddMembersToProjectInput = {
  members: Array<UserOrUserGroupInput>;
  projectId: Scalars['Int'];
};

export type Chat = {
  __typename?: 'Chat';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  messagesWithCount: MessageWithCountEntity;
  threadId: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type ChatMessagesWithCountArgs = {
  data?: InputMaybe<FindAllMessagesOptionsInput>;
};

export type ChatActivityEntity = {
  __typename?: 'ChatActivityEntity';
  chatId?: Maybe<Scalars['Int']>;
  deletedMessageId?: Maybe<Scalars['Int']>;
  fileId?: Maybe<Scalars['Int']>;
  message?: Maybe<Message>;
  type: Scalars['String'];
};

export enum ChatActivityEnum {
  AddedFiles = 'ADDED_FILES',
  AddedLinks = 'ADDED_LINKS',
  DeletedFile = 'DELETED_FILE',
  DeletedFiles = 'DELETED_FILES',
  DeletedLinks = 'DELETED_LINKS',
  DeletedMessage = 'DELETED_MESSAGE',
  DeletedReactionOnMessage = 'DELETED_REACTION_ON_MESSAGE',
  EditedMessage = 'EDITED_MESSAGE',
  NewMessage = 'NEW_MESSAGE',
  ReactionOnMessage = 'REACTION_ON_MESSAGE',
  UpdatedFile = 'UPDATED_FILE',
}

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  id: Scalars['Int'];
  isChecked: Scalars['Boolean'];
  threadId: Scalars['Int'];
  title: Scalars['String'];
};

export type ChecklistItemsOptionsInput = {
  threadId: Scalars['Int'];
};

export type ConfirmEmailResponseSchema = {
  __typename?: 'ConfirmEmailResponseSchema';
  registrationToken?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateChecklistItemInput = {
  isChecked?: InputMaybe<Scalars['Boolean']>;
  threadId: Scalars['Int'];
  title: Scalars['String'];
};

export type CreateFolderInput = {
  members?: InputMaybe<Array<FolderMemberInput>>;
  projectId: Scalars['Int'];
  responsibleId?: InputMaybe<Scalars['Int']>;
  responsibleUserGroupId?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  visibilityStatus?: InputMaybe<VisibilityStatusEnum>;
};

export type CreateGoalInput = {
  folderId: Scalars['Int'];
  title: Scalars['String'];
};

export type CreateMessageInput = {
  chatId: Scalars['Int'];
  content: Scalars['String'];
  filesIds?: InputMaybe<Array<Scalars['Int']>>;
  repliedToMessageId?: InputMaybe<Scalars['Int']>;
};

export type CreateProjectInput = {
  members?: InputMaybe<Array<ProjectMemberInput>>;
  title: Scalars['String'];
  visibilityStatus?: InputMaybe<VisibilityStatusEnum>;
  workspaceId: Scalars['Int'];
};

export type CreateThread = {
  attachments?: InputMaybe<Array<Scalars['Upload']>>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  goalId?: InputMaybe<Scalars['Int']>;
  responsibleId?: InputMaybe<Scalars['Int']>;
  responsibleUserGroupId?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  statusId?: InputMaybe<Scalars['Int']>;
  subscribers?: InputMaybe<Array<ThreadSubscribersInput>>;
  title: Scalars['String'];
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type CreateUserGroupInput = {
  title: Scalars['String'];
  userIds?: InputMaybe<Array<Scalars['Int']>>;
  workspaceId: Scalars['Int'];
};

export type CreateWorkspaceInput = {
  isInviteLinkAccess?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

/** Input for edit user */
export type EditUserInput = {
  firstName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type Emoji = {
  __typename?: 'Emoji';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['String'];
  fileName: Scalars['String'];
  id: Scalars['Int'];
  messageId?: Maybe<Scalars['Int']>;
  mimeType?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  threadId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['String'];
  userGroupId?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['Int']>;
};

export type FilesAndCount = {
  __typename?: 'FilesAndCount';
  count: Scalars['Int'];
  rows: Array<File>;
};

export type FindAllChatsInput = {
  threadId: Scalars['Int'];
};

export type FindAllMessagesOptionsInput = {
  chatId?: InputMaybe<Scalars['Int']>;
  firstId?: InputMaybe<Scalars['Int']>;
  lastId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type FindAllOptionsEntity = {
  workspaceId: Scalars['Int'];
};

export type FindFilesInput = {
  chatId?: InputMaybe<Scalars['Int']>;
  isOnlyFiles?: InputMaybe<Scalars['Boolean']>;
  isOnlyImages?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type FindLinksInput = {
  chatId: Scalars['Int'];
};

export type FindThreadsOptions = {
  fromTheWorkspaceId?: InputMaybe<Scalars['Int']>;
  isAuthor?: InputMaybe<Scalars['Boolean']>;
  isResponsible?: InputMaybe<Scalars['Boolean']>;
  isSubscriber?: InputMaybe<Scalars['Boolean']>;
  statusId?: InputMaybe<Scalars['Int']>;
};

export type FindUsersOptionsInput = {
  folderId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type Folder = {
  __typename?: 'Folder';
  authorId: Scalars['Int'];
  createdAt: Scalars['String'];
  goals: Array<Goal>;
  id: Scalars['Int'];
  membersUserGroups: Array<FolderMemberUserGroup>;
  membersUsers: Array<FolderMemberUser>;
  order?: Maybe<Scalars['Int']>;
  project: Project;
  responsible?: Maybe<User>;
  responsibleUserGroup?: Maybe<UserGroup>;
  threadsStatistics: ThreadsStatisticsEntity;
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  visibilityStatus: VisibilityStatusEnum;
};

export type FolderMemberInput = {
  userGroupId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type FolderMemberUser = {
  __typename?: 'FolderMemberUser';
  addedAt: Scalars['String'];
  user?: Maybe<User>;
};

export type FolderMemberUserGroup = {
  __typename?: 'FolderMemberUserGroup';
  addedAt: Scalars['String'];
  userGroup?: Maybe<UserGroup>;
};

export type FoldersSortOrderInput = {
  folderId: Scalars['Int'];
  order: Scalars['Int'];
};

export type Goal = {
  __typename?: 'Goal';
  authorId: Scalars['Int'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  threads: Array<Thread>;
  title: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type GoalThreadsArgs = {
  data?: InputMaybe<FindThreadsOptions>;
};

export type HandleReactionDataInput = {
  emojiId: Scalars['Int'];
  messageId: Scalars['Int'];
};

export type InviteToWorkspaceInput = {
  emails: Array<Scalars['String']>;
  workspaceId: Scalars['Int'];
};

export type InviteToWorkspaceResponseEntity = {
  __typename?: 'InviteToWorkspaceResponseEntity';
  failures: Array<Scalars['String']>;
  successes: Array<Scalars['String']>;
};

export type Karma = {
  __typename?: 'Karma';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  isPlus: Scalars['Boolean'];
  messageId: Scalars['Int'];
  updatedAt: Scalars['String'];
  user: User;
  userId: Scalars['Int'];
};

export type Link = {
  __typename?: 'Link';
  URL: Scalars['String'];
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  messageId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type LinksAndCountEntity = {
  __typename?: 'LinksAndCountEntity';
  count: Scalars['Int'];
  rows: Array<Link>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginSchema = {
  __typename?: 'LoginSchema';
  token: Scalars['String'];
  user: User;
};

export type LogsHistoryResponseEntity = {
  __typename?: 'LogsHistoryResponseEntity';
  logs: Array<ThreadLog>;
  maxCreatedAt: Scalars['String'];
  user: User;
};

export type Message = {
  __typename?: 'Message';
  attachments: Array<File>;
  author: User;
  chatId: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  isPossibleSetKarma: Scalars['Boolean'];
  reactions: Array<MessageReaction>;
  repliedToMessage?: Maybe<Message>;
  repliedToMessageId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['String'];
  userId: Scalars['Int'];
};

export type MessageReaction = {
  __typename?: 'MessageReaction';
  count: Scalars['Int'];
  emoji: Emoji;
  emojiId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
  users: Array<User>;
};

export type MessageWithCountEntity = {
  __typename?: 'MessageWithCountEntity';
  count: Scalars['Int'];
  rows: Array<Message>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMembersToFolder: Folder;
  addMembersToProject: Project;
  cancelInviteToWorkspace: ResponseSuccessSchema;
  confirmEmail: ConfirmEmailResponseSchema;
  createChecklistItem: ChecklistItem;
  createFolder: Folder;
  createGoal: Goal;
  createMessage: Message;
  createProject: Project;
  createThread: Thread;
  createUserGroup: UserGroup;
  createWorkspace: Workspace;
  deleteMessage: ResponseSuccessSchema;
  editUser: User;
  handleInviteLinkAccess: ResponseSuccessSchema;
  handleReaction: ResponseSuccessSchema;
  inviteToWorkspace: InviteToWorkspaceResponseEntity;
  joinToWorkspace: Workspace;
  leaveUserGroup: ResponseSuccessSchema;
  leaveWorkspace: ResponseSuccessSchema;
  login: LoginSchema;
  register: LoginSchema;
  removeChecklistItem: ResponseSuccessSchema;
  removeFile: ResponseSuccessSchema;
  removeFiles: ResponseSuccessSchema;
  removeFolder: ResponseSuccessSchema;
  removeGoal: ResponseSuccessSchema;
  removeMember: ResponseSuccessSchema;
  removeMemberFromFolder: Folder;
  removeMemberFromProject: Project;
  removeProject: ResponseSuccessSchema;
  removeThread: ResponseSuccessSchema;
  removeUserGroup: ResponseSuccessSchema;
  removeWorkspace: ResponseSuccessSchema;
  resetPassword: ResponseSuccessSchema;
  resetPasswordByToken: ResponseSuccessSchema;
  resetWorkspaceInviteLink: Scalars['String'];
  sendConfirmationEmail: ResponseSuccessSchema;
  sendPasswordReset: ResponseSuccessSchema;
  setKarma: Scalars['Boolean'];
  sortFolders: ResponseSuccessSchema;
  sortGoalThreads: ResponseSuccessSchema;
  sortThreads: ResponseSuccessSchema;
  subscribeToThread: ResponseSuccessSchema;
  unsubscribeFromThread: ResponseSuccessSchema;
  updateChecklistItem: ChecklistItem;
  updateFile: File;
  updateFiles: Array<File>;
  updateFolder: Folder;
  updateGoal: Goal;
  updateMember: WorkspaceUser;
  updateMessage: Message;
  updateProject: Project;
  updateThread: Thread;
  updateUserGroup: UserGroup;
  updateWorkspace: Workspace;
  uploadFile: File;
  uploadFiles: Array<File>;
};

export type MutationAddMembersToFolderArgs = {
  data: AddMembersToFolderInput;
};

export type MutationAddMembersToProjectArgs = {
  data: AddMembersToProjectInput;
};

export type MutationCancelInviteToWorkspaceArgs = {
  id: Scalars['Int'];
};

export type MutationConfirmEmailArgs = {
  token: Scalars['String'];
};

export type MutationCreateChecklistItemArgs = {
  data: CreateChecklistItemInput;
};

export type MutationCreateFolderArgs = {
  createFolderInput: CreateFolderInput;
};

export type MutationCreateGoalArgs = {
  createGoalInput: CreateGoalInput;
};

export type MutationCreateMessageArgs = {
  data: CreateMessageInput;
};

export type MutationCreateProjectArgs = {
  createProjectInput: CreateProjectInput;
};

export type MutationCreateThreadArgs = {
  data: CreateThread;
};

export type MutationCreateUserGroupArgs = {
  createUserGroupInput: CreateUserGroupInput;
};

export type MutationCreateWorkspaceArgs = {
  createWorkspaceInput: CreateWorkspaceInput;
};

export type MutationDeleteMessageArgs = {
  id: Scalars['Int'];
};

export type MutationEditUserArgs = {
  data: EditUserInput;
};

export type MutationHandleInviteLinkAccessArgs = {
  workspaceId: Scalars['Int'];
};

export type MutationHandleReactionArgs = {
  data: HandleReactionDataInput;
};

export type MutationInviteToWorkspaceArgs = {
  data: InviteToWorkspaceInput;
};

export type MutationJoinToWorkspaceArgs = {
  token: Scalars['String'];
};

export type MutationLeaveUserGroupArgs = {
  id: Scalars['Int'];
};

export type MutationLeaveWorkspaceArgs = {
  workspaceId: Scalars['Int'];
};

export type MutationLoginArgs = {
  data: LoginInput;
};

export type MutationRegisterArgs = {
  data: RegisterInput;
};

export type MutationRemoveChecklistItemArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveFileArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveFilesArgs = {
  ids: Array<Scalars['Int']>;
};

export type MutationRemoveFolderArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveGoalArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveMemberArgs = {
  data: RemoveMemberInput;
};

export type MutationRemoveMemberFromFolderArgs = {
  data: RemoveMemberFromFolderInput;
};

export type MutationRemoveMemberFromProjectArgs = {
  data: RemoveMemberFromProjectInput;
};

export type MutationRemoveProjectArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveThreadArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveUserGroupArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveWorkspaceArgs = {
  id: Scalars['Int'];
};

export type MutationResetPasswordArgs = {
  data: ResetPasswordUser;
};

export type MutationResetPasswordByTokenArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type MutationResetWorkspaceInviteLinkArgs = {
  workspaceId: Scalars['Int'];
};

export type MutationSendConfirmationEmailArgs = {
  email: Scalars['String'];
};

export type MutationSendPasswordResetArgs = {
  email: Scalars['String'];
};

export type MutationSetKarmaArgs = {
  isPlus: Scalars['Boolean'];
  messageId: Scalars['ID'];
};

export type MutationSortFoldersArgs = {
  data: SortFoldersInput;
};

export type MutationSortGoalThreadsArgs = {
  data: SortThreadInGoalsInput;
};

export type MutationSortThreadsArgs = {
  data: SortMyThreadsInput;
};

export type MutationSubscribeToThreadArgs = {
  threadId: Scalars['Int'];
};

export type MutationUnsubscribeFromThreadArgs = {
  threadId: Scalars['Int'];
};

export type MutationUpdateChecklistItemArgs = {
  data: UpdateChecklistItemInput;
};

export type MutationUpdateFileArgs = {
  data: UpdateFileInput;
  id: Scalars['Int'];
};

export type MutationUpdateFilesArgs = {
  data: UpdateFileInput;
  ids: Array<Scalars['Int']>;
};

export type MutationUpdateFolderArgs = {
  updateFolderInput: UpdateFolderInput;
};

export type MutationUpdateGoalArgs = {
  updateGoalInput: UpdateGoalInput;
};

export type MutationUpdateMemberArgs = {
  data: UpdateMemberInput;
};

export type MutationUpdateMessageArgs = {
  data: UpdateMessageInput;
  id: Scalars['Int'];
};

export type MutationUpdateProjectArgs = {
  updateProjectInput: UpdateProjectInput;
};

export type MutationUpdateThreadArgs = {
  data: UpdateThread;
};

export type MutationUpdateUserGroupArgs = {
  updateUserGroupInput: UpdateUserGroupInput;
};

export type MutationUpdateWorkspaceArgs = {
  avatar?: InputMaybe<Scalars['Upload']>;
  updateWorkspaceInput: UpdateWorkspaceInput;
};

export type MutationUploadFileArgs = {
  data: UploadFileInput;
  file: Scalars['Upload'];
};

export type MutationUploadFilesArgs = {
  data?: InputMaybe<UploadFileInput>;
  files: Array<Scalars['Upload']>;
};

export type NewChatAttachedActivity = {
  __typename?: 'NewChatAttachedActivity';
  files?: Maybe<Array<File>>;
  images?: Maybe<Array<File>>;
  links?: Maybe<Array<Link>>;
  type: ChatActivityEnum;
};

export type NewThreadActivityEntity = {
  __typename?: 'NewThreadActivityEntity';
  history?: Maybe<Array<LogsHistoryResponseEntity>>;
  type: NewThreadActivityTypeEnum;
};

export enum NewThreadActivityTypeEnum {
  ActivityInLog = 'ACTIVITY_IN_LOG',
}

export type Project = {
  __typename?: 'Project';
  authorId: Scalars['Int'];
  createdAt: Scalars['String'];
  folders: Array<Folder>;
  id: Scalars['Int'];
  membersUserGroups: Array<ProjectMemberUserGroup>;
  membersUsers: Array<ProjectMemberUser>;
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  visibilityStatus: VisibilityStatusEnum;
};

export type ProjectMemberInput = {
  userGroupId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type ProjectMemberUser = {
  __typename?: 'ProjectMemberUser';
  addedAt: Scalars['String'];
  user?: Maybe<User>;
};

export type ProjectMemberUserGroup = {
  __typename?: 'ProjectMemberUserGroup';
  addedAt: Scalars['String'];
  userGroup?: Maybe<UserGroup>;
};

export enum ProtectedMemberRoleEnum {
  Admin = 'Admin',
  Member = 'Member',
}

export type Query = {
  __typename?: 'Query';
  chat: Chat;
  checklistItems: Array<ChecklistItem>;
  currentUser: User;
  emojis: Array<Emoji>;
  files: Array<File>;
  filesAndCount: FilesAndCount;
  folder: Folder;
  folders: Array<Folder>;
  goal: Goal;
  goals: Array<Goal>;
  isValidPasswordResetToken: Scalars['Boolean'];
  isValidRegisterToken: ResponseSuccessSchema;
  linksAndCount: LinksAndCountEntity;
  messagesWithCount: MessageWithCountEntity;
  project: Project;
  projects: Array<Project>;
  thread: Thread;
  threadsAndCount: ThreadAndCountEntity;
  userGroup: UserGroup;
  userGroups: Array<UserGroup>;
  users: Array<User>;
  workspace: Workspace;
  workspaceAndUserDataByAccessToken: WorkspaceAndUserDataByAccessToken;
  workspaces: Array<Workspace>;
};

export type QueryChatArgs = {
  options: FindAllChatsInput;
};

export type QueryChecklistItemsArgs = {
  options: ChecklistItemsOptionsInput;
};

export type QueryFilesArgs = {
  data: FindFilesInput;
};

export type QueryFilesAndCountArgs = {
  data: FindFilesInput;
};

export type QueryFolderArgs = {
  id: Scalars['Int'];
};

export type QueryGoalArgs = {
  id: Scalars['Int'];
};

export type QueryIsValidPasswordResetTokenArgs = {
  token: Scalars['String'];
};

export type QueryIsValidRegisterTokenArgs = {
  token: Scalars['String'];
};

export type QueryLinksAndCountArgs = {
  data: FindLinksInput;
};

export type QueryMessagesWithCountArgs = {
  data?: InputMaybe<FindAllMessagesOptionsInput>;
};

export type QueryProjectArgs = {
  id: Scalars['Int'];
};

export type QueryProjectsArgs = {
  options: FindAllOptionsEntity;
};

export type QueryThreadArgs = {
  id: Scalars['Int'];
};

export type QueryThreadsAndCountArgs = {
  options: FindThreadsOptions;
};

export type QueryUserGroupArgs = {
  id: Scalars['Int'];
};

export type QueryUserGroupsArgs = {
  data: UserGroupsFindDataInput;
};

export type QueryUsersArgs = {
  options: FindUsersOptionsInput;
};

export type QueryWorkspaceArgs = {
  id: Scalars['Int'];
};

export type QueryWorkspaceAndUserDataByAccessTokenArgs = {
  workspaceAccessToken: Scalars['String'];
};

export type RegisterInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  registrationToken?: InputMaybe<Scalars['String']>;
  workspaceAccessToken?: InputMaybe<Scalars['String']>;
};

export type RemoveMemberFromFolderInput = {
  folderId: Scalars['Int'];
  member: FolderMemberInput;
};

export type RemoveMemberFromProjectInput = {
  member: UserOrUserGroupInput;
  projectId: Scalars['Int'];
};

export type RemoveMemberInput = {
  userId: Scalars['Int'];
  workspaceId: Scalars['Int'];
};

/** Input for reset password user */
export type ResetPasswordUser = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type ResponseSuccessSchema = {
  __typename?: 'ResponseSuccessSchema';
  success: Scalars['Boolean'];
};

export type SortFoldersInput = {
  foldersSortOrders: Array<FoldersSortOrderInput>;
};

export type SortMyThreadsInput = {
  threadSortOrders: Array<ThreadSortOrderInput>;
};

export type SortThreadInGoalsInput = {
  sortedThreadsInGoals: Array<SortedThreadsInGoalsInput>;
};

export type SortedThreadsInGoalsInput = {
  goalId?: InputMaybe<Scalars['Int']>;
  newGoalId?: InputMaybe<Scalars['Int']>;
  order: Scalars['Int'];
  threadId: Scalars['Int'];
};

export type Status = {
  __typename?: 'Status';
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  newChatActivity: ChatActivityEntity;
  newChatAttachedActivity: NewChatAttachedActivity;
  newThreadActivity: NewThreadActivityEntity;
};

export type SubscriptionNewChatActivityArgs = {
  chatId: Scalars['Int'];
};

export type SubscriptionNewChatAttachedActivityArgs = {
  chatId: Scalars['Int'];
};

export type SubscriptionNewThreadActivityArgs = {
  threadId: Scalars['Int'];
};

export enum SubscriptionStatusEnum {
  GroupSubscribed = 'GROUP_SUBSCRIBED',
  Subscribed = 'SUBSCRIBED',
  Unsubscribed = 'UNSUBSCRIBED',
}

export type Thread = {
  __typename?: 'Thread';
  attachments: Array<File>;
  author: User;
  authorId: Scalars['Int'];
  chat: Chat;
  checklistItems: Array<ChecklistItem>;
  description: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  history?: Maybe<Array<LogsHistoryResponseEntity>>;
  id: Scalars['Int'];
  order?: Maybe<Scalars['Int']>;
  path?: Maybe<ThreadPath>;
  responsible?: Maybe<User>;
  responsibleUserGroup?: Maybe<UserGroup>;
  result: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  status: Status;
  statusId: Scalars['Int'];
  subscribedUserGroups: Array<UserGroup>;
  subscribedUsers: Array<User>;
  subscriptionStatus: SubscriptionStatusEnum;
  title: Scalars['String'];
  usersActionsHistory?: Maybe<Array<User>>;
  workspaceId?: Maybe<Scalars['Int']>;
};

export type ThreadAndCountEntity = {
  __typename?: 'ThreadAndCountEntity';
  count: Scalars['Int'];
  rows: Array<Thread>;
};

export type ThreadLog = {
  __typename?: 'ThreadLog';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  title: Scalars['String'];
  userId: Scalars['Int'];
};

export type ThreadPath = {
  __typename?: 'ThreadPath';
  folder?: Maybe<Folder>;
  goal?: Maybe<Goal>;
  project?: Maybe<Project>;
};

export type ThreadSortOrderInput = {
  order: Scalars['Int'];
  threadId: Scalars['Int'];
};

export type ThreadSubscribersInput = {
  userGroupId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type ThreadsStatisticsEntity = {
  __typename?: 'ThreadsStatisticsEntity';
  completedThreadsCount: Scalars['Int'];
  completedThreadsPercent: Scalars['Int'];
  threadsCount: Scalars['Int'];
};

export type UpdateChecklistItemInput = {
  id: Scalars['Int'];
  isChecked?: InputMaybe<Scalars['Boolean']>;
  threadId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateFileInput = {
  fileName?: InputMaybe<Scalars['String']>;
  messageId?: InputMaybe<Scalars['Int']>;
};

export type UpdateFolderInput = {
  id: Scalars['Int'];
  members?: InputMaybe<Array<FolderMemberInput>>;
  projectId?: InputMaybe<Scalars['Int']>;
  responsibleId?: InputMaybe<Scalars['Int']>;
  responsibleUserGroupId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  visibilityStatus?: InputMaybe<VisibilityStatusEnum>;
};

export type UpdateGoalInput = {
  folderId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateMemberInput = {
  role: ProtectedMemberRoleEnum;
  userId: Scalars['Int'];
  workspaceId: Scalars['Int'];
};

export type UpdateMessageInput = {
  content?: InputMaybe<Scalars['String']>;
};

export type UpdateProjectInput = {
  id: Scalars['Int'];
  members?: InputMaybe<Array<ProjectMemberInput>>;
  title?: InputMaybe<Scalars['String']>;
  visibilityStatus?: InputMaybe<VisibilityStatusEnum>;
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type UpdateThread = {
  attachments?: InputMaybe<Array<Scalars['Upload']>>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  goalId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  responsibleId?: InputMaybe<Scalars['Int']>;
  responsibleUserGroupId?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  statusId?: InputMaybe<Scalars['Int']>;
  subscribers?: InputMaybe<Array<ThreadSubscribersInput>>;
  title?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type UpdateUserGroupInput = {
  id: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['Int']>>;
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type UpdateWorkspaceInput = {
  id: Scalars['Int'];
  isInviteLinkAccess?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UploadFileInput = {
  threadId?: InputMaybe<Scalars['Int']>;
  userGroupId?: InputMaybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<File>;
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isEmailConfirmed: Scalars['Boolean'];
  karmaStatistics?: Maybe<UserKarmaStatistics>;
  lastName?: Maybe<Scalars['String']>;
  logs?: Maybe<Array<ThreadLog>>;
  updatedAt: Scalars['String'];
  workspaces: Array<Workspace>;
  workspacesWithRole: Array<WorkspaceWithRoleEntity>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  authorId: Scalars['Int'];
  avatar?: Maybe<File>;
  id: Scalars['Int'];
  title: Scalars['String'];
  users: Array<User>;
  workspaceId: Scalars['Int'];
};

export type UserGroupsFindDataInput = {
  folderId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type UserKarmaStatistics = {
  __typename?: 'UserKarmaStatistics';
  items: Array<Karma>;
  karma: Scalars['Int'];
};

export type UserOrUserGroupInput = {
  userGroupId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export enum VisibilityStatusEnum {
  Public = 'public',
  Team = 'team',
}

export type Workspace = {
  __typename?: 'Workspace';
  authorId: Scalars['Int'];
  avatar?: Maybe<File>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  inviteLink: Scalars['String'];
  invites: Array<WorkspaceInvite>;
  isInviteLinkAccess: Scalars['Boolean'];
  members: Array<WorkspaceUser>;
  myRole: WorkspaceMemberRole;
  title: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type WorkspaceAndUserDataByAccessToken = {
  __typename?: 'WorkspaceAndUserDataByAccessToken';
  email?: Maybe<Scalars['String']>;
  isAccessToCurrentUser: Scalars['Boolean'];
  isInvitedUserAccessToken: Scalars['Boolean'];
  isRegister?: Maybe<Scalars['Boolean']>;
  workspaceTitle: Scalars['String'];
};

export type WorkspaceInvite = {
  __typename?: 'WorkspaceInvite';
  email: Scalars['String'];
  id: Scalars['Int'];
};

export enum WorkspaceMemberRole {
  Admin = 'Admin',
  Creator = 'Creator',
  Member = 'Member',
}

export type WorkspaceUser = {
  __typename?: 'WorkspaceUser';
  createdAt: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  role: WorkspaceMemberRole;
  user: User;
  userId: Scalars['Int'];
  workspaceId: Scalars['Int'];
};

export type WorkspaceWithRoleEntity = {
  __typename?: 'WorkspaceWithRoleEntity';
  role: WorkspaceMemberRole;
  workspace: Workspace;
};

export type ThreadFragmentFragment = {
  __typename?: 'Thread';
  id: number;
  title: string;
  description: string;
  startDate?: string | null;
  endDate?: string | null;
  statusId: number;
  order?: number | null;
  path?: {
    __typename?: 'ThreadPath';
    folder?: {
      __typename?: 'Folder';
      id: number;
      title: string;
      project: { __typename?: 'Project'; id: number; title: string };
    } | null;
  } | null;
  responsible?: { __typename?: 'User'; id: number; email?: string | null } | null;
};

export type UserFragmentFragment = {
  __typename?: 'User';
  id: number;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  avatar?: { __typename?: 'File'; id: number; path: string } | null;
  workspaces: Array<{
    __typename?: 'Workspace';
    id: number;
    title: string;
    myRole: WorkspaceMemberRole;
    inviteLink: string;
  }>;
};

export type AddMembersToFolderMutationVariables = Exact<{
  data: AddMembersToFolderInput;
}>;

export type AddMembersToFolderMutation = {
  __typename?: 'Mutation';
  addMembersToFolder: {
    __typename?: 'Folder';
    id: number;
    title: string;
    visibilityStatus: VisibilityStatusEnum;
    project: { __typename?: 'Project'; id: number; title: string };
    membersUsers: Array<{
      __typename?: 'FolderMemberUser';
      user?: {
        __typename?: 'User';
        id: number;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; path: string } | null;
      } | null;
    }>;
    membersUserGroups: Array<{
      __typename?: 'FolderMemberUserGroup';
      userGroup?: {
        __typename?: 'UserGroup';
        id: number;
        title: string;
        workspaceId: number;
        avatar?: { __typename?: 'File'; path: string; id: number } | null;
        users: Array<{
          __typename?: 'User';
          id: number;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
        }>;
      } | null;
    }>;
    threadsStatistics: {
      __typename?: 'ThreadsStatisticsEntity';
      completedThreadsCount: number;
      completedThreadsPercent: number;
      threadsCount: number;
    };
    goals: Array<{ __typename?: 'Goal'; id: number; title: string }>;
  };
};

export type AddMembersToProjectMutationVariables = Exact<{
  data: AddMembersToProjectInput;
}>;

export type AddMembersToProjectMutation = {
  __typename?: 'Mutation';
  addMembersToProject: {
    __typename?: 'Project';
    id: number;
    membersUserGroups: Array<{
      __typename?: 'ProjectMemberUserGroup';
      userGroup?: {
        __typename?: 'UserGroup';
        title: string;
        id: number;
        avatar?: { __typename?: 'File'; path: string } | null;
        users: Array<{
          __typename?: 'User';
          id: number;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
        }>;
      } | null;
    }>;
    membersUsers: Array<{
      __typename?: 'ProjectMemberUser';
      user?: {
        __typename?: 'User';
        firstName?: string | null;
        email?: string | null;
        lastName?: string | null;
        id: number;
        avatar?: { __typename?: 'File'; path: string } | null;
      } | null;
    }>;
  };
};

export type CancelInviteToWorkspaceMutationVariables = Exact<{
  cancelInviteToWorkspaceId: Scalars['Int'];
}>;

export type CancelInviteToWorkspaceMutation = {
  __typename?: 'Mutation';
  cancelInviteToWorkspace: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type ConfirmEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type ConfirmEmailMutation = {
  __typename?: 'Mutation';
  confirmEmail: {
    __typename?: 'ConfirmEmailResponseSchema';
    registrationToken?: string | null;
    success: boolean;
  };
};

export type RemoveChecklistItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveChecklistItemMutation = {
  __typename?: 'Mutation';
  removeChecklistItem: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type CreateChecklistItemMutationVariables = Exact<{
  data: CreateChecklistItemInput;
}>;

export type CreateChecklistItemMutation = {
  __typename?: 'Mutation';
  createChecklistItem: { __typename?: 'ChecklistItem'; title: string };
};

export type CreateFolderMutationVariables = Exact<{
  createFolderInput: CreateFolderInput;
}>;

export type CreateFolderMutation = {
  __typename?: 'Mutation';
  createFolder: { __typename?: 'Folder'; id: number; title: string; authorId: number };
};

export type CreateGoalMutationVariables = Exact<{
  createGoalInput: CreateGoalInput;
}>;

export type CreateGoalMutation = {
  __typename?: 'Mutation';
  createGoal: { __typename?: 'Goal'; id: number; title: string; authorId: number };
};

export type CreateMessageMutationVariables = Exact<{
  data: CreateMessageInput;
}>;

export type CreateMessageMutation = {
  __typename?: 'Mutation';
  createMessage: { __typename?: 'Message'; id: number };
};

export type CreateProjectMutationVariables = Exact<{
  createProjectInput: CreateProjectInput;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject: { __typename?: 'Project'; id: number; title: string; authorId: number };
};

export type CreateThreadMutationVariables = Exact<{
  data: CreateThread;
}>;

export type CreateThreadMutation = {
  __typename?: 'Mutation';
  createThread: { __typename?: 'Thread'; id: number; title: string };
};

export type CreateUserGroupMutationVariables = Exact<{
  createUserGroupInput: CreateUserGroupInput;
}>;

export type CreateUserGroupMutation = {
  __typename?: 'Mutation';
  createUserGroup: { __typename?: 'UserGroup'; id: number };
};

export type DeleteMessageMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteMessageMutation = {
  __typename?: 'Mutation';
  deleteMessage: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type EditUserMutationVariables = Exact<{
  data: EditUserInput;
}>;

export type EditUserMutation = {
  __typename?: 'Mutation';
  editUser: { __typename?: 'User'; id: number };
};

export type HandleInviteLinkAccessMutationVariables = Exact<{
  workspaceId: Scalars['Int'];
}>;

export type HandleInviteLinkAccessMutation = {
  __typename?: 'Mutation';
  handleInviteLinkAccess: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type HandleReactionMutationVariables = Exact<{
  data: HandleReactionDataInput;
}>;

export type HandleReactionMutation = {
  __typename?: 'Mutation';
  handleReaction: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type InviteToWorkspaceMutationVariables = Exact<{
  data: InviteToWorkspaceInput;
}>;

export type InviteToWorkspaceMutation = {
  __typename?: 'Mutation';
  inviteToWorkspace: {
    __typename?: 'InviteToWorkspaceResponseEntity';
    failures: Array<string>;
    successes: Array<string>;
  };
};

export type JoinToWorkspaceMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type JoinToWorkspaceMutation = {
  __typename?: 'Mutation';
  joinToWorkspace: { __typename?: 'Workspace'; id: number };
};

export type LeaveUserGroupMutationVariables = Exact<{
  leaveUserGroupId: Scalars['Int'];
}>;

export type LeaveUserGroupMutation = {
  __typename?: 'Mutation';
  leaveUserGroup: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type LeaveWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['Int'];
}>;

export type LeaveWorkspaceMutation = {
  __typename?: 'Mutation';
  leaveWorkspace: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'LoginSchema';
    token: string;
    user: { __typename?: 'User'; workspaces: Array<{ __typename?: 'Workspace'; id: number }> };
  };
};

export type RegisterMutationVariables = Exact<{
  data: RegisterInput;
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  register: {
    __typename?: 'LoginSchema';
    token: string;
    user: { __typename?: 'User'; workspaces: Array<{ __typename?: 'Workspace'; id: number }> };
  };
};

export type RemoveFileMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveFileMutation = {
  __typename?: 'Mutation';
  removeFile: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type RemoveFilesMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type RemoveFilesMutation = {
  __typename?: 'Mutation';
  removeFiles: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type RemoveFolderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveFolderMutation = {
  __typename?: 'Mutation';
  removeFolder: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type RemoveGoalMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveGoalMutation = {
  __typename?: 'Mutation';
  removeGoal: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type RemoveMemberMutationVariables = Exact<{
  data: RemoveMemberInput;
}>;

export type RemoveMemberMutation = {
  __typename?: 'Mutation';
  removeMember: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type RemoveMemberFromFolderMutationVariables = Exact<{
  data: RemoveMemberFromFolderInput;
}>;

export type RemoveMemberFromFolderMutation = {
  __typename?: 'Mutation';
  removeMemberFromFolder: {
    __typename?: 'Folder';
    id: number;
    membersUserGroups: Array<{
      __typename?: 'FolderMemberUserGroup';
      userGroup?: { __typename?: 'UserGroup'; id: number } | null;
    }>;
    membersUsers: Array<{
      __typename?: 'FolderMemberUser';
      user?: { __typename?: 'User'; id: number } | null;
    }>;
  };
};

export type RemoveMemberFromProjectMutationVariables = Exact<{
  data: RemoveMemberFromProjectInput;
}>;

export type RemoveMemberFromProjectMutation = {
  __typename?: 'Mutation';
  removeMemberFromProject: {
    __typename?: 'Project';
    id: number;
    membersUserGroups: Array<{
      __typename?: 'ProjectMemberUserGroup';
      userGroup?: { __typename?: 'UserGroup'; id: number } | null;
    }>;
    membersUsers: Array<{
      __typename?: 'ProjectMemberUser';
      user?: { __typename?: 'User'; id: number } | null;
    }>;
  };
};

export type RemoveProjectMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveProjectMutation = {
  __typename?: 'Mutation';
  removeProject: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type RemoveThreadMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveThreadMutation = {
  __typename?: 'Mutation';
  removeThread: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type RemoveUserGroupMutationVariables = Exact<{
  removeUserGroupId: Scalars['Int'];
}>;

export type RemoveUserGroupMutation = {
  __typename?: 'Mutation';
  removeUserGroup: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type RemoveWorkspaceMutationVariables = Exact<{
  removeWorkspaceId: Scalars['Int'];
}>;

export type RemoveWorkspaceMutation = {
  __typename?: 'Mutation';
  removeWorkspace: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordUser;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type ResetPasswordByTokenMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;

export type ResetPasswordByTokenMutation = {
  __typename?: 'Mutation';
  resetPasswordByToken: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type ResetWorkspaceInviteLinkMutationVariables = Exact<{
  workspaceId: Scalars['Int'];
}>;

export type ResetWorkspaceInviteLinkMutation = {
  __typename?: 'Mutation';
  resetWorkspaceInviteLink: string;
};

export type SendConfirmationEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type SendConfirmationEmailMutation = {
  __typename?: 'Mutation';
  sendConfirmationEmail: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type SendPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type SendPasswordResetMutation = {
  __typename?: 'Mutation';
  sendPasswordReset: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type SortFoldersMutationVariables = Exact<{
  data: SortFoldersInput;
}>;

export type SortFoldersMutation = {
  __typename?: 'Mutation';
  sortFolders: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type SortGoalThreadsMutationVariables = Exact<{
  data: SortThreadInGoalsInput;
}>;

export type SortGoalThreadsMutation = {
  __typename?: 'Mutation';
  sortGoalThreads: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type SortThreadsMutationVariables = Exact<{
  data: SortMyThreadsInput;
}>;

export type SortThreadsMutation = {
  __typename?: 'Mutation';
  sortThreads: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type SubscribeToThreadMutationVariables = Exact<{
  threadId: Scalars['Int'];
}>;

export type SubscribeToThreadMutation = {
  __typename?: 'Mutation';
  subscribeToThread: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type UnsubscribeFromThreadMutationVariables = Exact<{
  threadId: Scalars['Int'];
}>;

export type UnsubscribeFromThreadMutation = {
  __typename?: 'Mutation';
  unsubscribeFromThread: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type UpdateChecklistItemMutationVariables = Exact<{
  data: UpdateChecklistItemInput;
}>;

export type UpdateChecklistItemMutation = {
  __typename?: 'Mutation';
  updateChecklistItem: { __typename?: 'ChecklistItem'; id: number };
};

export type UpdateFileMutationVariables = Exact<{
  id: Scalars['Int'];
  data: UpdateFileInput;
}>;

export type UpdateFileMutation = {
  __typename?: 'Mutation';
  updateFile: { __typename?: 'File'; id: number };
};

export type UpdateFolderMutationVariables = Exact<{
  updateFolderInput: UpdateFolderInput;
}>;

export type UpdateFolderMutation = {
  __typename?: 'Mutation';
  updateFolder: {
    __typename?: 'Folder';
    id: number;
    title: string;
    authorId: number;
    visibilityStatus: VisibilityStatusEnum;
    project: { __typename?: 'Project'; id: number; title: string };
    membersUsers: Array<{
      __typename?: 'FolderMemberUser';
      user?: {
        __typename?: 'User';
        id: number;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; path: string } | null;
      } | null;
    }>;
    membersUserGroups: Array<{
      __typename?: 'FolderMemberUserGroup';
      userGroup?: {
        __typename?: 'UserGroup';
        id: number;
        title: string;
        workspaceId: number;
        avatar?: { __typename?: 'File'; path: string; id: number } | null;
        users: Array<{
          __typename?: 'User';
          id: number;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
        }>;
      } | null;
    }>;
    threadsStatistics: {
      __typename?: 'ThreadsStatisticsEntity';
      completedThreadsCount: number;
      completedThreadsPercent: number;
      threadsCount: number;
    };
  };
};

export type UpdateGoalMutationVariables = Exact<{
  updateGoalInput: UpdateGoalInput;
}>;

export type UpdateGoalMutation = {
  __typename?: 'Mutation';
  updateGoal: { __typename?: 'Goal'; id: number; title: string };
};

export type UpdateMemberMutationVariables = Exact<{
  data: UpdateMemberInput;
}>;

export type UpdateMemberMutation = {
  __typename?: 'Mutation';
  updateMember: { __typename?: 'WorkspaceUser'; userId: number };
};

export type UpdateMessageMutationVariables = Exact<{
  id: Scalars['Int'];
  data: UpdateMessageInput;
}>;

export type UpdateMessageMutation = {
  __typename?: 'Mutation';
  updateMessage: { __typename?: 'Message'; id: number; content?: string | null };
};

export type UpdateProjectMutationVariables = Exact<{
  updateProjectInput: UpdateProjectInput;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject: {
    __typename?: 'Project';
    id: number;
    title: string;
    authorId: number;
    visibilityStatus: VisibilityStatusEnum;
    folders: Array<{
      __typename?: 'Folder';
      id: number;
      title: string;
      goals: Array<{
        __typename?: 'Goal';
        id: number;
        title: string;
        threads: Array<{ __typename?: 'Thread'; id: number; title: string }>;
      }>;
    }>;
    membersUserGroups: Array<{
      __typename?: 'ProjectMemberUserGroup';
      userGroup?: {
        __typename?: 'UserGroup';
        id: number;
        title: string;
        workspaceId: number;
        avatar?: { __typename?: 'File'; path: string; id: number } | null;
        users: Array<{
          __typename?: 'User';
          id: number;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
        }>;
      } | null;
    }>;
    membersUsers: Array<{
      __typename?: 'ProjectMemberUser';
      user?: {
        __typename?: 'User';
        id: number;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; path: string } | null;
      } | null;
    }>;
  };
};

export type UpdateThreadMutationVariables = Exact<{
  data: UpdateThread;
}>;

export type UpdateThreadMutation = {
  __typename?: 'Mutation';
  updateThread: { __typename?: 'Thread'; id: number };
};

export type UpdateUserGroupMutationVariables = Exact<{
  updateUserGroupInput: UpdateUserGroupInput;
}>;

export type UpdateUserGroupMutation = {
  __typename?: 'Mutation';
  updateUserGroup: { __typename?: 'UserGroup'; id: number };
};

export type UpdateWorkspaceMutationVariables = Exact<{
  updateWorkspaceInput: UpdateWorkspaceInput;
  avatar?: InputMaybe<Scalars['Upload']>;
}>;

export type UpdateWorkspaceMutation = {
  __typename?: 'Mutation';
  updateWorkspace: { __typename?: 'Workspace'; id: number; title: string };
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  data: UploadFileInput;
}>;

export type UploadFileMutation = {
  __typename?: 'Mutation';
  uploadFile: { __typename?: 'File'; id: number; path: string };
};

export type UploadFilesMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
  data?: InputMaybe<UploadFileInput>;
}>;

export type UploadFilesMutation = {
  __typename?: 'Mutation';
  uploadFiles: Array<{
    __typename?: 'File';
    id: number;
    path: string;
    fileName: string;
    size?: number | null;
    mimeType?: string | null;
  }>;
};

export type CreateWorkspaceMutationVariables = Exact<{
  createWorkspaceInput: CreateWorkspaceInput;
}>;

export type CreateWorkspaceMutation = {
  __typename?: 'Mutation';
  createWorkspace: { __typename?: 'Workspace'; id: number };
};

export type ChatQueryVariables = Exact<{
  options: FindAllChatsInput;
  data?: InputMaybe<FindAllMessagesOptionsInput>;
}>;

export type ChatQuery = {
  __typename?: 'Query';
  chat: {
    __typename?: 'Chat';
    id: number;
    messagesWithCount: {
      __typename?: 'MessageWithCountEntity';
      count: number;
      rows: Array<{
        __typename?: 'Message';
        id: number;
        content?: string | null;
        author: {
          __typename?: 'User';
          id: number;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
        };
      }>;
    };
  };
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'User';
    id: number;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: { __typename?: 'File'; id: number; path: string } | null;
    workspaces: Array<{
      __typename?: 'Workspace';
      id: number;
      title: string;
      myRole: WorkspaceMemberRole;
      inviteLink: string;
    }>;
  };
};

export type FilesQueryVariables = Exact<{
  data: FindFilesInput;
}>;

export type FilesQuery = {
  __typename?: 'Query';
  files: Array<{
    __typename?: 'File';
    fileName: string;
    id: number;
    mimeType?: string | null;
    path: string;
    size?: number | null;
    threadId?: number | null;
    messageId?: number | null;
  }>;
};

export type FolderQueryVariables = Exact<{
  id: Scalars['Int'];
  dataThread?: InputMaybe<FindThreadsOptions>;
}>;

export type FolderQuery = {
  __typename?: 'Query';
  folder: {
    __typename?: 'Folder';
    id: number;
    title: string;
    authorId: number;
    visibilityStatus: VisibilityStatusEnum;
    project: { __typename?: 'Project'; id: number; title: string };
    membersUsers: Array<{
      __typename?: 'FolderMemberUser';
      user?: {
        __typename?: 'User';
        id: number;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; path: string } | null;
      } | null;
    }>;
    membersUserGroups: Array<{
      __typename?: 'FolderMemberUserGroup';
      userGroup?: {
        __typename?: 'UserGroup';
        id: number;
        title: string;
        workspaceId: number;
        avatar?: { __typename?: 'File'; path: string; id: number } | null;
        users: Array<{
          __typename?: 'User';
          id: number;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
        }>;
      } | null;
    }>;
    threadsStatistics: {
      __typename?: 'ThreadsStatisticsEntity';
      completedThreadsCount: number;
      completedThreadsPercent: number;
      threadsCount: number;
    };
    goals: Array<{
      __typename?: 'Goal';
      id: number;
      title: string;
      threads: Array<{
        __typename?: 'Thread';
        id: number;
        title: string;
        description: string;
        startDate?: string | null;
        endDate?: string | null;
        statusId: number;
        order?: number | null;
        path?: {
          __typename?: 'ThreadPath';
          folder?: { __typename?: 'Folder'; id: number; title: string } | null;
          project?: { __typename?: 'Project'; id: number; title: string } | null;
          goal?: { __typename?: 'Goal'; id: number; title: string } | null;
        } | null;
        responsible?: {
          __typename?: 'User';
          id: number;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
        } | null;
        responsibleUserGroup?: {
          __typename?: 'UserGroup';
          id: number;
          title: string;
          avatar?: { __typename?: 'File'; path: string } | null;
        } | null;
      }>;
    }>;
  };
};

export type IsValidPasswordResetTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type IsValidPasswordResetTokenQuery = {
  __typename?: 'Query';
  isValidPasswordResetToken: boolean;
};

export type IsValidRegisterTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type IsValidRegisterTokenQuery = {
  __typename?: 'Query';
  isValidRegisterToken: { __typename?: 'ResponseSuccessSchema'; success: boolean };
};

export type LinksAndCountQueryVariables = Exact<{
  data: FindLinksInput;
}>;

export type LinksAndCountQuery = {
  __typename?: 'Query';
  linksAndCount: {
    __typename?: 'LinksAndCountEntity';
    count: number;
    rows: Array<{
      __typename?: 'Link';
      id: number;
      title?: string | null;
      description?: string | null;
      URL: string;
      messageId: number;
      createdAt: string;
      updatedAt: string;
    }>;
  };
};

export type MessagesWithCountQueryVariables = Exact<{
  data?: InputMaybe<FindAllMessagesOptionsInput>;
}>;

export type MessagesWithCountQuery = {
  __typename?: 'Query';
  messagesWithCount: {
    __typename?: 'MessageWithCountEntity';
    count: number;
    rows: Array<{
      __typename?: 'Message';
      id: number;
      updatedAt: string;
      content?: string | null;
      attachments: Array<{
        __typename?: 'File';
        id: number;
        fileName: string;
        path: string;
        mimeType?: string | null;
        size?: number | null;
      }>;
      author: {
        __typename?: 'User';
        id: number;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        avatar?: { __typename?: 'File'; path: string } | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      };
      reactions: Array<{
        __typename?: 'MessageReaction';
        count: number;
        emojiId: number;
        userIds: Array<number>;
        emoji: { __typename?: 'Emoji'; id: number; name: string };
        users: Array<{
          __typename?: 'User';
          id: number;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
        }>;
      }>;
      repliedToMessage?: {
        __typename?: 'Message';
        id: number;
        content?: string | null;
        attachments: Array<{
          __typename?: 'File';
          id: number;
          fileName: string;
          path: string;
          mimeType?: string | null;
          size?: number | null;
        }>;
        author: {
          __typename?: 'User';
          id: number;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
          karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
        };
      } | null;
    }>;
  };
};

export type ProjectQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ProjectQuery = {
  __typename?: 'Query';
  project: {
    __typename?: 'Project';
    id: number;
    title: string;
    authorId: number;
    visibilityStatus: VisibilityStatusEnum;
    folders: Array<{
      __typename?: 'Folder';
      id: number;
      title: string;
      authorId: number;
      goals: Array<{
        __typename?: 'Goal';
        id: number;
        title: string;
        authorId: number;
        threads: Array<{ __typename?: 'Thread'; id: number; title: string }>;
      }>;
    }>;
    membersUserGroups: Array<{
      __typename?: 'ProjectMemberUserGroup';
      userGroup?: {
        __typename?: 'UserGroup';
        id: number;
        title: string;
        workspaceId: number;
        avatar?: { __typename?: 'File'; path: string; id: number } | null;
        users: Array<{
          __typename?: 'User';
          id: number;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
        }>;
      } | null;
    }>;
    membersUsers: Array<{
      __typename?: 'ProjectMemberUser';
      user?: {
        __typename?: 'User';
        id: number;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; path: string } | null;
      } | null;
    }>;
  };
};

export type ProjectFoldersQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ProjectFoldersQuery = {
  __typename?: 'Query';
  project: {
    __typename?: 'Project';
    id: number;
    title: string;
    authorId: number;
    membersUserGroups: Array<{
      __typename?: 'ProjectMemberUserGroup';
      userGroup?: {
        __typename?: 'UserGroup';
        id: number;
        title: string;
        workspaceId: number;
        avatar?: { __typename?: 'File'; path: string; id: number } | null;
        users: Array<{
          __typename?: 'User';
          id: number;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
        }>;
      } | null;
    }>;
    membersUsers: Array<{
      __typename?: 'ProjectMemberUser';
      user?: {
        __typename?: 'User';
        id: number;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; path: string } | null;
      } | null;
    }>;
    folders: Array<{
      __typename?: 'Folder';
      id: number;
      title: string;
      authorId: number;
      order?: number | null;
      responsible?: {
        __typename?: 'User';
        id: number;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; path: string } | null;
      } | null;
      responsibleUserGroup?: {
        __typename?: 'UserGroup';
        id: number;
        title: string;
        avatar?: { __typename?: 'File'; path: string } | null;
      } | null;
      threadsStatistics: {
        __typename?: 'ThreadsStatisticsEntity';
        completedThreadsCount: number;
        completedThreadsPercent: number;
        threadsCount: number;
      };
    }>;
  };
};

export type ProjectsQueryVariables = Exact<{
  options: FindAllOptionsEntity;
}>;

export type ProjectsQuery = {
  __typename?: 'Query';
  projects: Array<{
    __typename?: 'Project';
    id: number;
    title: string;
    authorId: number;
    visibilityStatus: VisibilityStatusEnum;
    folders: Array<{
      __typename?: 'Folder';
      id: number;
      title: string;
      authorId: number;
      visibilityStatus: VisibilityStatusEnum;
      goals: Array<{
        __typename?: 'Goal';
        id: number;
        title: string;
        threads: Array<{ __typename?: 'Thread'; id: number; title: string }>;
      }>;
    }>;
  }>;
};

export type ThreadQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ThreadQuery = {
  __typename?: 'Query';
  thread: {
    __typename?: 'Thread';
    id: number;
    title: string;
    description: string;
    startDate?: string | null;
    endDate?: string | null;
    statusId: number;
    order?: number | null;
    result: string;
    subscriptionStatus: SubscriptionStatusEnum;
    attachments: Array<{
      __typename?: 'File';
      id: number;
      fileName: string;
      path: string;
      size?: number | null;
      mimeType?: string | null;
    }>;
    path?: {
      __typename?: 'ThreadPath';
      folder?: { __typename?: 'Folder'; id: number; title: string } | null;
      project?: { __typename?: 'Project'; id: number; title: string } | null;
      goal?: { __typename?: 'Goal'; id: number; title: string } | null;
    } | null;
    responsible?: {
      __typename?: 'User';
      id: number;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; path: string } | null;
    } | null;
    responsibleUserGroup?: {
      __typename?: 'UserGroup';
      id: number;
      title: string;
      avatar?: { __typename?: 'File'; path: string } | null;
    } | null;
    subscribedUsers: Array<{
      __typename?: 'User';
      id: number;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; path: string } | null;
    }>;
    subscribedUserGroups: Array<{
      __typename?: 'UserGroup';
      id: number;
      title: string;
      avatar?: { __typename?: 'File'; path: string } | null;
    }>;
    checklistItems: Array<{
      __typename?: 'ChecklistItem';
      id: number;
      isChecked: boolean;
      threadId: number;
      title: string;
    }>;
    chat: { __typename?: 'Chat'; id: number };
  };
};

export type ThreadHistoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ThreadHistoryQuery = {
  __typename?: 'Query';
  thread: {
    __typename?: 'Thread';
    id: number;
    history?: Array<{
      __typename?: 'LogsHistoryResponseEntity';
      maxCreatedAt: string;
      logs: Array<{ __typename?: 'ThreadLog'; id: number; title: string }>;
      user: {
        __typename?: 'User';
        id: number;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; fileName: string; path: string } | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      };
    }> | null;
  };
};

export type ThreadsAndCountQueryVariables = Exact<{
  options: FindThreadsOptions;
}>;

export type ThreadsAndCountQuery = {
  __typename?: 'Query';
  threadsAndCount: {
    __typename?: 'ThreadAndCountEntity';
    count: number;
    rows: Array<{
      __typename?: 'Thread';
      id: number;
      title: string;
      description: string;
      startDate?: string | null;
      endDate?: string | null;
      statusId: number;
      order?: number | null;
      path?: {
        __typename?: 'ThreadPath';
        folder?: { __typename?: 'Folder'; id: number; title: string } | null;
        project?: { __typename?: 'Project'; id: number; title: string } | null;
        goal?: { __typename?: 'Goal'; id: number; title: string } | null;
      } | null;
      responsible?: {
        __typename?: 'User';
        id: number;
        email?: string | null;
        lastName?: string | null;
        firstName?: string | null;
        avatar?: { __typename?: 'File'; path: string } | null;
      } | null;
      responsibleUserGroup?: {
        __typename?: 'UserGroup';
        id: number;
        title: string;
        avatar?: { __typename?: 'File'; path: string } | null;
      } | null;
    }>;
  };
};

export type UserGroupQueryVariables = Exact<{
  userGroupId: Scalars['Int'];
}>;

export type UserGroupQuery = {
  __typename?: 'Query';
  userGroup: {
    __typename?: 'UserGroup';
    id: number;
    title: string;
    avatar?: { __typename?: 'File'; path: string; id: number } | null;
    users: Array<{
      __typename?: 'User';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; path: string } | null;
      workspaces: Array<{ __typename?: 'Workspace'; myRole: WorkspaceMemberRole }>;
    }>;
  };
};

export type UserGroupsQueryVariables = Exact<{
  data: UserGroupsFindDataInput;
}>;

export type UserGroupsQuery = {
  __typename?: 'Query';
  userGroups: Array<{
    __typename?: 'UserGroup';
    id: number;
    title: string;
    workspaceId: number;
    avatar?: { __typename?: 'File'; path: string; id: number } | null;
    users: Array<{
      __typename?: 'User';
      id: number;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatar?: { __typename?: 'File'; path: string } | null;
    }>;
  }>;
};

export type UsersQueryVariables = Exact<{
  options: FindUsersOptionsInput;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    id: number;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: { __typename?: 'File'; path: string } | null;
  }>;
};

export type WorkspaceQueryVariables = Exact<{
  workspaceId: Scalars['Int'];
}>;

export type WorkspaceQuery = {
  __typename?: 'Query';
  workspace: {
    __typename: 'Workspace';
    isInviteLinkAccess: boolean;
    inviteLink: string;
    id: number;
    myRole: WorkspaceMemberRole;
    title: string;
    avatar?: { __typename?: 'File'; path: string; id: number } | null;
    members: Array<{
      __typename?: 'WorkspaceUser';
      role: WorkspaceMemberRole;
      user: {
        __typename?: 'User';
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        id: number;
        avatar?: { __typename?: 'File'; path: string; id: number } | null;
      };
    }>;
    invites: Array<{ __typename?: 'WorkspaceInvite'; email: string; id: number }>;
  };
};

export type WorkspaceAndUserDataByAccessTokenQueryVariables = Exact<{
  workspaceAccessToken: Scalars['String'];
}>;

export type WorkspaceAndUserDataByAccessTokenQuery = {
  __typename?: 'Query';
  workspaceAndUserDataByAccessToken: {
    __typename?: 'WorkspaceAndUserDataByAccessToken';
    email?: string | null;
    isRegister?: boolean | null;
    workspaceTitle: string;
    isInvitedUserAccessToken: boolean;
  };
};

export type WorkspacesQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspacesQuery = {
  __typename?: 'Query';
  workspaces: Array<{
    __typename: 'Workspace';
    isInviteLinkAccess: boolean;
    inviteLink: string;
    id: number;
    myRole: WorkspaceMemberRole;
    title: string;
    avatar?: { __typename?: 'File'; path: string; id: number } | null;
    members: Array<{
      __typename?: 'WorkspaceUser';
      role: WorkspaceMemberRole;
      user: {
        __typename?: 'User';
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        id: number;
        avatar?: { __typename?: 'File'; path: string; id: number } | null;
      };
    }>;
    invites: Array<{ __typename?: 'WorkspaceInvite'; email: string; id: number }>;
  }>;
};

export type NewChatActivitySubscriptionVariables = Exact<{
  chatId: Scalars['Int'];
}>;

export type NewChatActivitySubscription = {
  __typename?: 'Subscription';
  newChatActivity: {
    __typename?: 'ChatActivityEntity';
    type: string;
    message?: {
      __typename?: 'Message';
      id: number;
      updatedAt: string;
      content?: string | null;
      attachments: Array<{
        __typename?: 'File';
        id: number;
        fileName: string;
        path: string;
        mimeType?: string | null;
        size?: number | null;
      }>;
      author: {
        __typename?: 'User';
        id: number;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        avatar?: { __typename?: 'File'; path: string } | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      };
      reactions: Array<{
        __typename?: 'MessageReaction';
        count: number;
        emojiId: number;
        userIds: Array<number>;
        emoji: { __typename?: 'Emoji'; id: number; name: string };
        users: Array<{
          __typename?: 'User';
          id: number;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
        }>;
      }>;
      repliedToMessage?: {
        __typename?: 'Message';
        id: number;
        content?: string | null;
        attachments: Array<{
          __typename?: 'File';
          id: number;
          fileName: string;
          path: string;
          mimeType?: string | null;
          size?: number | null;
        }>;
        author: {
          __typename?: 'User';
          id: number;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          avatar?: { __typename?: 'File'; path: string } | null;
          karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
        };
      } | null;
    } | null;
  };
};

export type NewChatAttachedActivitySubscriptionVariables = Exact<{
  chatId: Scalars['Int'];
}>;

export type NewChatAttachedActivitySubscription = {
  __typename?: 'Subscription';
  newChatAttachedActivity: {
    __typename?: 'NewChatAttachedActivity';
    type: ChatActivityEnum;
    images?: Array<{
      __typename?: 'File';
      fileName: string;
      id: number;
      mimeType?: string | null;
      path: string;
      size?: number | null;
      threadId?: number | null;
      messageId?: number | null;
    }> | null;
    files?: Array<{
      __typename?: 'File';
      fileName: string;
      id: number;
      mimeType?: string | null;
      path: string;
      size?: number | null;
      threadId?: number | null;
      messageId?: number | null;
    }> | null;
    links?: Array<{
      __typename?: 'Link';
      id: number;
      title?: string | null;
      description?: string | null;
      URL: string;
      messageId: number;
      createdAt: string;
      updatedAt: string;
    }> | null;
  };
};

export type NewThreadActivitySubscriptionVariables = Exact<{
  threadId: Scalars['Int'];
}>;

export type NewThreadActivitySubscription = {
  __typename?: 'Subscription';
  newThreadActivity: {
    __typename?: 'NewThreadActivityEntity';
    type: NewThreadActivityTypeEnum;
    history?: Array<{
      __typename?: 'LogsHistoryResponseEntity';
      maxCreatedAt: string;
      logs: Array<{ __typename?: 'ThreadLog'; id: number; title: string }>;
      user: {
        __typename?: 'User';
        id: number;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        avatar?: { __typename?: 'File'; fileName: string; path: string } | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      };
    }> | null;
  };
};

export const ThreadFragmentFragmentDoc = gql`
  fragment threadFragment on Thread {
    id
    title
    description
    startDate
    endDate
    statusId
    order
    path {
      folder {
        id
        title
        project {
          id
          title
        }
      }
    }
    responsible {
      id
      email
    }
  }
`;
export const UserFragmentFragmentDoc = gql`
  fragment userFragment on User {
    id
    email
    firstName
    lastName
    avatar {
      id
      path
    }
    workspaces {
      id
      title
      myRole
      inviteLink
    }
  }
`;
export const AddMembersToFolderDocument = gql`
  mutation addMembersToFolder($data: AddMembersToFolderInput!) {
    addMembersToFolder(data: $data) {
      id
      title
      project {
        id
        title
      }
      visibilityStatus
      membersUsers {
        user {
          id
          email
          firstName
          lastName
          avatar {
            path
          }
        }
      }
      membersUserGroups {
        userGroup {
          avatar {
            path
            id
          }
          id
          title
          workspaceId
          users {
            id
            email
            firstName
            lastName
            avatar {
              path
            }
          }
        }
      }
      threadsStatistics {
        completedThreadsCount
        completedThreadsPercent
        threadsCount
      }
      goals {
        id
        title
      }
    }
  }
`;
export type AddMembersToFolderMutationFn = Apollo.MutationFunction<
  AddMembersToFolderMutation,
  AddMembersToFolderMutationVariables
>;

/**
 * __useAddMembersToFolderMutation__
 *
 * To run a mutation, you first call `useAddMembersToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembersToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembersToFolderMutation, { data, loading, error }] = useAddMembersToFolderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddMembersToFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMembersToFolderMutation,
    AddMembersToFolderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMembersToFolderMutation, AddMembersToFolderMutationVariables>(
    AddMembersToFolderDocument,
    options,
  );
}
export type AddMembersToFolderMutationHookResult = ReturnType<typeof useAddMembersToFolderMutation>;
export type AddMembersToFolderMutationResult = Apollo.MutationResult<AddMembersToFolderMutation>;
export type AddMembersToFolderMutationOptions = Apollo.BaseMutationOptions<
  AddMembersToFolderMutation,
  AddMembersToFolderMutationVariables
>;
export const AddMembersToProjectDocument = gql`
  mutation addMembersToProject($data: AddMembersToProjectInput!) {
    addMembersToProject(data: $data) {
      id
      membersUserGroups {
        userGroup {
          title
          id
          avatar {
            path
          }
          users {
            id
            email
            firstName
            lastName
            avatar {
              path
            }
          }
        }
      }
      membersUsers {
        user {
          firstName
          email
          lastName
          id
          avatar {
            path
          }
        }
      }
      id
    }
  }
`;
export type AddMembersToProjectMutationFn = Apollo.MutationFunction<
  AddMembersToProjectMutation,
  AddMembersToProjectMutationVariables
>;

/**
 * __useAddMembersToProjectMutation__
 *
 * To run a mutation, you first call `useAddMembersToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembersToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembersToProjectMutation, { data, loading, error }] = useAddMembersToProjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddMembersToProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMembersToProjectMutation,
    AddMembersToProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMembersToProjectMutation, AddMembersToProjectMutationVariables>(
    AddMembersToProjectDocument,
    options,
  );
}
export type AddMembersToProjectMutationHookResult = ReturnType<
  typeof useAddMembersToProjectMutation
>;
export type AddMembersToProjectMutationResult = Apollo.MutationResult<AddMembersToProjectMutation>;
export type AddMembersToProjectMutationOptions = Apollo.BaseMutationOptions<
  AddMembersToProjectMutation,
  AddMembersToProjectMutationVariables
>;
export const CancelInviteToWorkspaceDocument = gql`
  mutation cancelInviteToWorkspace($cancelInviteToWorkspaceId: Int!) {
    cancelInviteToWorkspace(id: $cancelInviteToWorkspaceId) {
      success
    }
  }
`;
export type CancelInviteToWorkspaceMutationFn = Apollo.MutationFunction<
  CancelInviteToWorkspaceMutation,
  CancelInviteToWorkspaceMutationVariables
>;

/**
 * __useCancelInviteToWorkspaceMutation__
 *
 * To run a mutation, you first call `useCancelInviteToWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInviteToWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInviteToWorkspaceMutation, { data, loading, error }] = useCancelInviteToWorkspaceMutation({
 *   variables: {
 *      cancelInviteToWorkspaceId: // value for 'cancelInviteToWorkspaceId'
 *   },
 * });
 */
export function useCancelInviteToWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelInviteToWorkspaceMutation,
    CancelInviteToWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelInviteToWorkspaceMutation,
    CancelInviteToWorkspaceMutationVariables
  >(CancelInviteToWorkspaceDocument, options);
}
export type CancelInviteToWorkspaceMutationHookResult = ReturnType<
  typeof useCancelInviteToWorkspaceMutation
>;
export type CancelInviteToWorkspaceMutationResult =
  Apollo.MutationResult<CancelInviteToWorkspaceMutation>;
export type CancelInviteToWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  CancelInviteToWorkspaceMutation,
  CancelInviteToWorkspaceMutationVariables
>;
export const ConfirmEmailDocument = gql`
  mutation confirmEmail($token: String!) {
    confirmEmail(token: $token) {
      registrationToken
      success
    }
  }
`;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(
    ConfirmEmailDocument,
    options,
  );
}
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;
export const RemoveChecklistItemDocument = gql`
  mutation removeChecklistItem($id: Int!) {
    removeChecklistItem(id: $id) {
      success
    }
  }
`;
export type RemoveChecklistItemMutationFn = Apollo.MutationFunction<
  RemoveChecklistItemMutation,
  RemoveChecklistItemMutationVariables
>;

/**
 * __useRemoveChecklistItemMutation__
 *
 * To run a mutation, you first call `useRemoveChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChecklistItemMutation, { data, loading, error }] = useRemoveChecklistItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveChecklistItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveChecklistItemMutation,
    RemoveChecklistItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveChecklistItemMutation, RemoveChecklistItemMutationVariables>(
    RemoveChecklistItemDocument,
    options,
  );
}
export type RemoveChecklistItemMutationHookResult = ReturnType<
  typeof useRemoveChecklistItemMutation
>;
export type RemoveChecklistItemMutationResult = Apollo.MutationResult<RemoveChecklistItemMutation>;
export type RemoveChecklistItemMutationOptions = Apollo.BaseMutationOptions<
  RemoveChecklistItemMutation,
  RemoveChecklistItemMutationVariables
>;
export const CreateChecklistItemDocument = gql`
  mutation createChecklistItem($data: CreateChecklistItemInput!) {
    createChecklistItem(data: $data) {
      title
    }
  }
`;
export type CreateChecklistItemMutationFn = Apollo.MutationFunction<
  CreateChecklistItemMutation,
  CreateChecklistItemMutationVariables
>;

/**
 * __useCreateChecklistItemMutation__
 *
 * To run a mutation, you first call `useCreateChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChecklistItemMutation, { data, loading, error }] = useCreateChecklistItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateChecklistItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChecklistItemMutation,
    CreateChecklistItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChecklistItemMutation, CreateChecklistItemMutationVariables>(
    CreateChecklistItemDocument,
    options,
  );
}
export type CreateChecklistItemMutationHookResult = ReturnType<
  typeof useCreateChecklistItemMutation
>;
export type CreateChecklistItemMutationResult = Apollo.MutationResult<CreateChecklistItemMutation>;
export type CreateChecklistItemMutationOptions = Apollo.BaseMutationOptions<
  CreateChecklistItemMutation,
  CreateChecklistItemMutationVariables
>;
export const CreateFolderDocument = gql`
  mutation createFolder($createFolderInput: CreateFolderInput!) {
    createFolder(createFolderInput: $createFolderInput) {
      id
      title
      authorId
    }
  }
`;
export type CreateFolderMutationFn = Apollo.MutationFunction<
  CreateFolderMutation,
  CreateFolderMutationVariables
>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      createFolderInput: // value for 'createFolderInput'
 *   },
 * });
 */
export function useCreateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(
    CreateFolderDocument,
    options,
  );
}
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<
  CreateFolderMutation,
  CreateFolderMutationVariables
>;
export const CreateGoalDocument = gql`
  mutation createGoal($createGoalInput: CreateGoalInput!) {
    createGoal(createGoalInput: $createGoalInput) {
      id
      title
      authorId
    }
  }
`;
export type CreateGoalMutationFn = Apollo.MutationFunction<
  CreateGoalMutation,
  CreateGoalMutationVariables
>;

/**
 * __useCreateGoalMutation__
 *
 * To run a mutation, you first call `useCreateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoalMutation, { data, loading, error }] = useCreateGoalMutation({
 *   variables: {
 *      createGoalInput: // value for 'createGoalInput'
 *   },
 * });
 */
export function useCreateGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateGoalMutation, CreateGoalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGoalMutation, CreateGoalMutationVariables>(
    CreateGoalDocument,
    options,
  );
}
export type CreateGoalMutationHookResult = ReturnType<typeof useCreateGoalMutation>;
export type CreateGoalMutationResult = Apollo.MutationResult<CreateGoalMutation>;
export type CreateGoalMutationOptions = Apollo.BaseMutationOptions<
  CreateGoalMutation,
  CreateGoalMutationVariables
>;
export const CreateMessageDocument = gql`
  mutation createMessage($data: CreateMessageInput!) {
    createMessage(data: $data) {
      id
    }
  }
`;
export type CreateMessageMutationFn = Apollo.MutationFunction<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(
    CreateMessageDocument,
    options,
  );
}
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;
export const CreateProjectDocument = gql`
  mutation createProject($createProjectInput: CreateProjectInput!) {
    createProject(createProjectInput: $createProjectInput) {
      id
      title
      authorId
    }
  }
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      createProjectInput: // value for 'createProjectInput'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(
    CreateProjectDocument,
    options,
  );
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const CreateThreadDocument = gql`
  mutation createThread($data: CreateThread!) {
    createThread(data: $data) {
      id
      title
    }
  }
`;
export type CreateThreadMutationFn = Apollo.MutationFunction<
  CreateThreadMutation,
  CreateThreadMutationVariables
>;

/**
 * __useCreateThreadMutation__
 *
 * To run a mutation, you first call `useCreateThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThreadMutation, { data, loading, error }] = useCreateThreadMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateThreadMutation, CreateThreadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateThreadMutation, CreateThreadMutationVariables>(
    CreateThreadDocument,
    options,
  );
}
export type CreateThreadMutationHookResult = ReturnType<typeof useCreateThreadMutation>;
export type CreateThreadMutationResult = Apollo.MutationResult<CreateThreadMutation>;
export type CreateThreadMutationOptions = Apollo.BaseMutationOptions<
  CreateThreadMutation,
  CreateThreadMutationVariables
>;
export const CreateUserGroupDocument = gql`
  mutation createUserGroup($createUserGroupInput: CreateUserGroupInput!) {
    createUserGroup(createUserGroupInput: $createUserGroupInput) {
      id
    }
  }
`;
export type CreateUserGroupMutationFn = Apollo.MutationFunction<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>;

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      createUserGroupInput: // value for 'createUserGroupInput'
 *   },
 * });
 */
export function useCreateUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserGroupMutation,
    CreateUserGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(
    CreateUserGroupDocument,
    options,
  );
}
export type CreateUserGroupMutationHookResult = ReturnType<typeof useCreateUserGroupMutation>;
export type CreateUserGroupMutationResult = Apollo.MutationResult<CreateUserGroupMutation>;
export type CreateUserGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>;
export const DeleteMessageDocument = gql`
  mutation deleteMessage($id: Int!) {
    deleteMessage(id: $id) {
      success
    }
  }
`;
export type DeleteMessageMutationFn = Apollo.MutationFunction<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(
    DeleteMessageDocument,
    options,
  );
}
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;
export const EditUserDocument = gql`
  mutation editUser($data: EditUserInput!) {
    editUser(data: $data) {
      id
    }
  }
`;
export type EditUserMutationFn = Apollo.MutationFunction<
  EditUserMutation,
  EditUserMutationVariables
>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
  EditUserMutation,
  EditUserMutationVariables
>;
export const HandleInviteLinkAccessDocument = gql`
  mutation handleInviteLinkAccess($workspaceId: Int!) {
    handleInviteLinkAccess(workspaceId: $workspaceId) {
      success
    }
  }
`;
export type HandleInviteLinkAccessMutationFn = Apollo.MutationFunction<
  HandleInviteLinkAccessMutation,
  HandleInviteLinkAccessMutationVariables
>;

/**
 * __useHandleInviteLinkAccessMutation__
 *
 * To run a mutation, you first call `useHandleInviteLinkAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleInviteLinkAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleInviteLinkAccessMutation, { data, loading, error }] = useHandleInviteLinkAccessMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useHandleInviteLinkAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HandleInviteLinkAccessMutation,
    HandleInviteLinkAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HandleInviteLinkAccessMutation,
    HandleInviteLinkAccessMutationVariables
  >(HandleInviteLinkAccessDocument, options);
}
export type HandleInviteLinkAccessMutationHookResult = ReturnType<
  typeof useHandleInviteLinkAccessMutation
>;
export type HandleInviteLinkAccessMutationResult =
  Apollo.MutationResult<HandleInviteLinkAccessMutation>;
export type HandleInviteLinkAccessMutationOptions = Apollo.BaseMutationOptions<
  HandleInviteLinkAccessMutation,
  HandleInviteLinkAccessMutationVariables
>;
export const HandleReactionDocument = gql`
  mutation handleReaction($data: HandleReactionDataInput!) {
    handleReaction(data: $data) {
      success
    }
  }
`;
export type HandleReactionMutationFn = Apollo.MutationFunction<
  HandleReactionMutation,
  HandleReactionMutationVariables
>;

/**
 * __useHandleReactionMutation__
 *
 * To run a mutation, you first call `useHandleReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleReactionMutation, { data, loading, error }] = useHandleReactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useHandleReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<HandleReactionMutation, HandleReactionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HandleReactionMutation, HandleReactionMutationVariables>(
    HandleReactionDocument,
    options,
  );
}
export type HandleReactionMutationHookResult = ReturnType<typeof useHandleReactionMutation>;
export type HandleReactionMutationResult = Apollo.MutationResult<HandleReactionMutation>;
export type HandleReactionMutationOptions = Apollo.BaseMutationOptions<
  HandleReactionMutation,
  HandleReactionMutationVariables
>;
export const InviteToWorkspaceDocument = gql`
  mutation inviteToWorkspace($data: InviteToWorkspaceInput!) {
    inviteToWorkspace(data: $data) {
      failures
      successes
    }
  }
`;
export type InviteToWorkspaceMutationFn = Apollo.MutationFunction<
  InviteToWorkspaceMutation,
  InviteToWorkspaceMutationVariables
>;

/**
 * __useInviteToWorkspaceMutation__
 *
 * To run a mutation, you first call `useInviteToWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteToWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteToWorkspaceMutation, { data, loading, error }] = useInviteToWorkspaceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInviteToWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteToWorkspaceMutation, InviteToWorkspaceMutationVariables>(
    InviteToWorkspaceDocument,
    options,
  );
}
export type InviteToWorkspaceMutationHookResult = ReturnType<typeof useInviteToWorkspaceMutation>;
export type InviteToWorkspaceMutationResult = Apollo.MutationResult<InviteToWorkspaceMutation>;
export type InviteToWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  InviteToWorkspaceMutation,
  InviteToWorkspaceMutationVariables
>;
export const JoinToWorkspaceDocument = gql`
  mutation joinToWorkspace($token: String!) {
    joinToWorkspace(token: $token) {
      id
    }
  }
`;
export type JoinToWorkspaceMutationFn = Apollo.MutationFunction<
  JoinToWorkspaceMutation,
  JoinToWorkspaceMutationVariables
>;

/**
 * __useJoinToWorkspaceMutation__
 *
 * To run a mutation, you first call `useJoinToWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinToWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinToWorkspaceMutation, { data, loading, error }] = useJoinToWorkspaceMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useJoinToWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinToWorkspaceMutation,
    JoinToWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinToWorkspaceMutation, JoinToWorkspaceMutationVariables>(
    JoinToWorkspaceDocument,
    options,
  );
}
export type JoinToWorkspaceMutationHookResult = ReturnType<typeof useJoinToWorkspaceMutation>;
export type JoinToWorkspaceMutationResult = Apollo.MutationResult<JoinToWorkspaceMutation>;
export type JoinToWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  JoinToWorkspaceMutation,
  JoinToWorkspaceMutationVariables
>;
export const LeaveUserGroupDocument = gql`
  mutation leaveUserGroup($leaveUserGroupId: Int!) {
    leaveUserGroup(id: $leaveUserGroupId) {
      success
    }
  }
`;
export type LeaveUserGroupMutationFn = Apollo.MutationFunction<
  LeaveUserGroupMutation,
  LeaveUserGroupMutationVariables
>;

/**
 * __useLeaveUserGroupMutation__
 *
 * To run a mutation, you first call `useLeaveUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveUserGroupMutation, { data, loading, error }] = useLeaveUserGroupMutation({
 *   variables: {
 *      leaveUserGroupId: // value for 'leaveUserGroupId'
 *   },
 * });
 */
export function useLeaveUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<LeaveUserGroupMutation, LeaveUserGroupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveUserGroupMutation, LeaveUserGroupMutationVariables>(
    LeaveUserGroupDocument,
    options,
  );
}
export type LeaveUserGroupMutationHookResult = ReturnType<typeof useLeaveUserGroupMutation>;
export type LeaveUserGroupMutationResult = Apollo.MutationResult<LeaveUserGroupMutation>;
export type LeaveUserGroupMutationOptions = Apollo.BaseMutationOptions<
  LeaveUserGroupMutation,
  LeaveUserGroupMutationVariables
>;
export const LeaveWorkspaceDocument = gql`
  mutation leaveWorkspace($workspaceId: Int!) {
    leaveWorkspace(workspaceId: $workspaceId) {
      success
    }
  }
`;
export type LeaveWorkspaceMutationFn = Apollo.MutationFunction<
  LeaveWorkspaceMutation,
  LeaveWorkspaceMutationVariables
>;

/**
 * __useLeaveWorkspaceMutation__
 *
 * To run a mutation, you first call `useLeaveWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveWorkspaceMutation, { data, loading, error }] = useLeaveWorkspaceMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useLeaveWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<LeaveWorkspaceMutation, LeaveWorkspaceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveWorkspaceMutation, LeaveWorkspaceMutationVariables>(
    LeaveWorkspaceDocument,
    options,
  );
}
export type LeaveWorkspaceMutationHookResult = ReturnType<typeof useLeaveWorkspaceMutation>;
export type LeaveWorkspaceMutationResult = Apollo.MutationResult<LeaveWorkspaceMutation>;
export type LeaveWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  LeaveWorkspaceMutation,
  LeaveWorkspaceMutationVariables
>;
export const LoginDocument = gql`
  mutation login($data: LoginInput!) {
    login(data: $data) {
      token
      user {
        workspaces {
          id
        }
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RegisterDocument = gql`
  mutation register($data: RegisterInput!) {
    register(data: $data) {
      token
      user {
        workspaces {
          id
        }
      }
    }
  }
`;
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const RemoveFileDocument = gql`
  mutation removeFile($id: Int!) {
    removeFile(id: $id) {
      success
    }
  }
`;
export type RemoveFileMutationFn = Apollo.MutationFunction<
  RemoveFileMutation,
  RemoveFileMutationVariables
>;

/**
 * __useRemoveFileMutation__
 *
 * To run a mutation, you first call `useRemoveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileMutation, { data, loading, error }] = useRemoveFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFileMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveFileMutation, RemoveFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFileMutation, RemoveFileMutationVariables>(
    RemoveFileDocument,
    options,
  );
}
export type RemoveFileMutationHookResult = ReturnType<typeof useRemoveFileMutation>;
export type RemoveFileMutationResult = Apollo.MutationResult<RemoveFileMutation>;
export type RemoveFileMutationOptions = Apollo.BaseMutationOptions<
  RemoveFileMutation,
  RemoveFileMutationVariables
>;
export const RemoveFilesDocument = gql`
  mutation removeFiles($ids: [Int!]!) {
    removeFiles(ids: $ids) {
      success
    }
  }
`;
export type RemoveFilesMutationFn = Apollo.MutationFunction<
  RemoveFilesMutation,
  RemoveFilesMutationVariables
>;

/**
 * __useRemoveFilesMutation__
 *
 * To run a mutation, you first call `useRemoveFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFilesMutation, { data, loading, error }] = useRemoveFilesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveFilesMutation, RemoveFilesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFilesMutation, RemoveFilesMutationVariables>(
    RemoveFilesDocument,
    options,
  );
}
export type RemoveFilesMutationHookResult = ReturnType<typeof useRemoveFilesMutation>;
export type RemoveFilesMutationResult = Apollo.MutationResult<RemoveFilesMutation>;
export type RemoveFilesMutationOptions = Apollo.BaseMutationOptions<
  RemoveFilesMutation,
  RemoveFilesMutationVariables
>;
export const RemoveFolderDocument = gql`
  mutation removeFolder($id: Int!) {
    removeFolder(id: $id) {
      success
    }
  }
`;
export type RemoveFolderMutationFn = Apollo.MutationFunction<
  RemoveFolderMutation,
  RemoveFolderMutationVariables
>;

/**
 * __useRemoveFolderMutation__
 *
 * To run a mutation, you first call `useRemoveFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFolderMutation, { data, loading, error }] = useRemoveFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveFolderMutation, RemoveFolderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFolderMutation, RemoveFolderMutationVariables>(
    RemoveFolderDocument,
    options,
  );
}
export type RemoveFolderMutationHookResult = ReturnType<typeof useRemoveFolderMutation>;
export type RemoveFolderMutationResult = Apollo.MutationResult<RemoveFolderMutation>;
export type RemoveFolderMutationOptions = Apollo.BaseMutationOptions<
  RemoveFolderMutation,
  RemoveFolderMutationVariables
>;
export const RemoveGoalDocument = gql`
  mutation removeGoal($id: Int!) {
    removeGoal(id: $id) {
      success
    }
  }
`;
export type RemoveGoalMutationFn = Apollo.MutationFunction<
  RemoveGoalMutation,
  RemoveGoalMutationVariables
>;

/**
 * __useRemoveGoalMutation__
 *
 * To run a mutation, you first call `useRemoveGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGoalMutation, { data, loading, error }] = useRemoveGoalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveGoalMutation, RemoveGoalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveGoalMutation, RemoveGoalMutationVariables>(
    RemoveGoalDocument,
    options,
  );
}
export type RemoveGoalMutationHookResult = ReturnType<typeof useRemoveGoalMutation>;
export type RemoveGoalMutationResult = Apollo.MutationResult<RemoveGoalMutation>;
export type RemoveGoalMutationOptions = Apollo.BaseMutationOptions<
  RemoveGoalMutation,
  RemoveGoalMutationVariables
>;
export const RemoveMemberDocument = gql`
  mutation removeMember($data: RemoveMemberInput!) {
    removeMember(data: $data) {
      success
    }
  }
`;
export type RemoveMemberMutationFn = Apollo.MutationFunction<
  RemoveMemberMutation,
  RemoveMemberMutationVariables
>;

/**
 * __useRemoveMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberMutation, { data, loading, error }] = useRemoveMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveMemberMutation, RemoveMemberMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMemberMutation, RemoveMemberMutationVariables>(
    RemoveMemberDocument,
    options,
  );
}
export type RemoveMemberMutationHookResult = ReturnType<typeof useRemoveMemberMutation>;
export type RemoveMemberMutationResult = Apollo.MutationResult<RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<
  RemoveMemberMutation,
  RemoveMemberMutationVariables
>;
export const RemoveMemberFromFolderDocument = gql`
  mutation removeMemberFromFolder($data: RemoveMemberFromFolderInput!) {
    removeMemberFromFolder(data: $data) {
      id
      membersUserGroups {
        userGroup {
          id
        }
      }
      membersUsers {
        user {
          id
        }
      }
    }
  }
`;
export type RemoveMemberFromFolderMutationFn = Apollo.MutationFunction<
  RemoveMemberFromFolderMutation,
  RemoveMemberFromFolderMutationVariables
>;

/**
 * __useRemoveMemberFromFolderMutation__
 *
 * To run a mutation, you first call `useRemoveMemberFromFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberFromFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberFromFolderMutation, { data, loading, error }] = useRemoveMemberFromFolderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveMemberFromFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMemberFromFolderMutation,
    RemoveMemberFromFolderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMemberFromFolderMutation,
    RemoveMemberFromFolderMutationVariables
  >(RemoveMemberFromFolderDocument, options);
}
export type RemoveMemberFromFolderMutationHookResult = ReturnType<
  typeof useRemoveMemberFromFolderMutation
>;
export type RemoveMemberFromFolderMutationResult =
  Apollo.MutationResult<RemoveMemberFromFolderMutation>;
export type RemoveMemberFromFolderMutationOptions = Apollo.BaseMutationOptions<
  RemoveMemberFromFolderMutation,
  RemoveMemberFromFolderMutationVariables
>;
export const RemoveMemberFromProjectDocument = gql`
  mutation removeMemberFromProject($data: RemoveMemberFromProjectInput!) {
    removeMemberFromProject(data: $data) {
      id
      membersUserGroups {
        userGroup {
          id
        }
      }
      membersUsers {
        user {
          id
        }
      }
    }
  }
`;
export type RemoveMemberFromProjectMutationFn = Apollo.MutationFunction<
  RemoveMemberFromProjectMutation,
  RemoveMemberFromProjectMutationVariables
>;

/**
 * __useRemoveMemberFromProjectMutation__
 *
 * To run a mutation, you first call `useRemoveMemberFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberFromProjectMutation, { data, loading, error }] = useRemoveMemberFromProjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveMemberFromProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMemberFromProjectMutation,
    RemoveMemberFromProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMemberFromProjectMutation,
    RemoveMemberFromProjectMutationVariables
  >(RemoveMemberFromProjectDocument, options);
}
export type RemoveMemberFromProjectMutationHookResult = ReturnType<
  typeof useRemoveMemberFromProjectMutation
>;
export type RemoveMemberFromProjectMutationResult =
  Apollo.MutationResult<RemoveMemberFromProjectMutation>;
export type RemoveMemberFromProjectMutationOptions = Apollo.BaseMutationOptions<
  RemoveMemberFromProjectMutation,
  RemoveMemberFromProjectMutationVariables
>;
export const RemoveProjectDocument = gql`
  mutation removeProject($id: Int!) {
    removeProject(id: $id) {
      success
    }
  }
`;
export type RemoveProjectMutationFn = Apollo.MutationFunction<
  RemoveProjectMutation,
  RemoveProjectMutationVariables
>;

/**
 * __useRemoveProjectMutation__
 *
 * To run a mutation, you first call `useRemoveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectMutation, { data, loading, error }] = useRemoveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveProjectMutation, RemoveProjectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveProjectMutation, RemoveProjectMutationVariables>(
    RemoveProjectDocument,
    options,
  );
}
export type RemoveProjectMutationHookResult = ReturnType<typeof useRemoveProjectMutation>;
export type RemoveProjectMutationResult = Apollo.MutationResult<RemoveProjectMutation>;
export type RemoveProjectMutationOptions = Apollo.BaseMutationOptions<
  RemoveProjectMutation,
  RemoveProjectMutationVariables
>;
export const RemoveThreadDocument = gql`
  mutation removeThread($id: Int!) {
    removeThread(id: $id) {
      success
    }
  }
`;
export type RemoveThreadMutationFn = Apollo.MutationFunction<
  RemoveThreadMutation,
  RemoveThreadMutationVariables
>;

/**
 * __useRemoveThreadMutation__
 *
 * To run a mutation, you first call `useRemoveThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeThreadMutation, { data, loading, error }] = useRemoveThreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveThreadMutation, RemoveThreadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveThreadMutation, RemoveThreadMutationVariables>(
    RemoveThreadDocument,
    options,
  );
}
export type RemoveThreadMutationHookResult = ReturnType<typeof useRemoveThreadMutation>;
export type RemoveThreadMutationResult = Apollo.MutationResult<RemoveThreadMutation>;
export type RemoveThreadMutationOptions = Apollo.BaseMutationOptions<
  RemoveThreadMutation,
  RemoveThreadMutationVariables
>;
export const RemoveUserGroupDocument = gql`
  mutation removeUserGroup($removeUserGroupId: Int!) {
    removeUserGroup(id: $removeUserGroupId) {
      success
    }
  }
`;
export type RemoveUserGroupMutationFn = Apollo.MutationFunction<
  RemoveUserGroupMutation,
  RemoveUserGroupMutationVariables
>;

/**
 * __useRemoveUserGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserGroupMutation, { data, loading, error }] = useRemoveUserGroupMutation({
 *   variables: {
 *      removeUserGroupId: // value for 'removeUserGroupId'
 *   },
 * });
 */
export function useRemoveUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserGroupMutation,
    RemoveUserGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveUserGroupMutation, RemoveUserGroupMutationVariables>(
    RemoveUserGroupDocument,
    options,
  );
}
export type RemoveUserGroupMutationHookResult = ReturnType<typeof useRemoveUserGroupMutation>;
export type RemoveUserGroupMutationResult = Apollo.MutationResult<RemoveUserGroupMutation>;
export type RemoveUserGroupMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserGroupMutation,
  RemoveUserGroupMutationVariables
>;
export const RemoveWorkspaceDocument = gql`
  mutation removeWorkspace($removeWorkspaceId: Int!) {
    removeWorkspace(id: $removeWorkspaceId) {
      success
    }
  }
`;
export type RemoveWorkspaceMutationFn = Apollo.MutationFunction<
  RemoveWorkspaceMutation,
  RemoveWorkspaceMutationVariables
>;

/**
 * __useRemoveWorkspaceMutation__
 *
 * To run a mutation, you first call `useRemoveWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkspaceMutation, { data, loading, error }] = useRemoveWorkspaceMutation({
 *   variables: {
 *      removeWorkspaceId: // value for 'removeWorkspaceId'
 *   },
 * });
 */
export function useRemoveWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveWorkspaceMutation,
    RemoveWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveWorkspaceMutation, RemoveWorkspaceMutationVariables>(
    RemoveWorkspaceDocument,
    options,
  );
}
export type RemoveWorkspaceMutationHookResult = ReturnType<typeof useRemoveWorkspaceMutation>;
export type RemoveWorkspaceMutationResult = Apollo.MutationResult<RemoveWorkspaceMutation>;
export type RemoveWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  RemoveWorkspaceMutation,
  RemoveWorkspaceMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($data: ResetPasswordUser!) {
    resetPassword(data: $data) {
      success
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options,
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ResetPasswordByTokenDocument = gql`
  mutation resetPasswordByToken($token: String!, $newPassword: String!) {
    resetPasswordByToken(token: $token, newPassword: $newPassword) {
      success
    }
  }
`;
export type ResetPasswordByTokenMutationFn = Apollo.MutationFunction<
  ResetPasswordByTokenMutation,
  ResetPasswordByTokenMutationVariables
>;

/**
 * __useResetPasswordByTokenMutation__
 *
 * To run a mutation, you first call `useResetPasswordByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordByTokenMutation, { data, loading, error }] = useResetPasswordByTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordByTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordByTokenMutation,
    ResetPasswordByTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordByTokenMutation, ResetPasswordByTokenMutationVariables>(
    ResetPasswordByTokenDocument,
    options,
  );
}
export type ResetPasswordByTokenMutationHookResult = ReturnType<
  typeof useResetPasswordByTokenMutation
>;
export type ResetPasswordByTokenMutationResult =
  Apollo.MutationResult<ResetPasswordByTokenMutation>;
export type ResetPasswordByTokenMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordByTokenMutation,
  ResetPasswordByTokenMutationVariables
>;
export const ResetWorkspaceInviteLinkDocument = gql`
  mutation ResetWorkspaceInviteLink($workspaceId: Int!) {
    resetWorkspaceInviteLink(workspaceId: $workspaceId)
  }
`;
export type ResetWorkspaceInviteLinkMutationFn = Apollo.MutationFunction<
  ResetWorkspaceInviteLinkMutation,
  ResetWorkspaceInviteLinkMutationVariables
>;

/**
 * __useResetWorkspaceInviteLinkMutation__
 *
 * To run a mutation, you first call `useResetWorkspaceInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetWorkspaceInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetWorkspaceInviteLinkMutation, { data, loading, error }] = useResetWorkspaceInviteLinkMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useResetWorkspaceInviteLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetWorkspaceInviteLinkMutation,
    ResetWorkspaceInviteLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetWorkspaceInviteLinkMutation,
    ResetWorkspaceInviteLinkMutationVariables
  >(ResetWorkspaceInviteLinkDocument, options);
}
export type ResetWorkspaceInviteLinkMutationHookResult = ReturnType<
  typeof useResetWorkspaceInviteLinkMutation
>;
export type ResetWorkspaceInviteLinkMutationResult =
  Apollo.MutationResult<ResetWorkspaceInviteLinkMutation>;
export type ResetWorkspaceInviteLinkMutationOptions = Apollo.BaseMutationOptions<
  ResetWorkspaceInviteLinkMutation,
  ResetWorkspaceInviteLinkMutationVariables
>;
export const SendConfirmationEmailDocument = gql`
  mutation sendConfirmationEmail($email: String!) {
    sendConfirmationEmail(email: $email) {
      success
    }
  }
`;
export type SendConfirmationEmailMutationFn = Apollo.MutationFunction<
  SendConfirmationEmailMutation,
  SendConfirmationEmailMutationVariables
>;

/**
 * __useSendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useSendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfirmationEmailMutation, { data, loading, error }] = useSendConfirmationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendConfirmationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendConfirmationEmailMutation,
    SendConfirmationEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendConfirmationEmailMutation, SendConfirmationEmailMutationVariables>(
    SendConfirmationEmailDocument,
    options,
  );
}
export type SendConfirmationEmailMutationHookResult = ReturnType<
  typeof useSendConfirmationEmailMutation
>;
export type SendConfirmationEmailMutationResult =
  Apollo.MutationResult<SendConfirmationEmailMutation>;
export type SendConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<
  SendConfirmationEmailMutation,
  SendConfirmationEmailMutationVariables
>;
export const SendPasswordResetDocument = gql`
  mutation sendPasswordReset($email: String!) {
    sendPasswordReset(email: $email) {
      success
    }
  }
`;
export type SendPasswordResetMutationFn = Apollo.MutationFunction<
  SendPasswordResetMutation,
  SendPasswordResetMutationVariables
>;

/**
 * __useSendPasswordResetMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetMutation, { data, loading, error }] = useSendPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPasswordResetMutation,
    SendPasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendPasswordResetMutation, SendPasswordResetMutationVariables>(
    SendPasswordResetDocument,
    options,
  );
}
export type SendPasswordResetMutationHookResult = ReturnType<typeof useSendPasswordResetMutation>;
export type SendPasswordResetMutationResult = Apollo.MutationResult<SendPasswordResetMutation>;
export type SendPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  SendPasswordResetMutation,
  SendPasswordResetMutationVariables
>;
export const SortFoldersDocument = gql`
  mutation sortFolders($data: SortFoldersInput!) {
    sortFolders(data: $data) {
      success
    }
  }
`;
export type SortFoldersMutationFn = Apollo.MutationFunction<
  SortFoldersMutation,
  SortFoldersMutationVariables
>;

/**
 * __useSortFoldersMutation__
 *
 * To run a mutation, you first call `useSortFoldersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortFoldersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortFoldersMutation, { data, loading, error }] = useSortFoldersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSortFoldersMutation(
  baseOptions?: Apollo.MutationHookOptions<SortFoldersMutation, SortFoldersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SortFoldersMutation, SortFoldersMutationVariables>(
    SortFoldersDocument,
    options,
  );
}
export type SortFoldersMutationHookResult = ReturnType<typeof useSortFoldersMutation>;
export type SortFoldersMutationResult = Apollo.MutationResult<SortFoldersMutation>;
export type SortFoldersMutationOptions = Apollo.BaseMutationOptions<
  SortFoldersMutation,
  SortFoldersMutationVariables
>;
export const SortGoalThreadsDocument = gql`
  mutation sortGoalThreads($data: SortThreadInGoalsInput!) {
    sortGoalThreads(data: $data) {
      success
    }
  }
`;
export type SortGoalThreadsMutationFn = Apollo.MutationFunction<
  SortGoalThreadsMutation,
  SortGoalThreadsMutationVariables
>;

/**
 * __useSortGoalThreadsMutation__
 *
 * To run a mutation, you first call `useSortGoalThreadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortGoalThreadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortGoalThreadsMutation, { data, loading, error }] = useSortGoalThreadsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSortGoalThreadsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SortGoalThreadsMutation,
    SortGoalThreadsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SortGoalThreadsMutation, SortGoalThreadsMutationVariables>(
    SortGoalThreadsDocument,
    options,
  );
}
export type SortGoalThreadsMutationHookResult = ReturnType<typeof useSortGoalThreadsMutation>;
export type SortGoalThreadsMutationResult = Apollo.MutationResult<SortGoalThreadsMutation>;
export type SortGoalThreadsMutationOptions = Apollo.BaseMutationOptions<
  SortGoalThreadsMutation,
  SortGoalThreadsMutationVariables
>;
export const SortThreadsDocument = gql`
  mutation sortThreads($data: SortMyThreadsInput!) {
    sortThreads(data: $data) {
      success
    }
  }
`;
export type SortThreadsMutationFn = Apollo.MutationFunction<
  SortThreadsMutation,
  SortThreadsMutationVariables
>;

/**
 * __useSortThreadsMutation__
 *
 * To run a mutation, you first call `useSortThreadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortThreadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortThreadsMutation, { data, loading, error }] = useSortThreadsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSortThreadsMutation(
  baseOptions?: Apollo.MutationHookOptions<SortThreadsMutation, SortThreadsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SortThreadsMutation, SortThreadsMutationVariables>(
    SortThreadsDocument,
    options,
  );
}
export type SortThreadsMutationHookResult = ReturnType<typeof useSortThreadsMutation>;
export type SortThreadsMutationResult = Apollo.MutationResult<SortThreadsMutation>;
export type SortThreadsMutationOptions = Apollo.BaseMutationOptions<
  SortThreadsMutation,
  SortThreadsMutationVariables
>;
export const SubscribeToThreadDocument = gql`
  mutation subscribeToThread($threadId: Int!) {
    subscribeToThread(threadId: $threadId) {
      success
    }
  }
`;
export type SubscribeToThreadMutationFn = Apollo.MutationFunction<
  SubscribeToThreadMutation,
  SubscribeToThreadMutationVariables
>;

/**
 * __useSubscribeToThreadMutation__
 *
 * To run a mutation, you first call `useSubscribeToThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToThreadMutation, { data, loading, error }] = useSubscribeToThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useSubscribeToThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeToThreadMutation,
    SubscribeToThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubscribeToThreadMutation, SubscribeToThreadMutationVariables>(
    SubscribeToThreadDocument,
    options,
  );
}
export type SubscribeToThreadMutationHookResult = ReturnType<typeof useSubscribeToThreadMutation>;
export type SubscribeToThreadMutationResult = Apollo.MutationResult<SubscribeToThreadMutation>;
export type SubscribeToThreadMutationOptions = Apollo.BaseMutationOptions<
  SubscribeToThreadMutation,
  SubscribeToThreadMutationVariables
>;
export const UnsubscribeFromThreadDocument = gql`
  mutation unsubscribeFromThread($threadId: Int!) {
    unsubscribeFromThread(threadId: $threadId) {
      success
    }
  }
`;
export type UnsubscribeFromThreadMutationFn = Apollo.MutationFunction<
  UnsubscribeFromThreadMutation,
  UnsubscribeFromThreadMutationVariables
>;

/**
 * __useUnsubscribeFromThreadMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromThreadMutation, { data, loading, error }] = useUnsubscribeFromThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useUnsubscribeFromThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeFromThreadMutation,
    UnsubscribeFromThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnsubscribeFromThreadMutation, UnsubscribeFromThreadMutationVariables>(
    UnsubscribeFromThreadDocument,
    options,
  );
}
export type UnsubscribeFromThreadMutationHookResult = ReturnType<
  typeof useUnsubscribeFromThreadMutation
>;
export type UnsubscribeFromThreadMutationResult =
  Apollo.MutationResult<UnsubscribeFromThreadMutation>;
export type UnsubscribeFromThreadMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeFromThreadMutation,
  UnsubscribeFromThreadMutationVariables
>;
export const UpdateChecklistItemDocument = gql`
  mutation updateChecklistItem($data: UpdateChecklistItemInput!) {
    updateChecklistItem(data: $data) {
      id
    }
  }
`;
export type UpdateChecklistItemMutationFn = Apollo.MutationFunction<
  UpdateChecklistItemMutation,
  UpdateChecklistItemMutationVariables
>;

/**
 * __useUpdateChecklistItemMutation__
 *
 * To run a mutation, you first call `useUpdateChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChecklistItemMutation, { data, loading, error }] = useUpdateChecklistItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChecklistItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChecklistItemMutation,
    UpdateChecklistItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateChecklistItemMutation, UpdateChecklistItemMutationVariables>(
    UpdateChecklistItemDocument,
    options,
  );
}
export type UpdateChecklistItemMutationHookResult = ReturnType<
  typeof useUpdateChecklistItemMutation
>;
export type UpdateChecklistItemMutationResult = Apollo.MutationResult<UpdateChecklistItemMutation>;
export type UpdateChecklistItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateChecklistItemMutation,
  UpdateChecklistItemMutationVariables
>;
export const UpdateFileDocument = gql`
  mutation updateFile($id: Int!, $data: UpdateFileInput!) {
    updateFile(id: $id, data: $data) {
      id
    }
  }
`;
export type UpdateFileMutationFn = Apollo.MutationFunction<
  UpdateFileMutation,
  UpdateFileMutationVariables
>;

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdateFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFileMutation, UpdateFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFileMutation, UpdateFileMutationVariables>(
    UpdateFileDocument,
    options,
  );
}
export type UpdateFileMutationHookResult = ReturnType<typeof useUpdateFileMutation>;
export type UpdateFileMutationResult = Apollo.MutationResult<UpdateFileMutation>;
export type UpdateFileMutationOptions = Apollo.BaseMutationOptions<
  UpdateFileMutation,
  UpdateFileMutationVariables
>;
export const UpdateFolderDocument = gql`
  mutation updateFolder($updateFolderInput: UpdateFolderInput!) {
    updateFolder(updateFolderInput: $updateFolderInput) {
      id
      title
      authorId
      project {
        id
        title
      }
      visibilityStatus
      membersUsers {
        user {
          id
          email
          firstName
          lastName
          avatar {
            path
          }
        }
      }
      membersUserGroups {
        userGroup {
          avatar {
            path
            id
          }
          id
          title
          workspaceId
          users {
            id
            email
            firstName
            lastName
            avatar {
              path
            }
          }
        }
      }
      threadsStatistics {
        completedThreadsCount
        completedThreadsPercent
        threadsCount
      }
    }
  }
`;
export type UpdateFolderMutationFn = Apollo.MutationFunction<
  UpdateFolderMutation,
  UpdateFolderMutationVariables
>;

/**
 * __useUpdateFolderMutation__
 *
 * To run a mutation, you first call `useUpdateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderMutation, { data, loading, error }] = useUpdateFolderMutation({
 *   variables: {
 *      updateFolderInput: // value for 'updateFolderInput'
 *   },
 * });
 */
export function useUpdateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFolderMutation, UpdateFolderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFolderMutation, UpdateFolderMutationVariables>(
    UpdateFolderDocument,
    options,
  );
}
export type UpdateFolderMutationHookResult = ReturnType<typeof useUpdateFolderMutation>;
export type UpdateFolderMutationResult = Apollo.MutationResult<UpdateFolderMutation>;
export type UpdateFolderMutationOptions = Apollo.BaseMutationOptions<
  UpdateFolderMutation,
  UpdateFolderMutationVariables
>;
export const UpdateGoalDocument = gql`
  mutation updateGoal($updateGoalInput: UpdateGoalInput!) {
    updateGoal(updateGoalInput: $updateGoalInput) {
      id
      title
    }
  }
`;
export type UpdateGoalMutationFn = Apollo.MutationFunction<
  UpdateGoalMutation,
  UpdateGoalMutationVariables
>;

/**
 * __useUpdateGoalMutation__
 *
 * To run a mutation, you first call `useUpdateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalMutation, { data, loading, error }] = useUpdateGoalMutation({
 *   variables: {
 *      updateGoalInput: // value for 'updateGoalInput'
 *   },
 * });
 */
export function useUpdateGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateGoalMutation, UpdateGoalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGoalMutation, UpdateGoalMutationVariables>(
    UpdateGoalDocument,
    options,
  );
}
export type UpdateGoalMutationHookResult = ReturnType<typeof useUpdateGoalMutation>;
export type UpdateGoalMutationResult = Apollo.MutationResult<UpdateGoalMutation>;
export type UpdateGoalMutationOptions = Apollo.BaseMutationOptions<
  UpdateGoalMutation,
  UpdateGoalMutationVariables
>;
export const UpdateMemberDocument = gql`
  mutation updateMember($data: UpdateMemberInput!) {
    updateMember(data: $data) {
      userId
    }
  }
`;
export type UpdateMemberMutationFn = Apollo.MutationFunction<
  UpdateMemberMutation,
  UpdateMemberMutationVariables
>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(
    UpdateMemberDocument,
    options,
  );
}
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberMutation,
  UpdateMemberMutationVariables
>;
export const UpdateMessageDocument = gql`
  mutation updateMessage($id: Int!, $data: UpdateMessageInput!) {
    updateMessage(id: $id, data: $data) {
      id
      content
    }
  }
`;
export type UpdateMessageMutationFn = Apollo.MutationFunction<
  UpdateMessageMutation,
  UpdateMessageMutationVariables
>;

/**
 * __useUpdateMessageMutation__
 *
 * To run a mutation, you first call `useUpdateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageMutation, { data, loading, error }] = useUpdateMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMessageMutation, UpdateMessageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMessageMutation, UpdateMessageMutationVariables>(
    UpdateMessageDocument,
    options,
  );
}
export type UpdateMessageMutationHookResult = ReturnType<typeof useUpdateMessageMutation>;
export type UpdateMessageMutationResult = Apollo.MutationResult<UpdateMessageMutation>;
export type UpdateMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessageMutation,
  UpdateMessageMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation updateProject($updateProjectInput: UpdateProjectInput!) {
    updateProject(updateProjectInput: $updateProjectInput) {
      id
      title
      authorId
      folders {
        id
        title
        goals {
          id
          title
          threads {
            id
            title
          }
        }
      }
      visibilityStatus
      membersUserGroups {
        userGroup {
          avatar {
            path
            id
          }
          id
          title
          workspaceId
          users {
            id
            email
            firstName
            lastName
            avatar {
              path
            }
          }
        }
      }
      membersUsers {
        user {
          id
          email
          firstName
          lastName
          avatar {
            path
          }
        }
      }
    }
  }
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      updateProjectInput: // value for 'updateProjectInput'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    UpdateProjectDocument,
    options,
  );
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const UpdateThreadDocument = gql`
  mutation updateThread($data: UpdateThread!) {
    updateThread(data: $data) {
      id
    }
  }
`;
export type UpdateThreadMutationFn = Apollo.MutationFunction<
  UpdateThreadMutation,
  UpdateThreadMutationVariables
>;

/**
 * __useUpdateThreadMutation__
 *
 * To run a mutation, you first call `useUpdateThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThreadMutation, { data, loading, error }] = useUpdateThreadMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateThreadMutation, UpdateThreadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateThreadMutation, UpdateThreadMutationVariables>(
    UpdateThreadDocument,
    options,
  );
}
export type UpdateThreadMutationHookResult = ReturnType<typeof useUpdateThreadMutation>;
export type UpdateThreadMutationResult = Apollo.MutationResult<UpdateThreadMutation>;
export type UpdateThreadMutationOptions = Apollo.BaseMutationOptions<
  UpdateThreadMutation,
  UpdateThreadMutationVariables
>;
export const UpdateUserGroupDocument = gql`
  mutation updateUserGroup($updateUserGroupInput: UpdateUserGroupInput!) {
    updateUserGroup(updateUserGroupInput: $updateUserGroupInput) {
      id
    }
  }
`;
export type UpdateUserGroupMutationFn = Apollo.MutationFunction<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;

/**
 * __useUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMutation, { data, loading, error }] = useUpdateUserGroupMutation({
 *   variables: {
 *      updateUserGroupInput: // value for 'updateUserGroupInput'
 *   },
 * });
 */
export function useUpdateUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(
    UpdateUserGroupDocument,
    options,
  );
}
export type UpdateUserGroupMutationHookResult = ReturnType<typeof useUpdateUserGroupMutation>;
export type UpdateUserGroupMutationResult = Apollo.MutationResult<UpdateUserGroupMutation>;
export type UpdateUserGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;
export const UpdateWorkspaceDocument = gql`
  mutation updateWorkspace($updateWorkspaceInput: UpdateWorkspaceInput!, $avatar: Upload) {
    updateWorkspace(updateWorkspaceInput: $updateWorkspaceInput, avatar: $avatar) {
      id
      title
    }
  }
`;
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables
>;

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      updateWorkspaceInput: // value for 'updateWorkspaceInput'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceMutation,
    UpdateWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>(
    UpdateWorkspaceDocument,
    options,
  );
}
export type UpdateWorkspaceMutationHookResult = ReturnType<typeof useUpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationResult = Apollo.MutationResult<UpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables
>;
export const UploadFileDocument = gql`
  mutation uploadFile($file: Upload!, $data: UploadFileInput!) {
    uploadFile(file: $file, data: $data) {
      id
      path
    }
  }
`;
export type UploadFileMutationFn = Apollo.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UploadFileDocument,
    options,
  );
}
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const UploadFilesDocument = gql`
  mutation uploadFiles($files: [Upload!]!, $data: UploadFileInput) {
    uploadFiles(files: $files, data: $data) {
      id
      path
      fileName
      size
      mimeType
    }
  }
`;
export type UploadFilesMutationFn = Apollo.MutationFunction<
  UploadFilesMutation,
  UploadFilesMutationVariables
>;

/**
 * __useUploadFilesMutation__
 *
 * To run a mutation, you first call `useUploadFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFilesMutation, { data, loading, error }] = useUploadFilesMutation({
 *   variables: {
 *      files: // value for 'files'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUploadFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadFilesMutation, UploadFilesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFilesMutation, UploadFilesMutationVariables>(
    UploadFilesDocument,
    options,
  );
}
export type UploadFilesMutationHookResult = ReturnType<typeof useUploadFilesMutation>;
export type UploadFilesMutationResult = Apollo.MutationResult<UploadFilesMutation>;
export type UploadFilesMutationOptions = Apollo.BaseMutationOptions<
  UploadFilesMutation,
  UploadFilesMutationVariables
>;
export const CreateWorkspaceDocument = gql`
  mutation createWorkspace($createWorkspaceInput: CreateWorkspaceInput!) {
    createWorkspace(createWorkspaceInput: $createWorkspaceInput) {
      id
    }
  }
`;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      createWorkspaceInput: // value for 'createWorkspaceInput'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkspaceMutation,
    CreateWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(
    CreateWorkspaceDocument,
    options,
  );
}
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;
export const ChatDocument = gql`
  query chat($options: FindAllChatsInput!, $data: FindAllMessagesOptionsInput) {
    chat(options: $options) {
      id
      messagesWithCount(data: $data) {
        count
        rows {
          id
          author {
            id
            firstName
            lastName
            avatar {
              path
            }
          }
          content
        }
      }
    }
  }
`;

/**
 * __useChatQuery__
 *
 * To run a query within a React component, call `useChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatQuery({
 *   variables: {
 *      options: // value for 'options'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChatQuery(baseOptions: Apollo.QueryHookOptions<ChatQuery, ChatQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatQuery, ChatQueryVariables>(ChatDocument, options);
}
export function useChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatQuery, ChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatQuery, ChatQueryVariables>(ChatDocument, options);
}
export type ChatQueryHookResult = ReturnType<typeof useChatQuery>;
export type ChatLazyQueryHookResult = ReturnType<typeof useChatLazyQuery>;
export type ChatQueryResult = Apollo.QueryResult<ChatQuery, ChatQueryVariables>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const FilesDocument = gql`
  query files($data: FindFilesInput!) {
    files(data: $data) {
      fileName
      id
      mimeType
      path
      size
      threadId
      messageId
    }
  }
`;

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFilesQuery(
  baseOptions: Apollo.QueryHookOptions<FilesQuery, FilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
}
export function useFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilesQuery, FilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
}
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>;
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>;
export type FilesQueryResult = Apollo.QueryResult<FilesQuery, FilesQueryVariables>;
export const FolderDocument = gql`
  query folder($id: Int!, $dataThread: FindThreadsOptions) {
    folder(id: $id) {
      id
      title
      authorId
      project {
        id
        title
      }
      visibilityStatus
      membersUsers {
        user {
          id
          email
          firstName
          lastName
          avatar {
            path
          }
        }
      }
      membersUserGroups {
        userGroup {
          avatar {
            path
            id
          }
          id
          title
          workspaceId
          users {
            id
            email
            firstName
            lastName
            avatar {
              path
            }
          }
        }
      }
      threadsStatistics {
        completedThreadsCount
        completedThreadsPercent
        threadsCount
      }
      goals {
        id
        title
        threads(data: $dataThread) {
          id
          title
          description
          startDate
          endDate
          statusId
          order
          path {
            folder {
              id
              title
            }
            project {
              id
              title
            }
            goal {
              id
              title
            }
          }
          responsible {
            id
            email
            firstName
            lastName
            avatar {
              path
            }
          }
          responsibleUserGroup {
            id
            title
            avatar {
              path
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFolderQuery__
 *
 * To run a query within a React component, call `useFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dataThread: // value for 'dataThread'
 *   },
 * });
 */
export function useFolderQuery(
  baseOptions: Apollo.QueryHookOptions<FolderQuery, FolderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options);
}
export function useFolderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FolderQuery, FolderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options);
}
export type FolderQueryHookResult = ReturnType<typeof useFolderQuery>;
export type FolderLazyQueryHookResult = ReturnType<typeof useFolderLazyQuery>;
export type FolderQueryResult = Apollo.QueryResult<FolderQuery, FolderQueryVariables>;
export const IsValidPasswordResetTokenDocument = gql`
  query isValidPasswordResetToken($token: String!) {
    isValidPasswordResetToken(token: $token)
  }
`;

/**
 * __useIsValidPasswordResetTokenQuery__
 *
 * To run a query within a React component, call `useIsValidPasswordResetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsValidPasswordResetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsValidPasswordResetTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useIsValidPasswordResetTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsValidPasswordResetTokenQuery,
    IsValidPasswordResetTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsValidPasswordResetTokenQuery, IsValidPasswordResetTokenQueryVariables>(
    IsValidPasswordResetTokenDocument,
    options,
  );
}
export function useIsValidPasswordResetTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsValidPasswordResetTokenQuery,
    IsValidPasswordResetTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsValidPasswordResetTokenQuery,
    IsValidPasswordResetTokenQueryVariables
  >(IsValidPasswordResetTokenDocument, options);
}
export type IsValidPasswordResetTokenQueryHookResult = ReturnType<
  typeof useIsValidPasswordResetTokenQuery
>;
export type IsValidPasswordResetTokenLazyQueryHookResult = ReturnType<
  typeof useIsValidPasswordResetTokenLazyQuery
>;
export type IsValidPasswordResetTokenQueryResult = Apollo.QueryResult<
  IsValidPasswordResetTokenQuery,
  IsValidPasswordResetTokenQueryVariables
>;
export const IsValidRegisterTokenDocument = gql`
  query isValidRegisterToken($token: String!) {
    isValidRegisterToken(token: $token) {
      success
    }
  }
`;

/**
 * __useIsValidRegisterTokenQuery__
 *
 * To run a query within a React component, call `useIsValidRegisterTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsValidRegisterTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsValidRegisterTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useIsValidRegisterTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsValidRegisterTokenQuery,
    IsValidRegisterTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsValidRegisterTokenQuery, IsValidRegisterTokenQueryVariables>(
    IsValidRegisterTokenDocument,
    options,
  );
}
export function useIsValidRegisterTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsValidRegisterTokenQuery,
    IsValidRegisterTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsValidRegisterTokenQuery, IsValidRegisterTokenQueryVariables>(
    IsValidRegisterTokenDocument,
    options,
  );
}
export type IsValidRegisterTokenQueryHookResult = ReturnType<typeof useIsValidRegisterTokenQuery>;
export type IsValidRegisterTokenLazyQueryHookResult = ReturnType<
  typeof useIsValidRegisterTokenLazyQuery
>;
export type IsValidRegisterTokenQueryResult = Apollo.QueryResult<
  IsValidRegisterTokenQuery,
  IsValidRegisterTokenQueryVariables
>;
export const LinksAndCountDocument = gql`
  query linksAndCount($data: FindLinksInput!) {
    linksAndCount(data: $data) {
      count
      rows {
        id
        title
        description
        URL
        messageId
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useLinksAndCountQuery__
 *
 * To run a query within a React component, call `useLinksAndCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksAndCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksAndCountQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLinksAndCountQuery(
  baseOptions: Apollo.QueryHookOptions<LinksAndCountQuery, LinksAndCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinksAndCountQuery, LinksAndCountQueryVariables>(
    LinksAndCountDocument,
    options,
  );
}
export function useLinksAndCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LinksAndCountQuery, LinksAndCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinksAndCountQuery, LinksAndCountQueryVariables>(
    LinksAndCountDocument,
    options,
  );
}
export type LinksAndCountQueryHookResult = ReturnType<typeof useLinksAndCountQuery>;
export type LinksAndCountLazyQueryHookResult = ReturnType<typeof useLinksAndCountLazyQuery>;
export type LinksAndCountQueryResult = Apollo.QueryResult<
  LinksAndCountQuery,
  LinksAndCountQueryVariables
>;
export const MessagesWithCountDocument = gql`
  query messagesWithCount($data: FindAllMessagesOptionsInput) {
    messagesWithCount(data: $data) {
      count
      rows {
        id
        updatedAt
        attachments {
          id
          fileName
          path
          mimeType
          size
        }
        author {
          id
          firstName
          lastName
          email
          avatar {
            path
          }
          karmaStatistics {
            karma
          }
        }
        reactions {
          count
          emojiId
          userIds
          emoji {
            id
            name
          }
          users {
            id
            firstName
            lastName
            email
            avatar {
              path
            }
          }
        }
        content
        repliedToMessage {
          id
          content
          attachments {
            id
            fileName
            path
            mimeType
            size
          }
          author {
            id
            firstName
            lastName
            email
            avatar {
              path
            }
            karmaStatistics {
              karma
            }
          }
        }
      }
    }
  }
`;

/**
 * __useMessagesWithCountQuery__
 *
 * To run a query within a React component, call `useMessagesWithCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesWithCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesWithCountQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMessagesWithCountQuery(
  baseOptions?: Apollo.QueryHookOptions<MessagesWithCountQuery, MessagesWithCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessagesWithCountQuery, MessagesWithCountQueryVariables>(
    MessagesWithCountDocument,
    options,
  );
}
export function useMessagesWithCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessagesWithCountQuery,
    MessagesWithCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessagesWithCountQuery, MessagesWithCountQueryVariables>(
    MessagesWithCountDocument,
    options,
  );
}
export type MessagesWithCountQueryHookResult = ReturnType<typeof useMessagesWithCountQuery>;
export type MessagesWithCountLazyQueryHookResult = ReturnType<typeof useMessagesWithCountLazyQuery>;
export type MessagesWithCountQueryResult = Apollo.QueryResult<
  MessagesWithCountQuery,
  MessagesWithCountQueryVariables
>;
export const ProjectDocument = gql`
  query project($id: Int!) {
    project(id: $id) {
      id
      title
      authorId
      folders {
        id
        title
        authorId
        goals {
          id
          title
          authorId
          threads {
            id
            title
          }
        }
      }
      visibilityStatus
      membersUserGroups {
        userGroup {
          avatar {
            path
            id
          }
          id
          title
          workspaceId
          users {
            id
            email
            firstName
            lastName
            avatar {
              path
            }
          }
        }
      }
      membersUsers {
        user {
          id
          email
          firstName
          lastName
          avatar {
            path
          }
        }
      }
    }
  }
`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
}
export function useProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
}
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectFoldersDocument = gql`
  query projectFolders($id: Int!) {
    project(id: $id) {
      id
      title
      authorId
      membersUserGroups {
        userGroup {
          avatar {
            path
            id
          }
          id
          title
          workspaceId
          users {
            id
            email
            firstName
            lastName
            avatar {
              path
            }
          }
        }
      }
      membersUsers {
        user {
          id
          email
          firstName
          lastName
          avatar {
            path
          }
        }
      }
      folders {
        id
        title
        authorId
        order
        responsible {
          id
          email
          firstName
          lastName
          avatar {
            path
          }
        }
        responsibleUserGroup {
          id
          title
          avatar {
            path
          }
        }
        threadsStatistics {
          completedThreadsCount
          completedThreadsPercent
          threadsCount
        }
      }
    }
  }
`;

/**
 * __useProjectFoldersQuery__
 *
 * To run a query within a React component, call `useProjectFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFoldersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectFoldersQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectFoldersQuery, ProjectFoldersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectFoldersQuery, ProjectFoldersQueryVariables>(
    ProjectFoldersDocument,
    options,
  );
}
export function useProjectFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectFoldersQuery, ProjectFoldersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectFoldersQuery, ProjectFoldersQueryVariables>(
    ProjectFoldersDocument,
    options,
  );
}
export type ProjectFoldersQueryHookResult = ReturnType<typeof useProjectFoldersQuery>;
export type ProjectFoldersLazyQueryHookResult = ReturnType<typeof useProjectFoldersLazyQuery>;
export type ProjectFoldersQueryResult = Apollo.QueryResult<
  ProjectFoldersQuery,
  ProjectFoldersQueryVariables
>;
export const ProjectsDocument = gql`
  query projects($options: FindAllOptionsEntity!) {
    projects(options: $options) {
      id
      title
      authorId
      visibilityStatus
      folders {
        id
        title
        authorId
        visibilityStatus
        goals {
          id
          title
          threads {
            id
            title
          }
        }
      }
    }
  }
`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
}
export function useProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
}
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const ThreadDocument = gql`
  query thread($id: Int!) {
    thread(id: $id) {
      id
      title
      description
      startDate
      endDate
      statusId
      order
      result
      attachments {
        id
        fileName
        path
        size
        mimeType
      }
      path {
        folder {
          id
          title
        }
        project {
          id
          title
        }
        goal {
          id
          title
        }
      }
      responsible {
        id
        email
        firstName
        lastName
        avatar {
          path
        }
      }
      responsibleUserGroup {
        id
        title
        avatar {
          path
        }
      }
      subscribedUsers {
        id
        email
        firstName
        lastName
        avatar {
          path
        }
      }
      subscribedUserGroups {
        id
        title
        avatar {
          path
        }
      }
      checklistItems {
        id
        isChecked
        threadId
        title
      }
      chat {
        id
      }
      subscriptionStatus
    }
  }
`;

/**
 * __useThreadQuery__
 *
 * To run a query within a React component, call `useThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThreadQuery(
  baseOptions: Apollo.QueryHookOptions<ThreadQuery, ThreadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThreadQuery, ThreadQueryVariables>(ThreadDocument, options);
}
export function useThreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ThreadQuery, ThreadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThreadQuery, ThreadQueryVariables>(ThreadDocument, options);
}
export type ThreadQueryHookResult = ReturnType<typeof useThreadQuery>;
export type ThreadLazyQueryHookResult = ReturnType<typeof useThreadLazyQuery>;
export type ThreadQueryResult = Apollo.QueryResult<ThreadQuery, ThreadQueryVariables>;
export const ThreadHistoryDocument = gql`
  query threadHistory($id: Int!) {
    thread(id: $id) {
      id
      history {
        logs {
          id
          title
        }
        maxCreatedAt
        user {
          id
          email
          firstName
          lastName
          avatar {
            fileName
            path
          }
          karmaStatistics {
            karma
          }
        }
      }
    }
  }
`;

/**
 * __useThreadHistoryQuery__
 *
 * To run a query within a React component, call `useThreadHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThreadHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<ThreadHistoryQuery, ThreadHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThreadHistoryQuery, ThreadHistoryQueryVariables>(
    ThreadHistoryDocument,
    options,
  );
}
export function useThreadHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ThreadHistoryQuery, ThreadHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThreadHistoryQuery, ThreadHistoryQueryVariables>(
    ThreadHistoryDocument,
    options,
  );
}
export type ThreadHistoryQueryHookResult = ReturnType<typeof useThreadHistoryQuery>;
export type ThreadHistoryLazyQueryHookResult = ReturnType<typeof useThreadHistoryLazyQuery>;
export type ThreadHistoryQueryResult = Apollo.QueryResult<
  ThreadHistoryQuery,
  ThreadHistoryQueryVariables
>;
export const ThreadsAndCountDocument = gql`
  query threadsAndCount($options: FindThreadsOptions!) {
    threadsAndCount(options: $options) {
      rows {
        id
        title
        description
        startDate
        endDate
        statusId
        order
        path {
          folder {
            id
            title
          }
          project {
            id
            title
          }
          goal {
            id
            title
          }
        }
        responsible {
          id
          email
          lastName
          firstName
          avatar {
            path
          }
        }
        responsibleUserGroup {
          id
          title
          avatar {
            path
          }
        }
      }
      count
    }
  }
`;

/**
 * __useThreadsAndCountQuery__
 *
 * To run a query within a React component, call `useThreadsAndCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadsAndCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadsAndCountQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useThreadsAndCountQuery(
  baseOptions: Apollo.QueryHookOptions<ThreadsAndCountQuery, ThreadsAndCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThreadsAndCountQuery, ThreadsAndCountQueryVariables>(
    ThreadsAndCountDocument,
    options,
  );
}
export function useThreadsAndCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ThreadsAndCountQuery, ThreadsAndCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThreadsAndCountQuery, ThreadsAndCountQueryVariables>(
    ThreadsAndCountDocument,
    options,
  );
}
export type ThreadsAndCountQueryHookResult = ReturnType<typeof useThreadsAndCountQuery>;
export type ThreadsAndCountLazyQueryHookResult = ReturnType<typeof useThreadsAndCountLazyQuery>;
export type ThreadsAndCountQueryResult = Apollo.QueryResult<
  ThreadsAndCountQuery,
  ThreadsAndCountQueryVariables
>;
export const UserGroupDocument = gql`
  query userGroup($userGroupId: Int!) {
    userGroup(id: $userGroupId) {
      avatar {
        path
        id
      }
      id
      users {
        id
        firstName
        lastName
        avatar {
          path
        }
        workspaces {
          myRole
        }
      }
      title
    }
  }
`;

/**
 * __useUserGroupQuery__
 *
 * To run a query within a React component, call `useUserGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupQuery({
 *   variables: {
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useUserGroupQuery(
  baseOptions: Apollo.QueryHookOptions<UserGroupQuery, UserGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserGroupQuery, UserGroupQueryVariables>(UserGroupDocument, options);
}
export function useUserGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserGroupQuery, UserGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserGroupQuery, UserGroupQueryVariables>(UserGroupDocument, options);
}
export type UserGroupQueryHookResult = ReturnType<typeof useUserGroupQuery>;
export type UserGroupLazyQueryHookResult = ReturnType<typeof useUserGroupLazyQuery>;
export type UserGroupQueryResult = Apollo.QueryResult<UserGroupQuery, UserGroupQueryVariables>;
export const UserGroupsDocument = gql`
  query userGroups($data: UserGroupsFindDataInput!) {
    userGroups(data: $data) {
      avatar {
        path
        id
      }
      id
      title
      workspaceId
      users {
        id
        email
        firstName
        lastName
        avatar {
          path
        }
      }
    }
  }
`;

/**
 * __useUserGroupsQuery__
 *
 * To run a query within a React component, call `useUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserGroupsQuery, UserGroupsQueryVariables>(UserGroupsDocument, options);
}
export function useUserGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserGroupsQuery, UserGroupsQueryVariables>(
    UserGroupsDocument,
    options,
  );
}
export type UserGroupsQueryHookResult = ReturnType<typeof useUserGroupsQuery>;
export type UserGroupsLazyQueryHookResult = ReturnType<typeof useUserGroupsLazyQuery>;
export type UserGroupsQueryResult = Apollo.QueryResult<UserGroupsQuery, UserGroupsQueryVariables>;
export const UsersDocument = gql`
  query users($options: FindUsersOptionsInput!) {
    users(options: $options) {
      id
      email
      firstName
      lastName
      avatar {
        path
      }
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const WorkspaceDocument = gql`
  query workspace($workspaceId: Int!) {
    workspace(id: $workspaceId) {
      __typename
      avatar {
        path
        id
      }
      members {
        role
        user {
          email
          firstName
          lastName
          id
          avatar {
            path
            id
          }
        }
      }
      isInviteLinkAccess
      invites {
        email
        id
      }
      inviteLink
      id
      myRole
      title
    }
  }
`;

/**
 * __useWorkspaceQuery__
 *
 * To run a query within a React component, call `useWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<WorkspaceQuery, WorkspaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkspaceQuery, WorkspaceQueryVariables>(WorkspaceDocument, options);
}
export function useWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceQuery, WorkspaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkspaceQuery, WorkspaceQueryVariables>(WorkspaceDocument, options);
}
export type WorkspaceQueryHookResult = ReturnType<typeof useWorkspaceQuery>;
export type WorkspaceLazyQueryHookResult = ReturnType<typeof useWorkspaceLazyQuery>;
export type WorkspaceQueryResult = Apollo.QueryResult<WorkspaceQuery, WorkspaceQueryVariables>;
export const WorkspaceAndUserDataByAccessTokenDocument = gql`
  query workspaceAndUserDataByAccessToken($workspaceAccessToken: String!) {
    workspaceAndUserDataByAccessToken(workspaceAccessToken: $workspaceAccessToken) {
      email
      isRegister
      workspaceTitle
      isInvitedUserAccessToken
    }
  }
`;

/**
 * __useWorkspaceAndUserDataByAccessTokenQuery__
 *
 * To run a query within a React component, call `useWorkspaceAndUserDataByAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceAndUserDataByAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceAndUserDataByAccessTokenQuery({
 *   variables: {
 *      workspaceAccessToken: // value for 'workspaceAccessToken'
 *   },
 * });
 */
export function useWorkspaceAndUserDataByAccessTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceAndUserDataByAccessTokenQuery,
    WorkspaceAndUserDataByAccessTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceAndUserDataByAccessTokenQuery,
    WorkspaceAndUserDataByAccessTokenQueryVariables
  >(WorkspaceAndUserDataByAccessTokenDocument, options);
}
export function useWorkspaceAndUserDataByAccessTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceAndUserDataByAccessTokenQuery,
    WorkspaceAndUserDataByAccessTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceAndUserDataByAccessTokenQuery,
    WorkspaceAndUserDataByAccessTokenQueryVariables
  >(WorkspaceAndUserDataByAccessTokenDocument, options);
}
export type WorkspaceAndUserDataByAccessTokenQueryHookResult = ReturnType<
  typeof useWorkspaceAndUserDataByAccessTokenQuery
>;
export type WorkspaceAndUserDataByAccessTokenLazyQueryHookResult = ReturnType<
  typeof useWorkspaceAndUserDataByAccessTokenLazyQuery
>;
export type WorkspaceAndUserDataByAccessTokenQueryResult = Apollo.QueryResult<
  WorkspaceAndUserDataByAccessTokenQuery,
  WorkspaceAndUserDataByAccessTokenQueryVariables
>;
export const WorkspacesDocument = gql`
  query workspaces {
    workspaces {
      __typename
      avatar {
        path
        id
      }
      members {
        role
        user {
          email
          firstName
          lastName
          id
          avatar {
            path
            id
          }
        }
      }
      isInviteLinkAccess
      invites {
        email
        id
      }
      inviteLink
      id
      myRole
      title
    }
  }
`;

/**
 * __useWorkspacesQuery__
 *
 * To run a query within a React component, call `useWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspacesQuery(
  baseOptions?: Apollo.QueryHookOptions<WorkspacesQuery, WorkspacesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkspacesQuery, WorkspacesQueryVariables>(WorkspacesDocument, options);
}
export function useWorkspacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkspacesQuery, WorkspacesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkspacesQuery, WorkspacesQueryVariables>(
    WorkspacesDocument,
    options,
  );
}
export type WorkspacesQueryHookResult = ReturnType<typeof useWorkspacesQuery>;
export type WorkspacesLazyQueryHookResult = ReturnType<typeof useWorkspacesLazyQuery>;
export type WorkspacesQueryResult = Apollo.QueryResult<WorkspacesQuery, WorkspacesQueryVariables>;
export const NewChatActivityDocument = gql`
  subscription newChatActivity($chatId: Int!) {
    newChatActivity(chatId: $chatId) {
      type
      message {
        id
        updatedAt
        attachments {
          id
          fileName
          path
          mimeType
          size
        }
        author {
          id
          firstName
          lastName
          email
          avatar {
            path
          }
          karmaStatistics {
            karma
          }
        }
        reactions {
          count
          emojiId
          userIds
          emoji {
            id
            name
          }
          users {
            id
            firstName
            lastName
            email
            avatar {
              path
            }
          }
        }
        content
        repliedToMessage {
          id
          content
          attachments {
            id
            fileName
            path
            mimeType
            size
          }
          author {
            id
            firstName
            lastName
            email
            avatar {
              path
            }
            karmaStatistics {
              karma
            }
          }
        }
      }
    }
  }
`;

/**
 * __useNewChatActivitySubscription__
 *
 * To run a query within a React component, call `useNewChatActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewChatActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewChatActivitySubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useNewChatActivitySubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewChatActivitySubscription,
    NewChatActivitySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<NewChatActivitySubscription, NewChatActivitySubscriptionVariables>(
    NewChatActivityDocument,
    options,
  );
}
export type NewChatActivitySubscriptionHookResult = ReturnType<
  typeof useNewChatActivitySubscription
>;
export type NewChatActivitySubscriptionResult =
  Apollo.SubscriptionResult<NewChatActivitySubscription>;
export const NewChatAttachedActivityDocument = gql`
  subscription NewChatAttachedActivity($chatId: Int!) {
    newChatAttachedActivity(chatId: $chatId) {
      images {
        fileName
        id
        mimeType
        path
        size
        threadId
        messageId
      }
      files {
        fileName
        id
        mimeType
        path
        size
        threadId
        messageId
      }
      links {
        id
        title
        description
        URL
        messageId
        createdAt
        updatedAt
      }
      type
    }
  }
`;

/**
 * __useNewChatAttachedActivitySubscription__
 *
 * To run a query within a React component, call `useNewChatAttachedActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewChatAttachedActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewChatAttachedActivitySubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useNewChatAttachedActivitySubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewChatAttachedActivitySubscription,
    NewChatAttachedActivitySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewChatAttachedActivitySubscription,
    NewChatAttachedActivitySubscriptionVariables
  >(NewChatAttachedActivityDocument, options);
}
export type NewChatAttachedActivitySubscriptionHookResult = ReturnType<
  typeof useNewChatAttachedActivitySubscription
>;
export type NewChatAttachedActivitySubscriptionResult =
  Apollo.SubscriptionResult<NewChatAttachedActivitySubscription>;
export const NewThreadActivityDocument = gql`
  subscription NewThreadActivity($threadId: Int!) {
    newThreadActivity(threadId: $threadId) {
      type
      history {
        logs {
          id
          title
        }
        maxCreatedAt
        user {
          id
          email
          firstName
          lastName
          avatar {
            fileName
            path
          }
          karmaStatistics {
            karma
          }
        }
      }
    }
  }
`;

/**
 * __useNewThreadActivitySubscription__
 *
 * To run a query within a React component, call `useNewThreadActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewThreadActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewThreadActivitySubscription({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useNewThreadActivitySubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewThreadActivitySubscription,
    NewThreadActivitySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewThreadActivitySubscription,
    NewThreadActivitySubscriptionVariables
  >(NewThreadActivityDocument, options);
}
export type NewThreadActivitySubscriptionHookResult = ReturnType<
  typeof useNewThreadActivitySubscription
>;
export type NewThreadActivitySubscriptionResult =
  Apollo.SubscriptionResult<NewThreadActivitySubscription>;
