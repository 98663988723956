import { styled, Stack } from '@mui/material';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';

export const StackWrapperStyle = styled(Stack)(() => ({
  width: '100%',
  borderRadius: '6px',
  height: '100%',
  justifyContent: 'center',
}));
export const StackWrapperInnerStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '6px',
  outline: `2px solid ${theme.palette.black600}`,
  padding: '10px 8px',
}));

export const StackResultStyle = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: '10px 8px',
}));

export const FlagRoundedIconStyle = styled(FlagRoundedIcon)(({ fill }: { fill: string }) => ({
  color: fill,
  marginBottom: 'auto',
  marginRight: '6px',
}));
