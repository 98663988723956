import { gql } from '@apollo/client';

export const FILES = gql`
  query files($data: FindFilesInput!) {
    files(data: $data) {
      fileName
      id
      mimeType
      path
      size
      threadId
      messageId
    }
  }
`;
