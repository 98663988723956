import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useThreadQuery, useUpdateThreadMutation } from '../../graphql/generated/graphql';
import {
  EditThreadTitle,
  EditDescription,
  EditThreadStatus,
  EditThreadResponsible,
  EditSubscribes,
  EditDeadline,
  EditProject,
  EditResult,
  EditCheckbox,
  EditFiles,
  Chat,
  BreadcrumbsWrapper,
} from '../../components';
import { THREAD } from '../../graphql/queries/thread.gql';
import {
  StackStyleLeft,
  StackEditItemStyle,
  StackEditItemDescriptionStyle,
  StackEditableNameStyle,
  StackEditableContentStyle,
  StackBreadcrumbsStyle,
  StackEditableResultStyle,
} from './style';
import { useWorkspaceContext } from '../../context';
import { ROUTES } from '../../constants/constants';
import { useNavigateSearch } from '../../hooks';

export const Thread = () => {
  const { threadId } = useParams();

  const { data } = useThreadQuery({
    variables: {
      id: Number(threadId),
    },
    skip: !threadId,
  });

  const [updateThreadMutation] = useUpdateThreadMutation({
    refetchQueries: [THREAD],
  });

  const thread = data?.thread;

  const theme = useTheme();

  const { workspaces, workspaceId, setSelectProjectId, setSelectFolderId } = useWorkspaceContext();

  const { navigateSearchParams } = useNavigateSearch();
  const createBreadcrumbsOptions = () => {
    const workspace = workspaces?.find((e) => e.id === workspaceId);
    const project = thread?.path?.project;
    const folder = thread?.path?.folder;

    project && setSelectProjectId(project?.id);
    folder && setSelectFolderId(folder.id);

    const workspaceOption = workspace
      ? {
          text: workspace.title,
          id: workspace.id,
          onClallback: () => {
            navigateSearchParams({ path: ROUTES.home });
            setSelectProjectId(null);
          },
        }
      : null;

    const isProject = project && folder;

    const projectOption = {
      text: isProject ? project.title : 'Мои треды',
      ...(isProject ? { id: project.id } : {}),
      onClallback: () => {
        navigateSearchParams({ path: ROUTES.home });
        setSelectFolderId(null);
      },
    };

    const folderOption = folder
      ? {
          text: folder.title,
          id: folder.id,
          onClallback: () => {
            navigateSearchParams({ path: ROUTES.home });
          },
        }
      : null;

    const threadOption = {
      text: `${thread?.id}` || 'Мои треды',
      id: thread?.id,
      onClallback: () => {},
    };

    return [workspaceOption, projectOption, folderOption, threadOption].filter(
      (option): option is { text: string; id?: number; onClallback: () => void } => option !== null,
    );
  };

  const options = createBreadcrumbsOptions();

  return (
    <Box>
      <StackBreadcrumbsStyle>
        <BreadcrumbsWrapper options={options} sx={{ padding: '0px' }} />

        <EditSubscribes
          name={'subscribed'}
          threadId={thread?.id}
          subscribedUsers={thread?.subscribedUsers}
          subscribedUserGroups={thread?.subscribedUserGroups}
          updateThreadMutation={updateThreadMutation}
          subscriptionStatus={thread?.subscriptionStatus}
          folderId={thread?.path?.folder?.id}
          projectId={thread?.path?.project?.id}
        />
      </StackBreadcrumbsStyle>

      <Stack direction={'row'} sx={{ height: 'calc(100vh - 72px)' }}>
        <StackStyleLeft>
          <EditThreadTitle
            name={'title'}
            title={thread?.title}
            threadId={thread?.id}
            updateThreadMutation={updateThreadMutation}
          />
          <Stack>
            <StackEditItemStyle>
              <StackEditableNameStyle>
                <Typography
                  sx={{
                    color: theme.palette.neutralPlainDisabledColor.main,
                  }}
                >
                  Статус
                </Typography>
              </StackEditableNameStyle>
              <StackEditableContentStyle>
                <EditThreadStatus
                  name='statusId'
                  threadId={thread?.id}
                  statusId={thread?.statusId}
                  updateThreadMutation={updateThreadMutation}
                />
              </StackEditableContentStyle>
            </StackEditItemStyle>
            <StackEditItemStyle>
              <StackEditableNameStyle>
                <Typography sx={{ color: theme.palette.neutralPlainDisabledColor.main }}>
                  Ответственный
                </Typography>
              </StackEditableNameStyle>
              <StackEditableContentStyle>
                <EditThreadResponsible
                  name={'responsible'}
                  updateThreadMutation={updateThreadMutation}
                  threadId={thread?.id}
                  folderId={thread?.path?.folder?.id}
                  projectId={thread?.path?.project?.id}
                  responsible={thread?.responsible}
                  responsibleUserGroup={thread?.responsibleUserGroup}
                />
              </StackEditableContentStyle>
            </StackEditItemStyle>
            <StackEditItemStyle>
              <StackEditableNameStyle>
                <Typography sx={{ color: theme.palette.neutralPlainDisabledColor.main }}>
                  Сроки
                </Typography>
              </StackEditableNameStyle>
              <StackEditableContentStyle sx={{ padding: '0px' }}>
                <EditDeadline
                  threadId={thread?.id}
                  updateThreadMutation={updateThreadMutation}
                  startDate={thread?.startDate}
                  endDate={thread?.endDate}
                />
              </StackEditableContentStyle>
            </StackEditItemStyle>
            <StackEditItemStyle>
              <StackEditableNameStyle>
                <Typography sx={{ color: theme.palette.neutralPlainDisabledColor.main }}>
                  Проект
                </Typography>
              </StackEditableNameStyle>
              <StackEditableContentStyle sx={{ padding: '0px' }}>
                <EditProject
                  threadId={thread?.id}
                  updateThreadMutation={updateThreadMutation}
                  path={thread?.path}
                />
              </StackEditableContentStyle>
            </StackEditItemStyle>
            <StackEditItemStyle>
              <StackEditableNameStyle>
                <Typography sx={{ color: theme.palette.neutralPlainDisabledColor.main }}>
                  Вложения
                </Typography>
              </StackEditableNameStyle>
              <StackEditableContentStyle sx={{ padding: '0px' }}>
                <EditFiles threadId={thread?.id} attachments={thread?.attachments} />
              </StackEditableContentStyle>
            </StackEditItemStyle>
            <StackEditItemStyle sx={{ minHeight: '40px', height: 'auto' }}>
              <StackEditableResultStyle>
                <Typography sx={{ color: theme.palette.neutralPlainDisabledColor.main }}>
                  Результат
                </Typography>
              </StackEditableResultStyle>
              <StackEditableContentStyle sx={{ padding: '0px' }}>
                <EditResult
                  updateThreadMutation={updateThreadMutation}
                  threadId={thread?.id}
                  result={thread?.result}
                />
              </StackEditableContentStyle>
            </StackEditItemStyle>
            <StackEditItemDescriptionStyle>
              <EditDescription
                name='description'
                threadId={thread?.id}
                updateThreadMutation={updateThreadMutation}
                description={thread?.description}
              />
            </StackEditItemDescriptionStyle>
          </Stack>
          <EditCheckbox threadId={thread?.id} checklistItems={thread?.checklistItems} />
        </StackStyleLeft>
        <Stack sx={{ padding: '0px 24px 16px 24px', width: '50%' }}>
          <Chat chatId={thread?.chat?.id} threadId={thread?.id} />
        </Stack>
      </Stack>
    </Box>
  );
};
