import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { UpdateWorkspaceForm } from './components/Form';
import { TabType } from '../../../const';
import { DeleteButton } from './style';
import { WorkspaceDeleteModal } from '../../../../WorkspaceDeleteModal/WorkspaceDeleteModal';
import { UniversalModal } from '../../../../UniversalModal/UniversalModal';
import { useWorkspace } from '../../../../../../../hooks';

export const Workspace = ({ handleCloseModal, modalParameters }: TabType) => {
  const { refetch } = useWorkspace();
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleShowModal = () => {
    refetch();
    setShowModal((prevState) => !prevState);
  };
  return (
    <>
      <Stack p='24px'>
        <Typography variant='h3' mb='24px'>
          Рабочая область
        </Typography>
        <UpdateWorkspaceForm />
        <Stack maxWidth='300px' mt='48px'>
          <Typography variant='h3' mb='10px'>
            Удалить рабочую область
          </Typography>
          <Typography variant='h4' mb='24px'>
            Удаление рабочей области и всех данных, включая пользователей
          </Typography>
          <DeleteButton size='small' variant='contained' onClick={handleShowModal}>
            Удалить
          </DeleteButton>
        </Stack>
      </Stack>
      <UniversalModal open={showModal} handleCloseModal={handleShowModal} sx={{ width: '360px' }}>
        <WorkspaceDeleteModal
          modalParameters={modalParameters}
          handleCloseModal={handleShowModal}
          handleCloseRootModal={handleCloseModal}
        />
      </UniversalModal>
    </>
  );
};
