import { Box, LinearProgress, Stack } from '@mui/material';
import { LoaderWrapper } from './style';
import { AuthLogo } from '../icons';

export const Loader = () => {
  return (
    <LoaderWrapper>
      <AuthLogo />
      <Box width='448px' mt='32px'>
        <LinearProgress />
      </Box>
    </LoaderWrapper>
  );
};
