import {
  useFilesQuery,
  useLinksAndCountQuery,
  useNewChatAttachedActivitySubscription,
  NewChatAttachedActivity,
  ChatActivityEnum,
} from '../../../../graphql/generated/graphql';
import { FilterFileType } from '../../../../types/enum';
import { useApolloClient } from '@apollo/client';
import { FILES } from '../../../../graphql/queries/files.gql';
import { LINKS_AND_COUNT } from '../../../../graphql/queries/linksAndCount.gql';

type AttachmentsType = {
  chatId?: number;
  filter?: FilterFileType;
};

export const useAttachments = ({ chatId, filter }: AttachmentsType) => {
  const client = useApolloClient();

  const { data } = useFilesQuery({
    variables: {
      data: {
        chatId: chatId,
        isOnlyFiles: filter === FilterFileType.File,
        isOnlyImages: filter === FilterFileType.Image,
      },
    },
    skip: !chatId,
    fetchPolicy: 'cache-and-network',
  });

  const { data: linksData } = useLinksAndCountQuery({
    variables: {
      data: { chatId: Number(chatId) },
    },
    skip: !chatId,
    fetchPolicy: 'cache-and-network',
  });

  const links = linksData?.linksAndCount?.rows;

  const updateCache = (newData?: NewChatAttachedActivity) => {
    const isOnlyFiles = !!newData?.files?.length;
    const isOnlyImages = !!newData?.images?.length;
    const type = newData?.type;

    const isDeleteFile =
      type === ChatActivityEnum.DeletedFile || type === ChatActivityEnum.DeletedFiles;

    const isDeleteLinks = type === ChatActivityEnum.DeletedLinks;

    const currentFilesData = client.readQuery({
      query: FILES,
      variables: {
        data: {
          chatId: chatId,
          isOnlyFiles: isOnlyFiles,
          isOnlyImages: isOnlyImages,
        },
      },
    });

    const currentLinksData = client.readQuery({
      query: LINKS_AND_COUNT,
      variables: { data: { chatId } },
    });

    const files = [...(newData?.files || []), ...(newData?.images || [])];
    const links = newData?.links;
    let updatedFiles = [...(currentFilesData?.files || []), ...(currentFilesData?.images || [])];
    let updatedLinks = [...(currentLinksData?.linksAndCount?.rows || [])];

    if (isDeleteFile || isDeleteLinks) {
      if (files) {
        const deletedFileIds = files.map((file) => file.id);
        updatedFiles = updatedFiles.filter((file) => !deletedFileIds.includes(file.id));
      }
      if (links) {
        const deletedLinkIds = links.map((link) => link.id);
        updatedLinks = updatedLinks.filter((link) => !deletedLinkIds.includes(link.id));
      }
    } else {
      files.forEach((newFile) => {
        const fileIndex = updatedFiles.findIndex((file) => file.id === newFile.id);
        if (fileIndex !== -1) {
          updatedFiles[fileIndex] = newFile;
        } else {
          updatedFiles.push(newFile);
        }
      });

      links?.forEach((newLink) => {
        const linkIndex = updatedLinks.findIndex((link) => link.id === newLink.id);
        if (linkIndex !== -1) {
          updatedLinks[linkIndex] = newLink;
        } else {
          updatedLinks.push(newLink);
        }
      });
    }

    client.writeQuery({
      query: FILES,
      data: { count: currentFilesData?.count, files: updatedFiles },
      variables: {
        data: {
          chatId: chatId,
          isOnlyFiles: isOnlyFiles,
          isOnlyImages: isOnlyImages,
        },
      },
    });

    client.writeQuery({
      query: LINKS_AND_COUNT,
      data: {
        linksAndCount: { count: currentLinksData?.count, rows: updatedLinks },
      },
      variables: { data: { chatId } },
    });
  };

  useNewChatAttachedActivitySubscription({
    variables: {
      chatId: Number(chatId),
    },
    onSubscriptionData: (e) => {
      updateCache(e.subscriptionData.data?.newChatAttachedActivity as NewChatAttachedActivity);
    },
  });

  return { links, data };
};
