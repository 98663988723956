import { styled, Stack } from '@mui/material';

export const StackStyle = styled(Stack)(() => ({
  padding: '8px 4px',
  maxHeight: '100%',
  overflowY: 'auto',
}));

export const StackFileNamekStyle = styled(Stack)(() => ({
  margin: '0px 12px',
  width: '100%',
  justifyContent: 'center',
}));

export const ImageStyle = styled('img')(() => ({
  width: '48px',
  height: '48px',
  maxWidth: '48px',
  maxHeight: '48px',
}));
