import * as yup from 'yup';

import {
  INCORRECT_CONFIRM_PASSWORD,
  INCORRECT_EMAIL,
  incorrectMaXLength,
  incorrectMinLength,
  REQUIRE_MESSAGE,
} from './messages';
import { emailRegex } from '../constants/constants';

export const registrationSchemaEmail = yup.object().shape({
  email: yup
    .string()
    .email(INCORRECT_EMAIL)
    .matches(emailRegex, INCORRECT_EMAIL)
    .max(100, incorrectMaXLength(100))
    .required(REQUIRE_MESSAGE),
});
export const registrationSchemaWorkspace = yup.object().shape({
  workspaceName: yup.string().max(50, incorrectMaXLength(50)).required(REQUIRE_MESSAGE),
});
export const registrationSchemaAdditional = yup.object().shape({
  firstName: yup.string().max(50, incorrectMaXLength(50)).required(REQUIRE_MESSAGE),
  lastName: yup.string().max(50, incorrectMaXLength(50)).required(REQUIRE_MESSAGE), // исправил "lastname" на "lastName"
  password: yup
    .string()
    .required(REQUIRE_MESSAGE)
    .min(8, incorrectMinLength(8))
    .max(50, incorrectMaXLength(50)),
  confirmPassword: yup
    .string()
    .required(REQUIRE_MESSAGE)
    .min(8, incorrectMinLength(8))
    .max(50, incorrectMaXLength(50))
    .oneOf([yup.ref('password'), 'null'], INCORRECT_CONFIRM_PASSWORD),
});
