import { Stack, Typography, IconButton, useTheme } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { StackStylePopper, PopperStyle } from './style';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import { OutsideClickFlagType } from '../../../types/enum';

type PopperInnerType = { handleDownloadAllFiles: () => void; handleRemoveAllFiles: () => void };

export const PopperInner = ({ handleDownloadAllFiles, handleRemoveAllFiles }: PopperInnerType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'simple-popper' : undefined;

  const refClickOutside = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const isInsideDateCalendar = fixOutsideClick(event);

    if (
      !isInsideDateCalendar &&
      refClickOutside.current &&
      !refClickOutside.current.contains(event.target as Node)
    ) {
      setAnchorEl(null);
    }
  };

  const fixOutsideClick = (event: MouseEvent) => {
    const path = event.composedPath();
    const isInsideDateCalendar = path.some((el) => {
      return (
        el instanceof Element && el.classList.contains(OutsideClickFlagType.outsideClickFlagFile)
      );
    });
    return isInsideDateCalendar;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const theme = useTheme();

  const handleDownload = () => {
    handleDownloadAllFiles();
    setAnchorEl(null);
  };

  const handleRemove = () => {
    handleRemoveAllFiles();
    setAnchorEl(null);
  };

  return (
    <Box component={'span'} ref={refClickOutside}>
      <IconButton sx={{ padding: '4px' }} onClick={handleClick}>
        <MoreHorizIcon sx={{ color: theme.palette.black600 }} />
      </IconButton>
      <PopperStyle
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        placement='bottom-end'
        className={[
          OutsideClickFlagType.outsideClickFlagDefault,
          OutsideClickFlagType.outsideClickFlagFile,
        ].join(' ')}
      >
        <Stack
          sx={{
            width: '220px',
          }}
        >
          <StackStylePopper onClick={handleDownload}>
            <FileDownloadOutlinedIcon />
            <Typography sx={{ color: theme.palette.black600, marginLeft: '14px' }}>
              Скачать все
            </Typography>
          </StackStylePopper>

          <StackStylePopper onClick={handleRemove}>
            <DeleteOutlineOutlinedIcon />
            <Typography sx={{ color: theme.palette.black600, marginLeft: '14px' }}>
              Удалить все
            </Typography>
          </StackStylePopper>
        </Stack>
      </PopperStyle>
    </Box>
  );
};
