import { Stack, Typography, Button } from '@mui/material';
import { FormFlex, CloseButton } from '../../../index';
import { useForm, SubmitHandler, FormProvider, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalParametersTypes } from '../../../../types/types';
import { useCreateWorkspaceMutation } from '../../../../graphql/generated/graphql';
import { SizeOptions } from '../../../../types/enum';
import { useWorkspace } from '../../../../hooks';
import { RequestHandler } from '../../../RequestHandler/RequestHandler';
import { WorkspaceSchema } from '../../../../validation/workspace.schem';
import { TextFieldRHFStyle } from '../../../WorkspaceHubElements/style';
import { StackStyle } from './style';
import { WORKSPACES } from '../../../../graphql/queries/workspaces';

interface IFormData {
  title: string;
}

type ThreadModalType = {
  modalParameters: ModalParametersTypes;
  handleCloseModal: () => void;
};

export const CreateWorkspaceModal = ({ handleCloseModal }: ThreadModalType) => {
  const { handleWorkspace } = useWorkspace();
  const [createWorkspaceMutation, { loading }] = useCreateWorkspaceMutation({
    onCompleted: (data) => {
      handleWorkspace(data.createWorkspace.id);
      handleCloseModal();
    },
    refetchQueries: [WORKSPACES],
  });

  const methods = useForm<IFormData>({
    resolver: yupResolver(WorkspaceSchema) as Resolver<IFormData>,
    defaultValues: {
      title: '',
    },
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const onSubmit: SubmitHandler<IFormData> = (data) => {
    createWorkspaceMutation({
      variables: {
        createWorkspaceInput: {
          title: data.title,
        },
      },
    });
  };
  return (
    <RequestHandler loading={loading}>
      <FormProvider {...methods}>
        <FormFlex onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <StackStyle>
              <Typography variant='h3'>Создать рабочую область</Typography>
              <CloseButton onClick={handleCloseModal} />
            </StackStyle>
            <Typography variant='h4' mt='10px' mb='24px'>
              Рабочие области - это общие среды, в которых команды могут работать над проектами,
              целями и тредами.
            </Typography>
            <Stack flexDirection='row'>
              <TextFieldRHFStyle
                sx={{
                  marginRight: '8px',
                }}
                placeholder='Моя рабочая область'
                name='title'
                size={SizeOptions.small}
              />
              <Button
                disabled={!isDirty}
                variant='contained'
                type='submit'
                sx={{
                  height: '32px',
                }}
              >
                Создать
              </Button>
            </Stack>
          </Stack>
        </FormFlex>
      </FormProvider>
    </RequestHandler>
  );
};
