import { StyledTableCell, StyledTableCellDrag, StyledTableRow } from '../../style';
import { IconButton, Stack, useTheme, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { NameCreateCell } from '../GoalCreate/GoalRowAdd/NameCreateCell/NameCreateCell';
import { useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { PositionedPopper } from '../../../uiComponents';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { StackStyle } from './style';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useModalContext } from '../../../../context';
import { ModalStatuses } from '../../../../types/enum';

type BodyColapseItemType = {
  emptyWipth: string;
  handleClick: () => void;
  open: boolean;
  goalTitle: string;
  goalId: number;
  emptyCellWidth: string;
};

export const BodyColapseItem = ({
  goalTitle,
  emptyWipth,
  handleClick,
  open,
  goalId,
  emptyCellWidth,
}: BodyColapseItemType) => {
  const [isEditName, setIsEditName] = useState(false);

  const theme = useTheme();

  const { handleChangeModalParameters } = useModalContext();

  const hanldeDeleteGoal = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.GOAL_DELETE_MODAL,
      },
      {
        key: 'goalId',
        value: goalId,
      },
    ]);
  };

  return (
    <>
      <StyledTableRow sx={{ borderTop: '1px solid red' }}>
        <StyledTableCellDrag
          sx={{
            borderBottom: 'none',
          }}
          padding='none'
          width={emptyWipth}
        ></StyledTableCellDrag>
        {isEditName ? (
          <NameCreateCell
            setCreateGoal={setIsEditName}
            update={true}
            goalId={goalId}
            goalTitle={goalTitle}
          />
        ) : (
          <StyledTableCell>
            <Stack direction={'row'} alignItems={'center'}>
              <Stack direction={'row'} alignItems={'center'}>
                <Stack sx={{ marginRight: '8px', cursor: 'pointer' }} onClick={handleClick}>
                  {open ? (
                    <ArrowDropDownIcon sx={{ width: '20px', height: '20px' }} />
                  ) : (
                    <ArrowRightIcon sx={{ width: '20px', height: '20px' }} />
                  )}
                </Stack>
                {goalTitle}
              </Stack>
              <IconButton
                sx={{ marginLeft: 'auto', padding: '2px' }}
                onClick={() => {
                  setIsEditName(true);
                }}
              >
                <EditOutlinedIcon
                  className='hidden'
                  fontSize='small'
                  sx={{ width: '20px', height: '20px', visibility: 'hidden' }}
                />
              </IconButton>
            </Stack>
          </StyledTableCell>
        )}
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCellDrag padding='none' width={emptyCellWidth}>
          <PositionedPopper
            ClickComponent={
              <IconButton sx={{ padding: '0px', visibility: 'hidden' }}>
                <MoreHorizIcon />
              </IconButton>
            }
            PopperContent={
              <StackStyle onClick={hanldeDeleteGoal}>
                <DeleteOutlineOutlinedIcon
                  sx={{
                    marginRight: '8px',
                    color: theme.palette.neutral500.main,
                  }}
                />
                <Typography color='black600'>Удалить</Typography>
              </StackStyle>
            }
            placement='bottom-end'
          />
        </StyledTableCellDrag>
      </StyledTableRow>
    </>
  );
};
