import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment userFragment on User {
    id
    email
    firstName
    lastName
    avatar {
      id
      path
    }
    workspaces {
      id
      title
      myRole
      inviteLink
    }
  }
`;
