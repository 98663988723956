import { SvgIcon, SxProps } from '@mui/material';

type CheckCircleRoundenType = {
  sx: SxProps;
};

export const CheckCircleRounden = ({ sx }: CheckCircleRoundenType) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20, ...sx }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M9.99996 1.66699C5.39996 1.66699 1.66663 5.40033 1.66663 10.0003C1.66663 14.6003 5.39996 18.3337 9.99996 18.3337C14.6 18.3337 18.3333 14.6003 18.3333 10.0003C18.3333 5.40033 14.6 1.66699 9.99996 1.66699ZM7.74163 13.5753L4.74996 10.5837C4.42496 10.2587 4.42496 9.73366 4.74996 9.40866C5.07496 9.08366 5.59996 9.08366 5.92496 9.40866L8.33329 11.8087L14.0666 6.07533C14.3916 5.75033 14.9166 5.75033 15.2416 6.07533C15.5666 6.40033 15.5666 6.92533 15.2416 7.25033L8.91663 13.5753C8.59996 13.9003 8.06663 13.9003 7.74163 13.5753Z'
          fill='#51BC51'
        />
      </svg>
    </SvgIcon>
  );
};
