import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { useWorkspace } from '../../../../../../../../../hooks';
import React, { useState } from 'react';
import {
  ProtectedMemberRoleEnum,
  useLeaveWorkspaceMutation,
  User,
  useRemoveMemberMutation,
  useUpdateMemberMutation,
  WorkspaceMemberRole,
} from '../../../../../../../../../graphql/generated/graphql';
import { ActionWrapperStack } from '../../../Groups/components/GroupsColumn/components/GroupsColumnItem/style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MemberRoleStack, MemberWrapper } from './style';
import { conditionalClassNames } from '../../../../../../../../../utils/conditionalClassNames';
import { useAuthContext } from '../../../../../../../../../context';
import { WorkspaceRemoveMemberModal } from '../../../../../../WorkspaceRemoveMemberModal/WorkspaceRemoveMemberModal';
import { DefaultModal } from '../../../../../../DefaultModal/DefaultModal';
import { PositionedPopper } from '../../../../../../../../uiComponents';
import { getRoleTitle } from '../../../../../../../../../utils/getRoleTitle';
import { AvatarCustom } from '../../../../../../../../AvatarCustom/AvatarCustom';
import { WORKSPACE } from '../../../../../../../../../graphql/queries/workspace';
import { WORKSPACES } from '../../../../../../../../../graphql/queries/workspaces';

export const WorkspaceMembers = () => {
  const { currentWorkspace } = useWorkspace();
  return (
    <Stack mt='48px'>
      <Typography variant='h3' mb='24px'>
        Пользователи
      </Typography>
      {currentWorkspace?.members.map((member, index) => (
        <MemberOfWorkspace
          key={index}
          role={member.role}
          userId={member.user.id}
          name={`${member.user.firstName} ${member.user.lastName}`}
          email={member.user.email || ''}
          path={member?.user?.avatar?.path}
        />
      ))}
    </Stack>
  );
};

type MemberOfWorkspaceType = {
  name: string;
  email: string;
  role: WorkspaceMemberRole;
  userId: number;
  path?: string;
};
enum ActionType {
  DIVIDER,
  ACTION,
}
export const MemberOfWorkspace = ({ name, email, role, userId, path }: MemberOfWorkspaceType) => {
  const theme = useTheme();
  const { currentUser } = useAuthContext();
  const { currentWorkspace, refetch } = useWorkspace();
  const myRole = currentWorkspace?.myRole as WorkspaceMemberRole;
  const isCurrentUser = currentUser?.id === userId;
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleShowModal = () => {
    refetch();
    setShowModal((prevState) => !prevState);
  };
  const [useUpdateMember] = useUpdateMemberMutation({
    refetchQueries: [WORKSPACE],
  });
  const [useRemoveMember] = useRemoveMemberMutation({
    refetchQueries: [WORKSPACE],
  });
  const [useLeaveWorkspace] = useLeaveWorkspaceMutation({
    variables: {
      workspaceId: Number(currentWorkspace?.id),
    },
    refetchQueries: [WORKSPACES],
  });

  const handleChangeMemberRole = (newRole: ProtectedMemberRoleEnum) => {
    useUpdateMember({
      variables: {
        data: {
          role: newRole,
          workspaceId: currentWorkspace!.id,
          userId: userId,
        },
      },
    });
  };
  const handleRemoveMember = () => {
    useRemoveMember({
      variables: {
        data: {
          workspaceId: currentWorkspace!.id,
          userId: userId,
        },
      },
    });
  };
  function determineActions() {
    const actions = [];

    if (isCurrentUser) {
      if (myRole === WorkspaceMemberRole.Admin) {
        actions.push({
          type: ActionType.ACTION,
          title: 'Стать участником',
          onClick: () => handleChangeMemberRole(ProtectedMemberRoleEnum.Member),
        });
        actions.push({ type: ActionType.DIVIDER });
      }
      if (myRole !== WorkspaceMemberRole.Creator) {
        actions.push({
          type: ActionType.ACTION,
          title: 'Выйти',
          onClick: () => useLeaveWorkspace(),
        });
      }
    } else {
      switch (myRole) {
        case WorkspaceMemberRole.Creator:
          actions.push({
            type: ActionType.ACTION,
            title: 'Админ',
            onClick: () => handleChangeMemberRole(ProtectedMemberRoleEnum.Admin),
          });

          actions.push({
            type: ActionType.ACTION,
            title: 'Участник',
            onClick: () => handleChangeMemberRole(ProtectedMemberRoleEnum.Member),
          });
          actions.push({ type: ActionType.DIVIDER });
          actions.push({ type: ActionType.ACTION, title: 'Удалить', onClick: handleShowModal });
          break;
        case WorkspaceMemberRole.Admin:
          if (role === WorkspaceMemberRole.Member) {
            actions.push({ type: ActionType.ACTION, title: 'Удалить', onClick: handleShowModal });
          }
          break;
        default:
          break;
      }
    }

    return actions;
  }
  const currentAccessAction = determineActions();
  const hasAccess = !!currentAccessAction.length;
  const memberRoleClasses = conditionalClassNames({
    hasAccess: hasAccess,
  });

  const roleTitle = getRoleTitle(role);

  return (
    <>
      <MemberWrapper flexDirection='row' alignItems='center'>
        <AvatarCustom
          user={
            {
              email: name || '',
              avatar: {
                path: path,
              },
            } as User
          }
          sx={{ marginRight: '8px', width: '32px', height: '32px', fontSize: '14px' }}
        />

        <Stack flex={1}>
          <Typography fontWeight='500' color='black600'>
            {email}
          </Typography>
          <Typography fontSize={12}>{name}</Typography>
        </Stack>
        <PositionedPopper
          placement='bottom-end'
          isDisableClick={!hasAccess}
          ClickComponent={
            <MemberRoleStack className={memberRoleClasses}>
              <Typography color='black600' fontWeight='500'>
                {roleTitle}
              </Typography>
              {hasAccess && <KeyboardArrowDownIcon sx={{ color: 'black600', marginLeft: '6px' }} />}
            </MemberRoleStack>
          }
          PopperContent={
            <Stack
              sx={{
                width: '160px',
                borderRadius: '8px',
              }}
            >
              {currentAccessAction.map((action, index) => {
                if (action.type === ActionType.DIVIDER) {
                  return (
                    <Divider
                      key={index}
                      sx={{
                        backgroundColor: theme.palette.neutralOutlinedBorder.main,
                      }}
                    />
                  );
                }
                return (
                  <ActionWrapperStack key={index} onClick={action.onClick}>
                    <Typography color='black600.main' variant='h4' ml='8px'>
                      {action.title}
                    </Typography>
                  </ActionWrapperStack>
                );
              })}
            </Stack>
          }
        />
      </MemberWrapper>
      <DefaultModal open={showModal} handleCloseModal={handleShowModal} sx={{ width: '360px' }}>
        <WorkspaceRemoveMemberModal
          userName={name}
          handleCloseModal={handleShowModal}
          handleSubmitModal={handleRemoveMember}
        />
      </DefaultModal>
    </>
  );
};
