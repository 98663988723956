import { styled, Stack } from '@mui/material';

export const StackNodataStyle = styled(Stack)(() => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  alignItems: 'center',
  justifyContent: 'center',
}));
