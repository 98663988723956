import { useTheme, Stack, Popover, Box } from '@mui/material';
import { StyledTableCell } from '../../style';
import { useForm, FormProvider } from 'react-hook-form';
import { useClickCellPopup } from '../../../../hooks';
import { MemberThredTable, AvatarCustom } from '../../../index';
import { useEffect, useState, useMemo } from 'react';
import {
  UpdateFolderMutationFn,
  UpdateThreadMutationFn,
  User,
  UserGroup,
} from '../../../../graphql/generated/graphql';
import { MemberTypes, ResponsibleCellTyle, OutsideClickFlagType } from '../../../../types/enum';
import { useWorkspaceContext } from '../../../../context';
import { createTextUserAndGroup } from '../../../../utils';

type ResponsibleTypee = {
  responsible?: {
    id: number;
    email?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    avatar?: {
      path: string;
    } | null;
  } | null;
  responsibleUserGroup?: {
    id: number;
    title?: string | null;
    avatar?: {
      path: string;
    } | null;
  } | null;
  entityId: number;
  updateFolderMutation?: UpdateFolderMutationFn;
  updateThreadMutation?: UpdateThreadMutationFn;
  type: ResponsibleCellTyle;
  responsibleWidth?: string;
  folderId?: number;
  projectId?: number;
  isAcess?: boolean;
};

export const ResponsibleCell = ({
  responsible,
  responsibleUserGroup,
  entityId,
  updateFolderMutation,
  updateThreadMutation,
  type,
  responsibleWidth,
  folderId,
  projectId,
  isAcess = true,
}: ResponsibleTypee) => {
  const theme = useTheme();
  const { anchorEl, open, handleClick, handleClose, refClickOutside, setOpen } = useClickCellPopup({
    isAcess,
  });
  const { workspaceId } = useWorkspaceContext();
  const initValue = useMemo(() => {
    return responsible || responsibleUserGroup
      ? {
          value: responsible?.id || responsibleUserGroup?.id || 0,
          title: createTextUserAndGroup({
            user: responsible as User,
            userGroup: responsibleUserGroup as UserGroup,
          }),
          type: responsible ? MemberTypes.user : MemberTypes.groups,
          avatar: responsible?.avatar || responsibleUserGroup?.avatar,
        }
      : null;
  }, [responsible, responsibleUserGroup]);

  type FormData = {
    users?: {
      title: string;
      value: number;
      type: MemberTypes;
      avatar?: {
        path: string;
      } | null;
    } | null;
  };

  const methods = useForm<FormData>({
    defaultValues: {
      users: initValue,
    },
  });

  const [isInitDisable, setIsInitDisable] = useState(true);
  const { getValues, watch } = methods;

  const userWatch = watch('users');

  useEffect(() => {
    if (userWatch?.type !== initValue?.value || userWatch?.type !== initValue?.type) {
      setOpen(false);
    }
  }, [userWatch]);

  useEffect(() => {
    setIsInitDisable(false);
    if (isInitDisable || open) return;
    const currentValues = getValues();

    const value = {
      id: entityId,
      responsibleId:
        currentValues.users?.value !== 0 && currentValues.users?.type === MemberTypes.user
          ? currentValues.users?.value
          : null,
      responsibleUserGroupId:
        currentValues.users?.value !== 0 && currentValues.users?.type === MemberTypes.groups
          ? currentValues.users?.value
          : null,
    };
    if (updateFolderMutation && type === ResponsibleCellTyle.folder) {
      updateFolderMutation({
        variables: {
          updateFolderInput: value,
        },
      });
    }
    if (updateThreadMutation && type === ResponsibleCellTyle.thread) {
      updateThreadMutation({
        variables: {
          data: value,
        },
      });
    }
  }, [open]);

  return (
    <FormProvider {...methods}>
      <StyledTableCell
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          minWidth: '16%',
          width: '20%',
          '&:hover': {
            backgroundColor: `${theme.palette.tableHover.main} !important`,
          },
        }}
        width={responsibleWidth}
      >
        {responsible && (
          <Stack direction='row' alignItems={'center'} ref={refClickOutside}>
            {responsible?.email && <AvatarCustom user={responsible as User} isOneLether={false} />}
            {createTextUserAndGroup({ user: responsible as User })}
          </Stack>
        )}
        {responsibleUserGroup && (
          <Stack direction='row' alignItems={'center'} ref={refClickOutside}>
            {responsibleUserGroup?.title && (
              <AvatarCustom user={responsibleUserGroup as User} isOneLether={false} />
            )}
            {createTextUserAndGroup({ userGroup: responsibleUserGroup as UserGroup })}
          </Stack>
        )}
      </StyledTableCell>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'visible',
          },
        }}
      >
        <Box className={OutsideClickFlagType.outsideClickFlagDefault}>
          <MemberThredTable
            name='users'
            workspaceId={workspaceId!}
            folderId={folderId}
            projectId={projectId}
          />
        </Box>
      </Popover>
    </FormProvider>
  );
};
