import { Stack, Typography, Button } from '@mui/material';
import { CloseButton } from '../../../index';
import { StackStyle, StackMainStyle, HeaderStack } from './style';
import { useRemoveWorkspaceMutation } from '../../../../graphql/generated/graphql';
import { useWorkspace } from '../../../../hooks';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DeleteWorkspaceSchema } from '../../../../validation/workspace.schem';
import { FormWrapper } from '../InviteWorkspaceModal/style';
import { SizeOptions } from '../../../../types/enum';
import { TextFieldRHFStyle } from '../../../WorkspaceHubElements/style';
import React from 'react';
import { RequestHandler } from '../../../RequestHandler/RequestHandler';
import { ModalParametersTypes } from '../../../../types/types';

type WorkspaceDeleteModalType = {
  handleCloseModal: () => void;
  handleCloseRootModal: () => void;
  modalParameters: ModalParametersTypes;
};
interface IFormData {
  keyWord?: string;
}
export const WorkspaceDeleteModal = ({
  handleCloseModal,
  handleCloseRootModal,
  modalParameters,
}: WorkspaceDeleteModalType) => {
  const { currentWorkspace, workspaces, handleWorkspace } = useWorkspace();
  const methods = useForm<IFormData>({
    resolver: yupResolver(DeleteWorkspaceSchema),
    defaultValues: {
      keyWord: '',
    },
  });
  const [useRemoveWorkspace, { loading }] = useRemoveWorkspaceMutation({
    variables: {
      removeWorkspaceId: Number(currentWorkspace?.id),
    },
    update(cache, { data }) {
      const workspaceCount = workspaces?.length;
      const workspaceIds = workspaces!.map((item) => item.id);
      if (workspaceCount === 1) {
        modalParameters.createNewWorkspace?.();
      } else {
        const filteredWorkspacesIds = workspaceIds.filter((item) => item !== currentWorkspace?.id);
        handleWorkspace(filteredWorkspacesIds[0]);
      }
      handleCloseModal();
      handleCloseRootModal();
      cache.evict({
        id: `Workspace:${currentWorkspace!.id}`,
      });
    },
  });
  const onSubmit: SubmitHandler<IFormData> = () => {
    useRemoveWorkspace();
  };

  return (
    <RequestHandler loading={loading}>
      <FormProvider {...methods}>
        <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack p='16px'>
            <HeaderStack>
              <Typography variant='h3'>Удаление рабочей области</Typography>
              <CloseButton onClick={handleCloseModal} />
            </HeaderStack>
            <Stack>
              <StackMainStyle>
                <Stack direction='row' alignItems={'center'}>
                  <Typography variant='customDefault' fontWeight={400}>
                    Введите слово “Удалить” ниже если вы хотите удалить рабочую область со всем
                    содержимым
                  </Typography>
                </Stack>
              </StackMainStyle>
              <TextFieldRHFStyle
                name='keyWord'
                type='text'
                placeholder='Введите слово'
                size={SizeOptions.small}
                sx={{
                  marginTop: '16px',
                }}
              />
              <StackStyle>
                <Button variant='cancel' onClick={handleCloseModal} sx={{ marginLeft: '8px' }}>
                  Отмена
                </Button>
                <Button
                  variant='confirm'
                  type='submit'
                  sx={{ marginLeft: '8px', fontWeight: '500' }}
                >
                  Удалить
                </Button>
              </StackStyle>
            </Stack>
          </Stack>
        </FormWrapper>
      </FormProvider>
    </RequestHandler>
  );
};
