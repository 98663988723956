import { useEffect, useState } from 'react';
import { TypeTable, ModalStatuses, StatusThreadTypes } from '../../types/enum';
import { FolderTables } from './FolderTables/FolderTables';
import { FilterTables } from './FilterTables/FilterTables';
import { GoalTables } from './GoalTables/GoalTables';
import { useModalContext, useWorkspaceContext } from '../../context/index';

const emptyCellWidth = '32px';
const titleCellWidth = '48%';
const responsibleCellWidth = '24%';

export interface DataItem {
  uuid: string;
  description: string;
  unitPrice: number;
  quantity: number;
}

type TableTypeControllType = {
  selectAuthorUser: string;
  filterStatus: StatusThreadTypes;
};

export const TableTypeControll = ({ selectAuthorUser, filterStatus }: TableTypeControllType) => {
  const [type, setType] = useState(TypeTable.filter);
  const { handleChangeModalParameters } = useModalContext();
  const { workspaceId, selectProjectId, selectFolderId } = useWorkspaceContext();

  const hanldeCreateThread = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.THREAD_MODAl,
      },
      {
        key: 'workspaceId',
        value: workspaceId,
      },
    ]);
  };

  useEffect(() => {
    if (selectProjectId && !selectFolderId) {
      setType(TypeTable.folder);
    } else if (selectProjectId && selectFolderId) {
      setType(TypeTable.goal);
    } else {
      setType(TypeTable.filter);
    }
  }, [selectProjectId, selectFolderId]);

  if (TypeTable.goal === type) {
    return (
      <GoalTables
        filterStatus={filterStatus}
        emptyCellWidth={emptyCellWidth}
        titleCellWidth={titleCellWidth}
      />
    );
  }

  if (TypeTable.folder === type) {
    return (
      <FolderTables
        emptyCellWidth={emptyCellWidth}
        titleCellWidth={titleCellWidth}
        responsibleCellWidth={responsibleCellWidth}
        hanldeCreateThread={hanldeCreateThread}
      />
    );
  }

  return (
    <FilterTables
      emptyCellWidth={emptyCellWidth}
      titleCellWidth={titleCellWidth}
      hanldeCreateThread={hanldeCreateThread}
      selectAuthorUser={selectAuthorUser}
      filterStatus={filterStatus}
    />
  );
};
