export const SideburLogo = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='138'
      height='25'
      viewBox='0 0 138 25'
      fill='none'
    >
      <g clipPath='url(#clip0_330_1069)'>
        <path
          d='M35.6602 23.8152V6.92769H39.6038V9.82177H39.6992C39.9642 8.81467 40.4359 8.0249 41.1145 7.45244C41.7929 6.87998 42.625 6.59375 43.6109 6.59375C43.8654 6.59375 44.1039 6.60965 44.3266 6.64145C44.5492 6.67325 44.7347 6.71037 44.8831 6.75277V10.3147C44.7134 10.2511 44.4856 10.1981 44.1993 10.1557C43.913 10.1133 43.6003 10.0921 43.2612 10.0921C42.5084 10.0921 41.8565 10.2458 41.3052 10.5532C40.7539 10.8607 40.3299 11.3059 40.033 11.889C39.7469 12.4721 39.6038 13.177 39.6038 14.0039V23.8152H35.6602Z'
          fill='black'
        />
        <path
          d='M49.8072 24.0686C49.1605 24.0686 48.6145 23.846 48.1692 23.4007C47.7241 22.9555 47.5015 22.4096 47.5015 21.7629C47.5015 21.1163 47.7241 20.5703 48.1692 20.125C48.6145 19.6797 49.1605 19.4572 49.8072 19.4572C50.4538 19.4572 50.9997 19.6797 51.445 20.125C51.8902 20.5703 52.1129 21.1163 52.1129 21.7629C52.1129 22.4096 51.8902 22.9555 51.445 23.4007C50.9997 23.846 50.4538 24.0686 49.8072 24.0686ZM49.8072 12.381C49.1605 12.381 48.6145 12.1584 48.1692 11.7131C47.7241 11.2679 47.5015 10.7219 47.5015 10.0753C47.5015 9.4286 47.7241 8.88264 48.1692 8.4374C48.6145 7.99215 49.1605 7.76953 49.8072 7.76953C50.4538 7.76953 50.9997 7.99215 51.445 8.4374C51.8902 8.88264 52.1129 9.4286 52.1129 10.0753C52.1129 10.7219 51.8902 11.2679 51.445 11.7131C50.9997 12.1584 50.4538 12.381 49.8072 12.381Z'
          fill='black'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M23.9082 24.1016C22.7951 24.1016 21.8145 23.8896 20.9664 23.4656C20.1184 23.0309 19.4558 22.432 18.9787 21.6688C18.5017 20.8949 18.2632 20.0043 18.2632 18.9973V18.9813C18.2632 17.9743 18.5123 17.1102 19.0106 16.3893C19.5193 15.6686 20.2509 15.1013 21.2049 14.6879C22.1591 14.2639 23.3146 14.0094 24.6715 13.9246L29.0922 13.6524V12.4776C29.0922 11.6189 28.8218 10.9458 28.2811 10.4581C27.7405 9.97046 26.972 9.72663 25.9754 9.72663C25.0319 9.72663 24.2793 9.92274 23.7174 10.315C23.1556 10.7072 22.8057 11.2161 22.6679 11.8415L22.6361 11.9846H18.9628L18.9787 11.7938C19.0742 10.7973 19.4133 9.90684 19.9965 9.12237C20.5795 8.33789 21.3852 7.71774 22.4134 7.2619C23.4523 6.80604 24.6821 6.57812 26.1027 6.57812C27.5232 6.57812 28.7476 6.81134 29.7759 7.2778C30.8148 7.74424 31.6152 8.40151 32.177 9.24959C32.7495 10.0871 33.0357 11.073 33.0357 12.2073V23.8155H29.0922V21.2394H28.9968C28.6681 21.8224 28.2494 22.3313 27.7405 22.7659C27.2316 23.1899 26.654 23.5186 26.0072 23.7518C25.3605 23.9851 24.6609 24.1016 23.9082 24.1016ZM22.986 17.2162C23.5054 16.8135 24.2527 16.5802 25.228 16.5166L29.0922 16.277V17.5661C29.0922 18.234 28.9119 18.8329 28.5515 19.3629C28.2016 19.8931 27.7246 20.3117 27.1203 20.6192C26.5267 20.9266 25.8482 21.0804 25.0849 21.0804C24.2156 21.0804 23.516 20.8842 22.986 20.492C22.4664 20.0891 22.2068 19.5432 22.2068 18.8542V18.8223C22.2068 18.1544 22.4664 17.6191 22.986 17.2162Z'
          fill='black'
        />
        <path
          d='M53.8225 0C53.5527 0 53.334 0.218707 53.334 0.488496V23.9397C53.334 24.2076 53.5511 24.4248 53.8189 24.4248C55.6574 24.4248 57.4988 24.1929 59.1821 23.454C62.188 22.1347 66.444 19.6883 70.0581 15.957C70.3095 15.6974 70.7745 15.9736 70.653 16.3139L67.9893 23.772C67.8758 24.0902 68.1116 24.4248 68.4494 24.4248H73.6879C80.4327 24.4248 85.9003 18.957 85.9003 12.2124C85.9003 5.46767 80.4327 0 73.6879 0H53.8225Z'
          fill='url(#paint0_linear_330_1069)'
        />
        <path
          opacity='0.8'
          d='M53.8225 0C53.5527 0 53.334 0.218707 53.334 0.488496V23.9397C53.334 24.2076 53.5511 24.4248 53.8189 24.4248C55.6574 24.4248 57.4988 24.1929 59.1821 23.454C62.188 22.1347 66.444 19.6883 70.0581 15.957C70.3095 15.6974 70.7745 15.9736 70.653 16.3139L67.9893 23.772C67.8758 24.0902 68.1116 24.4248 68.4494 24.4248H73.6879C80.4327 24.4248 85.9003 18.957 85.9003 12.2124C85.9003 5.46767 80.4327 0 73.6879 0H53.8225Z'
          fill='url(#paint1_linear_330_1069)'
        />
        <path
          d='M92.9574 24.1472C91.7171 24.1472 90.6623 23.8981 89.793 23.3998C88.9238 22.8909 88.2611 22.1648 87.8054 21.2212C87.3495 20.2778 87.1216 19.154 87.1216 17.8501V6.92578H91.0811V17.0551C91.0811 18.2636 91.362 19.1965 91.9239 19.8538C92.4856 20.5005 93.3338 20.8237 94.4681 20.8237C95.0405 20.8237 95.5546 20.7283 96.0105 20.5375C96.4664 20.3361 96.8586 20.0552 97.1873 19.6947C97.5159 19.3343 97.7649 18.8997 97.9346 18.3908C98.1148 17.882 98.2049 17.3254 98.2049 16.7211V6.92578H102.164V23.8132H98.2049V21.1736H98.1096C97.8127 21.7672 97.4258 22.292 96.9486 22.7478C96.4716 23.193 95.8992 23.5375 95.2313 23.7814C94.5634 24.0252 93.8055 24.1472 92.9574 24.1472Z'
          fill='black'
        />
        <path
          d='M112.294 24.1474C110.62 24.1474 109.172 23.7923 107.954 23.082C106.734 22.3717 105.791 21.3593 105.123 20.0448C104.466 18.7196 104.137 17.1559 104.137 15.3539V15.3379C104.137 13.5463 104.471 11.9933 105.139 10.6788C105.807 9.36425 106.745 8.35185 107.954 7.64158C109.172 6.93131 110.609 6.57617 112.262 6.57617C113.736 6.57617 115.014 6.85711 116.094 7.41896C117.176 7.98082 118.024 8.72819 118.639 9.66108C119.254 10.594 119.604 11.6276 119.688 12.7619L119.704 12.8732H115.983L115.951 12.7459C115.793 11.9191 115.406 11.2194 114.791 10.647C114.187 10.0745 113.354 9.78829 112.294 9.78829C111.457 9.78829 110.731 10.0162 110.116 10.4721C109.501 10.9173 109.023 11.5587 108.685 12.3962C108.346 13.2231 108.176 14.209 108.176 15.3539V15.3698C108.176 16.5464 108.346 17.5536 108.685 18.391C109.023 19.2179 109.501 19.854 110.116 20.2992C110.742 20.7338 111.473 20.9512 112.31 20.9512C113.317 20.9512 114.128 20.6808 114.744 20.1402C115.369 19.5995 115.777 18.8893 115.967 18.0093L115.983 17.8662H119.72V17.9776C119.604 19.1438 119.227 20.1932 118.592 21.1261C117.956 22.059 117.102 22.7958 116.031 23.3364C114.961 23.877 113.715 24.1474 112.294 24.1474Z'
          fill='black'
        />
        <path
          d='M121.708 23.8142V0.804688H125.652V13.9235H125.748L132.061 6.92678H136.625L129.929 14.194L136.926 23.8142H132.347L126.995 16.5526L125.652 17.9539V23.8142H121.708Z'
          fill='black'
        />
        <path
          d='M1.22119 24.3146V2.44336H5.30298V9.85926H5.47211C5.68259 9.43831 5.97951 8.99104 6.36289 8.51746C6.74626 8.03637 7.26494 7.62669 7.91893 7.28842C8.57291 6.94263 9.40731 6.76974 10.4221 6.76974C11.7602 6.76974 12.9667 7.11176 14.0416 7.79582C15.1241 8.47236 15.981 9.47589 16.6124 10.8064C17.2514 12.1294 17.5709 13.7531 17.5709 15.6775C17.5709 17.5793 17.2589 19.1955 16.635 20.526C16.0111 21.8565 15.1616 22.8714 14.0867 23.5704C13.0118 24.2695 11.794 24.6191 10.4334 24.6191C9.44114 24.6191 8.61801 24.4537 7.96403 24.123C7.31004 23.7922 6.78384 23.3938 6.38544 22.9277C5.99455 22.4542 5.69011 22.0069 5.47211 21.5859H5.23532V24.3146H1.22119ZM5.22405 15.6549C5.22405 16.775 5.3819 17.756 5.69762 18.5979C6.02086 19.4398 6.48316 20.0975 7.08453 20.5711C7.69341 21.0372 8.43009 21.2702 9.29455 21.2702C10.1966 21.2702 10.9521 21.0297 11.561 20.5486C12.1698 20.06 12.6284 19.3947 12.9366 18.5528C13.2523 17.7034 13.4102 16.7374 13.4102 15.6549C13.4102 14.58 13.2561 13.6253 12.9479 12.7909C12.6397 11.9565 12.1811 11.3025 11.5722 10.829C10.9633 10.3554 10.2041 10.1186 9.29455 10.1186C8.42257 10.1186 7.68214 10.3479 7.07325 10.8064C6.46437 11.265 6.00207 11.9077 5.68635 12.7346C5.37815 13.5614 5.22405 14.5349 5.22405 15.6549Z'
          fill='black'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_330_1069'
          x1='53.334'
          y1='24.4248'
          x2='76.7818'
          y2='-6.83894'
          gradientUnits='userSpaceOnUse'
        >
          <stop />
          <stop offset='0.558129' stopColor='#4E4E4E' />
          <stop offset='1' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_330_1069'
          x1='53.334'
          y1='24.4248'
          x2='76.7818'
          y2='-6.83894'
          gradientUnits='userSpaceOnUse'
        >
          <stop />
          <stop offset='0.558129' stopColor='#4E4E4E' />
          <stop offset='1' />
        </linearGradient>
        <clipPath id='clip0_330_1069'>
          <rect width='138' height='24.4248' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
