export enum TypeTable {
  filter = 'filter',
  folder = 'folder',
  goal = 'goal',
}

export enum ModalStatuses {
  CLOSE = 'close',
  WITHOUT_STATUS = 'without-status',
  PROJECT_MODAL = 'project-modal',
  PROJECT_DELETE_MODAL = 'project-delete-modal',
  FOLDER_DELETE_MODAL = 'folder-delete-modal',
  GOAL_DELETE_MODAL = 'goal-delete-modal',
  THREAD_MODAl = 'thread-modal',
  THREAD_DELETE_MODAl = 'thread-delete-modal',
  CREATE_WORKSPACE_MODAl = 'create-workspace-modal',
  INVITE_WORKSPACE_MODAl = 'invite-workspace-modal',
  SETTINGS_WORKSPACE_MODAl = 'settings-workspace-modal',
  SINGLE_IMAGE_MODAL = 'single-image-modal',
  RENAME_FILE_MODAL = 'rename-file-modal',
  LIST_IMAGE_MODAL = 'list_image_modal',
}

export enum AuthorSelectFilter {
  isAuthor = 'isAuthor',
  isResponsible = 'isResponsible',
  isSubscriber = 'isSubscriber',
  none = 'none',
}

export enum TypeTableRow {
  goal = 'goal',
  thread = 'thread',
  folder = 'folder',
}

export enum SizeOptions {
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
}

export enum MemberTypes {
  user = 'user',
  groups = 'groups',
  project = 'project',
}
export enum StatusThreadTypes {
  open = 1,
  in_progress = 2,
  postponed = 3,
  done = 4,
  none = 0,
}

export enum StatusThreadTableButtonType {
  filter = 'filter',
  table = 'table',
}
export enum ResponsibleCellTyle {
  thread = 'thread',
  folder = 'folder',
}

export enum OutsideClickFlagType {
  outsideClickFlagDefault = 'outsideClickFlag',
  outsideClickFlagFile = 'outsideClickFlagFile',
}

export enum OriginVerticalType {
  top = 'top',
  center = 'center',
  bottom = 'bottom',
}
export enum OriginHorizontalType {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum AttachmentsClickType {
  ThreadItem = 'ThreadItem',
  ThreadModal = 'ThreadModal',
  Comment = 'Comment',
}

export enum FilterFileType {
  Image = 'Image',
  File = 'File',
  Link = 'Link',
}
export enum TypeMessageChat {
  NEW_MESSAGE = 'NEW_MESSAGE',
  MY_NEW_MESSAGE = 'MY_NEW_MESSAGE',
  DELETE_MESSAGE = 'DELETED_MESSAGE',
  CLEAN = '',
}

export enum LOCATION_ID_QUERY_PARAMETER {
  workspaceId = 'workspaceId',
  folderId = 'folderId',
  projectId = 'projectId',
}
