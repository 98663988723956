import { SvgIcon } from '@mui/material';

type AddRoundedType = { color?: string };

export const AddRounded = ({ color = '#F0F4F8' }: AddRoundedType) => {
  return (
    <SvgIcon>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M14.9998 10.8337H10.8332V15.0003C10.8332 15.4587 10.4582 15.8337 9.99984 15.8337C9.5415 15.8337 9.1665 15.4587 9.1665 15.0003V10.8337H4.99984C4.5415 10.8337 4.1665 10.4587 4.1665 10.0003C4.1665 9.54199 4.5415 9.16699 4.99984 9.16699H9.1665V5.00033C9.1665 4.54199 9.5415 4.16699 9.99984 4.16699C10.4582 4.16699 10.8332 4.54199 10.8332 5.00033V9.16699H14.9998C15.4582 9.16699 15.8332 9.54199 15.8332 10.0003C15.8332 10.4587 15.4582 10.8337 14.9998 10.8337Z'
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
