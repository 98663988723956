import { LoginForm } from './components';
import { Button, Stack, Typography } from '@mui/material';
import { ROUTES } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export const Login = () => {
  const navigate = useNavigate();
  const handleNavigateToRegister = () => {
    navigate(ROUTES.registration);
  };
  return (
    <>
      <Typography variant='h1' mb='8px'>
        Войти в аккаунт
      </Typography>
      <Typography variant='h4'>Впишите данные, использованные при регистрации</Typography>
      <LoginForm />
      <Stack flexDirection='row' mt='24px'>
        <Typography variant='h4' mr='4px'>
          Еще нет аккаунта?
        </Typography>
        <Button onClick={handleNavigateToRegister} variant='text'>
          Создать
        </Button>
      </Stack>
    </>
  );
};
