import React, { ReactElement, useState } from 'react';
import { AtTheBeginning, CreateNewPassword, RecoveryInstructions } from './components';
import { FORGOT_PASSWORD_STEP } from './const';
import { ForgotPasswordProvider } from './context/context';
import { useForgotPassword } from '../../hooks/useForgotPassword';

interface IStep {
  component: () => ReactElement;
  stepName: FORGOT_PASSWORD_STEP;
}

const stepsOfForgotPassword: IStep[] = [
  {
    component: AtTheBeginning,
    stepName: FORGOT_PASSWORD_STEP.atTheBeginning,
  },
  {
    component: RecoveryInstructions,
    stepName: FORGOT_PASSWORD_STEP.recoveryInstructions,
  },
  {
    component: CreateNewPassword,
    stepName: FORGOT_PASSWORD_STEP.createNewPassword,
  },
];
export const ForgotPassword = () => {
  const { forgotPasswordData, updateForgotPasswordData } = useForgotPassword();

  const [currentStepName, setCurrentStepName] = useState<FORGOT_PASSWORD_STEP>(
    forgotPasswordData.stepName,
  );

  const handleGoToStep = (newStepName: FORGOT_PASSWORD_STEP) => {
    setCurrentStepName(newStepName);
    updateForgotPasswordData({ stepName: newStepName });
  };

  const currentStep = stepsOfForgotPassword.find(
    (step) => step.stepName === currentStepName,
  ) as IStep;
  return (
    <>
      <ForgotPasswordProvider
        forgotPasswordData={forgotPasswordData}
        updateData={updateForgotPasswordData}
        handleGoToStep={handleGoToStep}
      >
        <currentStep.component />
      </ForgotPasswordProvider>
    </>
  );
};
