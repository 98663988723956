import { SxProps, FormControl, Box } from '@mui/material';
import { useFormContext, useController } from 'react-hook-form';
import { useState, useRef } from 'react';
import { statusOptions } from '../StatusThreadModal/StatusThreadModal';
import { MenuWrapper } from '../StatusThreadTable/StatusThreadTable';
import { OriginVerticalType, OriginHorizontalType, StatusThreadTypes } from '../../../types/enum';
import { StackStyle } from './style';

type StatusThreadTableRHFType = {
  sx?: SxProps;
  name: string;
};

export const StatusThreadEditItemRHF = ({ sx, name }: StatusThreadTableRHFType) => {
  const methods = useFormContext();

  const {
    field: { value, onChange },
  } = useController({
    name,
    control: methods.control,
  });

  return <StatusThreadEditItem sx={sx} onChange={(e) => onChange(e)} value={value} />;
};

type StatusThreadTableType = {
  sx?: SxProps;
  onChange: (value: number) => void;
  value: number;
};

export const StatusThreadEditItem = ({
  sx,
  onChange,
  value = StatusThreadTypes.open,
}: StatusThreadTableType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setAnchorEl(buttonRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (optionValue: any) => {
    onChange(optionValue);
    handleClose();
  };

  const selectItem = statusOptions?.find((e) => e.value === value);

  return (
    <FormControl sx={sx}>
      <Box onClick={handleClick} sx={{ width: '100%', height: '100%' }}>
        <StackStyle ref={buttonRef}>
          {selectItem?.icons}
          {selectItem?.title}
        </StackStyle>
      </Box>
      <MenuWrapper
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        origin={{
          anchorOrigin: {
            vertical: OriginVerticalType.top,
            horizontal: OriginHorizontalType.left,
          },
          transformOrigin: {
            vertical: OriginVerticalType.top,
            horizontal: OriginHorizontalType.left,
          },
        }}
        value={value}
        handleMenuItemClick={handleMenuItemClick}
      />
    </FormControl>
  );
};
