import { Button, Box, Typography, useTheme } from '@mui/material';
import { PositionedPopper, AutocompliteMember, AvatarCustom } from '../index';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { useCreateOptions } from './hooks/useCreateOptions';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { OutsideClickFlagType } from '../../types/enum';
import { User } from '../../graphql/generated/graphql';

type MemberThredModalType = {
  workspaceId: number;
  name: string;
  isOpen?: boolean;
  folderId?: number;
  projectId?: number;
};

export const MemberThredModal = ({
  workspaceId,
  name,
  isOpen,
  folderId,
  projectId,
}: MemberThredModalType) => {
  const [outOupen, setOutOpen] = useState(false);
  const options = useCreateOptions({ workspaceId, folderId, projectId });

  const theme = useTheme();
  const {
    field: { value },
  } = useController({
    name,
  });

  return (
    <PositionedPopper
      setOutOpen={setOutOpen}
      sxPopper={{
        borderRadius: '6px 0px',
        border: 'none',
      }}
      ClickComponent={
        <Button
          sx={{
            background: outOupen ? theme.palette.backgroundLevel1.main : theme.palette.white.main,
          }}
          variant='popper'
        >
          {value?.title ? (
            <>
              <AvatarCustom user={{ email: value.title, avatar: value.avatar } as User} />
              <Typography
                variant='customDefault'
                fontWeight={400}
                color={theme.palette.black600}
                sx={{ textTransform: 'none' }}
              >
                {value?.title}
              </Typography>
            </>
          ) : (
            <>
              <AccountCircleIcon
                sx={{
                  color: theme.palette.neutralPlainDisabledColor.main,
                  marginRight: '6px',
                }}
              />
              Ответственный
            </>
          )}
        </Button>
      }
      PopperContent={
        <Box className={OutsideClickFlagType.outsideClickFlagDefault}>
          {options && (
            <AutocompliteMember
              options={options}
              name={name}
              sx={{ width: '220px' }}
              isHasPopupIcon
              isHasClearIcon
              placeholder='Ответственный'
              isOpen={isOpen}
            />
          )}
        </Box>
      }
    />
  );
};
