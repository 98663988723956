import { Stack, styled } from '@mui/material';

export const StackStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0 8px',
  height: '32px',
  maxWidth: '293px',
  alignItems: 'center',
  borderRadius: '6px',
  marginBottom: '4px',
  '& .MuiStack-root': {
    visibility: 'hidden',
  },
  '&.select': {
    background: theme.palette.neutralPlainHoverBg.main,
  },
  '&:hover, &.open': {
    background: theme.palette.neutralPlainHoverBg.main,
    '& .MuiStack-root': {
      visibility: 'visible',
    },
  },
}));

export const ActionWrapperStack = styled(Stack)(({ theme }) => ({
  padding: '4px 8px',
  flexDirection: 'row',
  borderRadius: '6px',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.neutralPlainHoverBg.main,
  },
}));
