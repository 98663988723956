import { ModalParametersTypes } from '../../../../types/types';

export enum WORKSPACE_SETTINGS_SCREENS {
  myProfile = 'myProfile',
  users = 'users',
  groups = 'groups',
  workspace = 'workspace',
}

export type TabType = {
  handleCloseModal: () => void;
  modalParameters: ModalParametersTypes;
};
