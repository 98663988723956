import { ReactNode, useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { PopperPlacementType } from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { useClickOutside } from '../../../hooks';
import { PopperStyle, PaperStyle } from './style';
import { SxProps } from '@mui/material';
import { PopperModifiers } from '../../../types/types';

type PositionedPopperType = {
  ClickComponent: ReactNode;
  PopperContent: ReactNode;
  placement?: PopperPlacementType;
  timeout?: number;
  setOutOpen?: (value: boolean) => void;
  hover?: boolean;
  sx?: SxProps;
  outOpen?: boolean;
  isDisableClick?: boolean;
  sxPopper?: SxProps;
  isOutControll?: boolean;
  modifiers?: PopperModifiers;
};

export const PositionedPopper = ({
  ClickComponent,
  PopperContent,
  placement = 'bottom-start',
  timeout = 150,
  setOutOpen,
  isOutControll,
  outOpen,
  hover,
  sx,
  sxPopper,
  modifiers,
  isDisableClick = false,
}: PositionedPopperType) => {
  const triggerRef = useRef<HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isHoveringOverPopper, setIsHoveringOverPopper] = useState(false);

  // Синхронизация внутреннего состояния с внешним при его изменении
  useEffect(() => {
    if (typeof outOpen === 'boolean' && isOutControll) {
      setIsOpen(outOpen);
      setAnchorEl(triggerRef.current);
    }
  }, [outOpen]);

  // Обновление внешнего состояния при изменении внутреннего
  useEffect(() => {
    if (setOutOpen) {
      setOutOpen(isOpen);
    }
  }, [isOpen, setOutOpen]);

  const { refClickOutside } = useClickOutside({
    callBack: () => {
      setIsOpen(false);
      setAnchorEl(null);
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isDisableClick) return;
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setIsOpen(anchorEl ? false : true);
    setOutOpen?.(true);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!hover) return;
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    if (!hover || isHoveringOverPopper) return;
    setIsOpen(false);
    setAnchorEl(null);
  };

  const hanldeOnMouseEnter = () => {
    setIsHoveringOverPopper(true);
  };

  const hanldeOnMouseLeave = () => {
    setIsHoveringOverPopper(false);
  };

  return (
    <Box ref={refClickOutside}>
      <PopperStyle
        modifiers={modifiers}
        open={isOpen}
        anchorEl={anchorEl}
        placement={placement}
        transition
        sx={sx}
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            timeout={timeout}
            onMouseEnter={hanldeOnMouseEnter}
            onMouseLeave={hanldeOnMouseLeave}
          >
            <PaperStyle sx={{ marginTop: '5px', ...sxPopper }}>{PopperContent}</PaperStyle>
          </Fade>
        )}
      </PopperStyle>
      <Box
        ref={triggerRef}
        onClick={handleClick}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {ClickComponent}
      </Box>
    </Box>
  );
};
