import { styled, Stack } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export const StackWrapper = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
}));

export const LockOutlinedIconStyle = styled(LockOutlinedIcon)(({ theme }) => ({
  color: theme.palette.textIcon.main,
  width: '14px',
  height: '14px',
  visibility: 'visible',
}));

export const StackOutlinedWrapperStyle = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
  margin: '2px',
}));
