import { Stack, Typography, useTheme } from '@mui/material';
import { NavigationItem } from './style';
import { WORKSPACE_SETTINGS_SCREENS } from '../../const';
import { navigationItems } from '../../WorkspaceSettings';
import { useWorkspace } from '../../../../../../hooks';
import { conditionalClassNames } from '../../../../../../utils/conditionalClassNames';
import { WorkspaceMemberRole } from '../../../../../../graphql/generated/graphql';

type SettingsNavigationType = {
  currentSettingsScreen: WORKSPACE_SETTINGS_SCREENS;
  handleSettingsScreen: (screen: WORKSPACE_SETTINGS_SCREENS) => void;
};
export const SettingsNavigation = ({
  handleSettingsScreen,
  currentSettingsScreen,
}: SettingsNavigationType) => {
  return (
    <Stack width='250px' p='24px 16px'>
      {navigationItems.map((item) => (
        <SettingsNavigationItem
          title={item.title}
          key={item.title}
          screenName={item.screenName}
          isSelect={currentSettingsScreen === item.screenName}
          onClick={() => handleSettingsScreen(item.screenName)}
        />
      ))}
    </Stack>
  );
};

type SettingsNavigationItemType = {
  title: string;
  onClick: () => void;
  isSelect: boolean;
  screenName: WORKSPACE_SETTINGS_SCREENS;
};
const SettingsNavigationItem = ({
  title,
  onClick,
  isSelect,
  screenName,
}: SettingsNavigationItemType) => {
  const theme = useTheme();
  const classes = conditionalClassNames({
    isSelect: isSelect,
  });
  const { currentWorkspace } = useWorkspace();
  const myRole = currentWorkspace?.myRole as WorkspaceMemberRole;
  const hasNotPermission = {
    [WorkspaceMemberRole.Creator]: [],
    [WorkspaceMemberRole.Admin]: [WORKSPACE_SETTINGS_SCREENS.workspace],
    [WorkspaceMemberRole.Member]: [WORKSPACE_SETTINGS_SCREENS.workspace],
  };
  const hasPermission = !hasNotPermission[myRole]?.find((item) => item === screenName);
  return (
    <NavigationItem onClick={hasPermission ? onClick : undefined} className={classes}>
      <Typography
        fontWeight={500}
        color={
          !hasPermission
            ? theme.palette.neutralPlainDisabledColor.main
            : isSelect
            ? theme.palette.black900
            : theme.palette.textTertiary.main
        }
        fontSize={14}
      >
        {title}
      </Typography>
    </NavigationItem>
  );
};
