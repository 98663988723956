import { useWorkspace } from '../../../../../../../../../hooks';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useCancelInviteToWorkspaceMutation } from '../../../../../../../../../graphql/generated/graphql';
import { conditionalClassNames } from '../../../../../../../../../utils/conditionalClassNames';
import { MemberRoleStack, MemberWrapper } from '../WorkspaceMembers/style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ActionWrapperStack } from '../../../Groups/components/GroupsColumn/components/GroupsColumnItem/style';
import { PositionedPopper } from '../../../../../../../../uiComponents';
import { WORKSPACE } from '../../../../../../../../../graphql/queries/workspace';

export const WorkspaceInvitations = () => {
  const { currentWorkspace } = useWorkspace();
  return (
    <Stack mt='48px'>
      <Typography variant='h3' mb='24px'>
        Приглашения
      </Typography>
      {currentWorkspace?.invites.map((invite, index) => (
        <InvitationOfWorkspace key={index} id={invite.id} email={invite.email} />
      ))}
    </Stack>
  );
};

type InvitationOfWorkspaceType = {
  email: string;
  id: number;
};

export const InvitationOfWorkspace = ({ email, id }: InvitationOfWorkspaceType) => {
  const [useCancelInviteToWorkspace] = useCancelInviteToWorkspaceMutation({
    variables: {
      cancelInviteToWorkspaceId: id,
    },
    refetchQueries: [WORKSPACE],
  });
  const memberRoleClasses = conditionalClassNames({
    hasAccess: true,
  });

  const handleCancelInvite = () => useCancelInviteToWorkspace();

  return (
    <MemberWrapper flexDirection='row' alignItems='center'>
      <Stack flex={1}>
        <Typography fontWeight='500' color='black600'>
          {email}
        </Typography>
      </Stack>
      <PositionedPopper
        placement='bottom-end'
        ClickComponent={
          <MemberRoleStack className={memberRoleClasses}>
            <Typography color='black600' fontWeight='500'>
              Приглашен
            </Typography>
            <KeyboardArrowDownIcon sx={{ color: 'black600', marginLeft: '6px' }} />
          </MemberRoleStack>
        }
        PopperContent={
          <Stack
            sx={{
              width: '160px',
              borderRadius: '8px',
            }}
          >
            <ActionWrapperStack onClick={handleCancelInvite}>
              <Typography color='black600.main' variant='h4' ml='8px'>
                Отменить
              </Typography>
            </ActionWrapperStack>
          </Stack>
        }
      />
    </MemberWrapper>
  );
};
