import { styled, LinearProgress, Typography } from '@mui/material';

export const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  width: '160px',
  borderRadius: '6px',
  backgroundColor: theme.palette.backgroundLevel1.main,
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.black600,
  },
}));

export const TypographyStyle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  marginLeft: '16px',
  color: theme.palette.black600,
}));
