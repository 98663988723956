import { WorkspaceMemberRole } from '../graphql/generated/graphql';
import { useWorkspaceContext, useAuthContext } from '../context/index';

export const useDefineAcess = ({ authorId }: { authorId?: number | null }) => {
  const { myWorkspaceRole } = useWorkspaceContext();
  const { currentUser } = useAuthContext();

  const isProjectAcess =
    myWorkspaceRole === WorkspaceMemberRole.Admin ||
    myWorkspaceRole === WorkspaceMemberRole.Creator;

  const isEditFolderAcess =
    myWorkspaceRole === WorkspaceMemberRole.Admin ||
    myWorkspaceRole === WorkspaceMemberRole.Creator ||
    (myWorkspaceRole === WorkspaceMemberRole.Member && authorId === currentUser?.id);

  const isEditWorkspaceAcess = myWorkspaceRole === WorkspaceMemberRole.Creator;

  return { isProjectAcess, isEditFolderAcess, isEditWorkspaceAcess };
};
