import {
  useClickCellPopup,
  useClickOutside,
  useWorkspace,
} from '../../../../../../../../../../../hooks';
import {
  useRemoveUserGroupMutation,
  useUpdateUserGroupMutation,
} from '../../../../../../../../../../../graphql/generated/graphql';
import React, { useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Popover, Stack, Typography, useTheme } from '@mui/material';
import { TextFieldStyle } from '../../style';
import { SizeOptions } from '../../../../../../../../../../../types/enum';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateUserGroupSchema } from '../../../../../../../../../../../validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionWrapperStack, StackStyle } from './style';
import { USER_GROUPS } from '../../../../../../../../../../../graphql/queries/userGroups.gql';
import { conditionalClassNames } from '../../../../../../../../../../../utils/conditionalClassNames';

type GroupsColumnItemType = {
  title: string;
  id: number;
  handleSelectUserGroup: (id: number) => void;
  select: boolean;
  isHasPermission: boolean;
};
export const GroupsColumnItem = ({
  title,
  id,
  handleSelectUserGroup,
  select,
  isHasPermission,
}: GroupsColumnItemType) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const theme = useTheme();
  const { currentWorkspace } = useWorkspace();
  const { anchorEl, open, handleClick, handleClose, refClickOutside } = useClickCellPopup({});
  const [useRemoveUserGroup] = useRemoveUserGroupMutation();
  const methods = useForm<any>({
    resolver: yupResolver(CreateUserGroupSchema),
    defaultValues: {
      title: title,
    },
  });
  const [useUpdateUserGroup] = useUpdateUserGroupMutation({
    refetchQueries: [USER_GROUPS],
  });
  const handleRemoveGroup = () => {
    handleSelectUserGroup(0);
    useRemoveUserGroup({
      variables: {
        removeUserGroupId: id,
      },
      refetchQueries: [USER_GROUPS],
    });
  };

  const handleEditMode = () => {
    setIsEdit((prevState) => !prevState);
  };
  const { getValues, trigger } = methods;
  const { refClickOutside: refClickOutsideInput } = useClickOutside({
    callBack: () => {
      const currentValues = getValues();
      const validateAndSubmit = async () => {
        const isValid = await trigger();
        if (isValid) {
          useUpdateUserGroup({
            variables: {
              updateUserGroupInput: {
                title: currentValues.title,
                workspaceId: currentWorkspace!.id,
                id: id,
              },
            },
          });
        }
      };
      handleEditMode();
      validateAndSubmit();
    },
  });
  const groupActions = [
    {
      icon: EditOutlinedIcon,
      title: 'Переименовать',
      onClick: handleEditMode,
    },
    {
      icon: DeleteOutlineOutlinedIcon,
      title: 'Удалить',
      onClick: handleRemoveGroup,
    },
  ];
  const classes = conditionalClassNames({
    select: select,
    open: open,
  });
  return (
    <Stack flexDirection='row' width='100%'>
      {!isEdit && (
        <StackStyle className={classes} onClick={() => handleSelectUserGroup(id)}>
          <Typography
            variant='h4'
            fontWeight='500'
            color='black600'
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {title}
          </Typography>
          {isHasPermission && (
            <Stack
              ref={refClickOutside}
              onClick={handleClick}
              alignItems='center'
              sx={{ cursor: 'pointer' }}
            >
              <MoreVertIcon />
            </Stack>
          )}
        </StackStyle>
      )}
      {isEdit && (
        <FormProvider {...methods}>
          <Box ref={refClickOutsideInput} width='100%' mb='4px'>
            <TextFieldStyle
              name='title'
              type='text'
              placeholder='Workspace Name'
              size={SizeOptions.small}
              InputProps={{
                endAdornment: (
                  <CancelIcon
                    onClick={handleEditMode}
                    sx={{
                      cursor: 'pointer',
                      color: theme.palette.textIcon.main,
                    }}
                  />
                ),
              }}
            />
          </Box>
        </FormProvider>
      )}
      <Popover
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '8px',
          },
          marginTop: '5px',
          border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Stack
          sx={{
            width: '220px',
          }}
        >
          {groupActions.map((action, index) => (
            <ActionWrapperStack key={index} onClick={action.onClick}>
              <action.icon sx={{ color: theme.palette.textIcon.main }} />
              <Typography color='black600' variant='h4' ml='8px'>
                {action.title}
              </Typography>
            </ActionWrapperStack>
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
};
