import { styled, Paper, Autocomplete, TextField } from '@mui/material';

export const AutocompleteStyle = styled(Autocomplete)(() => ({
  zIndex: '9999',
  paddingRight: '0px',
  boxSizing: 'border-box',
  '& .MuiInputBase-root': { borderRadius: '8px 8px 0px 0px' },
  input: {
    width: '100%',
  },
}));

export const TextFieldStyle = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiAutocomplete-input': {
    paddingLeft: '0px',
  },
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': { paddingLeft: '8px' },
  '& .MuiInputBase-root, & .MuiOutlinedInput-root': {
    paddingRight: '0px !important',
    border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  },
  '& .MuiAvatar-root': {
    marginRight: '6px',
  },
  '& .MuiInputBase-input': {
    padding: '0px !important',
  },
  '&:hover': {
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
  },
}));

export const PaperStyle = styled(Paper)(({ theme }) => ({
  boxShadow: theme.palette.boxShadowPopper.main,
  borderRadius: '0px 0px 6px 6px',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  },
  '& .MuiAutocomplete-listbox': {
    padding: '0px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '142%',
  },
  '& .MuiAutocomplete-option': {
    padding: '4px 8px !important',
  },
}));
