import { StyledTableCell, StyledTableRow } from '../../style';

type EmptyRowType = {
  countRow?: number;
};

export const EmptyRow = ({ countRow = 6 }: EmptyRowType) => {
  return (
    <StyledTableRow>
      {[...Array(countRow)]?.map((_, index) => {
        return <StyledTableCell sx={{ border: 'none !important' }} key={index}></StyledTableCell>;
      })}
    </StyledTableRow>
  );
};
