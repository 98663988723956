import { useApolloClient } from '@apollo/client';
import { THREAD_HISTORY } from '../../../../graphql/queries/threadHistory.gql';
import {
  useThreadHistoryQuery,
  useNewThreadActivitySubscription,
  LogsHistoryResponseEntity,
} from '../../../../graphql/generated/graphql';

type UpdateThreadHistoryType = {
  threadId?: number;
};

export const useUpdateThreadHistory = ({ threadId }: UpdateThreadHistoryType) => {
  const client = useApolloClient();

  const { data } = useThreadHistoryQuery({
    variables: {
      id: Number(threadId),
    },
    skip: !threadId,
    fetchPolicy: 'cache-and-network',
  });

  const updateThreadCache = (newActionsHistory: Array<LogsHistoryResponseEntity>) => {
    const query = {
      query: THREAD_HISTORY,
      variables: {
        id: Number(threadId),
      },
    };

    const cacheData = client.readQuery(query);

    if (cacheData && cacheData.thread && cacheData.thread.history) {
      const updatedActionsHistory = [...cacheData.thread.history];
      newActionsHistory.forEach((newItem) => {
        const lastIndex = updatedActionsHistory.length - 1;
        if (lastIndex >= 0 && updatedActionsHistory[lastIndex].user.id === newItem.user.id) {
          updatedActionsHistory[lastIndex] = {
            ...updatedActionsHistory[lastIndex],
            maxCreatedAt: newItem.maxCreatedAt,
            logs: [...updatedActionsHistory[lastIndex].logs, ...(newItem.logs || [])],
          };
        } else {
          updatedActionsHistory.push(newItem);
        }
      });

      client.writeQuery({
        ...query,
        data: {
          thread: {
            ...cacheData.thread,
            history: updatedActionsHistory,
            __typename: 'Thread',
          },
        },
      });
    }
  };

  useNewThreadActivitySubscription({
    variables: {
      threadId: Number(threadId),
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const newMessage = subscriptionData.data?.newThreadActivity?.history;
      if (newMessage) {
        updateThreadCache(newMessage as Array<LogsHistoryResponseEntity>);
      }
    },
    fetchPolicy: 'no-cache',
  });

  return { data };
};
