import { StackUserStyle, TypographyDotStyle } from './style';
import { userNameCalc } from '../../../../utils/index';
import { Stack, Typography, useTheme } from '@mui/material';
import { LogsHistoryResponseEntity, User } from '../../../../graphql/generated/graphql';
import { TimeMessageMemo } from '../../TimeMessage/TimeMessage';
import { AvatarCustom } from '../../../AvatarCustom/AvatarCustom';

type ItemType = {
  item?: LogsHistoryResponseEntity;
};

export const Item = ({ item }: ItemType) => {
  const user = item?.user;

  const theme = useTheme();

  return (
    <Stack
      sx={{
        padding: ' 8px 8px 16px 8px',
      }}
      direction={'row'}
    >
      <Stack sx={{ marginRight: '14px' }}>
        <AvatarCustom
          user={user as User}
          sx={{ height: '32px', width: '32px', marginTop: '6px', marginRight: '0px !important' }}
        />
      </Stack>
      <StackUserStyle>
        <Stack alignItems={'center'} flexDirection={'row'}>
          <Stack direction={'row'}>
            <Typography color='textPrimary.main' variant='h5'>
              {userNameCalc({
                email: user?.email,
                firstName: user?.firstName,
                lastName: user?.lastName,
              })}
            </Typography>
            <TypographyDotStyle>&middot;</TypographyDotStyle>
            <Typography variant='h5' fontWeight={500} color='textTertiary.main'>
              {user?.karmaStatistics?.karma} кармы
            </Typography>
            <TypographyDotStyle>&middot;</TypographyDotStyle>
            <TimeMessageMemo time={item?.maxCreatedAt} />
          </Stack>
        </Stack>
        <Stack sx={{ marginTop: '4px' }}>
          {item?.logs?.map((e, index: number) => {
            return (
              <Typography
                key={index}
                sx={{
                  lineHeight: '142%',
                  '& b': {
                    color: theme.palette.black600,
                    fontWeight: '500',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: e.title }}
              />
            );
          })}
        </Stack>
      </StackUserStyle>
    </Stack>
  );
};
