import { useState, ChangeEvent, useRef, useEffect, useCallback } from 'react';
import { EditindnameDataType, FileWithPreview, AttachmentFile } from '../../../types/types';
import {
  useRemoveFileMutation,
  useUploadFilesMutation,
  useRemoveFilesMutation,
} from '../../../graphql/generated/graphql';
import { THREAD } from '../../../graphql/queries/thread.gql';
import { downloadFile } from '../../../utils';

import { useFileSizeAlert } from '../../../hooks/useFileSizeAlert';

type ThreadType = {
  outChange: (value: AttachmentFile[]) => void;
  value: Array<AttachmentFile>;
  threadId?: number;
};

export const useThread = ({ value, threadId }: ThreadType) => {
  const [files, setFiles] = useState<Array<AttachmentFile>>(value);

  const [uploadFilesMutation, { loading: loadingFiles }] = useUploadFilesMutation({
    refetchQueries: [THREAD],
  });

  const [removeFileMutation] = useRemoveFileMutation({
    refetchQueries: [THREAD],
  });

  const [removeFilesMutation] = useRemoveFilesMutation({
    refetchQueries: [THREAD],
  });

  useEffect(() => {
    setFiles(value);
  }, [value]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };
  const { fileSizeAlert } = useFileSizeAlert();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (fileSizeAlert(event)) return;
      uploadFilesMutation({
        variables: {
          data: { threadId: Number(threadId) },
          files: event.target.files,
        },
      });
    }
  };

  const handleRemoveFile = (index: number) => {
    removeFileMutation({
      variables: {
        id: files[index].id,
      },
    });
  };

  const handleDownloadFile = useCallback((fileObject: FileWithPreview | AttachmentFile) => {
    if ('path' in fileObject) {
      downloadFile({ path: fileObject.path, fileName: fileObject.fileName });
    }
  }, []);

  const [editindnameData, setEditindnameData] = useState<EditindnameDataType>({
    editingName: false,
    index: null,
    newName: '',
  });

  const startEditing = (index: number) => {
    setEditindnameData({
      editingName: true,
      index: index,
      newName: files[index]?.fileName,
      fileId: files[index]?.id,
    });
  };

  const handleRemoveAllFiles = () => {
    removeFilesMutation({
      variables: {
        ids: files?.map((e) => e.id),
      },
    });
  };

  const handleDownloadAllFiles = useCallback(() => {
    files.forEach((fileObject) => {
      downloadFile({ path: fileObject.path, fileName: fileObject.fileName });
    });
  }, [files]);

  const handleCloseModal = () => {
    setEditindnameData({
      editingName: false,
      index: null,
      newName: '',
    });
  };

  return {
    setEditindnameData,
    setFiles,
    editindnameData,
    handleCloseModal,
    handleButtonClick,
    files,
    fileInputRef,
    handleFileChange,
    handleDownloadAllFiles,
    handleRemoveAllFiles,
    startEditing,
    handleDownloadFile,
    handleRemoveFile,
    loadingFiles,
  };
};
