import { styled, Stack } from '@mui/material';

export const StackStyle = styled(Stack)(({ theme }) => ({
  borderRadius: '6px',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  padding: '8px',
}));

export const StackButtonStyle = styled(Stack)(() => ({
  marginTop: '8px',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}));
