import { Stack, Typography, Button } from '@mui/material';
import { CloseButton } from '../../../index';
import { StackStyle, StackMainStyle } from './style';
import { ModalParametersTypes } from '../../../../types/types';
import { useRemoveThreadMutation } from '../../../../graphql/generated/graphql';
import { FOLDER } from '../../../../graphql/queries/folder.gql';
import { THREADS_AND_COUNT } from '../../../../graphql/queries/threadsAndCount.gql';

type GoalDeleteModalType = {
  modalParameters: ModalParametersTypes;
  handleCloseModal: () => void;
};

export const ThreadDeleteModal = ({ modalParameters, handleCloseModal }: GoalDeleteModalType) => {
  const [removeThreadMutation] = useRemoveThreadMutation({
    variables: {
      id: Number(modalParameters.threadId),
    },
    refetchQueries: [FOLDER, THREADS_AND_COUNT],
    onCompleted: () => {
      modalParameters.removeThreadCallback?.();
    },
  });

  const handleRemove = () => {
    removeThreadMutation();
    handleCloseModal();
  };

  return (
    <Stack>
      <Stack>
        <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant='h3'>Удаление треда</Typography>

          <CloseButton onClick={handleCloseModal} />
        </Stack>
      </Stack>

      <Stack>
        <StackMainStyle>
          <Stack direction='row' alignItems={'center'}>
            <Typography variant='customDefault' fontWeight={400}>
              Вы действительно хотите удалить тред и все его содержимое?
            </Typography>
          </Stack>
        </StackMainStyle>

        <StackStyle>
          <Button variant='cancel' onClick={handleCloseModal} sx={{ marginLeft: '8px' }}>
            Отмена
          </Button>
          <Button
            variant='confirm'
            type='submit'
            onClick={handleRemove}
            sx={{ marginLeft: '8px', fontWeight: '500' }}
          >
            Удалить
          </Button>
        </StackStyle>
      </Stack>
    </Stack>
  );
};
