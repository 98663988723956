import { Stack, Divider, useTheme, Typography, Button } from '@mui/material';
import {
  CustomSwitch,
  TextFieldRHF,
  FormFlex,
  AutocompliteUsers,
  CloseButton,
} from '../../../index';
import { StackStyle } from './style';
import LockIcon from '@mui/icons-material/Lock';
import { useForm, SubmitHandler, FormProvider, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProjectSchema } from '../../../../validation';
import { ModalParametersTypes, MembersType } from '../../../../types/types';
import {
  User,
  UserGroup,
  useUserGroupsQuery,
  useUsersQuery,
} from '../../../../graphql/generated/graphql';
import { MemberTypes } from '../../../../types/enum';
import { createTextUserAndGroup } from '../../../../utils/index';

type UserType = { title: string; value: number; type: MemberTypes };

type FormData = {
  title: string;
  visibilityStatus: boolean;
  users?: Array<UserType> | null;
};

type ProjectModalType = {
  modalParameters: ModalParametersTypes;
  handleCloseModal: () => void;
};

export const ProjectModal = ({ modalParameters, handleCloseModal }: ProjectModalType) => {
  const theme = useTheme();
  const createProject = modalParameters?.createProject;
  const workspaceId = modalParameters?.workspaceId;
  const workspaceName = modalParameters?.workspaceName;

  const { data: usersGroup } = useUserGroupsQuery({
    variables: {
      data: {
        workspaceId: Number(workspaceId),
      },
    },
  });

  const { data: users } = useUsersQuery({
    variables: {
      options: {
        workspaceId: Number(workspaceId),
      },
    },
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(ProjectSchema) as Resolver<FormData>,
    defaultValues: {
      title: '',
      visibilityStatus: true,
      users: [],
    },
  });

  const { watch, handleSubmit } = methods;
  const isAccess = watch('visibilityStatus');

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const transformedArray =
      ((data?.users as Array<UserType>) || [])
        .map((item) => {
          if (item?.type === MemberTypes.user) {
            return { userId: item?.value, userGroupId: null };
          } else if (item?.type === MemberTypes.groups) {
            return { userGroupId: item?.value, userId: null };
          }
        })
        .filter((item) => item) || [];

    createProject?.({
      title: data.title,
      visibilityStatus: data.visibilityStatus,
      members: transformedArray as MembersType,
    });
    handleCloseModal();
  };

  const createOptionsUsers = () => {
    const groupsOptions =
      usersGroup?.userGroups?.map((e, index) => {
        return {
          value: e.id,
          title: createTextUserAndGroup({ userGroup: e as UserGroup }),
          type: MemberTypes.groups,
          last: usersGroup?.userGroups?.length === index + 1,
          avatar: e.avatar,
          users: e.users?.map((e) => {
            return {
              value: e.id,
              title: createTextUserAndGroup({ user: e as User }),
              avatar: e.avatar,
            };
          }),
        };
      }) || [];
    const usersOptions =
      users?.users?.map((e) => {
        return {
          value: e.id,
          title: createTextUserAndGroup({ user: e as User }),
          type: MemberTypes.user,
          avatar: e.avatar,
        };
      }) || [];

    return [...groupsOptions, ...usersOptions];
  };

  const options = createOptionsUsers();

  return (
    <FormProvider {...methods}>
      <FormFlex onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant='h3'>Создание проекта</Typography>
            <CloseButton onClick={handleCloseModal} />
          </Stack>
          <Divider />
        </Stack>
        <TextFieldRHF
          fullWidth
          placeholder='Введите название'
          minRows={1}
          name='title'
          type='text'
          multiline
        />
        <Stack>
          <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction='row' alignItems={'center'}>
              <LockIcon
                sx={{
                  marginRight: '8px',
                  color: theme.palette.neutral500.main,
                }}
              />
              <Typography variant='customDefault' fontWeight={400}>
                Полный доступ для участников {workspaceName}
              </Typography>
            </Stack>

            <CustomSwitch name='visibilityStatus' />
          </Stack>
          {!isAccess && (
            <Stack direction='row' sx={{ marginTop: '16px' }} alignItems={'center'}>
              <AutocompliteUsers options={options} name='users' />
            </Stack>
          )}
          <Divider sx={{ paddingTop: '16px' }} />
          <StackStyle>
            <Button variant='cancel' sx={{ marginLeft: '8px' }} onClick={handleCloseModal}>
              Отменить
            </Button>
            <Button
              variant='confirm'
              type='submit'
              sx={{
                marginLeft: '8px',
                fontWeight: '500',
              }}
            >
              Создать проект
            </Button>
          </StackStyle>
        </Stack>
      </FormFlex>
    </FormProvider>
  );
};
