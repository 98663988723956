import { StyledTableCellDrag, StyledTableRow, StyledTableCell } from '../../style';

import { NameAndStatusCellCreate } from '../ThredRow/NameAndStatusCellCreate/NameAndStatusCellCreate';
import { TypeTableRow } from '../../../../types/enum';

type TredRowType = {
  emptyCellWidth: string;
  titleCellWidth: string;
  setCreateThread: (value: boolean) => void;
  goalId?: number;
  type?: TypeTableRow;
};

export const ThredRowAdd = ({
  emptyCellWidth,
  titleCellWidth,
  setCreateThread,
  goalId,
  type,
}: TredRowType) => {
  return (
    <StyledTableRow>
      <StyledTableCellDrag width={emptyCellWidth}></StyledTableCellDrag>
      <NameAndStatusCellCreate
        goalId={goalId}
        titleCellWidth={titleCellWidth}
        setCreateThread={setCreateThread}
        type={type}
      />
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCellDrag width={emptyCellWidth}></StyledTableCellDrag>
    </StyledTableRow>
  );
};
