import { VisibilityStatusEnum } from '../../../../../graphql/generated/graphql';
import { LockOutlinedIconStyle, StackOutlinedWrapperStyle, StackWrapper } from './style';
import { IconButtonStyle } from '../../../../index';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type ShowRightIconsType = {
  visibilityStatus: VisibilityStatusEnum;
  isHover: boolean;
  isEditable?: boolean;
};

export const ShowRightIcons = ({ visibilityStatus, isHover, isEditable }: ShowRightIconsType) => {
  const isStatusTeam = visibilityStatus === VisibilityStatusEnum.Team;
  if (isEditable) return null;
  return (
    <StackWrapper>
      {isStatusTeam && !isHover && (
        <StackOutlinedWrapperStyle>
          <LockOutlinedIconStyle />
        </StackOutlinedWrapperStyle>
      )}
      <IconButtonStyle
        sx={
          isStatusTeam
            ? { display: isHover ? 'flex' : 'none' }
            : { visibility: isHover ? 'visable' : 'hidden' }
        }
      >
        <MoreVertIcon />
      </IconButtonStyle>
    </StackWrapper>
  );
};
