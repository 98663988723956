import { styled, Stack, Typography, IconButton } from '@mui/material';

export const TypographyStyle = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  lineHeight: '133%',
  fontWeight: '500',
}));

export const StackWrapperStyle = styled(Stack)(() => ({
  padding: '8px 4px',
  maxHeight: '100%',
  overflowY: 'auto',
}));

export const StackItemStyle = styled(Stack)(() => ({
  padding: '4px',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '8px 0px',
}));

export const StackImageStyle = styled(Stack)(({ theme }) => ({
  minWidth: '48px',
  width: '48px',
  height: '48px',
  marginRight: '12px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  backgroundColor: theme.palette.backgroundLevel1.main,
}));

export const IconButtonStyle = styled(IconButton)(() => ({
  width: '32px',
  height: '32px',
  marginLeft: '12px',
}));
