import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { StyledTableCellDrag, StyledTableRow } from '../../style';
import { Stack, useTheme } from '@mui/material';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { NameAndStatusCell } from './NameAndStatusCell/NameAndStatusCell';
import { useUpdateThreadMutation } from '../../../../graphql/generated/graphql';
import { THREADS_AND_COUNT } from '../../../../graphql/queries/threadsAndCount.gql';
import { Dealines } from './Deadlines/Deadlines';
import { ThreadTableType } from '../../../../types/types';
import { useWorkspaceContext } from '../../../../context';
import { ProjectSelect } from './ProjectSelect/ProjectSelect';
import { ResponsibleCell } from '../../Cell/ResponsibleCell/ResponsibleCell';
import { ResponsibleCellTyle, TypeTableRow } from '../../../../types/enum';
import { FOLDER } from '../../../../graphql/queries/folder.gql';
import { RemoveCell } from '../../Cell/RemoveCell/RemoveCell';

type ThredRowType = {
  emptyCellWidth: string;
  titleCellWidth: string;
  item: ThreadTableType;
  index: number;
  type?: TypeTableRow;
};

export const ThredRow = ({
  emptyCellWidth,
  titleCellWidth,
  item,
  index,
  type = TypeTableRow.thread,
}: ThredRowType) => {
  const theme = useTheme();

  const [updateThreadMutation] = useUpdateThreadMutation({
    refetchQueries: type === TypeTableRow.thread ? [THREADS_AND_COUNT] : [FOLDER],
  });

  const { workspaceId } = useWorkspaceContext();

  return (
    <Draggable key={`${item.id}`} draggableId={`${item.id}`} index={index}>
      {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        return (
          <StyledTableRow
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            sx={{
              ...draggableProvided.draggableProps.style,
              background: snapshot.isDragging ? theme.palette.white.main : '',
              width: '100%',
            }}
          >
            <StyledTableCellDrag
              sx={{
                borderBottom: 'none',
                minWidth: emptyCellWidth,
              }}
              padding='none'
            >
              <Stack justifyContent={'center'} alignItems={'center'}>
                <div {...draggableProvided.dragHandleProps}>
                  <DragIndicatorOutlinedIcon
                    sx={{
                      visibility: 'hidden',
                    }}
                  />
                </div>
              </Stack>
            </StyledTableCellDrag>
            <NameAndStatusCell
              titleCellWidth={titleCellWidth}
              title={item.title}
              statusId={item.statusId}
              threadId={item.id}
              updateThreadMutation={updateThreadMutation}
            />
            <ResponsibleCell
              responsible={item?.responsible}
              responsibleUserGroup={item?.responsibleUserGroup}
              updateThreadMutation={updateThreadMutation}
              entityId={item.id}
              projectId={item.path?.project?.id}
              folderId={item.path?.folder?.id}
              type={ResponsibleCellTyle.thread}
            />
            <Dealines
              startDate={item?.startDate}
              endDate={item?.endDate}
              threadId={item.id}
              updateThreadMutation={updateThreadMutation}
            />
            <ProjectSelect
              workspaceId={workspaceId}
              updateThreadMutation={updateThreadMutation}
              threadId={item.id}
              path={item.path}
            />

            <RemoveCell emptyCellWidth={emptyCellWidth} threadId={item.id} />
          </StyledTableRow>
        );
      }}
    </Draggable>
  );
};
