import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Stack, Typography, useTheme } from '@mui/material';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import utc from 'dayjs/plugin/utc';
import { CalendarIcons } from '../../icons';
import { StackStyle, StackCleanStyle } from './style';
import { OutsideClickFlagType } from '../../../types/enum';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { getFormattedDateWiev } from '../../../utils/index';

type PickerValueType = {
  startValue: dayjs.Dayjs | null;
  endValue: dayjs.Dayjs | null;
};

type DatePickerWrapperType = {
  pickerValue: PickerValueType;
  setPickerValue: React.Dispatch<React.SetStateAction<PickerValueType>>;
};

export const DatePickerWrapper = ({ pickerValue, setPickerValue }: DatePickerWrapperType) => {
  dayjs.locale('ru');
  dayjs.extend(utc);

  enum TypeValue {
    start = 'start',
    end = 'end',
  }

  const [type, setType] = useState(TypeValue.start);

  const theme = useTheme();

  const isInit = useRef(true);

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      return;
    }
    setType(TypeValue.end);
  }, [pickerValue.startValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
      <Stack className={OutsideClickFlagType.outsideClickFlagDefault}>
        <Stack
          direction='row'
          sx={{
            borderRadius: `6px 6px 0px 0px`,
          }}
        >
          <StackStyle
            sx={{
              borderRight: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
              borderRadius: '6px 0px 0px',
              backgroundColor: type === TypeValue.start ? theme.palette.backgroundLevel1.main : '',
            }}
            onClick={() => {
              setType(TypeValue.start);
            }}
          >
            <CalendarIcons sx={{ marginRight: '8px' }} />
            {pickerValue.startValue ? (
              <Typography sx={{ color: 'textPrimary.main' }}>
                {getFormattedDateWiev(dayjs(pickerValue?.startValue), 'DD MMM')}
              </Typography>
            ) : (
              'Дата начала'
            )}
          </StackStyle>
          <StackStyle
            sx={{
              backgroundColor: type === TypeValue.end ? theme.palette.backgroundLevel1.main : '',
            }}
            onClick={() => {
              setType(TypeValue.end);
            }}
          >
            <CalendarIcons sx={{ marginRight: '8px' }} />
            {pickerValue.endValue ? (
              <Typography sx={{ color: 'textPrimary.main' }}>
                {getFormattedDateWiev(dayjs(pickerValue?.endValue), 'DD MMM')}
              </Typography>
            ) : (
              'Дата заверш...'
            )}
          </StackStyle>
        </Stack>
        {type === TypeValue.start ? (
          <DateCalendar
            value={pickerValue.startValue}
            sx={{
              '& .MuiPickersCalendarHeader-label': {
                textTransform: 'capitalize	',
              },
            }}
            shouldDisableDate={(date) => {
              // Check that startValue is selected and the date is earlier than endValue
              return pickerValue.endValue
                ? dayjs(date).isAfter(dayjs(pickerValue.endValue), 'day')
                : false;
            }}
            onChange={(e) =>
              setPickerValue({
                startValue: e,
                endValue: pickerValue.endValue,
              })
            }
          />
        ) : (
          <DateCalendar
            value={pickerValue.endValue}
            sx={{
              '& .MuiPickersCalendarHeader-label': {
                textTransform: 'capitalize	',
              },
            }}
            shouldDisableDate={(date) => {
              // Check that startValue is selected and the date is earlier than endValue
              return pickerValue.startValue
                ? dayjs(date).isBefore(dayjs(pickerValue.startValue), 'day')
                : false;
            }}
            onChange={(e) => {
              setPickerValue((prev) => {
                return {
                  startValue: prev.startValue,
                  endValue: e,
                };
              });
            }}
          />
        )}
        <Stack
          sx={{
            padding: '4px 0px',
            borderTop: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
          }}
        >
          <StackCleanStyle
            onClick={() => {
              setPickerValue({
                endValue: null,
                startValue: null,
              });
            }}
          >
            <CloseRoundedIcon />
            <Typography ml={'8px'} sx={{ color: 'black600' }}>
              Очистить
            </Typography>
          </StackCleanStyle>
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
};
