import { styled, Stack, Typography } from '@mui/material';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';

export const StackHeaderStyle = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'row',
}));

export const StackUserStyle = styled(Stack)(() => ({
  width: '100%',
  overflow: 'hidden',
  padding: '4px 0px',
}));

export const TypographyHeaderStyle = styled(Typography)(() => ({
  height: '24px',
  alignItems: 'center',
  flexDirection: 'row',
}));

export const StackReactionStyle = styled(Stack)(({ theme }) => ({
  width: '46px',
  height: '24px',
  borderRadius: '24px',
  marginLeft: '4px',
  backgroundColor: theme.palette.neutralOutlinedBorder.main,
  padding: '0px 8px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.neutral200.main,
    '& .MuiTypography-root': {
      color: `${theme.palette.textPrimary.main} !important`,
    },
  },
  flexDirection: 'row',
  alignItems: 'center',
}));

export const TypographyDotStyle = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '600',
  padding: '0px 4px',
}));

export const AddReactionOutlinedIconStyle = styled(AddReactionOutlinedIcon)(() => ({
  color: 'black600',
  width: '16px',
  height: '16px',
  marginRight: '6px',
}));

export const StackWrapperIconsStyle = styled(Stack)(() => ({
  padding: '4px',
  maxWidth: '136px',
  flexWrap: 'wrap',
  flexDirection: 'row',
}));
