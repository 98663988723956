import { styled, IconButton } from '@mui/material';

export const IconButtonStyle = styled(IconButton)`
  border-radius: 6px;
  padding: 0px;
  margin-left: auto;
  &.hiden {
    visibility: hidden;
  }
`;

export const IconButtonStyle2 = styled(IconButton)`
  border-radius: 6px;
`;
