import { useEffect, useState } from 'react';
import { List, Collapse } from '@mui/material';

import { FoldersType } from '../../../../../types/types';
import { EditableFolderLine } from './EditableFolderLine/EditableFolderLine';
import { useWorkspaceContext } from '../../../../../context';
import { VisibilityStatusEnum } from '../../../../../graphql/generated/graphql';

type CollapseWrapperType = {
  isOpen: boolean;
  isAddFolder: boolean;
  setIsAddFolder: (value: boolean) => void;
  projectId: number;
  folders?: FoldersType;
  visibilityStatus: VisibilityStatusEnum;
};

export const CollapseWrapper = ({
  isOpen,
  isAddFolder,
  setIsAddFolder,
  projectId,
  folders,
  visibilityStatus,
}: CollapseWrapperType) => {
  return (
    <Collapse in={isOpen} timeout='auto' unmountOnExit>
      <List component='div' disablePadding>
        <EditableFolderLine
          title=''
          projectId={projectId}
          isEditable={isAddFolder}
          setIsEditable={setIsAddFolder}
          isAddFolder
          visibilityStatus={visibilityStatus}
        />

        {folders?.map((e) => {
          return (
            <ListItemWrapper
              key={e.id}
              title={e.title}
              projectId={projectId}
              folderId={e.id}
              visibilityStatus={e.visibilityStatus}
              authorId={e?.authorId}
            />
          );
        })}
      </List>
    </Collapse>
  );
};

type ListItemWrapperType = {
  title: string;
  projectId: number;
  folderId: number;
  visibilityStatus: VisibilityStatusEnum;
  authorId?: number;
};

const ListItemWrapper = ({
  title,
  projectId,
  folderId,
  visibilityStatus,
  authorId,
}: ListItemWrapperType) => {
  const [isEditable, setIsEditable] = useState(false);

  const { selectFolderId, isEditableFolder, setIsEditableFolder } = useWorkspaceContext();

  useEffect(() => {
    if (selectFolderId === folderId && isEditableFolder) {
      setIsEditable(true);
    }
  }, [isEditableFolder]);

  useEffect(() => {
    if (!isEditable) {
      setIsEditableFolder(false);
    }
  }, [isEditable]);

  const handleSetIsEditable = (value: boolean) => {
    setIsEditable(value);
  };

  return (
    <EditableFolderLine
      isEditable={isEditable}
      setIsEditable={handleSetIsEditable}
      projectId={projectId}
      title={title}
      folderId={folderId}
      visibilityStatus={visibilityStatus}
      authorId={authorId}
    />
  );
};
