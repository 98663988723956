import { styled } from '@mui/material';
import { TextFieldRHF } from '../uiComponents';
import { PasswordTextFieldRHF } from '../PasswordTextFieldRHF/PasswordTextFieldRHF';

export const TextFieldRHFStyle = styled(TextFieldRHF)(({ theme }) => ({
  '& .MuiInputBase-input': {
    border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
    padding: '6px  8px',
    height: '32px',
    boxSizing: 'border-box',
    borderRadius: '6px',
  },
}));
export const PasswordTextFieldRHFStyle = styled(PasswordTextFieldRHF)(({ theme }) => ({
  '& .MuiButtonBase-root': {
    height: '32px',
  },
  '& .MuiOutlinedInput-root': {
    height: '32px',
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
}));
