import { Stack, Typography, Button } from '@mui/material';
import { CloseButton } from '../../../index';
import { StackStyle, StackMainStyle } from './style';
import { ModalParametersTypes } from '../../../../types/types';
import { useRemoveGoalMutation } from '../../../../graphql/generated/graphql';
import { FOLDER } from '../../../../graphql/queries/folder.gql';

type GoalDeleteModalType = {
  modalParameters: ModalParametersTypes;
  handleCloseModal: () => void;
};

export const GoalDeleteModal = ({ modalParameters, handleCloseModal }: GoalDeleteModalType) => {
  const [removeGoalMutation] = useRemoveGoalMutation({
    variables: {
      id: Number(modalParameters?.goalId),
    },
    refetchQueries: [FOLDER],
  });

  const handleRemove = () => {
    removeGoalMutation();
    handleCloseModal();
  };

  return (
    <Stack>
      <Stack>
        <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant='h3'>Удаление цели</Typography>

          <CloseButton onClick={handleCloseModal} />
        </Stack>
      </Stack>

      <Stack>
        <StackMainStyle>
          <Stack direction='row' alignItems={'center'}>
            <Typography variant='customDefault' fontWeight={400}>
              Вы действительно хотите удалить цель со всеми тредами внутри?
            </Typography>
          </Stack>
        </StackMainStyle>

        <StackStyle>
          <Button variant='cancel' onClick={handleCloseModal} sx={{ marginLeft: '8px' }}>
            Отмена
          </Button>
          <Button
            variant='confirm'
            type='submit'
            onClick={handleRemove}
            sx={{ marginLeft: '8px', fontWeight: '500' }}
          >
            Удалить
          </Button>
        </StackStyle>
      </Stack>
    </Stack>
  );
};
