import { styled, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const StackStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: theme.palette.backgroundLevel1.main,
  borderRadius: '24px',
  marginTop: '5px !important',
  marginBottom: '5px !important',
}));

export const CloseIconStyle = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.neutral500.main,
  width: '14px',
  height: '14px',
  marginRight: '6px',
  cursor: 'pointer',
  marginLeft: '6px',
}));
