import { styled } from '@mui/material';
import { TextFieldRHF } from '../../../../../uiComponents';

export const TextFieldStyle = styled(TextFieldRHF)(({ theme }) => ({
  '& .MuiInputBase-input': {
    minWidth: '100px',
    height: '24px',
    flex: 1,
    whiteSpace: 'nowrap',
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
    borderRadius: '6px',
    padding: '4px 8px',
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    gap: '4px',
  },
}));
