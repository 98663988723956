import { Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import {
  StackWrapperItemStyle,
  StackWrapperImageStyle,
  IconButtonStyle,
  TypographyStyle,
  StackWrapperIconsStyle,
} from './style';
import { UploadFilesTyle, EditindnameDataType } from '../../../../../types/types';
import { BASE_URL } from '../../../../../constants/constants';
import { createAttachmentImage, getFileSizeInKilobytes } from '../../../../../utils/index';
import {
  useRemoveFileMutation,
  useUpdateFileMutation,
} from '../../../../../graphql/generated/graphql';
import { useModalContext } from '../../../../../context';
import { ModalStatuses, AttachmentsClickType } from '../../../../../types/enum';

type MessageFilesType = {
  files: Array<UploadFilesTyle> | null;
  setFiles: (value: Array<UploadFilesTyle>) => void;
};

export const MessageFiles = ({ files, setFiles }: MessageFilesType) => {
  const [removeFileMutation] = useRemoveFileMutation();

  const handleRemoveFie = (id: number) => {
    if (files) {
      setFiles(files.filter((e) => e && e.id !== id));
    }
    removeFileMutation({
      variables: {
        id: id,
      },
    });
  };

  const [updateFileMutation] = useUpdateFileMutation();

  const { handleChangeModalParameters } = useModalContext();

  const handleEditFile = ({ id, name }: { id: number; name: string }) => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.RENAME_FILE_MODAL,
      },
      {
        key: 'editindnameData',
        value: { editingName: false, index: id, newName: name },
      },
      {
        key: 'renameFileModalType',
        value: AttachmentsClickType.Comment,
      },
      {
        key: 'setEditindnameData',
        value: (value: EditindnameDataType) => {
          const fileIndex = files?.findIndex((e) => e?.id === value.index);
          if (fileIndex === -1 || !files) return;
          const updatedFiles = files.map((file, index) => {
            if (file && file.id !== undefined && index === fileIndex) {
              return { ...file, fileName: value.newName };
            }
            return file;
          });
          setFiles(updatedFiles);
          updateFileMutation({
            variables: {
              id: value.index as number,
              data: {
                fileName: value.newName,
              },
            },
          });
        },
      },
    ]);
  };

  const handleClickImage = (file: UploadFilesTyle) => {
    const { isImage } = createAttachmentImage({ mimeType: file?.mimeType });

    if (!isImage) return;
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.SINGLE_IMAGE_MODAL,
      },
      {
        key: 'singleImagePath',
        value: `${BASE_URL}${file.path}`,
      },
    ]);
  };

  return (
    <Stack direction={'row'} sx={{ overflowX: 'auto' }}>
      {files?.map((file, index: number) => {
        const url = `${BASE_URL}${file?.path}`;
        if (!file) return <></>;
        return (
          <StackWrapperItemStyle key={index}>
            <StackWrapperImageStyle
              sx={{ backgroundImage: `url(${url})` }}
              onClick={() => {
                handleClickImage(file);
              }}
            >
              <StackWrapperIconsStyle>
                <IconButtonStyle
                  sx={{
                    marginRight: '2px',
                  }}
                  className='hidden'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditFile({ id: file?.id, name: file?.fileName });
                  }}
                >
                  <EditOutlinedIcon />
                </IconButtonStyle>
                <IconButtonStyle
                  className='hidden'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveFie(file?.id as number);
                  }}
                >
                  <CloseRoundedIcon />
                </IconButtonStyle>
              </StackWrapperIconsStyle>
              <InsertDriveFileRoundedIcon />
            </StackWrapperImageStyle>
            <TypographyStyle color='textTertiary.main'>
              {getFileSizeInKilobytes(Number(file?.size))} кб
            </TypographyStyle>
            <TypographyStyle color='textPrimary.main'>{file?.fileName}</TypographyStyle>
          </StackWrapperItemStyle>
        );
      })}
    </Stack>
  );
};
