import { styled, Stack } from '@mui/material';

export const StackStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '56px',
  width: '160px',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: ' 142%',
  color: theme.palette.textTertiary.main,
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
}));

export const StackCleanStyle = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  flexDirection: 'row',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.backgroundLevel1.main,
  },
}));
