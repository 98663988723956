import { SvgIcon, SxProps } from '@mui/material';

type FolderTwoToneType = {
  sx?: SxProps;
};

export const FolderTwoTone = ({ sx }: FolderTwoToneType) => {
  return (
    <SvgIcon sx={sx}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          opacity='0.3'
          d='M9.3085 6.66667L8.82516 6.175L7.64183 5H3.3335V15H16.6668V6.66667H10.0002H9.3085Z'
          fill='#636B74'
        />
        <path
          d='M16.6665 5.00065H9.99984L8.33317 3.33398H3.33317C2.4165 3.33398 1.67484 4.08398 1.67484 5.00065L1.6665 15.0007C1.6665 15.9173 2.4165 16.6673 3.33317 16.6673H16.6665C17.5832 16.6673 18.3332 15.9173 18.3332 15.0007V6.66732C18.3332 5.75065 17.5832 5.00065 16.6665 5.00065ZM16.6665 15.0007H3.33317V5.00065H7.6415L8.8165 6.17565L9.30817 6.66732H16.6665V15.0007Z'
          fill='#9FA6AD'
        />
      </svg>
    </SvgIcon>
  );
};
