import { styled, Stack, Box } from '@mui/material';

export const StackWrapperStyle = styled(Stack)(() => ({
  padding: '6px 8px',
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const BoxWrapperStyle = styled(Box)(({ theme }) => ({
  width: '360px',
  padding: '16px',
  backgroundColor: theme.palette.white.main,
  borderRadius: '6px',
}));
