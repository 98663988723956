import { useCallback, useState } from 'react';
import { REGISTRATION_STEP } from '../pages/Registration/const';
import { LOCAL_STORAGE_KEY } from '../constants/constants';

export interface IRegistrationData {
  stepName: REGISTRATION_STEP;
  email: string;
  isInvitedUserAccessToken: boolean;
}

export const useRegistration = () => {
  const savedData = localStorage.getItem(LOCAL_STORAGE_KEY.REGISTRATION_DATA);

  const currentData = savedData
    ? JSON.parse(savedData)
    : { stepName: REGISTRATION_STEP.atTheBeginning, email: '', isInvitedUserAccessToken: false };

  const [registrationData, setRegistrationData] = useState<IRegistrationData>(currentData);

  const updateRegistrationData = useCallback(
    (newData: Partial<IRegistrationData>) => {
      const updatedData = { ...registrationData, ...newData };
      setRegistrationData(updatedData);
      localStorage.setItem(LOCAL_STORAGE_KEY.REGISTRATION_DATA, JSON.stringify(updatedData));
    },
    [registrationData],
  );
  const handleRemoveData = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.REGISTRATION_DATA);
    localStorage.removeItem(LOCAL_STORAGE_KEY.WORKSPACE_INVITE_TOKEN);
  };

  return { registrationData, updateRegistrationData, handleRemoveData };
};
