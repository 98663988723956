import * as yup from 'yup';

import { REQUIRE_MESSAGE } from './messages';

export const ThreadSchema = yup.object().shape({
  title: yup.string().required(REQUIRE_MESSAGE),
  status: yup.number(),
  description: yup.string(),
  startDate: yup.string(),
  endDate: yup.string(),
  files: yup.array().of(yup.mixed().nullable()),
  user: yup
    .object({
      title: yup.string(),
      value: yup.number(),
      type: yup.string(),
    })
    .nullable()
    .optional(),
});
