import { Stack, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { ListItemStyle, ListStyle } from './style';
import { useNavigateSearch, useWorkspace } from '../../../../../hooks';
import { WorkspaceType } from '../../../../../types/types';
import { useWorkspaceContext } from '../../../../../context';
import { TitleTypography } from '../../style';
import { getRoleTitle } from '../../../../../utils/getRoleTitle';
import { AvatarCustom } from '../../../../AvatarCustom/AvatarCustom';
import { User } from '../../../../../graphql/generated/graphql';
import { ROUTES } from '../../../../../constants/constants';

export const WorkspacesList = () => {
  const { workspaces, currentWorkspace } = useWorkspaceContext();
  const { handleWorkspace } = useWorkspace();
  return (
    <ListStyle>
      {workspaces?.map((workspace) => (
        <WorkspacesItem
          key={workspace.id}
          isSelect={workspace.id === currentWorkspace?.id}
          workspace={workspace}
          handleWorkspace={handleWorkspace}
        />
      ))}
    </ListStyle>
  );
};
type WorkspacesItemType = {
  workspace: WorkspaceType;
  isSelect: boolean;
  handleWorkspace: (id: number) => void;
};

const WorkspacesItem = ({ workspace, isSelect, handleWorkspace }: WorkspacesItemType) => {
  const { setSelectFolderId, setSelectProjectId } = useWorkspaceContext();
  const { navigateSearchParams } = useNavigateSearch();
  const handleClick = () => {
    handleWorkspace(workspace.id);
    setSelectFolderId(0);
    setSelectProjectId(0);
    navigateSearchParams({ path: ROUTES.home });
  };
  const roleTitle = getRoleTitle(workspace.myRole);
  return (
    <ListItemStyle onClick={handleClick}>
      <Stack flexDirection='row' alignItems='center' flex={1}>
        <AvatarCustom
          sx={{ marginRight: '8px', width: '32px', height: '32px' }}
          user={{ email: workspace.title || '', avatar: workspace.avatar } as User}
        />
        <Stack flex={1} sx={{ textOverflow: 'ellipsis', width: '50px' }}>
          <TitleTypography fontWeight={500} color='black600'>
            {workspace.title}
          </TitleTypography>
          <Typography fontWeight={500} color='textTertiary.main' fontSize={12}>
            {roleTitle}
          </Typography>
        </Stack>
      </Stack>
      {isSelect && <DoneIcon sx={{ height: '20px', width: '20px' }} />}
    </ListItemStyle>
  );
};
