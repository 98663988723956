import React, { useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../context';
import {
  useJoinToWorkspaceMutation,
  useLoginMutation,
} from '../../../../graphql/generated/graphql';
import { loginSchema } from '../../../../validation';
import { PasswordTextFieldRHF, TextFieldRHF } from '../../../../components';
import { SizeOptions } from '../../../../types/enum';
import { FormWrapper } from '../../../../components/AuthFormElements/form.style';
import { Button, FormControlLabel, useTheme } from '@mui/material';
import { LOCAL_STORAGE_KEY, ROUTES } from '../../../../constants/constants';
import { CheckboxStyle, StackStyle } from './style';
import { LoadingButton } from '@mui/lab';
import { RequestHandler } from '../../../../components/RequestHandler/RequestHandler';
import { useRegistration } from '../../../../hooks/useRegistration';

interface IFormData {
  email: string;
  password: string;
}
enum ServerError {
  WRONG_EMAIL_OR_PASSWORD = 'Wrong email or password',
}
export const LoginForm = () => {
  const theme = useTheme();
  const { handleRemoveData } = useRegistration();
  const { signIn } = useAuthContext();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const [joinToWorkspaceMutation] = useJoinToWorkspaceMutation();
  const workspaceInviteToken = localStorage.getItem(LOCAL_STORAGE_KEY.WORKSPACE_INVITE_TOKEN);
  function handleJoinToWorkspace() {
    joinToWorkspaceMutation({
      variables: {
        token: String(workspaceInviteToken),
      },
    });
  }
  const [loginMutation, { loading: isLoading }] = useLoginMutation();
  const handleNavigateToResetPassword = () => navigate(ROUTES.resetPassword);
  const methods = useForm<IFormData>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { setError } = methods;

  const onSubmit: SubmitHandler<IFormData> = (formData) => {
    loginMutation({
      variables: {
        data: { email: formData.email, password: formData.password },
      },
      onCompleted: (responseData) => {
        signIn(responseData?.login?.token, rememberMe);
        if (workspaceInviteToken) {
          handleJoinToWorkspace();
        } else if (!responseData?.login.user.workspaces.length) {
          navigate(ROUTES.createWorkspace);
        }
        handleRemoveData();
      },
      onError: (error) => {
        if (error.message === ServerError.WRONG_EMAIL_OR_PASSWORD) {
          setError('password', {
            type: 'server',
            message: 'Неверная почта или пароль',
          });
          setError('email', {});
        }
      },
    });
  };
  return (
    <RequestHandler loading={isLoading}>
      <FormProvider {...methods}>
        <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
          <TextFieldRHF
            name='email'
            type='text'
            placeholder='Почта'
            size={SizeOptions.extraLarge}
            sx={{
              marginTop: '32px',
            }}
          />
          <PasswordTextFieldRHF
            name='password'
            type='password'
            placeholder='Пароль'
            size={SizeOptions.extraLarge}
            sx={{
              marginTop: '4px',
            }}
          />
          <StackStyle mt='26px'>
            <FormControlLabel
              sx={{
                marginLeft: 0,
              }}
              value={rememberMe}
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                setRememberMe(target.checked);
              }}
              control={<CheckboxStyle sx={{ background: theme.palette.backgroundSurface.main }} />}
              label='Запомнить меня'
            />
            <Button onClick={handleNavigateToResetPassword} variant='text'>
              Забыли пароль?
            </Button>
          </StackStyle>
          <LoadingButton
            fullWidth
            loading={isLoading}
            variant='contained'
            type='submit'
            sx={{ marginTop: '24px', width: '100%' }}
          >
            Войти
          </LoadingButton>
        </FormWrapper>
      </FormProvider>
    </RequestHandler>
  );
};
