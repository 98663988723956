import { Stack, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useForm, FormProvider } from 'react-hook-form';
import { FormFlex, TextFieldRHF } from '../../../index';
import { SizeOptions } from '../../../../types/enum';
import SendIcon from '@mui/icons-material/Send';
import { useRef, ChangeEvent, useEffect, KeyboardEvent, useState } from 'react';
import { MessageFiles } from './MessageFiles/MessageFiles';
import { BoxStyle, StackInputWrapperStyle, StackFilesWrapperStyle } from './style';
import {
  useCreateMessageMutation,
  useUploadFilesMutation,
} from '../../../../graphql/generated/graphql';
import { useFileSizeAlert } from '../../../../hooks/index';
import { UploadFilesTyle, MessageChatType } from '../../../../types/types';
import { Reply } from './Reply/Reply';
import { useChatContext } from '../../context/ChatContext';

type InputItemType = {
  chatId: number;
};

export const InputItem = ({ chatId }: InputItemType) => {
  const { reply, setReply, setInputHeight } = useChatContext();

  type FormDataType = {
    text: string;
    files: Array<UploadFilesTyle> | null;
    reply: MessageChatType | null;
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const methods = useForm<FormDataType>({
    defaultValues: {
      text: '',
      files: [],
      reply: reply,
    },
  });
  const { getValues, reset, watch, setValue } = methods;
  const handleSetReplay = (reply: MessageChatType | null) => {
    setValue('reply', reply);
    !reply && setReply(null);
  };
  useEffect(() => {
    handleSetReplay(reply);
  }, [reply]);

  const replayWatch = watch('reply');
  const filesWatch = watch('files');
  const textWatch = watch('text');

  const handleSetFile = (value: Array<UploadFilesTyle>) => {
    setValue('files', value);
  };

  const [createMessageMutation] = useCreateMessageMutation({
    onCompleted: () => {
      reset();
      handleSetFile([]);
    },
  });

  const handleSubmitt = () => {
    const currentValues = getValues();

    if (!currentValues.text.trim() && !currentValues.files?.length && !currentValues?.reply?.id)
      return;
    createMessageMutation({
      variables: {
        data: {
          chatId: Number(chatId),
          content: currentValues.text,
          filesIds: currentValues.files?.map((e) => e?.id).filter((id) => id !== undefined) || [],
          repliedToMessageId: currentValues?.reply?.id,
        },
      },
    });
  };
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmitt();
    }
  };

  const [uploadFilesMutation] = useUploadFilesMutation({
    onCompleted: (e) => {
      if (e?.uploadFiles) {
        handleSetFile([...(filesWatch ? filesWatch : []), ...e.uploadFiles]);
      }
    },
  });
  const { fileSizeAlert } = useFileSizeAlert();
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (fileSizeAlert(event)) return;
      const newFiles = Array.from(event.target.files).map((file) => file);
      uploadFilesMutation({
        variables: {
          files: newFiles,
        },
      });
    }
  };

  const isShowFile = !!filesWatch?.length;
  const isReply = !!replayWatch;

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const inputElement = wrapperRef.current;

    if (inputElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0];
        const { height } = entry.contentRect;
        setInputHeight(height);
      });

      resizeObserver.observe(inputElement);
      return () => resizeObserver.disconnect();
    }
  }, []);

  const [isInputMultiline, setIsInputMultiline] = useState(false);

  useEffect(() => {
    const lineCount = textWatch?.split(/\r*\n/)?.length;
    setIsInputMultiline(lineCount > 1);
  }, [textWatch]);

  return (
    <BoxStyle ref={wrapperRef}>
      <FormProvider {...methods}>
        <FormFlex>
          <Stack>
            {isReply && <Reply reply={replayWatch} setReply={handleSetReplay} />}
            <StackFilesWrapperStyle
              sx={isReply ? { borderTop: 'none', borderRadius: '0px 0px 12px 12px' } : {}}
            >
              {isShowFile && <MessageFiles files={filesWatch} setFiles={handleSetFile} />}
              <StackInputWrapperStyle isShowFile={isShowFile} multiline={isInputMultiline}>
                <IconButton sx={{ marginTop: 'auto' }} onClick={handleButtonClick}>
                  <AttachFileIcon sx={{ color: 'black600' }} />
                </IconButton>
                <input
                  style={{ display: 'none' }}
                  type='file'
                  multiple
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
                <TextFieldRHF
                  name='text'
                  size={SizeOptions.small}
                  placeholder='Введите комментарий...'
                  multiline
                  onKeyDown={handleKeyDown}
                  sx={{
                    '& .MuiInputBase-input': { padding: '4px' },
                    maxHeight: '40vh',
                    overflowY: 'auto',
                  }}
                />
                <IconButton sx={{ marginTop: 'auto' }} onClick={handleSubmitt}>
                  <SendIcon sx={{ color: 'black600' }} />
                </IconButton>
              </StackInputWrapperStyle>
            </StackFilesWrapperStyle>
          </Stack>
        </FormFlex>
      </FormProvider>
    </BoxStyle>
  );
};
