import { AutocompliteMember } from '../index';
import { useCreateOptions } from './hooks/useCreateOptions';

type MemberThredTableType = {
  workspaceId: number;
  name: string;
  isMultiple?: boolean;
  placeholder?: string;
  folderId?: number;
  projectId?: number;
};

export const MemberThredTable = ({
  workspaceId,
  name,
  isMultiple,
  placeholder = 'Ответственный',
  folderId,
  projectId,
}: MemberThredTableType) => {
  const options = useCreateOptions({ workspaceId, folderId, projectId });

  return (
    <AutocompliteMember
      options={options || []}
      name={name}
      sx={{ width: '220px' }}
      isHasPopupIcon
      isHasClearIcon
      placeholder={placeholder}
      isOpen={true}
      isMultiple={isMultiple}
    />
  );
};
