import { gql } from '@apollo/client';

export const MESSAGES_WITH_COUNT = gql`
  query messagesWithCount($data: FindAllMessagesOptionsInput) {
    messagesWithCount(data: $data) {
      count
      rows {
        id
        updatedAt
        attachments {
          id
          fileName
          path
          mimeType
          size
        }
        author {
          id
          firstName
          lastName
          email
          avatar {
            path
          }
          karmaStatistics {
            karma
          }
        }
        reactions {
          count
          emojiId
          userIds
          emoji {
            id
            name
          }
          users {
            id
            firstName
            lastName
            email
            avatar {
              path
            }
          }
        }
        content
        repliedToMessage {
          id
          content
          attachments {
            id
            fileName
            path
            mimeType
            size
          }
          author {
            id
            firstName
            lastName
            email
            avatar {
              path
            }
            karmaStatistics {
              karma
            }
          }
        }
      }
    }
  }
`;
