import { styled, Typography } from '@mui/material';

export const TypographyStyle = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  color: theme.palette.textPrimary.main,
  padding: '0px 0px 15px 0px',
  whiteSpace: 'pre-line',
  lineHeight: '150%',
}));
