import { useState, SyntheticEvent, useRef, useEffect } from 'react';
import { Stack } from '@mui/material';
import { Comments } from './Comments/Comments';
import { History } from './History/History';
import { Attachments } from './Attachments/Attachments';
import { BoxStyle } from './style';
import { TabsCustom } from '../index';
import { RequestHandler } from '../RequestHandler/RequestHandler';
import { ChatProvider, useChatContext } from './context/ChatContext';

type ChatType = {
  chatId?: number;
  threadId?: number;
};

const ChatLayout = ({ chatId, threadId }: ChatType) => {
  const { tabIndex, setTabIndex } = useChatContext();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const optionsTabs = [{ title: 'Комментарии' }, { title: 'История' }, { title: 'Вложения' }];

  const refWrapper = useRef<HTMLDivElement>(null);

  const [wrapperHeight, setWrapperHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (refWrapper.current) {
        setWrapperHeight(refWrapper.current.offsetHeight);
      }
    };
    const resizeObserver = new ResizeObserver(handleResize);
    if (refWrapper.current) {
      resizeObserver.observe(refWrapper.current);
    }
    return () => {
      if (refWrapper.current) {
        resizeObserver.unobserve(refWrapper.current);
      }
    };
  }, []);

  return (
    <Stack sx={{ height: '100%', position: 'relative' }} ref={refWrapper}>
      <BoxStyle>
        <TabsCustom handleTabChange={handleChange} tabIndex={tabIndex} options={optionsTabs} />
      </BoxStyle>
      <RequestHandler loading={!chatId}>
        {tabIndex === 0 && (
          <Comments wrapperHeight={wrapperHeight} chatId={chatId} threadId={threadId} />
        )}
        {tabIndex === 1 && (
          <History chatId={chatId} threadId={threadId} wrapperHeight={wrapperHeight} />
        )}
        {tabIndex === 2 && <Attachments chatId={chatId} wrapperHeight={wrapperHeight} />}
      </RequestHandler>
    </Stack>
  );
};

export const Chat = ({ chatId, threadId }: ChatType) => {
  return (
    <ChatProvider>
      <ChatLayout chatId={chatId} threadId={threadId} />
    </ChatProvider>
  );
};
