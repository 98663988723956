import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registrationSchemaAdditional } from '../../../../validation';
import { FormWrapper } from '../../../../components/AuthFormElements/form.style';
import { TextFieldRHF, PasswordTextFieldRHF } from '../../../../components';
import { useRegistrationContext } from '../../context/context';
import {
  REGISTRATION_TOKEN_QUERY_KEY,
  CONFIRM_EMAIL_QUERY_KEY,
  REGISTRATION_STEP,
} from '../../const';
import { SizeOptions } from '../../../../types/enum';
import {
  useJoinToWorkspaceMutation,
  useRegisterMutation,
  useIsValidRegisterTokenQuery,
} from '../../../../graphql/generated/graphql';
import { useQueryParams } from '../../../../hooks';
import { useAuthContext } from '../../../../context';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEY, ROUTES } from '../../../../constants/constants';
import { LoadingButton } from '@mui/lab';
import { useRegistration } from '../../../../hooks/useRegistration';

interface IFormData {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
}

export const PersonalDetailsForm = () => {
  const { signIn } = useAuthContext();
  const { handleRemoveData } = useRegistration();
  const navigate = useNavigate();

  const { registrationData, handleGoToStep } = useRegistrationContext();
  const queryParams = useQueryParams();

  const token = queryParams.get(CONFIRM_EMAIL_QUERY_KEY);
  const handleInvalidToken = () => {
    navigate(ROUTES.invalidLink);
    handleGoToStep(REGISTRATION_STEP.atTheBeginning);
  };
  useIsValidRegisterTokenQuery({
    variables: {
      token: String(token),
    },
    skip: !token,
    onCompleted: (data) => {
      if (!data.isValidRegisterToken.success) {
        handleInvalidToken();
      }
    },
    onError: () => {
      handleInvalidToken();
    },
  });

  const [joinToWorkspaceMutation] = useJoinToWorkspaceMutation();
  const workspaceInviteToken = localStorage.getItem(LOCAL_STORAGE_KEY.WORKSPACE_INVITE_TOKEN);
  function handleJoinToWorkspace() {
    joinToWorkspaceMutation({
      variables: {
        token: String(workspaceInviteToken),
      },
    });
  }
  const [registerMutation, { loading: isLoading }] = useRegisterMutation({
    onCompleted: (data) => {
      signIn(data?.register?.token, true);
      if (workspaceInviteToken) {
        handleJoinToWorkspace();
      } else if (!data?.register.user.workspaces.length) {
        navigate(ROUTES.createWorkspace);
      }
      handleRemoveData();
    },
  });

  const methods = useForm<IFormData>({
    resolver: yupResolver(registrationSchemaAdditional),
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
    },
  });
  const {
    watch,
    trigger,
    formState: { isSubmitted },
  } = methods;
  const password = watch('password');
  const confirmPassword = watch('confirmPassword');
  useEffect(() => {
    if (isSubmitted) {
      trigger('confirmPassword');
      trigger('password');
    }
  }, [password, trigger, confirmPassword]);
  const onSubmit: SubmitHandler<IFormData> = (data) => {
    const registrationToken = queryParams.get(REGISTRATION_TOKEN_QUERY_KEY);
    registerMutation({
      variables: {
        data: {
          email: registrationData.email,
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
          registrationToken: registrationToken,
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
        <TextFieldRHF
          name='firstName'
          type='text'
          placeholder='Имя'
          size={SizeOptions.extraLarge}
          sx={{
            marginBottom: '4px',
          }}
        />

        <TextFieldRHF
          name='lastName'
          type='text'
          placeholder='Фамилия'
          size={SizeOptions.extraLarge}
          sx={{
            marginBottom: '4px',
          }}
        />

        <PasswordTextFieldRHF
          name='password'
          type='password'
          placeholder='Пароль'
          size={SizeOptions.extraLarge}
          sx={{
            marginBottom: '4px',
          }}
        />
        <PasswordTextFieldRHF
          name='confirmPassword'
          type='password'
          placeholder='Повторите пароль'
          size={SizeOptions.extraLarge}
          sx={{
            marginBottom: '24px',
          }}
        />

        <LoadingButton loading={isLoading} variant='contained' type='submit' sx={{ width: '100%' }}>
          Продолжить
        </LoadingButton>
      </FormWrapper>
    </FormProvider>
  );
};
