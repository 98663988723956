export const REQUIRE_MESSAGE = 'Обязательное поле';
export const INCORRECT_LOGIN = 'Некорректный логин';
export const INCORRECT_EMAIL = 'Некорректный email';
export const INCORRECT_NAME = 'Некорректное имя';
export const INCORRECT_LASTNAME = 'Некорректная фамилия';
export const INCORRECT_PROJECT_NAME = 'Некорректное название проекта';
export const INCORRECT_TASK_NAME = 'Некорректное название задачи';
export const INCORRECT_TASK_PRICE = 'Некорректная цена реализации';
export const INCORRECT_PASSWORD = 'Некорректный пароль';
export const INCORRECT_CONFIRM_PASSWORD = 'Пароли не совпадают';

export const POLYGON_NOT_FOUND = 'Полигон не существует';
export const POLYGON_TREE_ERROR = 'Нельзя указывать ссылку на родителя, и начало ветки';
export const SELECT_COLOR = 'Выберите цвет';
export const WRONG_WORD = 'Кажется, вы ввели неправильное слово.';

export const titleTooLong = (value: number) => {
  return `Длина заголовка не должна превышать ${value} символов`;
};

export const incorrectMaxTag = (value: number) => {
  return `Максимальное количество тегов ${value}`;
};

export const incorrectMinLength = (value: number) => {
  return `Минимальное количество символов ${value}`;
};

export const incorrectMaXLength = (value: number) => {
  return `Максимальная длина ${value} символов'`;
};
