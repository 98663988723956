import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderNameSchema } from '../../../../validation/index';
import {
  useCreateFolderMutation,
  useUpdateFolderMutation,
  useRemoveFolderMutation,
} from '../../../../graphql/generated/graphql';
import { PROJECTS } from '../../../../graphql/queries/projects.gql';

type EditFolderType = {
  nameFolder: string;
  isEditable: boolean;
  projectId: number;
  isAddFolder: boolean;
  folderId?: number;
  setSelectFolderId: (value: number | null) => void;
};

export const useEditFolder = ({
  nameFolder,
  isEditable,
  projectId,
  isAddFolder,
  folderId,
  setSelectFolderId,
}: EditFolderType) => {
  interface FormData {
    title: string;
  }

  const [createFolderMutation] = useCreateFolderMutation({
    refetchQueries: [PROJECTS],
  });

  const [updateFolderMutation] = useUpdateFolderMutation({ refetchQueries: [PROJECTS] });

  const [removeFolderMutation] = useRemoveFolderMutation({
    variables: {
      id: Number(folderId),
    },
    refetchQueries: [PROJECTS],
    onCompleted: () => {
      setSelectFolderId(null);
    },
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(FolderNameSchema),
    defaultValues: {
      title: nameFolder,
    },
  });
  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (isAddFolder) {
      createFolderMutation({
        variables: {
          createFolderInput: {
            projectId: projectId,
            title: data.title,
          },
        },
      });
      reset({ title: '' });
    } else {
      updateFolderMutation({
        variables: {
          updateFolderInput: {
            id: Number(folderId),
            projectId: projectId,
            title: data.title,
          },
        },
      });
    }
  };

  const { getValues, trigger, reset } = methods;
  const currentValues = getValues();
  useEffect(() => {
    const validateAndSubmit = async () => {
      const isValid = await trigger();
      if (isValid) {
        const currentValues = getValues();
        onSubmit(currentValues);
      } else {
        reset({ title: nameFolder });
      }
    };

    const conformState = !isEditable;

    if (currentValues?.title !== nameFolder && conformState) {
      validateAndSubmit();
    }
  }, [isEditable]);

  return { methods, onSubmit, removeFolderMutation };
};
