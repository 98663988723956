import { StyledTableCell, StyledTableCellDrag, StyledTableRow } from '../style';
import { Stack, useTheme, IconButton, Box } from '@mui/material';
import { AddRounded } from '../../icons';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { TypeTableRow } from '../../../types/enum';
import { StackStyle } from './srtyle';

type AddRowsType = {
  emptyCellWidth: string;
  text: string;
  type?: TypeTableRow;
  onCallback: () => void;
  onCallbackOuther: () => void;
  isDraggingOccurs: boolean;
};

export const AddRows = ({
  emptyCellWidth,
  text,
  type = TypeTableRow.thread,
  onCallback,
  onCallbackOuther,
  isDraggingOccurs,
}: AddRowsType) => {
  const theme = useTheme();
  return (
    <>
      {isDraggingOccurs && (
        <StyledTableRow>
          <Box />
        </StyledTableRow>
      )}

      <StyledTableRow
        onClick={onCallbackOuther}
        sx={{
          '&:hover': {
            backgroundColor: theme.palette.neutralPlainHoverBg.main,
            cursor: 'pointer',
            '.MuiTableCell-root': {
              backgroundColor: theme.palette.neutralPlainHoverBg.main,
              cursor: 'pointer',
            },
          },
        }}
      >
        <StyledTableCellDrag
          sx={{
            borderBottom: 'none',
          }}
          padding='none'
          width={emptyCellWidth}
        ></StyledTableCellDrag>
        <StyledTableCell>
          <StackStyle>
            <Stack sx={{ marginRight: '10px' }}>
              <IconButton
                sx={{ padding: '0px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  onCallback();
                }}
              >
                {type === TypeTableRow.goal ? (
                  <AddBoxOutlinedIcon
                    sx={{
                      color: theme.palette.neutralPlainDisabledColor.main,
                    }}
                  />
                ) : (
                  <AddRounded color={theme.palette.neutralPlainDisabledColor.main} />
                )}
              </IconButton>
            </Stack>
            {text}
          </StackStyle>
        </StyledTableCell>
        {renderTableCells(TableCellCountEmpty[type] || 0)}

        <StyledTableCellDrag padding='none'></StyledTableCellDrag>
      </StyledTableRow>
    </>
  );
};

const TableCellCountEmpty = {
  [TypeTableRow.folder]: 2,
  [TypeTableRow.thread]: 3,
  [TypeTableRow.goal]: 3,
};

const renderTableCells = (count: number) => {
  return [...Array(count)].map((_, index) => <StyledTableCell key={index}></StyledTableCell>);
};
