import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

export const ErrorMessageWrapperCSS = styled(Stack)({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

export const TypographyCSS = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.black600,
  };
});
