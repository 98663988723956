import { gql } from '@apollo/client';

export const USER_GROUPS = gql`
  query userGroups($data: UserGroupsFindDataInput!) {
    userGroups(data: $data) {
      avatar {
        path
        id
      }
      id
      title
      workspaceId
      users {
        id
        email
        firstName
        lastName
        avatar {
          path
        }
      }
    }
  }
`;
