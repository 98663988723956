import { styled, ListItemButton, List } from '@mui/material';

export const ListStyle = styled(List)`
  max-height: calc(100vh - ${486}px);
  overflow-y: auto;
`;

// START GENERAL STYLE //

export const ListItemButtonStyle = styled(ListItemButton)(({ theme }) => ({
  padding: '4px 8px',
  borderRadius: '6px',
  height: '32px',
  marginBottom: '2px',
  '&:hover': {
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
    '& .MuiIconButton-root.hiden': {
      visibility: 'visible',
    },
  },
  '&.editable': {
    borderRadius: '6px',
    border: `2px solid ${theme.palette.black600}`,
    '& .MuiIconButton-root.hiden': {
      display: 'none',
    },
  },
}));

// END GENERAL STYLE //
