import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { PositionedPopper } from '../../uiComponents';
import { OutsideClickFlagType, MemberTypes } from '../../../types/enum';
import { MemberThredTable } from '../../MemberThred';
import { useWorkspaceContext } from '../../../context';
import { useForm, FormProvider } from 'react-hook-form';
import {
  UpdateThreadMutationFn,
  ThreadSubscribersInput,
  SubscriptionStatusEnum,
  User,
} from '../../../graphql/generated/graphql';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { NotificationIconsWrapper } from './NotificationIconsWrapper/NotificationIconsWrapper';
import { DeleteThreadPopper } from './DeleteThreadPopper/DeleteThreadPopper';
import { UserAndGroupDataType } from '../../../types/types';
import { DISPLAYED_AVATARS_COUNT } from '../../../constants/constants';
import { AvatarCustom } from '../../AvatarCustom/AvatarCustom';

type EditSubscribesType = {
  name: string;
  subscribedUsers?: Array<UserAndGroupDataType> | null;
  subscribedUserGroups?: Array<UserAndGroupDataType> | null;
  updateThreadMutation: UpdateThreadMutationFn;
  threadId?: number;
  subscriptionStatus?: SubscriptionStatusEnum;
  folderId?: number;
  projectId?: number;
};

export const EditSubscribes = ({
  name,
  subscribedUsers,
  subscribedUserGroups,
  threadId,
  updateThreadMutation,
  subscriptionStatus,
  folderId,
  projectId,
}: EditSubscribesType) => {
  const { workspaceId } = useWorkspaceContext();

  type ValueType = {
    title: string;
    value: number;
    type: MemberTypes;
    avatar?: {
      path: string;
    } | null;
  };

  type FormData = {
    subscribed: Array<ValueType | null>;
  };

  const createInitValue = () => {
    const combinedData = [
      ...(subscribedUserGroups?.map((group) => ({ ...group, type: MemberTypes.groups })) || []),
      ...(subscribedUsers?.map((user) => ({ ...user, type: MemberTypes.user })) || []),
    ];

    return combinedData.map((item) => {
      return {
        title: String(item.type === MemberTypes.groups ? item.title : item.email),
        value: Number(item.id),
        type: item.type,
        avatar: item?.avatar,
      };
    });
  };

  const methods = useForm<FormData>({
    defaultValues: {
      subscribed: createInitValue(),
    },
  });

  const { watch } = methods;

  const subscribedWatch = watch('subscribed');

  useEffect(() => {
    if (subscribedUsers) {
      methods.reset({
        subscribed: createInitValue(),
      });
    }
  }, [subscribedUsers, subscribedUserGroups]);

  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (isDisable) return;

    const createSubscribers = () => {
      return subscribedWatch
        ?.map((e) => {
          if (!e) return;
          if (e.type === MemberTypes.user) {
            return { userId: e.value };
          } else {
            return { userGroupId: e.value };
          }
        })
        .filter((subscriber) => !!subscriber);
    };

    updateThreadMutation({
      variables: {
        data: {
          id: Number(threadId),
          subscribers: createSubscribers() as Array<ThreadSubscribersInput>,
        },
      },
    });
  }, [subscribedWatch]);

  const theme = useTheme();

  return (
    <FormProvider {...methods}>
      <Stack
        sx={{
          marginLeft: 'auto',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => {
          setIsDisable(false);
        }}
      >
        <PositionedPopper
          sxPopper={{
            borderRadius: '6px 0px',
            border: 'none',
          }}
          ClickComponent={
            <Button variant='white' sx={{ padding: '2px 12px' }}>
              <Typography sx={{ fontWeight: '500', marginRight: '6px' }}>Подписчики</Typography>
              <AvatarGroup
                sx={{
                  '& .MuiAvatar-root': {
                    marginRight: '-8px',
                    marginLeft: '0px',
                  },
                }}
              >
                {subscribedWatch?.map((e, index) => {
                  if (index + 1 > DISPLAYED_AVATARS_COUNT) return;
                  return (
                    <AvatarCustom
                      key={index}
                      sx={{
                        zIndex: index,
                      }}
                      user={{ email: e?.title, avatar: e?.avatar } as User}
                    />
                  );
                })}
                {DISPLAYED_AVATARS_COUNT < subscribedWatch.length && (
                  <Avatar
                    style={{
                      zIndex: 3,
                    }}
                    sx={{
                      backgroundColor: theme.palette.white.main,
                      color: theme.palette.black600,
                    }}
                  >
                    {`+${subscribedWatch.length - DISPLAYED_AVATARS_COUNT}`}
                  </Avatar>
                )}
              </AvatarGroup>
            </Button>
          }
          PopperContent={
            <Box component={'span'} className={OutsideClickFlagType.outsideClickFlagDefault}>
              <MemberThredTable
                placeholder='Подписчики'
                name={name}
                workspaceId={workspaceId!}
                isMultiple={true}
                folderId={folderId}
                projectId={projectId}
              />
            </Box>
          }
        />

        <NotificationIconsWrapper threadId={threadId} subscriptionStatus={subscriptionStatus} />

        <DeleteThreadPopper threadId={threadId} />
      </Stack>
    </FormProvider>
  );
};
