import { Box, Stack, Typography } from '@mui/material';
import { CommentItem } from './CommentItem/CommentItem';
import { InputItem } from './InputItem/InputItem';

import { useQueryChat, useScrollChat } from './hook/index';
import { useChatContext } from '../context/ChatContext';

type CommentsType = {
  wrapperHeight: number;
  threadId?: number;
  chatId?: number;
};

export const Comments = ({ wrapperHeight, chatId }: CommentsType) => {
  const { commentList } = useQueryChat({ chatId });
  const { refBottom, refWrapper } = useScrollChat({
    commentList: commentList,
  });

  const { inputHeight } = useChatContext();

  return (
    <Stack position={'relative'} sx={{ height: `calc(${wrapperHeight}px - 35px)` }}>
      <Stack
        sx={{ maxHeight: `calc(100% - ${inputHeight}px)`, overflowY: 'auto' }}
        ref={refWrapper}
      >
        {commentList?.map((message) => {
          return <CommentItem key={message.id} message={message} wrapperHeight={wrapperHeight} />;
        })}

        <Box ref={refBottom}></Box>
      </Stack>

      {!commentList?.length && (
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          height={'100%'}
          sx={{ marginBottom: '50px' }}
        >
          <Typography sx={{ color: 'neutralPlainDisabledColor.main' }}>Нет комментариев</Typography>
        </Stack>
      )}
      <InputItem chatId={Number(chatId)} />
    </Stack>
  );
};
