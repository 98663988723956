import { AttachmentsClickType } from '../../types/enum';
import { AttachmentFile } from '../../types/types';

import { useThread } from './hooks';
import { FileListManagerDefault } from './FileListManagerDefault';

type FileListManagerThreadType = {
  outChange: (value: Array<AttachmentFile>) => void;
  value: Array<AttachmentFile>;
  type?: AttachmentsClickType;
  threadId?: number;
  handleClose?: () => void;
};

export const FileListManagerThread = ({
  outChange,
  value = [],
  threadId,
  handleClose,
}: FileListManagerThreadType) => {
  const {
    setEditindnameData,
    setFiles,
    editindnameData,
    handleCloseModal,
    handleButtonClick,
    files,
    fileInputRef,
    handleFileChange,
    handleDownloadAllFiles,
    handleRemoveAllFiles,
    startEditing,
    handleDownloadFile,
    handleRemoveFile,
    loadingFiles,
  } = useThread({ outChange, value, threadId });

  return (
    <FileListManagerDefault
      startEditing={startEditing}
      handleDownloadFile={handleDownloadFile}
      handleRemoveFile={handleRemoveFile}
      handleDownloadAllFiles={handleDownloadAllFiles}
      handleRemoveAllFiles={handleRemoveAllFiles}
      handleFileChange={handleFileChange}
      fileInputRef={fileInputRef}
      files={files}
      handleButtonClick={handleButtonClick}
      handleCloseModal={handleCloseModal}
      editindnameData={editindnameData}
      setFiles={setFiles}
      setEditindnameData={setEditindnameData}
      type={AttachmentsClickType.ThreadItem}
      loadingFiles={loadingFiles}
      handleClose={handleClose}
    />
  );
};
