import { CircularProgress } from '@mui/material';
import { StackStyle } from './style';

export const Preloader = () => {
  return (
    <StackStyle>
      <CircularProgress />
    </StackStyle>
  );
};
