import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { GroupsColumn } from './components/GroupsColumn/GroupsColumn';
import { GroupInfo } from './components/GroupInfo/GroupInfo';
import { WorkspaceMemberRole } from '../../../../../../../graphql/generated/graphql';

export const Groups = () => {
  const theme = useTheme();

  const [selectUserGroupId, setSelectUserGroupId] = useState<number | null>(null);
  const handleSelectUserGroup = (id: number) => {
    setSelectUserGroupId(id);
  };
  const hasPermission = [WorkspaceMemberRole.Admin, WorkspaceMemberRole.Creator];
  return (
    <Stack p='24px' flex={1}>
      <Typography mb='24px' variant='h3'>
        Группы
      </Typography>
      <Stack flexDirection='row' height='100%'>
        <GroupsColumn
          handleSelectUserGroup={handleSelectUserGroup}
          hasPermission={hasPermission}
          selectUserGroupId={selectUserGroupId}
        />
        <Divider
          sx={{ backgroundColor: theme.palette.neutralOutlinedBorder.main }}
          orientation='vertical'
        />
        <Box flex={1}>
          {!!selectUserGroupId && (
            <GroupInfo selectUserGroupId={selectUserGroupId} hasPermission={hasPermission} />
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
