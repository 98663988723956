import { IconButton, Stack, styled } from '@mui/material';

export const MemberWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  height: '38px',
  padding: '8px 0',
  marginBottom: '8px',
}));

export const MemberRoleStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  height: '32px',
  alignItems: 'center',
  padding: '0 12px',
  borderRadius: '6px',
  '&.hasAccess': {
    cursor: 'pointer',
  },
  '&.hasAccess:hover, &.open': {
    background: theme.palette.neutralPlainHoverBg.main,
  },
}));
