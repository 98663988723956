import { Stack, IconButton, Box, useTheme } from '@mui/material';
import { StyledTableCell } from '../../../style';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect, useState } from 'react';
import { TextFieldRHF } from '../../../../uiComponents';
import { useForm, FormProvider, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThreadSchema } from '../../../../../validation';
import { SizeOptions } from '../../../../../types/enum';
import { BoxOutherStyle, StackInnerStyle, TypographyStyle, StackDefaultStyle } from './style';
import { useClickOutside } from '../../../../../hooks';
import { UpdateFolderMutationFn } from '../../../../../graphql/generated/graphql';
import { FolderTwoTone } from '../../../../icons';
import { useWorkspaceContext } from '../../../../../context';

type NameCellType = {
  title: string;
  nameWidth: string;
  updateFolderMutation: UpdateFolderMutationFn;
  folderId: number;
  isAcess?: boolean;
};

export const NameCell = ({
  title,
  nameWidth,
  updateFolderMutation,
  folderId,
  isAcess = true,
}: NameCellType) => {
  const [isEditable, setIsEditable] = useState(false);

  const { refClickOutside } = useClickOutside({
    callBack: () => {
      setIsEditable(false);
    },
  });

  type FormData = {
    title: string;
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(ThreadSchema) as Resolver<FormData>,
    defaultValues: {
      title: title,
    },
  });
  const { getValues, trigger, reset } = methods;

  const [isInitDisable, setIsInitDisable] = useState(true);

  useEffect(() => {
    setIsInitDisable(false);
    if (isEditable || isInitDisable) return;
    const validateAndSubmit = async () => {
      const isValid = await trigger();
      if (isValid) {
        const currentValues = getValues();
        updateFolderMutation({
          variables: {
            updateFolderInput: {
              id: folderId,
              title: currentValues.title,
            },
          },
        });
      } else {
        reset({ title: title });
      }
    };

    validateAndSubmit();
  }, [isEditable]);

  const theme = useTheme();

  const { setSelectFolderId } = useWorkspaceContext();

  return (
    <StyledTableCell
      width={nameWidth}
      sx={{
        '&:hover': {
          backgroundColor: !isEditable ? `${theme.palette.tableHover.main} !important` : '',
        },
      }}
      onClick={() => {
        setSelectFolderId(folderId);
      }}
    >
      <FormProvider {...methods}>
        {isEditable ? (
          <Stack
            direction={'row'}
            alignItems={'center'}
            ref={refClickOutside}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <BoxOutherStyle>
              <StackInnerStyle direction={'row'} alignItems={'center'}>
                <FolderTwoTone
                  sx={{
                    marginRight: '5px',
                    marginBottom: 'auto',
                  }}
                />
                <TextFieldRHF
                  fullWidth
                  placeholder='Введите название'
                  minRows={1}
                  name='title'
                  type='text'
                  multiline
                  size={SizeOptions.small}
                />
              </StackInnerStyle>
            </BoxOutherStyle>
            <TypographyStyle sx={{ opacity: '0' }}>fix</TypographyStyle>
          </Stack>
        ) : (
          <StackDefaultStyle>
            <Stack direction={'row'} alignItems={'center'}>
              <FolderTwoTone />
              <Box
                sx={{
                  marginLeft: '5px',
                }}
              >
                {title}
              </Box>
            </Stack>
            {isAcess && (
              <IconButton
                sx={{
                  marginLeft: 'auto',
                  padding: '2px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditable(true);
                }}
              >
                <EditOutlinedIcon
                  fontSize='small'
                  sx={{
                    visibility: 'hidden',
                  }}
                  className='hidden'
                />
              </IconButton>
            )}
          </StackDefaultStyle>
        )}{' '}
      </FormProvider>
    </StyledTableCell>
  );
};
