import React from 'react';
import { SvgIcon, SxProps } from '@mui/material';

type CheckboxNoCheckedIconType = {
  sx?: SxProps;
  hover?: boolean;
};

export const CheckboxNoCheckedIcon = ({ sx, hover }: CheckboxNoCheckedIconType) => {
  return (
    <SvgIcon
      sx={{
        width: 20,
        height: 20,
        ...sx,
      }}
    >
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='0.5'
          y='0.5'
          width='19'
          height='19'
          rx='3.5'
          fill={hover ? '#CDD7E1' : '#FBFCFE'}
          stroke={'#CDD7E1'}
        />
      </svg>
    </SvgIcon>
  );
};
