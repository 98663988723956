import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { DragDropContext, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { HeaderTable } from '../HeaderTable/HeaderTable';
import { BodyThredContent } from '../BodyThredContent/BodyThredContent';
import {
  useThreadsAndCountQuery,
  useSortThreadsMutation,
} from '../../../graphql/generated/graphql';
import { useWorkspaceContext } from '../../../context';
import { useEffect, useState } from 'react';
import { THREADS_AND_COUNT } from '../../../graphql/queries/threadsAndCount.gql';
import { AuthorSelectFilter, StatusThreadTypes } from '../../../types/enum';

export interface DataItem {
  uuid: string;
  description: string;
  unitPrice: number;
  quantity: number;
}

type FilterTablesType = {
  emptyCellWidth: string;
  titleCellWidth: string;
  hanldeCreateThread: () => void;
  selectAuthorUser: string;
  filterStatus: StatusThreadTypes;
};

export const FilterTables = ({
  emptyCellWidth,
  titleCellWidth,
  hanldeCreateThread,
  selectAuthorUser,
  filterStatus,
}: FilterTablesType) => {
  const [sortThreadsMutation] = useSortThreadsMutation({
    refetchQueries: [THREADS_AND_COUNT],
  });

  const [isDraggingOccurs, setIsDraggingOccurs] = useState(false);

  const [threadList, setThreadList] = useState<Array<any>>();

  const handleDragStart = () => {
    setIsDraggingOccurs(true);
  };

  const handleDragEnd = (result: any) => {
    if (!threadList) return;
    setIsDraggingOccurs(false);
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const items = [...threadList];
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    const sortMyThreads = items.map((e, index) => {
      return { threadId: e.id, order: index };
    });

    sortThreadsMutation({
      variables: {
        data: {
          threadSortOrders: sortMyThreads,
        },
      },
    });
    setThreadList(items);
  };

  const headerOptions = [
    { title: '', width: emptyCellWidth, isEmpty: true },
    { title: 'Название', width: titleCellWidth, isEmpty: false },
    { title: 'Ответственный', isEmpty: false },
    { title: 'Сроки', isEmpty: false },
    { title: 'Проект', isEmpty: false },
    { title: '', width: emptyCellWidth, isEmpty: true },
  ];

  const { workspaceId } = useWorkspaceContext();

  const { data } = useThreadsAndCountQuery({
    variables: {
      options: {
        fromTheWorkspaceId: workspaceId,
        isAuthor: selectAuthorUser === AuthorSelectFilter.isAuthor,
        isSubscriber: selectAuthorUser === AuthorSelectFilter.isSubscriber,
        isResponsible: selectAuthorUser === AuthorSelectFilter.isResponsible,
        statusId: filterStatus,
      },
    },
    skip: !workspaceId,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setThreadList(data?.threadsAndCount?.rows);
  }, [data]);

  return (
    <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 150px)' }}>
      <Table sx={{ minWidth: 700 }} stickyHeader>
        <HeaderTable options={headerOptions} />
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <Droppable droppableId='droppable'>
            {(droppableProvided: DroppableProvided) => (
              <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                <BodyThredContent
                  titleCellWidth={titleCellWidth}
                  emptyCellWidth={emptyCellWidth}
                  firstProjectThreаdList={threadList}
                  hanldeCreateThread={hanldeCreateThread}
                  isDraggingOccurs={isDraggingOccurs}
                />
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};
