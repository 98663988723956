import { BASE_URL } from '../constants/constants';

type DownloadFileType = {
  path?: string;
  fileName: string;
  file?: File;
};

export const downloadFile = ({ path, fileName, file }: DownloadFileType) => {
  let url: string | undefined;

  if (file) {
    url = window.URL.createObjectURL(file);
  } else if (path) {
    url = `${BASE_URL}${path}`;
  }

  if (!url) return;

  const link = document.createElement('a');
  link.href = url;
  link.target = path || '_blank';
  link.download = fileName || 'download';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  if (file) {
    window.URL.revokeObjectURL(url);
  }
};
