import { Stack } from '@mui/material';
import { Item } from './Item/Item';
import { LogsHistoryResponseEntity } from '../../../graphql/generated/graphql';
import { useUpdateThreadHistory } from './hook/useUpdateThreadHistory';

type HistoryType = { threadId?: number; chatId?: number; wrapperHeight: number };

export const History = ({ threadId, wrapperHeight }: HistoryType) => {
  const { data } = useUpdateThreadHistory({ threadId });

  const historyList = data?.thread.history;
  return (
    <Stack position={'relative'} sx={{ maxHeight: `calc(${wrapperHeight}px - 35px)` }}>
      <Stack sx={{ maxHeight: '100%', overflowY: 'auto' }}>
        {historyList?.map((item, index: number) => {
          return <Item key={index} item={item as LogsHistoryResponseEntity} />;
        })}
      </Stack>
    </Stack>
  );
};
