import { Divider, Stack, useTheme } from '@mui/material';
import { useClickCellPopup } from '../../../hooks';
import { PopoverWorkspaceHub, StackWorkspaceHubStyle, TitleTypography } from './style';
import { WorkspacesList } from './components';
import { WorkspacesOptions } from './components/WorkspacesOptions/WorkspacesOptions';
import { useWorkspaceContext } from '../../../context';
import { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOCATION_ID_QUERY_PARAMETER } from '../../../types/enum';
import { AvatarCustom } from '../../AvatarCustom/AvatarCustom';
import { User } from '../../../graphql/generated/graphql';

const useControlSyncParams = () => {
  const {
    selectFolderId,
    selectProjectId,
    workspaceId,
    setSelectProjectId,
    setWorkspaceId,
    setSelectFolderId,
  } = useWorkspaceContext();
  const navigate = useNavigate();

  enum SyncActionTypes {
    SYNC_CONTEXT = 'SYNC-CONTEXT',
    SYNC_QUERY_PARAMS = 'SYNC-QUERY-PARAMS',
  }

  type SyncStateType = {
    actionType: null | SyncActionTypes;
  };

  type SyncAction = { type: SyncActionTypes.SYNC_CONTEXT | SyncActionTypes.SYNC_QUERY_PARAMS };

  const syncReducer = (state: SyncStateType, action: SyncAction) => {
    switch (action.type) {
      case SyncActionTypes.SYNC_CONTEXT: {
        return { actionType: SyncActionTypes.SYNC_CONTEXT };
      }
      case SyncActionTypes.SYNC_QUERY_PARAMS: {
        return { actionType: SyncActionTypes.SYNC_QUERY_PARAMS };
      }
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(syncReducer, {
    actionType: null,
  });

  useEffect(() => {
    if (state.actionType === SyncActionTypes.SYNC_CONTEXT) {
      const queryParams = new URLSearchParams();
      if (workspaceId) queryParams.set(LOCATION_ID_QUERY_PARAMETER.workspaceId, `${workspaceId}`);
      if (selectFolderId)
        queryParams.set(LOCATION_ID_QUERY_PARAMETER.folderId, `${selectFolderId}`);
      if (selectProjectId)
        queryParams.set(LOCATION_ID_QUERY_PARAMETER.projectId, `${selectProjectId}`);
      navigate(
        {
          search: queryParams.toString(),
        },
        { replace: true },
      );
    }
    if (state.actionType === SyncActionTypes.SYNC_QUERY_PARAMS) {
      const queryParams = new URLSearchParams(window.location.search);
      const workspaceIdQuery = parseInt(
        queryParams.get(LOCATION_ID_QUERY_PARAMETER.workspaceId) || '',
      );
      const folderIdQuery = parseInt(queryParams.get(LOCATION_ID_QUERY_PARAMETER.folderId) || '');
      const projectIdQuery = parseInt(queryParams.get(LOCATION_ID_QUERY_PARAMETER.projectId) || '');

      if (workspaceIdQuery && workspaceIdQuery !== workspaceId) {
        setWorkspaceId(workspaceIdQuery);
      }
      if (folderIdQuery !== selectFolderId) {
        setSelectFolderId(folderIdQuery);
      }
      if (projectIdQuery !== selectProjectId) {
        setSelectProjectId(projectIdQuery);
      }
    }
  }, [state]);

  useEffect(() => {
    dispatch({ type: SyncActionTypes.SYNC_CONTEXT });
  }, [selectFolderId, selectProjectId, workspaceId]);

  useEffect(() => {
    const handleQueryParamsChange = () => {
      dispatch({ type: SyncActionTypes.SYNC_QUERY_PARAMS });
    };
    window.addEventListener('popstate', handleQueryParamsChange);
    return () => {
      window.removeEventListener('popstate', handleQueryParamsChange);
    };
  }, []);
};

export const WorkspaceHub = () => {
  const theme = useTheme();
  const { currentWorkspace } = useWorkspaceContext();
  useControlSyncParams();

  const { anchorEl, open, handleClick, handleClose, refClickOutside } = useClickCellPopup({});
  return (
    <>
      <StackWorkspaceHubStyle
        ref={refClickOutside}
        onClick={handleClick}
        className={open ? 'open' : ''}
      >
        <AvatarCustom
          user={{ email: currentWorkspace?.title || '', avatar: currentWorkspace?.avatar } as User}
          sx={{ marginRight: '8px' }}
        />

        <TitleTypography fontWeight={500} color='black600'>
          {currentWorkspace?.title}
        </TitleTypography>
      </StackWorkspaceHubStyle>
      <PopoverWorkspaceHub
        anchorReference='anchorPosition'
        anchorPosition={{ top: 107, left: 16 }}
        sx={{
          marginTop: '5px',
          height: 'calc(100vh - 107px)',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Stack>
          <WorkspacesList />
          <Divider sx={{ backgroundColor: theme.palette.neutralOutlinedBorder.main }} />
          <WorkspacesOptions />
        </Stack>
      </PopoverWorkspaceHub>
    </>
  );
};
