import { CloseIconStyle } from './style';
import { Button, useTheme } from '@mui/material';

type CloseButtonType = {
  onClick: () => void;
};

export const CloseButton = ({ onClick }: CloseButtonType) => {
  const theme = useTheme();

  return (
    <Button variant='icons' onClick={onClick}>
      <CloseIconStyle sx={{ color: theme.palette.black600 }} />
    </Button>
  );
};
