import React, { createContext, useContext, ReactNode, useState, useEffect, useRef } from 'react';
import {
  useProjectsQuery,
  ProjectsQuery,
  useWorkspacesQuery,
  WorkspacesQuery,
  useWorkspaceQuery,
  WorkspaceQuery,
  WorkspaceMemberRole,
} from '../graphql/generated/graphql';
import { RequestHandler } from '../components/RequestHandler/RequestHandler';
import { useAuthContext } from './AuthContext';
import { LOCATION_ID_QUERY_PARAMETER } from '../types/enum';

type WorkspaceContextType = {
  projects?: ProjectsQuery['projects'];
  currentWorkspace?: WorkspaceQuery['workspace'];
  workspaceId?: number;
  setWorkspaceId: (value?: number) => void;
  workspaces?: WorkspacesQuery['workspaces'];
  selectProjectId: number | null;
  setSelectProjectId: (value: number | null) => void;
  selectFolderId: number | null;
  setSelectFolderId: (value: number | null) => void;
  isEditableProject: boolean;
  setIsEditableProject: (value: boolean) => void;
  isEditableFolder: boolean;
  setIsEditableFolder: (value: boolean) => void;
  myWorkspaceRole?: WorkspaceMemberRole;
};

const WorkspaceContext = createContext<WorkspaceContextType | undefined>(undefined);

type WorkspaceProviderPropsType = {
  children: ReactNode;
};

const getInitParametrValue = (name: string) => {
  const currentUrl = window.location.href;
  const searchParams = new URLSearchParams(new URL(currentUrl).search);
  return parseInt(searchParams.get(name) as string);
};

const WorkspaceProvider = ({ children }: WorkspaceProviderPropsType) => {
  const { isAuth } = useAuthContext();
  const { data: workspacesData, loading: workspacesLoading } = useWorkspacesQuery({
    skip: !isAuth,
    fetchPolicy: 'cache-and-network',
  });

  const workspaceIdQuery = getInitParametrValue(LOCATION_ID_QUERY_PARAMETER.workspaceId);
  const [workspaceId, setWorkspaceId] = useState<number | undefined>(workspaceIdQuery);

  const { data, loading } = useProjectsQuery({
    variables: {
      options: {
        workspaceId: Number(workspaceId),
      },
    },
    skip: !workspaceId || !isAuth,
    fetchPolicy: 'cache-and-network',
  });
  const { data: currentWorkspaceData, loading: currentWorkspaceLoading } = useWorkspaceQuery({
    variables: {
      workspaceId: Number(workspaceId),
    },
    skip: !workspaceId || !isAuth,
  });
  const [selectProjectId, setSelectProjectId] = useState<number | null>(
    getInitParametrValue(LOCATION_ID_QUERY_PARAMETER.projectId),
  );
  const [selectFolderId, setSelectFolderId] = useState<number | null>(
    getInitParametrValue(LOCATION_ID_QUERY_PARAMETER.folderId),
  );

  const isInit = useRef(true);

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      return;
    }
    setSelectFolderId(null);
  }, [selectProjectId]);

  useEffect(() => {
    if (!isAuth) setSelectProjectId(null);
  }, [isAuth]);

  const [isEditableProject, setIsEditableProject] = useState(false);
  const [isEditableFolder, setIsEditableFolder] = useState(false);

  useEffect(() => {
    if (workspacesData) {
      const index = workspacesData.workspaces.map((e) => e.id).indexOf(workspaceIdQuery);

      if (index === -1) {
        setWorkspaceId(workspacesData.workspaces[0]?.id);
      }
    }
  }, [workspacesData]);

  const isLoading = !(!workspacesLoading && !loading && !currentWorkspaceLoading);

  return (
    <RequestHandler loading={isLoading}>
      <WorkspaceContext.Provider
        value={{
          projects: data?.projects,
          workspaces: workspacesData?.workspaces,
          currentWorkspace: currentWorkspaceData?.workspace,
          myWorkspaceRole: currentWorkspaceData?.workspace?.myRole,
          selectProjectId,
          setSelectProjectId,
          workspaceId,
          setWorkspaceId,
          selectFolderId,
          setSelectFolderId,
          setIsEditableProject,
          isEditableProject,
          isEditableFolder,
          setIsEditableFolder,
        }}
      >
        {children}
      </WorkspaceContext.Provider>
    </RequestHandler>
  );
};

const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext);
  if (context === undefined) {
    throw new Error('useWorkspace must be used within a WorkspaceProvider');
  }
  return context;
};

export { WorkspaceProvider, useWorkspaceContext };
