import React, { useContext, useEffect } from 'react';
import { CurrentUserQuery, useCurrentUserQuery } from '../graphql/generated/graphql';
import { Preloader } from '../components';
import { client } from '../graphql/client';
import { LOCAL_STORAGE_KEY } from '../constants/constants';

type AuthContextType = {
  isAuth: boolean;
  signIn: (token: string, rememberMe: boolean) => void;
  signOut: () => void;
  currentUser?: CurrentUserQuery['currentUser'];
};

export const AuthContext = React.createContext<AuthContextType>({
  isAuth: false,
  signIn: () => {},
  signOut: () => {},
  currentUser: undefined,
});

export function useAuthContext() {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}

type AuthContextProviderType = {
  children: React.ReactNode;
};

export function AuthContextProvider({ children }: AuthContextProviderType) {
  const token =
    sessionStorage.getItem(LOCAL_STORAGE_KEY.TOKEN) ||
    localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN);

  const { data, loading } = useCurrentUserQuery({
    skip: !token,
    fetchPolicy: 'cache-and-network',
  });
  const [state, dispatch] = React.useReducer(authReducer, {
    isAuth: false,
    isInit: false,
  });

  useEffect(() => {
    if (!loading) {
      if (data?.currentUser) {
        dispatch({ type: actionTypes.SUCCESS_AUTH });
      } else {
        dispatch({ type: actionTypes.INIT });
      }
    }
  }, [data, loading]);

  const signIn = (token: string, rememberMe: boolean) => {
    if (rememberMe) {
      localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, token);
    } else {
      sessionStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, token);
    }

    dispatch({ type: actionTypes.SUCCESS_AUTH });
  };

  const signOut = () => {
    dispatch({ type: actionTypes.LOGOUT });
    localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
    sessionStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
    client.clearStore();
  };

  if (!state.isInit) {
    return <Preloader />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuth: state.isAuth,
        signIn: signIn,
        signOut: signOut,
        currentUser: data?.currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

type AuthStateType = {
  isAuth: boolean;
  isInit: boolean;
};

type ActionType = { type: 'SUCCESS_AUTH' } | { type: 'LOGOUT' } | { type: 'INIT' };

function authReducer(state: AuthStateType, action: ActionType) {
  switch (action.type) {
    case actionTypes.INIT: {
      return { ...state, isInit: true };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        isAuth: false,
      };
    }
    case actionTypes.SUCCESS_AUTH: {
      return {
        ...state,
        isAuth: true,
        isInit: true,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

export const actionTypes = {
  SUCCESS_AUTH: 'SUCCESS_AUTH',
  LOGOUT: 'LOGOUT',
  INIT: 'INIT',
} as const;
