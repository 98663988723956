import { useNavigate, useLocation } from 'react-router-dom';

export const useNavigateSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  type NavigateSearchParamsType = {
    path: string;
    saveSearchParams?: boolean;
  };

  const navigateSearchParams = ({ path, saveSearchParams = true }: NavigateSearchParamsType) => {
    const currentSearchParams = location.search;

    if (!saveSearchParams) return navigate(path);

    return navigate(`${path}${currentSearchParams}`);
  };

  return { navigateSearchParams };
};
