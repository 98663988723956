import React from 'react';
import { useTheme, Tooltip } from '@mui/material';
import { StackStyle, CheckboxStyle } from './style';
import { CheckboxCheckedIcon, CheckboxNoCheckedIcon } from '../../icons/index';
import { AutocompliteUsersOptionType } from '../../../types/types';
import { AvatarCustom } from '../../AvatarCustom/AvatarCustom';
import { User } from '../../../graphql/generated/graphql';

type RenderOptionsMembersType = {
  props: React.HTMLAttributes<HTMLLIElement>;
  selected?: boolean;
  isMultiple?: boolean;
  option: AutocompliteUsersOptionType;
};

export const RenderOptionsMembers = ({
  props,
  option,
  selected,
  isMultiple,
}: RenderOptionsMembersType) => {
  const theme = useTheme();

  const renderTitle = () => {
    if (!option?.users) return;
    return `${option?.users?.map((e) => e.title)?.join(', ')}`;
  };

  return (
    <Tooltip
      title={renderTitle()}
      PopperProps={{
        style: { zIndex: 9999 },
      }}
      placement='right-start'
    >
      <StackStyle
        {...props}
        sx={{
          borderBottom: option.last ? `1px solid ${theme.palette.neutralOutlinedBorder.main}` : '',
        }}
      >
        {isMultiple && (
          <CheckboxStyle
            checkedIcon={<CheckboxCheckedIcon sx={{ marginRight: '8px' }} />}
            icon={<CheckboxNoCheckedIcon sx={{ marginRight: '8px' }} />}
            checked={selected}
          />
        )}
        <AvatarCustom user={{ email: option.title, avatar: option.avatar } as User} />
        {option.title}
      </StackStyle>
    </Tooltip>
  );
};
