import React, { createContext, ReactElement, useContext } from 'react';
import { REGISTRATION_STEP } from '../const';
import { IRegistrationData } from '../../../hooks/useRegistration';

type Props = {
  registrationData: IRegistrationData;
  updateData: (changes: Partial<IRegistrationData>) => void;
  handleGoToStep: (step: REGISTRATION_STEP) => void;
  children: ReactElement;
};

type RegistrationContextTypes = {
  registrationData: IRegistrationData;
  updateData: (changes: Partial<IRegistrationData>) => void;
  handleGoToStep: (step: REGISTRATION_STEP) => void;
};

const RegistrationContext = createContext<RegistrationContextTypes | null>(null);

export function RegistrationProvider(props: Props) {
  return (
    <RegistrationContext.Provider
      value={{
        registrationData: props.registrationData,
        updateData: props.updateData,
        handleGoToStep: props.handleGoToStep,
      }}
    >
      {props.children}
    </RegistrationContext.Provider>
  );
}

export const useRegistrationContext = () => {
  const context = useContext(RegistrationContext);
  if (!context) {
    throw new Error('sorry man.');
  }
  return context;
};
