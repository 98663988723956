import FilterListIcon from '@mui/icons-material/FilterList';

import { Stack, SxProps, FormControl, MenuItem, Button, Menu, useTheme } from '@mui/material';
import { useFormContext, useController } from 'react-hook-form';
import {
  StatusThreadTableButtonType,
  OutsideClickFlagType,
  OriginVerticalType,
  OriginHorizontalType,
} from '../../../types/enum';
import { useState, useRef } from 'react';
import { BoxStyle } from './style';
import { statusOptions } from '../StatusThreadModal/StatusThreadModal';
import { OriginType } from '../../../types/types';

type StatusThreadTableRHFType = {
  sx?: SxProps;
  type?: string;
  name: string;
};

export const StatusThreadTableRHF = ({
  sx,
  type = StatusThreadTableButtonType.filter,
  name,
}: StatusThreadTableRHFType) => {
  const methods = useFormContext();

  const {
    field: { value, onChange },
  } = useController({
    name,
    control: methods.control,
  });

  return <StatusThreadTable sx={sx} onChange={(e) => onChange(e)} value={value} type={type} />;
};

type StatusThreadTableType = {
  sx?: SxProps;
  type?: string;
  onChange: (value: number) => void;
  value: number;
};

export const StatusThreadTable = ({
  sx,
  onChange,
  value,
  type = StatusThreadTableButtonType.filter,
}: StatusThreadTableType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (optionValue: any) => {
    onChange(optionValue);
    handleClose();
  };

  const originCalc = (): OriginType => {
    if (type === StatusThreadTableButtonType.table) {
      return {
        anchorOrigin: {
          vertical: OriginVerticalType.bottom,
          horizontal: OriginHorizontalType.left,
        },
        transformOrigin: {
          vertical: OriginVerticalType.top,
          horizontal: OriginHorizontalType.left,
        },
      };
    }

    return {
      anchorOrigin: {
        vertical: OriginVerticalType.bottom,
        horizontal: OriginHorizontalType.right,
      },
      transformOrigin: { vertical: OriginVerticalType.top, horizontal: OriginHorizontalType.right },
    };
  };

  return (
    <FormControl sx={sx}>
      {type === StatusThreadTableButtonType.filter && (
        <Button variant='tableFilter' ref={buttonRef} onClick={handleClick}>
          <FilterListIcon />
          Фильтр
        </Button>
      )}
      {type === StatusThreadTableButtonType.table && value && (
        <BoxStyle ref={buttonRef} onClick={handleClick} component='span'>
          {statusOptions?.find((e) => e?.value === value)?.icons}
        </BoxStyle>
      )}
      <MenuWrapper
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        origin={originCalc()}
        value={value}
        handleMenuItemClick={handleMenuItemClick}
      />
    </FormControl>
  );
};

type MenuWrapperType = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  origin: OriginType;
  value: number;
  handleMenuItemClick: (value: number) => void;
};

export const MenuWrapper = ({
  anchorEl,
  open,
  handleClose,
  origin,
  value,
  handleMenuItemClick,
}: MenuWrapperType) => {
  const theme = useTheme();

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'customized-button',
      }}
      anchorOrigin={origin.anchorOrigin}
      transformOrigin={origin.transformOrigin}
      slotProps={{
        paper: {
          sx: {
            marginTop: '5px',
            borderRadius: '6px',
            border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
            background: theme.palette.backgroundSurface.main,
            boxShadow: theme.palette.boxShadowPopper.main,
            '& .MuiList-root': {
              padding: '6px 0px',
              width: '180px',
            },
          },
        },
      }}
    >
      {statusOptions?.map((e) => {
        return (
          <MenuItem
            sx={{
              backgroundColor: value === e.value ? theme.palette.backgroundLevel1.main : '',
              padding: '4px 8px !important',
            }}
            value={e.value}
            key={e.value}
            onClick={() => handleMenuItemClick(e.value)}
            className={OutsideClickFlagType.outsideClickFlagDefault}
          >
            <Stack
              direction='row'
              alignItems={'center'}
              sx={{
                color: value === e.value ? theme.palette.black600 : theme.palette.textTertiary.main,
              }}
            >
              {e.icons}
              {e.title}
            </Stack>
          </MenuItem>
        );
      })}
    </Menu>
  );
};
