import { styled } from '@mui/material';
import { TextFieldRHF } from '../../../../../../../../uiComponents';

export const TextFieldStyle = styled(TextFieldRHF)(({ theme }) => ({
  border: `2px solid ${theme.palette.black600}`,
  boxSizing: 'border-box',
  borderRadius: '6px',
  width: '100%',
  alignItems: 'center',
  '& .MuiInputBase-input': {
    padding: '4px 8px ',
  },
}));
