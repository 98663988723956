import { styled, Stack } from '@mui/material';

export const StackStyle = styled(Stack)(({ theme }) => ({
  padding: '3px 8px',
  flexDirection: 'row',
  cursor: 'pointer',
  alignItems: 'center',
  width: '220px',
  '&:hover': {
    borderRadius: '6px',
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
  },
  '& svg': {
    width: '20px',
    height: '20px',
  },
}));
