import { Typography } from '@mui/material';
import { useAuthContext, useModalContext } from '../../../../../context';
import { BoxStyle, StackStyle } from './style';
import { ModalStatuses } from '../../../../../types/enum';
import { ROUTES } from '../../../../../constants/constants';
import { useNavigate } from 'react-router-dom';

export const WorkspacesOptions = () => {
  const navigate = useNavigate();
  const { handleChangeModalParameters } = useModalContext();
  const { signOut } = useAuthContext();

  const handleCreateWorkspace = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.CREATE_WORKSPACE_MODAl,
      },
    ]);
  };
  const handleInviteWorkspace = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.INVITE_WORKSPACE_MODAl,
      },
    ]);
  };

  const handleSettingsWorkspace = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.SETTINGS_WORKSPACE_MODAl,
      },
      {
        key: 'createNewWorkspace',
        value: () => {
          navigate(ROUTES.createWorkspace);
        },
      },
    ]);
  };

  const workspaceOptions = [
    {
      title: 'Создать рабочую область',
      onClick: handleCreateWorkspace,
    },
    {
      title: 'Настройки',
      onClick: handleSettingsWorkspace,
    },
    {
      title: 'Пригласить',
      onClick: handleInviteWorkspace,
    },
    { title: 'Выйти', onClick: signOut },
  ];

  return (
    <StackStyle>
      {workspaceOptions.map((workspaceOption, index) => (
        <WorkspaceOption
          title={workspaceOption.title}
          onClick={workspaceOption.onClick}
          key={index}
        />
      ))}
    </StackStyle>
  );
};
type WorkspaceOptionType = {
  title: string;
  onClick: () => void;
};
const WorkspaceOption = ({ title, onClick }: WorkspaceOptionType) => {
  return (
    <BoxStyle onClick={onClick}>
      <Typography fontWeight={500} color='textTertiary.main' fontSize={12}>
        {title}
      </Typography>
    </BoxStyle>
  );
};
