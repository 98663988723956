import { gql } from '@apollo/client';

export const WORKSPACES = gql`
  query workspaces {
    workspaces {
      __typename
      avatar {
        path
        id
      }
      members {
        role
        user {
          email
          firstName
          lastName
          id
          avatar {
            path
            id
          }
        }
      }
      isInviteLinkAccess
      invites {
        email
        id
      }
      inviteLink
      id
      myRole
      title
    }
  }
`;
