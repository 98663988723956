import { TableBody, useTheme } from '@mui/material';
import { EmptyRow } from '../EmptyRow/EmptyRow';
import { AddRows } from '../../AddRows/AddRows';
import { TypeTableRow } from '../../../../types/enum';
import { useState } from 'react';
import { GoalRowAdd } from './GoalRowAdd/GoalRowAdd';

type GoalCreateType = {
  emptyCellWidth: string;
  titleCellWidth: string;
};

export const GoalCreate = ({ emptyCellWidth }: GoalCreateType) => {
  const theme = useTheme();
  const [isCreateGoal, setIsCreateGoal] = useState(false);
  const handleSetIsCreateGoal = () => {
    setIsCreateGoal(true);
  };

  return (
    <TableBody sx={{ borderTop: `1px solid ${theme.palette.white.main}` }}>
      {isCreateGoal && (
        <>
          <EmptyRow />
          <GoalRowAdd setCreateGoal={setIsCreateGoal} emptyWipth={emptyCellWidth} />
        </>
      )}
      <EmptyRow />
      <AddRows
        text='Добавить цель'
        emptyCellWidth={emptyCellWidth}
        type={TypeTableRow.goal}
        onCallbackOuther={handleSetIsCreateGoal}
        onCallback={handleSetIsCreateGoal}
        isDraggingOccurs={false}
      />
    </TableBody>
  );
};
