import { StackStyle, ImageStyle } from './style';

type SingleImageModalType = {
  singleImagePath: string;
};

export const SingleImageModal = ({ singleImagePath }: SingleImageModalType) => {
  return (
    <StackStyle>
      <ImageStyle src={singleImagePath} alt='Preview' />
    </StackStyle>
  );
};
