import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { MessageChatType } from '../../../../../types/types';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TypographyStyle, ImageStyle, StackStyle } from './style';
import { createAttachmentImage, userNameCalc } from '../../../../../utils';

type ReplyType = {
  reply: MessageChatType | null;
  setReply: (value: MessageChatType | null) => void;
};

export const Reply = ({ reply, setReply }: ReplyType) => {
  const attachFile = reply?.attachments[0];

  const { imageUrl } = createAttachmentImage({
    path: attachFile?.path,
    mimeType: attachFile?.mimeType,
  });
  const theme = useTheme();

  return (
    <StackStyle>
      {attachFile && <ImageStyle src={imageUrl} alt={attachFile?.fileName} />}
      <Stack width={'90%'}>
        <Typography variant='customDefault' sx={{ color: theme.palette.textPrimary.main }}>
          {'В ответ '}
          {userNameCalc({
            email: reply?.author?.email,
            firstName: reply?.author?.firstName,
            lastName: reply?.author?.lastName,
          })}
        </Typography>
        <TypographyStyle>{reply?.content ? reply?.content : attachFile?.fileName}</TypographyStyle>
      </Stack>
      <IconButton
        sx={{ width: '32px', height: '32px' }}
        onClick={() => {
          setReply(null);
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
    </StackStyle>
  );
};
