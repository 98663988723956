type ClassValue = string | Record<string, boolean> | Array<ClassValue>;

export function conditionalClassNames(...args: ClassValue[]): string {
  const classes: string[] = [];

  function addClass(arg: ClassValue) {
    if (typeof arg === 'string') {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      arg.forEach(addClass);
    } else if (typeof arg === 'object') {
      for (const key in arg) {
        if (arg[key]) {
          classes.push(key);
        }
      }
    }
  }

  args.forEach(addClass);

  return classes.join(' ');
}
