import { gql } from '@apollo/client';

export const THREADS_AND_COUNT = gql`
  query threadsAndCount($options: FindThreadsOptions!) {
    threadsAndCount(options: $options) {
      rows {
        id
        title
        description
        startDate
        endDate
        statusId
        order
        path {
          folder {
            id
            title
          }
          project {
            id
            title
          }
          goal {
            id
            title
          }
        }
        responsible {
          id
          email
          lastName
          firstName
          avatar {
            path
          }
        }
        responsibleUserGroup {
          id
          title
          avatar {
            path
          }
        }
      }
      count
    }
  }
`;
