import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/constants';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { CreateNewPasswordForm } from './Form';

export const CreateNewPassword = () => {
  const navigate = useNavigate();
  const handleNavigateToLogin = () => navigate(ROUTES.login);
  return (
    <>
      <Typography variant='h1' mb='8px'>
        Сброс пароля
      </Typography>
      <Typography variant='h4' maxWidth='448px' textAlign='center'>
        Выберите новый пароль
      </Typography>
      <CreateNewPasswordForm />
      <Stack flexDirection='row' mt='24px'>
        <Typography variant='h4' mr='4px'>
          Вспомнили пароль?
        </Typography>
        <Button onClick={handleNavigateToLogin} variant='text'>
          Войти
        </Button>
      </Stack>
    </>
  );
};
