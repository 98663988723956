import { ListItemButtonStyle } from '../style';
import { useEffect, useState } from 'react';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { FormProvider } from 'react-hook-form';

import { Box, ListItemIcon, useTheme } from '@mui/material';
import { PositionedPopper, TextFieldRHF } from '../../../index';
import { ProjectPopperContent } from '../ProjectPopperContent/ProjectPopperContent';
import { useClickOutside, useAutoFocusLineEnd } from '../../../../hooks';

import { useModalContext, useWorkspaceContext } from '../../../../context/index';
import { ModalStatuses, SizeOptions } from '../../../../types/enum';
import { FoldersType } from '../../../../types/types';
import { useProjectEditable } from '../hooks/index';
import { CollapseWrapper } from './CollapseWrapper/CollapseWrapper';
import { ListItemTextStyle } from './style';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/constants';
import { VisibilityStatusEnum } from '../../../../graphql/generated/graphql';
import { ShowRightIcons } from './ShowRightIcons/ShowRightIcons';

type ListItemButtonWrapperType = {
  projectTitle: string;
  projectId: number;
  visibilityStatus: VisibilityStatusEnum;
  folders?: FoldersType;
};

export const ListItemButtonWrapper = ({
  projectTitle,
  projectId,
  folders,
  visibilityStatus,
}: ListItemButtonWrapperType) => {
  const [isAddFolder, setIsAddFolder] = useState(false);

  const {
    selectProjectId,
    isEditableFolder,
    setSelectProjectId,
    setIsEditableProject,
    isEditableProject,
    selectFolderId,
    setSelectFolderId,
  } = useWorkspaceContext();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(selectProjectId === projectId);
  }, [selectProjectId, isEditableFolder]);

  const [isEditable, setIsEditable] = useState(false);
  const { refClickOutside } = useClickOutside({
    callBack: () => {
      if (selectProjectId === projectId && isEditableProject) {
        setIsEditableProject(false);
      }
      setIsEditable(false);
    },
  });
  useAutoFocusLineEnd({
    ref: refClickOutside,
    trigger: isEditable,
  });

  const navigation = useNavigate();
  const handleClick = () => {
    navigation(ROUTES.home);
    setSelectProjectId(projectId);
    if (selectProjectId === projectId) {
      if (selectFolderId) {
        return setSelectFolderId(null);
      }
      setIsOpen((prev) => !prev);
    }
  };
  const handleSetIsEditable = () => {
    setIsEditable(true);
  };

  useEffect(() => {
    if (selectProjectId === projectId && isEditableProject) {
      handleSetIsEditable();
    }
  }, [isEditableProject]);

  const { handleChangeModalParameters } = useModalContext();

  const { methods, removeProjectMutation } = useProjectEditable({
    projectTitle,
    isEditable,
    projectId,
    setSelectProjectId,
  });

  const handleAddNewFolder = () => {
    setSelectProjectId(projectId);
    setIsAddFolder(true);
    setIsOpen(true);
  };

  const handleDeleteProject = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.PROJECT_DELETE_MODAL,
      },
      {
        key: 'removeProject',
        value: removeProjectMutation,
      },
    ]);
  };

  const theme = useTheme();

  const isSelectedProject = projectId === selectProjectId && !isEditable && !selectFolderId;

  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  return (
    <>
      <ListItemButtonStyle
        onClick={handleClick}
        className={isEditable ? 'editable' : ''}
        ref={refClickOutside}
        sx={{
          backgroundColor: isSelectedProject
            ? `${theme.palette.backgroundLevel1.main} !important`
            : '',
          height: isEditable ? 'auto' : '32px',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        <ListItemIcon
          sx={{
            minWidth: '24px',
            paddingRight: '8px',
          }}
        >
          <BusinessCenterIcon />
        </ListItemIcon>
        <FormProvider {...methods}>
          {isEditable ? (
            <Box
              component={'span'}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <TextFieldRHF
                fullWidth
                placeholder='Название'
                minRows={1}
                name='title'
                type='text'
                multiline
                size={SizeOptions.small}
                autoFocus={true}
              />
            </Box>
          ) : (
            <>
              <ListItemTextStyle
                primary={projectTitle}
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight: isSelectedProject ? `500` : '400',
                  },
                }}
              />
            </>
          )}
        </FormProvider>
        <Box
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <PositionedPopper
            ClickComponent={
              <ShowRightIcons
                visibilityStatus={visibilityStatus}
                isHover={isHover}
                isEditable={isEditable}
              />
            }
            PopperContent={
              <ProjectPopperContent
                handleSetIsEditable={handleSetIsEditable}
                handleDeleteProject={handleDeleteProject}
                handleAddNewFolder={handleAddNewFolder}
              />
            }
          />
        </Box>
      </ListItemButtonStyle>
      <CollapseWrapper
        isOpen={isOpen}
        isAddFolder={isAddFolder}
        setIsAddFolder={setIsAddFolder}
        projectId={projectId}
        folders={folders}
        visibilityStatus={visibilityStatus}
      />
    </>
  );
};
