import { WorkspaceMemberRole } from '../graphql/generated/graphql';

export function getRoleTitle(role: WorkspaceMemberRole | string) {
  switch (role) {
    case WorkspaceMemberRole.Creator:
      return 'Создатель';
    case WorkspaceMemberRole.Admin:
      return 'Админ';
    case WorkspaceMemberRole.Member:
      return 'Участник';
  }
}
