import { Attachments } from '../../index';
import { useForm, FormProvider } from 'react-hook-form';
import { AttachmentsClickType } from '../../../types/enum';
import { AttachmentFile } from '../../../types/types';

import { StackWrapperStyle } from './style';
import { useEffect } from 'react';

type EditFilesType = {
  threadId?: number;
  attachments?: Array<AttachmentFile>;
};

export const EditFiles = ({ threadId, attachments }: EditFilesType) => {
  type FormData = {
    files: Array<AttachmentFile | null>;
  };

  const methods = useForm<FormData>({
    defaultValues: {
      files: attachments,
    },
  });
  const { reset } = methods;
  useEffect(() => {
    reset({ files: attachments });
  }, [attachments]);

  return (
    <FormProvider {...methods}>
      <StackWrapperStyle>
        <Attachments name='files' type={AttachmentsClickType.ThreadItem} threadId={threadId} />
      </StackWrapperStyle>
    </FormProvider>
  );
};
