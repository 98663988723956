import * as yup from 'yup';

import { REQUIRE_MESSAGE } from './messages';

export const ProjectSchema = yup.object().shape({
  title: yup.string().required(REQUIRE_MESSAGE),
  visibilityStatus: yup.boolean().required(REQUIRE_MESSAGE),
  users: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().required(REQUIRE_MESSAGE),
        value: yup.number().required(REQUIRE_MESSAGE),
        type: yup.string().required(REQUIRE_MESSAGE),
      }),
    )
    .nullable()
    .optional(),
});
