import { styled, Checkbox } from '@mui/material';

export const StackStyle = styled('li')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  '& .MuiAutocomplete-option': {
    padding: '8px',
  },
  flexDirection: 'row',
}));
export const CheckboxStyle = styled(Checkbox)(() => ({
  padding: '0px',
  width: '20px',
  height: '20px',
  '& .MuiBox-root': { width: '20px', height: '20px' },
}));
