import { Stack, Tooltip, useTheme } from '@mui/material';
import { ModalParametersTypes, FilesFilterItem } from '../../../../types/types';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IconButtonStyle, ImageStyle, StackButtonStyle, IconButtonControllStyle } from './style';
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useState, useEffect, MouseEvent } from 'react';
import { createAttachmentImage, downloadFile } from '../../../../utils';

type ListImageModalType = {
  modalParameters: ModalParametersTypes;
  handleCloseModal: () => void;
};

export const ListImageModal = ({ modalParameters, handleCloseModal }: ListImageModalType) => {
  const imageList = modalParameters?.imageList;
  const initSelectId = modalParameters?.selectImageListId;

  const foundIndex = imageList?.findIndex((e) => e.id === initSelectId);

  const [selectIndex, setSelectIndex] = useState<number>(foundIndex || 0);
  const [selectImage, setSelectImage] = useState<FilesFilterItem | null>(null);

  useEffect(() => {
    if (imageList && imageList.length > 0) {
      setSelectImage(imageList[selectIndex >= 0 ? selectIndex : 0]);
    }
  }, [imageList, selectIndex]);

  const handleLeft = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const newIndex = selectIndex - 1;
    if (newIndex >= 0) setSelectIndex(newIndex);
  };
  const handleRight = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const newIndex = selectIndex + 1;
    if (imageList && newIndex <= imageList.length - 1) {
      setSelectIndex(newIndex);
    }
  };

  const handleLink = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const setChatLink = modalParameters?.setChatLink;
    setChatLink?.(Number(selectImage?.messageId));
    handleCloseModal();
  };

  const handleDowload = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    selectImage && downloadFile({ path: selectImage?.path, fileName: selectImage.fileName });
  };

  const { imageUrl } = createAttachmentImage({
    mimeType: selectImage?.mimeType,
    path: selectImage?.path,
  });

  const theme = useTheme();

  return (
    <Stack sx={{ position: 'relative', height: '100%' }}>
      <StackButtonStyle>
        <Tooltip
          title={'Перейти к сообщению'}
          placement='bottom-start'
          componentsProps={{
            popper: {
              sx: {
                zIndex: 99999,
                '& .MuiTooltip-tooltip': {
                  borderRadius: ' 6px',
                  background: theme.palette.neutral500.main,
                  marginTop: '7px !important',
                },
              },
            },
          }}
        >
          <IconButtonStyle onClick={handleLink}>
            <ForwardToInboxRoundedIcon sx={{ color: 'black600' }} />
          </IconButtonStyle>
        </Tooltip>

        <IconButtonStyle onClick={handleDowload} sx={{ marginLeft: '2px' }}>
          <FileDownloadOutlinedIcon sx={{ color: 'black600' }} />
        </IconButtonStyle>
      </StackButtonStyle>
      <IconButtonControllStyle onClick={handleLeft} sx={{ left: '0px' }}>
        <ArrowBackIosNewOutlinedIcon sx={{ color: 'black600' }} />
      </IconButtonControllStyle>
      <IconButtonControllStyle onClick={handleRight} sx={{ right: '0%' }}>
        <ArrowForwardIosOutlinedIcon sx={{ color: 'black600' }} />
      </IconButtonControllStyle>
      <ImageStyle
        src={imageUrl}
        alt={selectImage?.fileName}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Stack>
  );
};
