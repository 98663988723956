import React, { ReactElement, useState } from 'react';
import { REGISTRATION_STEP } from './const';
import { AtTheBeginning, ConfirmByEmail, PersonalDetails } from './components';
import { RegistrationProvider } from './context/context';
import { useRegistration } from '../../hooks/useRegistration';

interface IStep {
  component: () => ReactElement;
  stepName: REGISTRATION_STEP;
}

const stepsOfRegistration: IStep[] = [
  {
    component: AtTheBeginning,
    stepName: REGISTRATION_STEP.atTheBeginning,
  },
  {
    component: ConfirmByEmail,
    stepName: REGISTRATION_STEP.confirmByEmail,
  },
  {
    component: PersonalDetails,
    stepName: REGISTRATION_STEP.personalDetails,
  },
];

export const Registration = () => {
  const { registrationData, updateRegistrationData } = useRegistration();
  const [currentStepName, setCurrentStepName] = useState<REGISTRATION_STEP>(
    registrationData.stepName,
  );

  const handleGoToStep = (newStepName: REGISTRATION_STEP) => {
    setCurrentStepName(newStepName);
    updateRegistrationData({ stepName: newStepName });
  };

  const currentStep = stepsOfRegistration.find(
    (step) => step.stepName === currentStepName,
  ) as IStep;
  return (
    <>
      <RegistrationProvider
        registrationData={registrationData}
        updateData={updateRegistrationData}
        handleGoToStep={handleGoToStep}
      >
        <currentStep.component />
      </RegistrationProvider>
    </>
  );
};
