import { Stack, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { StackStyleHeader, ButtonAddFileStyle } from './style';
import { DefaultModal, RenameFileModal, SingleImageModal } from '../ModalLayout/Modals';
import { EditindnameDataType, FileWithPreview, AttachmentFile } from '../../types/types';
import { OutsideClickFlagType, AttachmentsClickType } from '../../types/enum';

import { PopperInner } from './PopperInner/PopperInner';
import { FileList } from './FileList/FileList';

type FileListManagerDefaultProps = {
  startEditing: (index: number) => void;
  handleDownloadFile: (fileObject: FileWithPreview | AttachmentFile) => void;
  handleRemoveFile: (index: number) => void;
  handleDownloadAllFiles: () => void;
  handleRemoveAllFiles: () => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  files: Array<FileWithPreview | AttachmentFile>;
  handleButtonClick: () => void;
  handleCloseModal: () => void;
  editindnameData: EditindnameDataType;
  setFiles: (value: any) => void;
  setEditindnameData: React.Dispatch<React.SetStateAction<EditindnameDataType>>;
  type?: AttachmentsClickType;
  loadingFiles?: boolean;
  handleClose?: () => void;
};

export const FileListManagerDefault = ({
  type = AttachmentsClickType.ThreadModal,
  startEditing,
  handleDownloadFile,
  handleRemoveFile,
  handleDownloadAllFiles,
  handleRemoveAllFiles,
  handleFileChange,
  fileInputRef,
  files,
  handleButtonClick,
  handleCloseModal,
  editindnameData,
  setFiles,
  setEditindnameData,
  loadingFiles,
  handleClose,
}: FileListManagerDefaultProps) => {
  const [singleImagePath, setSingleImagePath] = useState('');

  const handleCloseSingleModal = () => {
    setSingleImagePath('');
  };

  const [isInitDisable, setIsInitDisable] = useState(true);
  useEffect(() => {
    setIsInitDisable(false);
    if (!files.length && isInitDisable) {
      handleButtonClick();
    }
  }, []);

  return (
    <Stack>
      <StackStyleHeader direction={'row'}>
        <Typography variant='h3'>Вложения</Typography>
        <PopperInner
          handleDownloadAllFiles={handleDownloadAllFiles}
          handleRemoveAllFiles={handleRemoveAllFiles}
        />
      </StackStyleHeader>
      <input
        style={{ display: 'none' }}
        type='file'
        multiple
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <FileList
        files={files}
        startEditing={startEditing}
        handleDownloadFile={handleDownloadFile}
        handleRemoveFile={handleRemoveFile}
        loadingFiles={loadingFiles}
        handleClose={handleClose}
        setSingleImagePath={setSingleImagePath}
      />
      <ButtonAddFileStyle onClick={handleButtonClick} variant='default'>
        <AddRoundedIcon sx={{ marginRight: '8px' }} />
        Добавить файлы
      </ButtonAddFileStyle>
      <DefaultModal
        handleCloseModal={handleCloseModal}
        open={editindnameData.editingName}
        calssName={OutsideClickFlagType.outsideClickFlagDefault}
        sx={{ zIndex: '999999', width: '400px' }}
      >
        <RenameFileModal
          files={
            type === AttachmentsClickType.ThreadModal ? (files as Array<FileWithPreview>) : null
          }
          setFiles={setFiles}
          editindnameData={editindnameData}
          setEditindnameData={setEditindnameData}
          handleCloseModal={handleCloseModal}
          type={type}
          fileId={editindnameData?.fileId}
        />
      </DefaultModal>
      <DefaultModal
        open={!!singleImagePath}
        handleCloseModal={handleCloseSingleModal}
        sx={{ padding: '0px', border: 'none', borderRadius: '0px' }}
        calssName={OutsideClickFlagType.outsideClickFlagDefault}
      >
        <SingleImageModal singleImagePath={singleImagePath} />
      </DefaultModal>
    </Stack>
  );
};
