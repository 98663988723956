import { SvgIcon } from '@mui/material';

export const AuthLogo = () => {
  return (
    <SvgIcon sx={{ width: '62px', height: '38px' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='62'
        height='38'
        viewBox='0 0 62 38'
        fill='none'
      >
        <g clipPath='url(#clip0_1004_49)'>
          <path
            d='M4.73737 37.4465C3.7346 37.4465 2.88788 37.1001 2.1974 36.4074C1.50711 35.7146 1.16187 34.8653 1.16187 33.8593C1.16187 32.8532 1.50711 32.0037 2.1974 31.311C2.88788 30.6182 3.7346 30.2721 4.73737 30.2721C5.74014 30.2721 6.58668 30.6182 7.27716 31.311C7.96764 32.0037 8.31288 32.8532 8.31288 33.8593C8.31288 34.8653 7.96764 35.7146 7.27716 36.4074C6.58668 37.1001 5.74014 37.4465 4.73737 37.4465ZM4.73737 19.2629C3.7346 19.2629 2.88788 18.9165 2.1974 18.2238C1.50711 17.5311 1.16187 16.6817 1.16187 15.6756C1.16187 14.6695 1.50711 13.8202 2.1974 13.1275C2.88788 12.4347 3.7346 12.0884 4.73737 12.0884C5.74014 12.0884 6.58668 12.4347 7.27716 13.1275C7.96764 13.8202 8.31288 14.6695 8.31288 15.6756C8.31288 16.6817 7.96764 17.5311 7.27716 18.2238C6.58668 18.9165 5.74014 19.2629 4.73737 19.2629Z'
            fill='#2E2E2E'
          />
          <path
            d='M10.9636 0C10.5452 0 10.2061 0.340263 10.2061 0.76V37.2453C10.2061 37.6622 10.5428 38 10.9581 38C13.809 38 16.6645 37.6392 19.2749 36.4897C23.9361 34.4371 30.5361 30.631 36.1406 24.8258C36.5303 24.422 37.2515 24.8516 37.0631 25.3811L32.9325 36.9844C32.7564 37.4794 33.122 38 33.6459 38H41.7693C52.2286 38 60.7074 29.4933 60.7074 19C60.7074 8.50659 52.2286 0 41.7693 0H10.9636Z'
            fill='url(#paint0_linear_1004_49)'
          />
          <path
            opacity='0.8'
            d='M10.9636 0C10.5452 0 10.2061 0.340263 10.2061 0.76V37.2453C10.2061 37.6622 10.5428 38 10.9581 38C13.809 38 16.6645 37.6392 19.2749 36.4897C23.9361 34.4371 30.5361 30.631 36.1406 24.8258C36.5303 24.422 37.2515 24.8516 37.0631 25.3811L32.9325 36.9844C32.7564 37.4794 33.122 38 33.6459 38H41.7693C52.2286 38 60.7074 29.4933 60.7074 19C60.7074 8.50659 52.2286 0 41.7693 0H10.9636Z'
            fill='url(#paint1_linear_1004_49)'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_1004_49'
            x1='10.2061'
            y1='38'
            x2='46.7192'
            y2='-10.5255'
            gradientUnits='userSpaceOnUse'
          >
            <stop />
            <stop offset='0.558129' stopColor='#4E4E4E' />
            <stop offset='1' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_1004_49'
            x1='10.2061'
            y1='38'
            x2='46.7192'
            y2='-10.5255'
            gradientUnits='userSpaceOnUse'
          >
            <stop />
            <stop offset='0.558129' stopColor='#4E4E4E' />
            <stop offset='1' />
          </linearGradient>
          <clipPath id='clip0_1004_49'>
            <rect width='61' height='38' fill='white' transform='translate(0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
