import { MemberThredItem } from '../../index';
import { useForm, FormProvider } from 'react-hook-form';
import { useEffect, useState, useCallback } from 'react';
import { UpdateThreadMutationFn, User, UserGroup } from '../../../graphql/generated/graphql';
import { MemberTypes } from '../../../types/enum';
import { UserAndGroupDataType } from '../../../types/types';
import { StackStyle } from './style';
import { useWorkspaceContext } from '../../../context';
import { createTextUserAndGroup } from '../../../utils/index';

type EditThreadResponsibleType = {
  name: string;
  responsible?: UserAndGroupDataType | null;
  responsibleUserGroup?: UserAndGroupDataType | null;
  updateThreadMutation: UpdateThreadMutationFn;
  threadId?: number;
  folderId?: number;
  projectId?: number;
};

export const EditThreadResponsible = ({
  name,
  responsible,
  responsibleUserGroup,
  threadId,
  updateThreadMutation,
  folderId,
  projectId,
}: EditThreadResponsibleType) => {
  type FormData = {
    responsible: {
      title: string;
      value: number;
      type: MemberTypes;
      avatar?: {
        path: string;
      } | null;
    } | null;
  };
  const entity = responsibleUserGroup || responsible;

  const createInitValue = () => {
    if (!entity) {
      return null;
    }
    return {
      title: createTextUserAndGroup({
        user: responsible as User,
        userGroup: responsibleUserGroup as UserGroup,
      }),
      value: Number(entity.id),
      type: responsibleUserGroup ? MemberTypes.groups : MemberTypes.user,
      avatar: entity?.avatar,
    };
  };

  const { workspaceId } = useWorkspaceContext();

  const [isOpen, setIsOpen] = useState(false);

  const methods = useForm<FormData>({
    defaultValues: {
      responsible: createInitValue(),
    },
  });
  const { getValues, trigger, reset } = methods;

  const [isInitDisable, setIsInitDisable] = useState(true);

  const validateAndSubmit = useCallback(async () => {
    const isValid = await trigger();
    if (isValid) {
      const currentValues = getValues();

      const currentResponsibleValue = createInitValue();

      if (
        currentValues.responsible?.value === currentResponsibleValue?.value &&
        currentValues.responsible?.type === currentResponsibleValue?.type
      ) {
        return;
      }

      updateThreadMutation({
        variables: {
          data: {
            id: Number(threadId),
            responsibleId:
              currentValues.responsible?.type === MemberTypes.user
                ? currentValues.responsible.value
                : null,
            responsibleUserGroupId:
              currentValues.responsible?.type === MemberTypes.groups
                ? currentValues.responsible.value
                : null,
          },
        },
      });
    } else {
      reset({ responsible: createInitValue() });
    }
  }, [getValues, updateThreadMutation, threadId, reset, createInitValue]);

  useEffect(() => {
    setIsInitDisable(false);
    if (!isOpen && !isInitDisable && threadId) {
      validateAndSubmit();
    }
  }, [isOpen]);

  useEffect(() => {
    if (responsible || responsibleUserGroup) {
      methods.reset({
        responsible: createInitValue(),
      });
    }
  }, [responsible, responsibleUserGroup]);

  return (
    <FormProvider {...methods}>
      <StackStyle sx={{ paddingLeft: entity ? '2px' : '' }}>
        <MemberThredItem
          setIsOpen={setIsOpen}
          name={name}
          workspaceId={workspaceId!}
          folderId={folderId!}
          projectId={projectId!}
          isOpen={true}
          sxPopper={{
            borderRadius: '6px 0px',
            border: 'none',
          }}
          isOutControll={true}
        />
      </StackStyle>
    </FormProvider>
  );
};
