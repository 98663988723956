import { styled, Box } from '@mui/material';

export const BoxStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  borderRadius: '16px',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  backgroundColor: theme.palette.backgroundSurface?.main,
  padding: '16px',
  boxSizing: 'border-box',
  outline: 'none',
}));
