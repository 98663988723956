import { TextFieldRHF, CheckboxRHF } from '../../../index';
import CloseIcon from '@mui/icons-material/Close';
import { StackWrapperStyle, IconButtonStyle } from './style';
import { SizeOptions } from '../../../../types/enum';
import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useClickOutside } from '../../../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckTitleSchema } from '../../../../validation/index';
import {
  useUpdateChecklistItemMutation,
  useCreateChecklistItemMutation,
} from '../../../../graphql/generated/graphql';
import { THREAD } from '../../../../graphql/queries/thread.gql';

type ItemCheckType = {
  isAddItem?: boolean;
  title: string;
  isChecked: boolean;
  onRemove: () => void;
  setIsAddItem: (value: boolean) => void;
  threadId: number;
  checklistItemId?: number;
};

export const ItemCheck = ({
  onRemove,
  title,
  isChecked,
  setIsAddItem,
  threadId,
  checklistItemId,
  isAddItem,
}: ItemCheckType) => {
  type FormDataType = {
    title: string;
    isChecked?: boolean;
  };
  const [isActive, setIsActive] = useState(isAddItem || false);

  const [createChecklistItemMutation] = useCreateChecklistItemMutation({
    refetchQueries: [THREAD],
  });

  const [updateChecklistItemMutation] = useUpdateChecklistItemMutation({
    refetchQueries: [THREAD],
  });

  const methods = useForm<FormDataType>({
    resolver: yupResolver(CheckTitleSchema),
    defaultValues: {
      title: title || '',
      isChecked: isChecked || false,
    },
  });

  const { getValues, trigger, reset, watch } = methods;

  const isCheckedWatch = watch('isChecked');

  useEffect(() => {
    reset({ title: title || '', isChecked: isChecked || false });
  }, [title, isChecked]);

  useEffect(() => {
    if (isActive && checklistItemId) {
      const currentValues = getValues();
      if (title !== currentValues.title) return;
      updateChecklistItemMutation({
        variables: {
          data: {
            id: checklistItemId,
            threadId: threadId,
            isChecked: isCheckedWatch,
          },
        },
      });
      setIsActive(false);
    }
  }, [isCheckedWatch]);

  const { refClickOutside } = useClickOutside({
    callBack: () => {
      if (isActive) {
        const validateAndSubmit = async () => {
          const isValid = await trigger();
          if (isValid) {
            const currentValues = getValues();
            if (checklistItemId) {
              updateChecklistItemMutation({
                variables: {
                  data: {
                    id: checklistItemId,
                    threadId: threadId,
                    isChecked: isCheckedWatch,
                    title: currentValues.title,
                  },
                },
              });
            } else {
              createChecklistItemMutation({
                variables: {
                  data: {
                    threadId: threadId,
                    title: currentValues.title,
                    isChecked: currentValues.isChecked,
                  },
                },
              });
            }
          } else {
            reset({ title: title, isChecked: isChecked });
          }
        };
        validateAndSubmit();
        setIsActive(false);
        isAddItem && setIsAddItem(false);
      }
    },
  });

  return (
    <FormProvider {...methods}>
      <StackWrapperStyle
        ref={refClickOutside}
        onClick={() => {
          setIsActive(true);
        }}
      >
        <CheckboxRHF name='isChecked' />
        <TextFieldRHF
          name='title'
          size={SizeOptions.small}
          multiline
          placeholder='Введите описание...'
          sx={{
            '& .MuiInputBase-input': {
              padding: '0 0 0 8px !important',
              color: 'textTertiary.main',
              textDecoration: isCheckedWatch ? 'line-through' : '',
            },
          }}
        />
        <IconButtonStyle className='hidden' onClick={onRemove}>
          <CloseIcon sx={{ color: 'black600', cursor: 'pointer' }} />
        </IconButtonStyle>
      </StackWrapperStyle>
    </FormProvider>
  );
};
