import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { HeaderTable } from '../HeaderTable/HeaderTable';
import { BodyFolderContent } from '../BodyFolderContent/BodyFolderContent';
import { useState, useEffect, useCallback } from 'react';
import { useProjectFoldersQuery, useSortFoldersMutation } from '../../../graphql/generated/graphql';
import { useWorkspaceContext } from '../../../context';
import { PROJECT_FOLDER } from '../../../graphql/queries/projectFolders.gql';
import { FolderTableType } from '../../../types/types';

type FolderTablesType = {
  emptyCellWidth: string;
  titleCellWidth: string;
  responsibleCellWidth: string;
  hanldeCreateThread: () => void;
};

export const FolderTables = ({
  emptyCellWidth,
  titleCellWidth,
  responsibleCellWidth,
}: FolderTablesType) => {
  const [sortFoldersMutation] = useSortFoldersMutation({
    refetchQueries: [PROJECT_FOLDER],
  });

  const [isDraggingOccurs, setIsDraggingOccurs] = useState(false);

  const [folderList, setFolderList] = useState<Array<FolderTableType>>();

  const handleDragStart = () => {
    setIsDraggingOccurs(true);
  };

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!folderList) return;
      setIsDraggingOccurs(false);
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      const items = [...folderList];
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      const sortMyFolders = items.map((e, index) => {
        return { folderId: e.id, order: index };
      });

      sortFoldersMutation({
        variables: {
          data: {
            foldersSortOrders: sortMyFolders,
          },
        },
      });
      setFolderList(items);
    },
    [folderList, sortFoldersMutation],
  );

  const { selectProjectId } = useWorkspaceContext();

  const { data } = useProjectFoldersQuery({
    variables: {
      id: Number(selectProjectId),
    },
    skip: !selectProjectId,
    fetchPolicy: 'cache-and-network',
  });

  const headerOptions = [
    { title: '', width: emptyCellWidth, isEmpty: true },
    { title: 'Название', isEmpty: false },
    { title: 'Ответственный', width: responsibleCellWidth, isEmpty: false },
    { title: 'Прогресс', isEmpty: false },
    { title: '', width: emptyCellWidth, isEmpty: true },
  ];

  useEffect(() => {
    setFolderList(data?.project.folders);
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label='customized table'>
        <HeaderTable options={headerOptions} />
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <Droppable droppableId='droppable'>
            {(droppableProvided: DroppableProvided) => (
              <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                <BodyFolderContent
                  emptyCellWidth={emptyCellWidth}
                  titleCellWidth={titleCellWidth}
                  projectFoldersList={folderList}
                  responsibleCellWidth={responsibleCellWidth}
                  isDraggingOccurs={isDraggingOccurs}
                />
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};
