import { useState, ChangeEvent, useRef, useEffect, useCallback } from 'react';
import { EditindnameDataType, FileWithPreview, AttachmentFile } from '../../../types/types';
import { createAttachmentImage, downloadFile } from '../../../utils';
import { useFileSizeAlert } from '../../../hooks/useFileSizeAlert';

type NoThreadType = { outChange: (value: File[]) => void; value: File[] };

export const useNoThread = ({ outChange, value }: NoThreadType) => {
  const createFileWithPreviewArray = (files: File[]): FileWithPreview[] => {
    return files.map((file) => {
      const { isImage } = createAttachmentImage({ mimeType: file?.type });
      return {
        file,
        preview: isImage ? URL.createObjectURL(file) : null,
      };
    });
  };
  const [files, setFiles] = useState<FileWithPreview[]>(createFileWithPreviewArray(value));

  useEffect(() => {
    outChange(files.map((file) => file.file));
  }, [files]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const { fileSizeAlert } = useFileSizeAlert();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files).map((file) => {
        const { isImage } = createAttachmentImage({ mimeType: file.type });
        return {
          file,
          preview: isImage ? URL.createObjectURL(file) : null,
        };
      });
      if (fileSizeAlert(event)) return;

      setFiles((prev) => {
        return [...prev, ...newFiles];
      });
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = files.filter((_, fileIndex) => index !== fileIndex);
    setFiles(newFiles);
  };

  const handleDownloadFile = useCallback((fileObject: FileWithPreview | AttachmentFile) => {
    if ('file' in fileObject && fileObject.file instanceof File) {
      downloadFile({ file: fileObject.file, fileName: fileObject.file.name });
    }
  }, []);

  const [editindnameData, setEditindnameData] = useState<EditindnameDataType>({
    editingName: false,
    index: null,
    newName: '',
  });

  const startEditing = (index: number) => {
    setEditindnameData({ editingName: true, index: index, newName: files[index].file.name });
  };

  const handleRemoveAllFiles = () => {
    files.forEach((file) => {
      if (file.preview) {
        URL.revokeObjectURL(file.preview);
      }
    });
    setFiles([]);
  };

  const handleDownloadAllFiles = useCallback(() => {
    files.forEach((fileObject) => {
      downloadFile({ file: fileObject.file, fileName: fileObject.file.name });
    });
  }, [files]);

  const handleCloseModal = () => {
    setEditindnameData({
      editingName: false,
      index: null,
      newName: '',
    });
  };

  return {
    setEditindnameData,
    setFiles,
    editindnameData,
    handleCloseModal,
    handleButtonClick,
    files,
    fileInputRef,
    handleFileChange,
    handleDownloadAllFiles,
    handleRemoveAllFiles,
    startEditing,
    handleDownloadFile,
    handleRemoveFile,
  };
};
