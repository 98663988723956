import { Stack, styled, IconButton } from '@mui/material';

export const IconButtonStyle = styled(IconButton)`
  border-radius: 6px;
  padding: 0px;
  margin-left: auto;
  &.hiden {
    visibility: hidden;
  }
`;

export const StackPopperStyle = styled(Stack)(({ theme }) => ({
  padding: '3px 8px',
  flexDirection: 'row',
  cursor: 'pointer',
  alignItems: 'center',
  '&:hover': {
    borderRadius: '6px',
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
  },
  '& svg': {
    width: '20px',
    height: '20px',
  },
}));

export const StackPopperOutherStyle = styled(Stack)(() => ({
  borderRadius: '8px',
  padding: '4px 0px',
  width: '220px',
}));
