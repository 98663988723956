import {
  User,
  UserGroup,
  useUserGroupsQuery,
  useUsersQuery,
} from '../../../graphql/generated/graphql';
import { UnionProjectMembers } from '../../../types/types';
import { MemberTypes } from '../../../types/enum';
import { createTextUserAndGroup } from '../../../utils';

type CreateOptionsType = {
  workspaceId?: number;
  projectId?: number;
  folderId?: number;
};

export const useCreateOptions = ({ workspaceId, projectId, folderId }: CreateOptionsType) => {
  const { data: usersGroup } = useUserGroupsQuery({
    variables: {
      data: {
        workspaceId: Number(workspaceId),
        projectId,
        folderId,
      },
    },
  });

  const { data: users } = useUsersQuery({
    variables: {
      options: {
        workspaceId: Number(workspaceId),
        projectId,
        folderId,
      },
    },
  });

  const createOptionsUsers = (): Array<UnionProjectMembers> => {
    const groupsOptions =
      usersGroup?.userGroups?.map((e, index, array) => {
        return {
          value: e.id,
          title: createTextUserAndGroup({ userGroup: e as UserGroup }),
          last: array?.length === index + 1,
          avatar: e.avatar,
          users: e.users?.map((e) => {
            return {
              value: e.id,
              title: createTextUserAndGroup({ user: e as User }),
              avatar: e.avatar,
            };
          }),
          type: MemberTypes.groups,
        };
      }) || [];
    const usersOptions =
      users?.users?.map((e) => {
        return {
          value: e.id,
          title: createTextUserAndGroup({ user: e as User }),
          type: MemberTypes.user,
          avatar: e.avatar,
        };
      }) || [];

    return [...groupsOptions, ...usersOptions];
  };

  const options = createOptionsUsers();

  return options;
};
