import React, { useEffect, useState } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { SxProps } from '@mui/system';
import { AutocompleteStyle, TextFieldStyle, PaperStyle } from './style';
import { RenderOptionsMembers } from '../RenderOptionsMembers/RenderOptionsMembers';
import { UnionProjectMembers } from '../../../types/types';
import { MemberTypes } from '../../../types/enum';
import { AvatarCustom } from '../../AvatarCustom/AvatarCustom';
import { User } from '../../../graphql/generated/graphql';

type AutocompliteUsersType = {
  name: string;
  options: Array<UnionProjectMembers>;
  sx?: SxProps;
  isHasPopupIcon?: boolean;
  isHasClearIcon?: boolean;
  placeholder: string;
  isOpen?: boolean;
  isMultiple?: boolean;
};

export const AutocompliteMember = ({
  name,
  options,
  sx,
  isHasPopupIcon = undefined,
  isHasClearIcon = undefined,
  placeholder,
  isOpen = false,
  isMultiple = false,
}: AutocompliteUsersType) => {
  const methods = useFormContext();

  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control: methods.control,
  });

  const [open, setOpen] = useState(isOpen);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) return;
    handleClose();
  }, [value]);

  const updatedOptions = updateMultipleOptions(options, value, isMultiple);

  return (
    <AutocompleteStyle
      {...inputProps}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={value || (isMultiple ? [] : null)}
      disablePortal
      multiple={isMultiple}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      fullWidth
      options={updatedOptions || []}
      sx={sx}
      getOptionLabel={(option) => {
        const optionTyped = option as UnionProjectMembers;
        return optionTyped?.title || '';
      }}
      clearIcon={isHasClearIcon}
      popupIcon={isHasPopupIcon}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => {
        const optionTyped = option as UnionProjectMembers;
        const valueTypd = value as UnionProjectMembers;

        return optionTyped && valueTypd
          ? optionTyped.value === valueTypd.value && optionTyped.type === valueTypd.type
          : false;
      }}
      renderOption={(props, option) => {
        const optionTyped = option as UnionProjectMembers;
        const valueTyped = isMultiple
          ? (value as Array<UnionProjectMembers>)
          : [value as UnionProjectMembers];
        const isSelected =
          isMultiple &&
          valueTyped?.some(
            (item) => item.value === optionTyped.value && item.type === optionTyped.type,
          );

        return (
          <RenderOptionsMembers
            props={props}
            option={optionTyped}
            key={optionTyped?.value + optionTyped?.type}
            selected={isSelected}
            isMultiple={isMultiple}
          />
        );
      }}
      PaperComponent={(props: React.HTMLAttributes<HTMLDivElement>) => (
        <PaperStyle {...props} elevation={5}>
          {props?.children}
        </PaperStyle>
      )}
      renderTags={() => null}
      renderInput={(params) => (
        <TextFieldStyle
          {...params}
          error={!!error}
          helperText={error ? error.message : null}
          inputRef={ref}
          placeholder={placeholder}
          variant='outlined'
          size='small'
          type='text'
          InputProps={{
            ...params.InputProps,
            startAdornment: value?.title && (
              <AvatarCustom
                user={{ email: value?.title ? value?.title : '', avatar: value?.avatar } as User}
              />
            ),
          }}
        />
      )}
    />
  );
};

const updateMultipleOptions = (
  options: Array<UnionProjectMembers>,
  value: Array<UnionProjectMembers>,
  isMultiple: boolean,
): Array<UnionProjectMembers> => {
  if (!isMultiple) return options;

  const selectedOptions = options.filter((option) =>
    value.some(
      (selectedItem) => selectedItem.value === option.value && selectedItem.type === option.type,
    ),
  );

  const unselectedOptions = options.filter(
    (option) =>
      !value.some(
        (selectedItem) => selectedItem.value === option.value && selectedItem.type === option.type,
      ),
  );
  const unselectedGroups = unselectedOptions.filter((option) => option.type === MemberTypes.groups);
  const unselectedUsers = unselectedOptions.filter((option) => option.type === MemberTypes.user);

  const setLastProperty = (options: Array<UnionProjectMembers>) => {
    if (options.length > 0) {
      options.forEach((option, index, array) => {
        option.last = index === array.length - 1;
      });
    }
  };
  setLastProperty(selectedOptions);
  setLastProperty(unselectedGroups);

  return [...selectedOptions, ...unselectedGroups, ...unselectedUsers];
};
