import { Box, styled, Stack } from '@mui/material';

export const BoxStyle = styled(Box)(() => ({
  position: 'absolute',
  bottom: '0px',
  right: '0px',
  left: '0px',
}));

export const StackFilesWrapperStyle = styled(Stack)(({ theme }) => ({
  borderRadius: '12px',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  backgroundColor: `${theme.palette.white.main} !important`,
}));

type StackInputWrapperStylePropsType = {
  isShowFile: boolean;
  multiline?: boolean;
};

export const StackInputWrapperStyle = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isShowFile' && prop !== 'multiline',
})<StackInputWrapperStylePropsType>(({ theme, isShowFile, multiline }) => ({
  background: theme.palette.white.main,
  padding: '4px',
  flexDirection: 'row',
  alignItems: multiline ? 'flex-end' : 'center',
  justifyContent: 'center',
  borderTop: isShowFile ? `1px solid ${theme.palette.neutralOutlinedBorder.main}` : '',
  borderRadius: isShowFile ? '0px 0px 12px 12px' : '12px',
}));
