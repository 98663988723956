import { FilesFilterItem } from '../../../../types/types';
import { Stack, Tooltip, useTheme, IconButton, Typography } from '@mui/material';
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import noImage from '../../../../assets/noImage.png';
import { downloadFile, getFileSizeInKilobytes, measureTextSize } from '../../../../utils';
import { useState, useCallback } from 'react';
import { useChatContext } from '../../context/ChatContext';
import { StackStyle, StackFileNamekStyle, ImageStyle } from './style';

type FilesType = { files?: Array<FilesFilterItem> };

export const Files = ({ files }: FilesType) => {
  const theme = useTheme();
  const { setReplayId, setTabIndex } = useChatContext();

  const handleLinkMessage = (value: number) => {
    setTabIndex(0);
    setTimeout(() => {
      setReplayId(value);
    }, 0);
  };

  const handleDownloadFile = (file: FilesFilterItem) => {
    downloadFile({ path: file?.path, fileName: file.fileName });
  };

  return (
    <StackStyle>
      {files?.map((e) => {
        return (
          <Stack key={e.id} sx={{ padding: '4px', margin: '8px 0px' }} direction={'row'}>
            <ImageStyle src={noImage} alt='file' />
            <FileName fileName={e.fileName} size={e?.size} />

            <Stack direction={'row'} sx={{ marginLeft: 'auto' }}>
              <Tooltip
                title={'Перейти к сообщению'}
                placement='bottom-start'
                componentsProps={{
                  popper: {
                    sx: {
                      zIndex: 99999,
                      '& .MuiTooltip-tooltip': {
                        borderRadius: ' 6px',
                        background: theme.palette.neutral500.main,
                        marginTop: '7px !important',
                        boxShadow: theme.palette.joyShadoSm.main,
                      },
                    },
                  },
                }}
              >
                <IconButton
                  sx={{ width: '32px', height: '32px' }}
                  onClick={() => {
                    handleLinkMessage(Number(e.messageId));
                  }}
                >
                  <ForwardToInboxRoundedIcon sx={{ color: 'black600' }} />
                </IconButton>
              </Tooltip>

              <IconButton
                sx={{ marginLeft: '2px', width: '32px', height: '32px' }}
                onClick={() => {
                  handleDownloadFile(e);
                }}
              >
                <FileDownloadOutlinedIcon sx={{ color: 'black600' }} />
              </IconButton>
            </Stack>
          </Stack>
        );
      })}
    </StackStyle>
  );
};

type FileNameProps = {
  fileName: string;
  size?: number | null;
};

const FileName = ({ fileName, size }: FileNameProps) => {
  const [maxWidth, setMaxWidth] = useState<number>(0);

  const updateWidth = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setTimeout(() => {
        setMaxWidth(node.offsetWidth);
      }, 0);
    }
  }, []);

  return (
    <StackFileNamekStyle ref={updateWidth}>
      <Typography sx={{ color: 'textPrimary.main' }}>
        {calcTruncatedFileName(fileName, maxWidth)}
      </Typography>
      <Typography sx={{ fontSize: '12px' }}>{getFileSizeInKilobytes(size as number)} кб</Typography>
    </StackFileNamekStyle>
  );
};

const calcTruncatedFileName = (fileName: string, maxWidth: number): string => {
  if (maxWidth === 0 || !fileName) {
    return fileName;
  }
  const measureWidth = (t: string) => measureTextSize(t).width;
  if (measureWidth(fileName) <= maxWidth) {
    return fileName;
  }

  const ellipsis = '...';
  const lettersCountFromEnd = 6;
  let cutPosition = Math.max(fileName.length - lettersCountFromEnd, 0);
  let truncatedWidth = measureWidth(
    fileName.slice(0, cutPosition) + ellipsis + fileName.slice(-lettersCountFromEnd),
  );

  while (cutPosition > 0 && truncatedWidth > maxWidth) {
    cutPosition--;
    truncatedWidth = measureWidth(
      fileName.slice(0, cutPosition) + ellipsis + fileName.slice(-lettersCountFromEnd),
    );
  }

  return fileName.slice(0, cutPosition) + ellipsis + fileName.slice(-lettersCountFromEnd);
};
