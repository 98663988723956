import { StyledTableCellDrag, StyledTableRow, StyledTableCell } from '../../../style';
import { NameCreateCell } from './NameCreateCell/NameCreateCell';

type BodyColapseItemType = {
  emptyWipth: string;
  setCreateGoal: (value: boolean) => void;
};
export const GoalRowAdd = ({ emptyWipth, setCreateGoal }: BodyColapseItemType) => {
  return (
    <>
      <StyledTableRow sx={{ borderTop: '1px solid red' }}>
        <StyledTableCellDrag
          sx={{
            borderBottom: 'none',
          }}
          padding='none'
          width={emptyWipth}
        ></StyledTableCellDrag>
        <NameCreateCell setCreateGoal={setCreateGoal} />

        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>

        <StyledTableCellDrag padding='none' width={emptyWipth}></StyledTableCellDrag>
      </StyledTableRow>
    </>
  );
};
