import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TextFieldRHF } from '../../../../components';
import { SizeOptions } from '../../../../types/enum';
import { FormWrapper } from '../../../../components/AuthFormElements/form.style';
import { forgotPasswordSchema } from '../../../../validation/forgotPasswordSchemaEmail';
import {
  useIsValidPasswordResetTokenQuery,
  useResetPasswordByTokenMutation,
} from '../../../../graphql/generated/graphql';
import { RECOVERY_INSTRUCTIONS_QUERY_KEY } from '../../const';
import { useQueryParams } from '../../../../hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/constants';
import { LoadingButton } from '@mui/lab';

interface IFormData {
  password: string;
}

export const CreateNewPasswordForm = () => {
  const queryParams = useQueryParams();
  const resetToken = queryParams.get(RECOVERY_INSTRUCTIONS_QUERY_KEY);
  const navigate = useNavigate();
  const { data } = useIsValidPasswordResetTokenQuery({
    variables: {
      token: resetToken as string,
    },
    onCompleted: (data) => {
      if (!data.isValidPasswordResetToken) {
        navigate(ROUTES.invalidLink);
      }
    },
    onError: () => {
      navigate(ROUTES.invalidLink);
    },
  });

  const [sendPasswordResetMutation, { loading: isLoading }] = useResetPasswordByTokenMutation({
    onCompleted: () => {
      navigate(ROUTES.login);
    },
  });

  const methods = useForm<IFormData>({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      password: '',
    },
  });

  const onSubmit: SubmitHandler<IFormData> = (data) => {
    sendPasswordResetMutation({
      variables: {
        newPassword: data.password,
        token: resetToken as string,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
        <TextFieldRHF
          name='password'
          type='password'
          placeholder='Пароль'
          size={SizeOptions.extraLarge}
          sx={{
            marginTop: '32px',
          }}
        />

        <LoadingButton
          loading={isLoading}
          variant='contained'
          type='submit'
          sx={{ marginTop: '24px', width: '100%' }}
        >
          Сохранить пароль
        </LoadingButton>
      </FormWrapper>
    </FormProvider>
  );
};
