import { Box, Button, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { EditindnameDataType, FileWithPreview } from '../../../../types/types';
import { CloseButton, TextFieldRHF } from '../../../uiComponents';
import { StackStyle } from './style';
import { useForm, FormProvider } from 'react-hook-form';
import { SizeOptions, AttachmentsClickType } from '../../../../types/enum';
import { FileNameSchema } from '../../../../validation/index';
import { useUpdateFileMutation } from '../../../../graphql/generated/graphql';
import { THREAD } from '../../../../graphql/queries/thread.gql';

type RenameFileModalType = {
  setFiles?: (value: FileWithPreview[]) => void;
  files?: FileWithPreview[] | null;
  editindnameData?: EditindnameDataType;
  setEditindnameData?: (value: EditindnameDataType) => void;
  handleCloseModal: () => void;
  type?: AttachmentsClickType;
  fileId?: number;
};

export const RenameFileModal = ({
  setFiles,
  files,
  editindnameData,
  setEditindnameData,
  handleCloseModal,
  type = AttachmentsClickType.ThreadModal,
  fileId,
}: RenameFileModalType) => {
  const [updateFileMutation] = useUpdateFileMutation({
    refetchQueries: [THREAD],
  });
  const saveNewName = useCallback(
    (name: string) => {
      if (!files) return;
      const newFiles = [...(files || [])];
      const index = Number(editindnameData?.index);
      const newName = name.trim() || newFiles[index].file.name;

      newFiles[index] = {
        ...newFiles[index],
        file: new File([newFiles[index].file], newName, {
          type: newFiles[index].file.type,
        }),
      };
      setFiles?.(newFiles);
      setEditindnameData?.({
        index: null,
        newName: '',
        editingName: false,
      });
    },
    [files, editindnameData, setFiles, setEditindnameData],
  );

  type FormDataType = {
    name: string;
  };

  const methods = useForm<FormDataType>({
    resolver: yupResolver(FileNameSchema),
    defaultValues: {
      name: editindnameData?.newName,
    },
  });
  const { getValues, trigger } = methods;

  const handleSubmitt = () => {
    const validateAndSubmit = async () => {
      const isValid = await trigger();
      if (isValid) {
        const currentValues = getValues();
        if (AttachmentsClickType.ThreadItem === type) {
          if (!fileId) return;
          updateFileMutation({
            variables: {
              data: {
                fileName: currentValues.name,
              },
              id: Number(fileId),
            },
          });
          setEditindnameData?.({
            index: null,
            newName: '',
            editingName: false,
          });
        } else if (AttachmentsClickType.ThreadModal === type) {
          saveNewName(currentValues.name);
        } else if (AttachmentsClickType.Comment === type) {
          setEditindnameData?.({
            newName: currentValues.name,
            index: editindnameData?.index as number,
            editingName: false,
          });
          handleCloseModal();
        }
      }
    };
    validateAndSubmit();
  };

  return (
    <FormProvider {...methods}>
      <Box>
        <Stack>
          <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant='h3'>Переименование файла</Typography>
            <CloseButton onClick={handleCloseModal} />
          </Stack>
        </Stack>
        <Box sx={{ marginTop: '14px' }}>
          <TextFieldRHF
            fullWidth
            placeholder='Введите название'
            minRows={1}
            name='name'
            type='text'
            size={SizeOptions.large}
            multiline
          />
        </Box>

        <StackStyle>
          <Button variant='cancel' sx={{ marginLeft: '8px' }} onClick={handleCloseModal}>
            Отменить
          </Button>
          <Button
            variant='confirm'
            type='submit'
            onClick={handleSubmitt}
            sx={{
              marginLeft: '8px',
              fontWeight: '500',
            }}
          >
            Сохранить
          </Button>
        </StackStyle>
      </Box>
    </FormProvider>
  );
};
