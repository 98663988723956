import React from 'react';
import './App.css';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';
import { AuthContextProvider, ModalProvider, WorkspaceProvider } from './context';
import { RouterWrapper } from './router/Router';
import { MuiThemeProvider } from './components';
import './fonts/fonst.css';
import CssBaseline from '@mui/material/CssBaseline';

function App() {
  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider>
        <CssBaseline />
        <AuthContextProvider>
          <WorkspaceProvider>
            <ModalProvider>
              <RouterWrapper />
            </ModalProvider>
          </WorkspaceProvider>
        </AuthContextProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  );
}

export default App;
