import { styled, Stack, Typography, IconButton } from '@mui/material';

export const ImageSmallStyle = styled('img')(({ theme }) => ({
  width: '48px',
  height: '48px',
  minHeight: '48px',
  minWidth: '48px',
  objectFit: 'cover',
  borderRadius: '8px',
  background: theme.palette.backgroundLevel1.main,
  marginRight: '12px',
}));

export const ImageStyle = styled('img')(({ theme }) => ({
  width: '170px',
  height: '188px',
  margin: '1px',
  objectFit: 'cover',
  borderRadius: '8px',
  background: theme.palette.backgroundLevel1.main,
  cursor: 'pointer',
}));

export const StackWrapperStyle = styled(Stack)(({ theme }) => ({
  padding: '4px',
  marginTop: '8px',
  flexDirection: 'row',
  borderRadius: '12px',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  background: theme.palette.white.main,
}));

export const TypographyStyleName = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.palette.textPrimary.main,
}));

export const TypographyStyleSize = styled(Typography)(() => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
}));

export const IconButtonStyle = styled(IconButton)(() => ({
  width: '32px',
  height: '32px',
  paddingLeft: '2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0',
}));

export const ButtonWrapperStyle = styled(Stack)(() => ({
  right: '0px',
  position: 'absolute',
  padding: '4px',
  visibility: 'hidden',
  flexDirection: 'row',
}));
