import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { FileWithPreview, AttachmentFile } from '../../../types/types';
import {
  TypographyStyleName,
  ImageStyle,
  TypographyStyleSize,
  IconButtonStyle,
  StackWrapperStyle,
  LinearProgressStyle,
} from './style';
import noImage from '../../../assets/noImage.png';
import { createAttachmentImage, getFileSizeInKilobytes } from '../../../utils/index';

type FileListType = {
  files: Array<FileWithPreview | AttachmentFile>;
  startEditing: (index: number) => void;
  handleDownloadFile: (fileObject: FileWithPreview | AttachmentFile) => void;
  handleRemoveFile: (index: number) => void;
  loadingFiles?: boolean;
  handleClose?: () => void;
  setSingleImagePath: (value: string) => void;
};

export const FileList = ({
  files,
  startEditing,
  handleDownloadFile,
  handleRemoveFile,
  loadingFiles = false,
  handleClose,
  setSingleImagePath,
}: FileListType) => {
  const theme = useTheme();

  const renderActionButtons = (fileObject: FileWithPreview | AttachmentFile, index: number) => {
    return [
      { Icon: ModeOutlinedIcon, handleClick: () => startEditing(index) },
      { Icon: FileDownloadOutlinedIcon, handleClick: () => handleDownloadFile(fileObject) },
      { Icon: CloseIcon, handleClick: () => handleRemoveFile(index) },
    ].map((option, index) => {
      const { handleClick, Icon } = option;

      return (
        <IconButtonStyle key={index} onClick={handleClick} sx={{ paddingLeft: '2px' }}>
          <Icon sx={{ color: theme.palette.black600 }} />
        </IconButtonStyle>
      );
    });
  };

  const hanldeClickImage = (imageUrl: string) => {
    if (imageUrl === noImage) return;
    setSingleImagePath(imageUrl);
  };

  return (
    <Stack>
      {files.map((fileObject, index) => {
        let imageUrl = noImage;
        if ('file' in fileObject) {
          // FileWithPreview
          imageUrl = fileObject.preview || noImage;
        } else {
          // AttachmentFile
          const { imageUrl: imageUrlAttachment } = createAttachmentImage({
            mimeType: fileObject.mimeType,
            path: fileObject.path,
          });
          imageUrl = imageUrlAttachment;
        }

        const fileName = 'file' in fileObject ? fileObject.file.name : fileObject.fileName;
        const fileSize = 'file' in fileObject ? fileObject.file.size : fileObject.size;

        return (
          <StackWrapperStyle key={index}>
            <ImageStyle
              src={imageUrl}
              alt='Preview'
              onClick={() => {
                hanldeClickImage(imageUrl);
              }}
              sx={{ cursor: imageUrl === noImage ? 'default' : 'pointer' }}
            />
            <Stack width={'100%'}>
              <TypographyStyleName sx={{ maxWidth: '160px' }}>{fileName}</TypographyStyleName>
              <TypographyStyleSize>
                {getFileSizeInKilobytes(Number(fileSize))} кб
              </TypographyStyleSize>
            </Stack>
            <Stack direction={'row'} alignItems={'center'}>
              {renderActionButtons(fileObject, index)}
            </Stack>
          </StackWrapperStyle>
        );
      })}
      {loadingFiles && (
        <StackWrapperStyle sx={{ alignItems: 'center' }}>
          <ImageStyle src={noImage} alt='Preview' />
          <Stack sx={{ width: '100%', paddingRight: '12px' }}>
            <Typography>Обрабатывается</Typography>{' '}
            <Stack direction={'row'}>
              <LinearProgressStyle variant='indeterminate' />
            </Stack>
          </Stack>
          <IconButtonStyle
            sx={{ paddingLeft: '2px' }}
            onClick={() => {
              handleClose?.();
            }}
          >
            <CloseIcon sx={{ color: theme.palette.black600 }} />
          </IconButtonStyle>
        </StackWrapperStyle>
      )}
    </Stack>
  );
};
