import { ReactNode } from 'react';
import { Modal, SxProps } from '@mui/material';
import { BoxStyle, StackStyle } from './style';

type DefaultModalType = {
  children: ReactNode;
  handleCloseModal: () => void;
  sx?: SxProps;
  open?: boolean;
  calssName?: string;
};

export const UniversalModal = ({
  children,
  handleCloseModal,
  sx,
  open = true,
  calssName,
}: DefaultModalType) => {
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={calssName}
    >
      <StackStyle>
        <BoxStyle sx={sx}>{children}</BoxStyle>
      </StackStyle>
    </Modal>
  );
};
