import { UserGroup, User } from '../graphql/generated/graphql';

export const createTextUserAndGroup = ({
  user,
  userGroup,
}: {
  user?: User;
  userGroup?: UserGroup;
}) => {
  if (userGroup?.title) {
    return userGroup.title;
  }
  if (user?.firstName || user?.lastName) {
    return `${user?.firstName} ${user?.lastName}`;
  }
  if (user?.email) {
    return user?.email;
  }

  return '';
};
