import { styled, Stack, Typography, Button } from '@mui/material';

export const ButtonStyle = styled(Button)(() => ({
  maxHeight: '38px',
  height: '38px',
  justifyContent: 'center',
  marginLeft: '8px',
}));

export const StackMemberWrapperStyle = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.textIcon.main}`,
  padding: '16px',
  maxHeight: '70vh',
  overflowX: 'auto',
}));

export const StackMemberStyle = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StackWrapperOuterStyle = styled(Stack)(({ theme }) => ({
  padding: '0px 12px',
  cursor: 'pointer',
  borderRadius: '6px',
  height: '32px',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: theme.palette.backgroundLevel1.main,
  },
}));

export const StackWrapperSelectStyle = styled(Stack)(() => ({
  flexDirection: 'row',
  marginTop: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const TypographyStyle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  alignItems: 'center',
  padding: '3px 8px',
  cursor: 'pointer',
  '&:hover': {
    borderRadius: '6px',
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
  },
}));
