import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { SxProps } from '@mui/system';
import Switch from '@mui/material/Switch';

interface CustomSwitchProps {
  name: string;
  label?: string;
  sx?: SxProps;
}

export const CustomSwitch = ({ name, sx }: CustomSwitchProps) => {
  const methods = useFormContext();

  const {
    field: { value, ref, ...inputProps },
  } = useController({
    name,
    control: methods.control,
  });

  return <Switch {...inputProps} checked={value} inputRef={ref} sx={sx} />;
};
