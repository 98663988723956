import { ApolloLink, FetchResult, Observable, Operation } from '@apollo/client/core';
import { GraphQLError, print } from 'graphql';
import { Client, ClientOptions, createClient } from 'graphql-ws';

export class WebSocketLink extends ApolloLink {
  private client: Client;
  private options: ClientOptions;
  private isReconnecting = false;

  constructor(options: ClientOptions) {
    super();
    this.options = options;
    this.client = this.createWebSocketClient();
  }

  private createWebSocketClient() {
    return createClient(this.options);
  }

  private reconnect() {
    if (!this.isReconnecting) {
      this.isReconnecting = true;
      setTimeout(() => {
        this.client = this.createWebSocketClient();
        this.isReconnecting = false;
      }, 1000);
    }
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            this.reconnect();
            if (err instanceof Error) {
              return sink.error(err);
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                new Error(`Socket closed with event ${err.code} ${err.reason || ''}`),
              );
            }

            return sink.error(
              new Error((err as GraphQLError[])?.map(({ message }) => message)?.join(', ')),
            );
          },
        },
      );
    });
  }
}
