import { styled, Stack } from '@mui/material';

export const StackStyle = styled(Stack)(() => ({
  width: '220px',
  padding: '3px 8px',
  cursor: 'pointer',
  height: '32px',
  alignItems: 'center',
  flexDirection: 'row',
  '& svg': {
    width: '20px',
    height: '20px',
  },
}));
