import { SvgIcon, SxProps } from '@mui/material';

type CheckboxCheckedIconType = {
  sx?: SxProps;
};

export const CheckboxCheckedIcon = ({ sx }: CheckboxCheckedIconType) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20, ...sx }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <rect x='0.5' y='0.5' width='19' height='19' rx='3.5' fill='#FBFCFE' stroke='#CDD7E1' />
        <path
          d='M7.30964 13.4694L4.4185 10.5775C4.34142 10.5003 4.24986 10.4389 4.14907 10.3971C4.04828 10.3553 3.94022 10.3338 3.8311 10.3338C3.72198 10.3338 3.61392 10.3553 3.51313 10.3971C3.41234 10.4389 3.32078 10.5003 3.24371 10.5775C2.91876 10.9025 2.91876 11.4276 3.24371 11.7526L6.72642 15.2362C7.05136 15.5613 7.57626 15.5613 7.90121 15.2362L16.7163 6.41885C17.0412 6.09383 17.0412 5.56878 16.7163 5.24376C16.6392 5.16649 16.5477 5.10519 16.4469 5.06336C16.3461 5.02153 16.238 5 16.1289 5C16.0198 5 15.9117 5.02153 15.8109 5.06336C15.7101 5.10519 15.6186 5.16649 15.5415 5.24376L7.30964 13.4694Z'
          fill='#32383E'
        />
      </svg>
    </SvgIcon>
  );
};
