import { FormProvider, useForm } from 'react-hook-form';
import { EditAvatar } from '../../../../EditAvatar/EditAvatar';
import { TextFieldRHF } from '../../../../../../../../uiComponents';
import { GroupUserWrapper, StackStyle } from './style';
import { useWorkspace } from '../../../../../../../../../hooks';
import { MemberTypes, SizeOptions } from '../../../../../../../../../types/enum';
import { Autocomplete, Stack, Typography, useTheme } from '@mui/material';
import {
  useLeaveUserGroupMutation,
  User,
  useRemoveFileMutation,
  useUpdateUserGroupMutation,
  useUploadFileMutation,
  useUserGroupQuery,
  WorkspaceMemberRole,
} from '../../../../../../../../../graphql/generated/graphql';
import { USER_GROUP } from '../../../../../../../../../graphql/queries/userGroup.gql';
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { AutocompleteOptionType } from '../../../../../../../../../types/types';
import { RequestHandler } from '../../../../../../../../RequestHandler/RequestHandler';
import { useAuthContext } from '../../../../../../../../../context';
import { AvatarCustom } from '../../../../../../../../AvatarCustom/AvatarCustom';

type GroupInfoType = {
  selectUserGroupId: number;
  hasPermission: WorkspaceMemberRole[];
};
interface RolePermissions {
  [key: string]: {
    canRemove: WorkspaceMemberRole[];
  };
}
const rolePermissions: RolePermissions = {
  [WorkspaceMemberRole.Creator]: {
    canRemove: [WorkspaceMemberRole.Admin, WorkspaceMemberRole.Member, WorkspaceMemberRole.Creator],
  },
  [WorkspaceMemberRole.Admin]: {
    canRemove: [WorkspaceMemberRole.Member, WorkspaceMemberRole.Admin, WorkspaceMemberRole.Creator],
  },
  [WorkspaceMemberRole.Member]: {
    canRemove: [],
  },
};
export const GroupInfo = ({ selectUserGroupId, hasPermission }: GroupInfoType) => {
  const theme = useTheme();
  const { currentUser } = useAuthContext();
  const { currentWorkspace, myRole } = useWorkspace();
  const { data, loading } = useUserGroupQuery({
    variables: {
      userGroupId: selectUserGroupId,
    },
  });
  const userGroup = data?.userGroup;
  const methods = useForm();
  const [updateUserGroupMutation] = useUpdateUserGroupMutation({
    refetchQueries: [USER_GROUP],
  });
  const [removeFileMutation] = useRemoveFileMutation({
    update(cache) {
      cache.evict({
        id: `File:${userGroup?.avatar?.id}`,
      });
    },
  });
  const [uploadFileMutation] = useUploadFileMutation({
    refetchQueries: [USER_GROUP],
  });
  const [leaveUserGroupMutation] = useLeaveUserGroupMutation({
    variables: {
      leaveUserGroupId: selectUserGroupId,
    },
    refetchQueries: [USER_GROUP],
  });
  const handleUpdateAvatar = (image: File) => {
    uploadFileMutation({
      variables: {
        file: image,
        data: {
          userGroupId: selectUserGroupId,
        },
      },
    });
  };
  const handleRemoveAvatar = () => {
    userGroup?.avatar?.id &&
      removeFileMutation({
        variables: {
          id: userGroup?.avatar?.id,
        },
      });
  };
  const workspaceUsers =
    currentWorkspace?.members.map(({ user }) => ({
      title: `${user.firstName} ${user.lastName}` || '',
      value: user.id,
      type: MemberTypes.user,
    })) || [];
  const currentGroupUsers =
    userGroup?.users?.map((user) => ({
      title: user.firstName || '',
      value: user.id,
      type: MemberTypes.groups,
    })) || [];
  const handleUpdateGroupUsers = (newUsersData: AutocompleteOptionType[]) => {
    const newUsers = newUsersData.map((user) => user.value);
    updateUserGroupMutation({
      variables: {
        updateUserGroupInput: {
          userIds: newUsers,
          workspaceId: currentWorkspace!.id,
          id: selectUserGroupId,
        },
      },
    });
  };

  const handleDeleteGroupUser = (userId: number) => {
    if (currentUser?.id === userId) {
      leaveUserGroupMutation();
    } else {
      const newUsers = currentGroupUsers.reduce((acc: number[], user) => {
        if (user.value !== userId) {
          acc.push(user.value);
        }
        return acc;
      }, []);
      updateUserGroupMutation({
        variables: {
          updateUserGroupInput: {
            userIds: newUsers,
            workspaceId: currentWorkspace!.id,
            id: selectUserGroupId,
          },
        },
      });
    }
  };

  const userCanBeRemove = (id: number) => {
    const role = currentWorkspace?.members.find((user) => user.user.id === id)?.role;
    if (currentUser?.id === id) return true;
    return rolePermissions[myRole].canRemove.includes(role!);
  };
  const isHasPermission = hasPermission.includes(myRole);
  return (
    <RequestHandler loading={loading}>
      <FormProvider {...methods}>
        <StackStyle>
          {isHasPermission && (
            <Stack mb='24px'>
              <EditAvatar
                imageUrl={userGroup?.avatar?.path}
                title={userGroup?.title}
                handleUpdateAvatar={handleUpdateAvatar}
                handleRemoveAvatar={handleRemoveAvatar}
              />
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.value === value.value}
                value={currentGroupUsers}
                multiple
                options={workspaceUsers}
                onChange={(_, newValue) => {
                  handleUpdateGroupUsers(newValue);
                }}
                clearIcon={false}
                sx={{ width: '100%', marginTop: '24px' }}
                getOptionLabel={(option) => option.title}
                renderTags={() => null}
                renderInput={(params) => (
                  <TextFieldRHF
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
                        padding: '2.5px 4px 2.5px 8px!important',
                      },
                    }}
                    {...params}
                    name='users'
                    type='text'
                    placeholder='Добавить пользователя'
                    size={SizeOptions.small}
                  />
                )}
              />
            </Stack>
          )}
          <Stack>
            {userGroup?.users.map((user, index) => (
              <GroupUser
                userCanBeRemove={userCanBeRemove(user.id)}
                key={index}
                id={user.id}
                name={`${user.firstName} ${user.lastName}`}
                path={user.avatar?.path}
                handleDeleteGroupUser={handleDeleteGroupUser}
              />
            ))}
          </Stack>
        </StackStyle>
      </FormProvider>
    </RequestHandler>
  );
};

type UserGroupType = {
  id: number;
  name: string;
  handleDeleteGroupUser: (userId: number) => void;
  userCanBeRemove: boolean;
  path?: string;
};
const GroupUser = ({ id, name, handleDeleteGroupUser, userCanBeRemove, path }: UserGroupType) => {
  return (
    <GroupUserWrapper>
      <AvatarCustom
        user={
          {
            email: name || '',
            avatar: {
              path: path,
            },
          } as User
        }
        isOneLether={false}
        sx={{ marginRight: '8px', width: '32px', height: '32px', fontSize: '14px' }}
      />
      <Typography flex={1}>{name}</Typography>
      {userCanBeRemove && (
        <ClearIcon onClick={() => handleDeleteGroupUser(id)} sx={{ cursor: 'pointer' }} />
      )}
    </GroupUserWrapper>
  );
};
