import { AttachmentsClickType } from '../../types/enum';

import { FileListManagerDefault } from './FileListManagerDefault';
import { useNoThread } from './hooks';

type FileListManagerModalType = {
  outChange: (value: File[]) => void;
  value: File[];
  type?: AttachmentsClickType;
};

export const FileListManagerModal = ({ outChange, value = [] }: FileListManagerModalType) => {
  const {
    setEditindnameData,
    setFiles,
    editindnameData,
    handleCloseModal,
    handleButtonClick,
    files,
    fileInputRef,
    handleFileChange,
    handleDownloadAllFiles,
    handleRemoveAllFiles,
    startEditing,
    handleDownloadFile,
    handleRemoveFile,
  } = useNoThread({ outChange, value });

  return (
    <FileListManagerDefault
      startEditing={startEditing}
      handleDownloadFile={handleDownloadFile}
      handleRemoveFile={handleRemoveFile}
      handleDownloadAllFiles={handleDownloadAllFiles}
      handleRemoveAllFiles={handleRemoveAllFiles}
      handleFileChange={handleFileChange}
      fileInputRef={fileInputRef}
      files={files}
      handleButtonClick={handleButtonClick}
      handleCloseModal={handleCloseModal}
      editindnameData={editindnameData}
      setFiles={setFiles}
      setEditindnameData={setEditindnameData}
      type={AttachmentsClickType.ThreadModal}
    />
  );
};
