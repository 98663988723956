import { styled, Stack, Typography } from '@mui/material';

export const TypographyStyle = styled(Typography)(() => ({
  fontWeight: 600,
  color: 'textPrimary.main',
  padding: '6px 0px',
  marginBottom: '8px',
}));

export const StackAddStyle = styled(Stack)(({ theme }) => ({
  '& .MuiTypography-root': {
    color: `${theme.palette.neutralPlainDisabledColor.main} !important`,
  },
  '& .MuiSvgIcon-root': { color: theme.palette.neutralPlainDisabledColor.main },
  '&:hover': {
    color: 'black600',
    '& .MuiTypography-root': {
      color: `${theme.palette.black600} !important`,
    },
    '& .MuiSvgIcon-root': { color: theme.palette.black600 },
  },
  cursor: 'pointer',
  padding: '2px 12px',
  fontWeight: '500',
  flexDirection: 'row',
  alignItems: 'center',
  height: '32px',
}));
