import { Button, Box, Typography } from '@mui/material';

import { PositionedPopper, FileListManagerModal, FileListManagerThread } from '../index';
import { useFormContext, useController } from 'react-hook-form';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { OutsideClickFlagType, AttachmentsClickType } from '../../types/enum';
import { StackWrapperStyle, BoxWrapperStyle } from './style';
import { useState } from 'react';
import { AttachmentFile } from '../../types/types';

type AttachmentsType = { name: string; type?: AttachmentsClickType; threadId?: number };

export const Attachments = ({
  name,
  type = AttachmentsClickType.ThreadModal,
  threadId,
}: AttachmentsType) => {
  const methods = useFormContext();

  const {
    field: { value, onChange: onChange },
  } = useController({
    name: name,
    control: methods.control,
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <PositionedPopper
      isOutControll
      setOutOpen={setIsOpen}
      outOpen={isOpen}
      sx={{
        zIndex: '1300',
        marginTop: type === AttachmentsClickType.ThreadItem ? '-43px !important' : '',
      }}
      ClickComponent={<ClickControll value={value} type={type} />}
      PopperContent={
        <BoxWrapperStyle className={OutsideClickFlagType.outsideClickFlagDefault}>
          {type === AttachmentsClickType.ThreadModal ? (
            <FileListManagerModal outChange={onChange} value={value} />
          ) : (
            <FileListManagerThread
              outChange={onChange}
              value={value}
              threadId={threadId}
              handleClose={handleClose}
            />
          )}
        </BoxWrapperStyle>
      }
    />
  );
};

type ClickControllType = {
  value: File[] | Array<AttachmentFile>;
  type: AttachmentsClickType;
};

const ClickControll = ({ value, type = AttachmentsClickType.ThreadModal }: ClickControllType) => {
  const isInitStatus = !value?.length;

  if (type === AttachmentsClickType.ThreadItem) {
    return (
      <StackWrapperStyle>
        <AttachFileIcon
          fontSize='small'
          sx={{ marginRight: '6px', color: 'neutralPlainDisabledColor.main' }}
        />

        <Typography
          sx={{
            color: isInitStatus ? 'textTertiary.main' : 'black600',
          }}
        >
          {isInitStatus ? 'Добавить' : `Добавлено: ${value?.length}`}
        </Typography>
      </StackWrapperStyle>
    );
  }

  return (
    <Button variant='popper'>
      <AttachFileIcon fontSize='small' sx={{ color: 'neutral500.main' }} />
      <Box
        sx={{
          color: isInitStatus ? 'textTertiary.main' : 'black600',
        }}
      >
        {isInitStatus ? 'Вложения' : `Вложений: ${value?.length}`}
      </Box>
    </Button>
  );
};
