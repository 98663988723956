import { ModalParametersTypes } from '../../../../types/types';
import { ModalWrapper } from './style';
import { Divider, Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import { WORKSPACE_SETTINGS_SCREENS } from './const';
import { Groups, MyProfile, SettingsNavigation, Users, Workspace } from './components';
import { useWorkspace } from '../../../../hooks';
import { useUserGroupsQuery } from '../../../../graphql/generated/graphql';

type WorkspaceSettingsModalType = {
  modalParameters: ModalParametersTypes;
  handleCloseModal: () => void;
};

export const navigationItems = [
  {
    title: 'Мой профиль',
    screenName: WORKSPACE_SETTINGS_SCREENS.myProfile,
    screen: MyProfile,
  },
  {
    title: 'Пользователи',
    screenName: WORKSPACE_SETTINGS_SCREENS.users,
    screen: Users,
  },
  {
    title: 'Группы',
    screenName: WORKSPACE_SETTINGS_SCREENS.groups,
    screen: Groups,
  },
  {
    title: 'Рабочая область',
    screenName: WORKSPACE_SETTINGS_SCREENS.workspace,
    screen: Workspace,
  },
];
export const WorkspaceSettings = ({
  modalParameters,
  handleCloseModal,
}: WorkspaceSettingsModalType) => {
  const { refetch, currentWorkspace } = useWorkspace();
  const { refetch: refetchUserGroups } = useUserGroupsQuery({
    variables: {
      data: { workspaceId: currentWorkspace!.id },
    },
  });
  const theme = useTheme();
  const [currentSettingsScreenName, setCurrentSettingsScreenName] =
    useState<WORKSPACE_SETTINGS_SCREENS>(WORKSPACE_SETTINGS_SCREENS.myProfile);
  const handleSettingsScreen = (screen: WORKSPACE_SETTINGS_SCREENS) => {
    refetch();
    refetchUserGroups();
    setCurrentSettingsScreenName(screen);
  };
  const CurrentScreen = navigationItems.find(
    (item) => item.screenName === currentSettingsScreenName,
  )!.screen;
  return (
    <ModalWrapper>
      <SettingsNavigation
        handleSettingsScreen={handleSettingsScreen}
        currentSettingsScreen={currentSettingsScreenName}
      />
      <Divider
        sx={{ backgroundColor: theme.palette.neutralOutlinedBorder.main }}
        orientation='vertical'
      />
      <Stack overflow='auto' width='100%'>
        <CurrentScreen handleCloseModal={handleCloseModal} modalParameters={modalParameters} />
      </Stack>
    </ModalWrapper>
  );
};
