import { ChangeEvent } from 'react';
import { useModalContext } from '../context';
import { MAX_SIZE_FILE } from '../constants/constants';

export const useFileSizeAlert = () => {
  const { handleAlertChangeParameter } = useModalContext();

  const fileSizeAlert = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      for (const file of files) {
        if (file.size > MAX_SIZE_FILE) {
          handleAlertChangeParameter({
            severity: 'error',
            state: true,
            text: `Размер файла "${
              file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name
            }" не должен превышать 100 МБ.`,
          });
          return true;
        }
      }
    }
    return false;
  };

  return { fileSizeAlert };
};
