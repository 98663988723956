import React, { useState } from 'react';
import { CheckboxStyle } from './style';
import { CheckboxCheckedIcon, CheckboxNoCheckedIcon } from '../../icons/index';
import { useController, useFormContext } from 'react-hook-form';

type CheckboxRHFType = {
  name: string;
};

export const CheckboxRHF = ({ name }: CheckboxRHFType) => {
  const [isCheckboxHover, setIsCheckboxHover] = useState(false);
  const { control } = useFormContext();

  const {
    field: { ref, value, onChange, onBlur },
  } = useController({
    name,
    control,
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <CheckboxStyle
      onMouseEnter={() => setIsCheckboxHover(true)}
      onMouseLeave={() => setIsCheckboxHover(false)}
      checkedIcon={<CheckboxCheckedIcon />}
      icon={<CheckboxNoCheckedIcon hover={isCheckboxHover} />}
      checked={value ?? false}
      onChange={handleCheckboxChange}
      onBlur={onBlur}
      inputRef={ref}
    />
  );
};
