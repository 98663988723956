import { styled, Stack } from '@mui/material';

export const ImgStyle = styled('img')(({ theme }) => ({
  width: '134px',
  height: '134px',
  minHeight: '134px',
  minWidth: '134px',
  objectFit: 'cover',
  borderRadius: '8px',
  background: theme.palette.backgroundLevel1.main,
  margin: '6px',
}));

export const StackStyle = styled(Stack)(() => ({
  padding: '8px 2px',
  flexWrap: 'wrap',
  flexDirection: 'row',
  maxHeight: '100%',
  overflowY: 'auto',
}));
