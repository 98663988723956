import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { getFormattedDateWiev } from '../../../utils';

type PickerConrtollType = {
  startDate: string;
  onStartDateChange: (value: string) => void;
  endDate: string;
  onEndDateChange: (value: string) => void;
  cleanPicker?: boolean;
};

type PickerValueType = {
  startValue: dayjs.Dayjs | null;
  endValue: dayjs.Dayjs | null;
};

export const usePickerConrtoll = ({
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
}: PickerConrtollType) => {
  const [pickerValue, setPickerValue] = useState<PickerValueType>({
    startValue: startDate ? dayjs(startDate) : null,
    endValue: endDate ? dayjs(endDate) : null,
  });

  useEffect(() => {
    onStartDateChange(getFormattedDateWiev(pickerValue.startValue));
    onEndDateChange(getFormattedDateWiev(pickerValue.endValue));
  }, [pickerValue]);

  const [outOpen, setOutOpen] = useState(false);

  return { pickerValue, setPickerValue, outOpen, setOutOpen };
};
