import { List, ListItem, styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';

export const ListItemStyle = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px 8px',
  justifyContent: 'space-between',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
  },
}));

export const AvatarStyle = styled(Avatar)({
  marginRight: '8px',
  width: '32px',
  height: '32px',
});

export const ListStyle = styled(List)({
  padding: '8px 4px',
  maxHeight: 'calc(100vh - 266px)',
  overflow: 'auto',
});
