import { Stack, styled } from '@mui/material';

export const AuthLayoutStack = styled(Stack)(({ theme }) => ({
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.neutralPlainHoverBg.main,
}));

export const AuthLayoutWrapper = styled(Stack)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
}));
