import { Stack, IconButton, useTheme, Box } from '@mui/material';
import { StatusThreadTableRHF } from '../../../../index';
import { StyledTableCell } from '../../../style';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect, useState } from 'react';
import { TextFieldRHF } from '../../../../uiComponents';
import { useForm, FormProvider, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThreadSchema } from '../../../../../validation';
import { SizeOptions } from '../../../../../types/enum';
import { BoxOutherStyle, StackInnerStyle, TypographyStyle, StackDefaultStyle } from './style';
import { useClickOutside, useNavigateSearch } from '../../../../../hooks';
import { UpdateThreadMutationFn } from '../../../../../graphql/generated/graphql';
import { ROUTES } from '../../../../../constants/constants';

type NameCellType = {
  title: string;
  titleCellWidth: string;
  statusId: number;
  threadId: number;
  updateThreadMutation: UpdateThreadMutationFn;
};

export const NameAndStatusCell = ({
  title,
  titleCellWidth,
  statusId,
  threadId,
  updateThreadMutation,
}: NameCellType) => {
  const [isEditable, setIsEditable] = useState(false);

  const { refClickOutside } = useClickOutside({
    callBack: () => {
      setIsEditable(false);
    },
  });

  type FormData = {
    title: string;
    status: number;
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(ThreadSchema) as Resolver<FormData>,
    defaultValues: {
      title: title,
      status: statusId,
    },
  });
  const { getValues, trigger, reset, watch } = methods;

  const status = watch('status');

  const [isInitDisable, setIsInitDisable] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    setIsInitDisable(false);
    if (isEditable || isInitDisable) return;
    const validateAndSubmit = async () => {
      const isValid = await trigger();
      if (isValid) {
        const currentValues = getValues();
        updateThreadMutation({
          variables: {
            data: {
              id: threadId,
              statusId: currentValues.status,
              title: currentValues.title,
            },
          },
        });
      } else {
        reset({ title: title });
      }
    };

    validateAndSubmit();
  }, [isEditable, status]);

  const { navigateSearchParams } = useNavigateSearch();

  const navigateToThread = () => {
    const path = ROUTES.thread.replace(':threadId', `${threadId}`);
    navigateSearchParams({ path });
  };

  return (
    <StyledTableCell
      width={titleCellWidth}
      onClick={navigateToThread}
      sx={{
        maxWidth: '300px',
        cursor: 'pointer',
        width: '210px',
        '&:hover': {
          backgroundColor: !isEditable ? `${theme.palette.tableHover.main} !important` : '',
        },
      }}
    >
      <FormProvider {...methods}>
        {isEditable ? (
          <Stack
            direction={'row'}
            alignItems={'center'}
            ref={refClickOutside}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <BoxOutherStyle>
              <StackInnerStyle direction={'row'}>
                <StatusThreadTableRHF type='table' name='status' />

                <TextFieldRHF
                  fullWidth
                  placeholder='Введите название'
                  minRows={1}
                  name='title'
                  type='text'
                  multiline
                  size={SizeOptions.small}
                />
              </StackInnerStyle>
            </BoxOutherStyle>
            <TypographyStyle sx={{ opacity: '0' }}>fix</TypographyStyle>
          </Stack>
        ) : (
          <StackDefaultStyle>
            <Box
              component={'span'}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <StatusThreadTableRHF name='status' type='table' />
            </Box>
            <TypographyStyle sx={{ width: '99%' }}>{title}</TypographyStyle>
            <IconButton
              sx={{
                marginLeft: 'auto',
                padding: '2px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setIsEditable(true);
              }}
            >
              <EditOutlinedIcon
                fontSize='small'
                sx={{
                  visibility: 'hidden',
                }}
                className='hidden'
              />
            </IconButton>
          </StackDefaultStyle>
        )}{' '}
      </FormProvider>
    </StyledTableCell>
  );
};
