import { styled, Box, Stack } from '@mui/material';

export const StackStyle = styled(Stack)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
}));

export const BoxStyle = styled(Box)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  backgroundColor: theme.palette.backgroundSurface?.main,
  boxSizing: 'border-box',
  pointerEvents: 'auto',
  overflow: 'hidden',
}));
