import { styled, Stack, Typography } from '@mui/material';

export const StackUserStyle = styled(Stack)(() => ({
  width: '100%',
  overflow: 'hidden',
  padding: '4px 0px',
}));

export const TypographyDotStyle = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '600',
  padding: '0px 4px',
}));
