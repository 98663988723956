import { Stack, MenuItem, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { PositionedPopper } from '../../uiComponents';
import { useProjectsQuery } from '../../../graphql/generated/graphql';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { StackStyle, LimitedWidthBox } from './style';
import { PathThreadSelectType } from '../../../types/types';
import { useFormContext, useController } from 'react-hook-form';
import { PopperPlacementType } from '@mui/material/Popper';
import { OutsideClickFlagType } from '../../../types/enum';

type PathThreadWrapperRHFType = {
  name: string;
  workspaceId?: number;
  placement?: PopperPlacementType;
};

export const PathThreadWrapperRHF = ({
  name,
  workspaceId,
  placement,
}: PathThreadWrapperRHFType) => {
  const methods = useFormContext();

  const {
    field: { onChange },
  } = useController({
    name,
    control: methods.control,
  });

  return <PathThreadWrapper onChange={onChange} workspaceId={workspaceId} placement={placement} />;
};

type PathThreadWrapperType = {
  workspaceId?: number;
  onChange: (value: PathThreadSelectType) => void;
  placement?: PopperPlacementType;
};
export const PathThreadWrapper = ({
  onChange,
  workspaceId,
  placement = 'right-start',
}: PathThreadWrapperType) => {
  const [select, setSelect] = useState<PathThreadSelectType>({
    project: null,
    folder: null,
    goal: null,
  });

  useEffect(() => {
    if (!select.goal) {
      onChange({
        project: null,
        folder: null,
        goal: null,
      });
    } else {
      onChange(select);
    }
  }, [select]);

  const { data } = useProjectsQuery({
    variables: {
      options: {
        workspaceId: Number(workspaceId),
      },
    },
    skip: !workspaceId,
  });

  return (
    <>
      {data?.projects?.map((project) => {
        return (
          <PositionedPopper
            key={project.id}
            timeout={40}
            sxPopper={{
              margin: '0px 5px',
              borderRadius: '6px',
              padding: '4px 0px',
            }}
            isOutControll
            outOpen={select.project?.id === project.id}
            ClickComponent={
              <MenuItem
                sx={{ padding: '8px', width: '220px' }}
                onClick={() => {
                  setSelect({
                    project: { id: project.id, title: project.title },
                    folder: null,
                    goal: null,
                  });
                }}
              >
                <StackStyle>
                  <LimitedWidthBox>{project.title}</LimitedWidthBox>

                  {project.folders.length > 0 && (
                    <ChevronRightIcon fontSize='small' sx={{ marginLeft: 'auto' }} />
                  )}
                </StackStyle>
              </MenuItem>
            }
            PopperContent={
              <div>
                {project?.folders?.map((folder) => {
                  return (
                    <PositionedPopper
                      key={folder.id}
                      sxPopper={{
                        margin: '0px 5px',
                        borderRadius: '6px',
                        padding: '4px 0px',
                      }}
                      isOutControll
                      outOpen={select.folder?.id === folder.id}
                      ClickComponent={
                        <Box className={OutsideClickFlagType.outsideClickFlagDefault}>
                          <MenuItem
                            sx={{ padding: '8px', width: '220px' }}
                            onClick={() => {
                              setSelect((prev) => {
                                return {
                                  ...prev,
                                  folder: { id: folder.id, title: folder.title },
                                  goal: null,
                                };
                              });
                            }}
                          >
                            <StackStyle>
                              <LimitedWidthBox>{folder.title}</LimitedWidthBox>
                              {folder.goals.length > 0 && (
                                <ChevronRightIcon fontSize='small' sx={{ marginLeft: 'auto' }} />
                              )}
                            </StackStyle>
                          </MenuItem>
                        </Box>
                      }
                      PopperContent={
                        <Box className={OutsideClickFlagType.outsideClickFlagDefault}>
                          {folder?.goals?.map((goal: { id: number; title: string }) => {
                            return (
                              <MenuItem
                                key={goal.id}
                                sx={{ padding: '8px', width: '220px' }}
                                onClick={() => {
                                  setSelect((prev) => {
                                    return {
                                      ...prev,
                                      goal: { id: goal.id, title: goal.title },
                                    };
                                  });
                                }}
                              >
                                <Stack direction='row' alignItems={'center'}>
                                  <LimitedWidthBox>{goal?.title}</LimitedWidthBox>
                                </Stack>
                              </MenuItem>
                            );
                          })}
                        </Box>
                      }
                      placement={placement}
                    />
                  );
                })}
              </div>
            }
            placement={placement}
          />
        );
      })}
    </>
  );
};
