import { PositionedPopper } from '../index';
import { useTheme, Button } from '@mui/material';
import 'dayjs/locale/ru';
import { CalendarIcons } from '../icons';
import { useFormContext, useController } from 'react-hook-form';
import { getFormattedDateWiev, showElementDate } from '../../utils';
import { DatePickerWrapper } from './DatePickerWrapper/DatePickerWrapper';
import { usePickerConrtoll } from './hooks/usePickerConrtoll';
import { PopperModifiers } from '../../types/types';

type BasicDatePickerRHFType = {
  startDateName: string;
  endDateName: string;
};

export const BasicDatePickerRHF = ({ startDateName, endDateName }: BasicDatePickerRHFType) => {
  const methods = useFormContext();

  const {
    field: { value: startDateValue, onChange: onStartDateChange },
  } = useController({
    name: startDateName,
    control: methods.control,
  });

  const {
    field: { value: endDateValue, onChange: onEndDateChange },
  } = useController({
    name: endDateName,
    control: methods.control,
  });

  return (
    <BasicDatePicker
      startDate={startDateValue}
      onStartDateChange={onStartDateChange}
      endDate={endDateValue}
      onEndDateChange={onEndDateChange}
    />
  );
};

type BasicDatePickerType = {
  startDate: string;
  onStartDateChange: (value: string) => void;
  endDate: string;
  onEndDateChange: (value: string) => void;
};

export const BasicDatePicker = ({
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
}: BasicDatePickerType) => {
  const { pickerValue, setPickerValue, setOutOpen, outOpen } = usePickerConrtoll({
    endDate,
    onEndDateChange,
    startDate,
    onStartDateChange,
  });

  const theme = useTheme();
  const isChangeColor = !pickerValue.startValue && !pickerValue.endValue;

  return (
    <PositionedPopper
      setOutOpen={setOutOpen}
      sxPopper={{
        borderRadius: '6px',
        border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
      }}
      modifiers={
        [
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'viewport',
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
            },
          },
        ] as unknown as PopperModifiers
      }
      ClickComponent={
        <Button
          variant='popper'
          sx={{
            background: outOpen ? theme.palette.backgroundLevel1.main : theme.palette.white.main,
            color: isChangeColor ? theme.palette.textTertiary.main : theme.palette.black600,
          }}
        >
          <CalendarIcons
            sx={{ marginRight: '8px' }}
            color={
              isChangeColor
                ? theme.palette.neutralPlainDisabledColor.main
                : theme.palette.orangeColor.main
            }
          />
          {showElementDate({
            startValue: getFormattedDateWiev(pickerValue?.startValue, 'DD MMM'),
            endValue: getFormattedDateWiev(pickerValue?.endValue, 'DD MMM'),
          })}
        </Button>
      }
      PopperContent={
        <DatePickerWrapper pickerValue={pickerValue} setPickerValue={setPickerValue} />
      }
    />
  );
};
