import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ROUTES } from '../constants/constants';
import { Home, NotFound, Login, DevPage, Registration, Thread } from '../pages';
import { useAuthContext } from '../context';
import { NoLayout, LayoutDefault } from '../components';
import { Box } from '@mui/system';
import { AuthLayout } from '../components/AuthLayout/AuthLayout';
import { CreateWorkspace } from '../pages/CreateWorkspace';
import { ForgotPassword } from '../pages/ForgotPassword/ForgotPassword';
import { GuestInvite } from '../pages/GuestInvite/GuestInvite';
import { InvalidLink } from '../pages/InvalidLink/InvalidLink';

enum LayoutType {
  defaultLayout = 'defaultLayout',
  noLayout = 'noLayout',
  authLayout = 'authLayout',
}
const routes = [
  {
    path: ROUTES.home,
    element: Home,
    layout: LayoutType.defaultLayout,
  },
  {
    path: ROUTES.login,
    element: Login,
    layout: LayoutType.authLayout,
  },
  {
    path: ROUTES.resetPassword,
    element: ForgotPassword,
    layout: LayoutType.authLayout,
  },
  {
    path: ROUTES.registration,
    element: Registration,
    layout: LayoutType.authLayout,
  },
  {
    path: ROUTES.createWorkspace,
    element: CreateWorkspace,
    layout: LayoutType.authLayout,
  },
  {
    path: ROUTES.guestInvite,
    element: GuestInvite,
    layout: LayoutType.authLayout,
  },
  {
    path: ROUTES.invalidLink,
    element: InvalidLink,
    layout: LayoutType.noLayout,
  },
  {
    path: ROUTES.devPage,
    element: DevPage,
    layout: LayoutType.defaultLayout,
  },
  {
    path: ROUTES.thread,
    element: Thread,
    layout: LayoutType.defaultLayout,
  },
];

export const RouterWrapper = () => {
  const { isAuth } = useAuthContext();

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <LayoutController layout={route.layout}>
                <ProtectedRoute {...route} isAuth={isAuth} />
              </LayoutController>
            }
          />
        ))}
        <Route key={'NotFound'} path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

type ProtectedRouteType = {
  element: () => JSX.Element;
  path: string;
  isAuth: boolean;
};
enum RouteType {
  Other = 'other',
  Auth = 'auth',
  Protected = 'protected',
}
const routeTypeMapping: { [key: string]: RouteType } = {
  [ROUTES.login]: RouteType.Auth,
  [ROUTES.registration]: RouteType.Auth,
  [ROUTES.resetPassword]: RouteType.Auth,
  [ROUTES.guestInvite]: RouteType.Other,
  [ROUTES.invalidLink]: RouteType.Other,
};

const determineRouteType = (path: string): RouteType => {
  return routeTypeMapping[path] || RouteType.Protected;
};

const ProtectedRoute = ({ element: Element, path, isAuth = false }: ProtectedRouteType) => {
  const routeType = determineRouteType(path);

  if (routeType === RouteType.Other) {
    return <Element />;
  }

  if (routeType === RouteType.Auth) {
    return isAuth ? <Navigate to={ROUTES.home} /> : <Element />;
  }

  return isAuth ? <Element /> : <Navigate to={ROUTES.login} />;
};

type LayoutControllerType = {
  children: React.ReactNode;
  layout: LayoutType;
};

const LayoutController = ({ children, layout }: LayoutControllerType) => {
  if (layout === LayoutType.noLayout) {
    return <NoLayout>{children}</NoLayout>;
  }
  if (layout === LayoutType.defaultLayout) {
    return <LayoutDefault>{children}</LayoutDefault>;
  }
  if (layout === LayoutType.authLayout) {
    return <AuthLayout>{children}</AuthLayout>;
  }
  return <Box>{children}</Box>;
};
