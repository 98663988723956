import RadarIcon from '@mui/icons-material/Radar';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DateRangeIcon from '@mui/icons-material/DateRange';
import BarChartIcon from '@mui/icons-material/BarChart';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import ThumbsUp from '../assets/ThumbsUp.png';
import GriningFace from '../assets/GriningFace.png';
import OkHand from '../assets/OkHand.png';
import SmillingFace from '../assets/SmillFace.png';
import SmillingFaceWithSuglasses from '../assets/SmillingFaceWithSuglasses.png';
import IthimksFace from '../assets/ThinksFace.png';
import SighOftheHorns from '../assets/SighOftheHorns.png';
import FoldesHands from '../assets/FoldesHands.png';

export const ROUTES = {
  home: '/',
  devPage: '/devPage',
  login: '/login',
  resetPassword: '/reset-password',
  registration: '/registration',
  createWorkspace: '/create-workspace',
  guestInvite: '/invite',
  invalidLink: '/invalidLink',
  messages: '/messages',
  statistics: '/statistics',
  organization: '/organization',
  calendar: '/calendar',
  forum: '/forum',
  thread: '/thread/:threadId',
};

export const NavigationsData: Array<NavigationsDataItem> = [
  { text: 'Мои треды', Icon: RadarIcon, path: ROUTES.home },
  { text: 'Сообщения', Icon: ChatBubbleOutlineIcon, messageCount: 8, path: ROUTES.messages },
  { text: 'Статистика', Icon: BarChartIcon, path: ROUTES.statistics },
  { text: 'Организация', Icon: WorkspacesOutlinedIcon, path: ROUTES.organization },
  { text: 'Календарь', Icon: DateRangeIcon, path: ROUTES.calendar },
  { text: 'Форум', Icon: ForumOutlinedIcon, path: ROUTES.forum },
];

export const API_HOST: string = process.env.REACT_APP_API_HOST as string;
export const SOCKET_HOST: string = process.env.REACT_APP_SOCKET_HOST as string;
export const BASE_URL: string = process.env.REACT_APP_BASE_URL as string;

export enum LOCAL_STORAGE_KEY {
  REGISTRATION_DATA = 'registrationData',
  FORGOT_PASSWORD_DATA = 'forgotPasswordData',
  WORKSPACE_INVITE_TOKEN = 'workspaceInviteToken',
  TOKEN = 'token',
}
export enum QUERY_KEY {
  INVITE_TOKEN = 't',
}

export const DISPLAYED_AVATARS_COUNT = 3;

type NavigationsDataItem = {
  text: string;
  Icon: React.ElementType;
  messageCount?: number;
  path: string;
};
export const KEY_WORD_DELETE_WORKSPACE = 'Удалить';

export const MAX_SIZE_FILE = 100 * 1024 * 1024;

export const REACTION_EMOJI = [
  {
    src: ThumbsUp,
    id: 1,
  },
  {
    src: OkHand,
    id: 2,
  },
  {
    src: GriningFace,
    id: 3,
  },
  {
    src: SmillingFace,
    id: 4,
  },
  {
    src: SmillingFaceWithSuglasses,
    id: 5,
  },
  {
    src: IthimksFace,
    id: 6,
  },
  { src: SighOftheHorns, id: 7 },
  { src: FoldesHands, id: 8 },
];

export const SUPPORTED_IMAGE_FORMATS = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

export const emailRegex = /^$|[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}/;

export const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
