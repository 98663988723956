import { Popover, Stack, styled, Typography } from '@mui/material';

export const PopoverWorkspaceHub = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root  ': {
    width: '250px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
  },
}));

export const StackWorkspaceHubStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  margin: '16px 0px',
  padding: '8px',
  height: '36px',
  borderRadius: '6px',
  '&:hover,&.open': {
    backgroundColor: theme.palette.neutralPlainHoverBg.main,
  },
}));
export const TitleTypography = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
