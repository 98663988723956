import { StyledTableCell } from '../../../style';
import { UpdateThreadMutationFn } from '../../../../../graphql/generated/graphql';
import { useTheme, Popover, Box, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { PathThreadWrapperRHF } from '../../../../index';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';

import { useClickCellPopup } from '../../../../../hooks/useClickCellPopup';
import { PathType } from '../../../../../types/types';
import { OutsideClickFlagType } from '../../../../../types/enum';

import { BoxStyle } from './style';

type ProjectSelectTypes = {
  threadId: number;
  workspaceId?: number;
  updateThreadMutation: UpdateThreadMutationFn;
  path: PathType;
};

export const ProjectSelect = ({
  updateThreadMutation,
  threadId,
  workspaceId,
  path,
}: ProjectSelectTypes) => {
  const theme = useTheme();

  const { anchorEl, open, handleClick, handleClose, refClickOutside, setOpen, setIsDisable } =
    useClickCellPopup({ isSetDisableProject: true });

  type FormData = {
    path?: PathType;
  };

  const methods = useForm<FormData>({
    defaultValues: {
      path: path,
    },
  });
  const { getValues, watch } = methods;

  const pathWatch = watch('path');

  const [isInitDisable, setIsInitDisable] = useState(true);

  useEffect(() => {
    if (pathWatch?.goal?.id !== path?.goal?.id && pathWatch?.goal?.id) {
      setOpen(false);
    }
  }, [pathWatch]);

  useEffect(() => {
    setIsInitDisable(false);
    if (isInitDisable || open) return;
    const currentValues = getValues();
    updateThreadMutation({
      variables: {
        data: {
          id: threadId,
          goalId: currentValues.path?.goal?.id,
        },
      },
    });
  }, [open]);

  return (
    <FormProvider {...methods}>
      <StyledTableCell
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          width: '210px',
          '&:hover': {
            backgroundColor: open ? '' : `${theme.palette.tableHover.main} !important`,
          },
        }}
      >
        <Stack direction={'row'} ref={refClickOutside} alignItems={'center'}>
          {path?.goal?.title && (
            <BusinessCenterRoundedIcon
              fontSize='small'
              sx={{ marginRight: '6px', color: theme.palette.neutral500.main }}
            />
          )}
          {path?.goal?.title && (
            <BoxStyle>
              {path.project?.title} / {path.folder?.title} / {path.goal.title}
            </BoxStyle>
          )}
        </Stack>
      </StyledTableCell>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box className={OutsideClickFlagType.outsideClickFlagDefault}>
          <PathThreadWrapperRHF name='path' workspaceId={workspaceId} placement='left-start' />
        </Box>
      </Popover>
    </FormProvider>
  );
};
