import { useRef, useEffect } from 'react';
import { OutsideClickFlagType } from '../types/enum';

type UseClickOutsideProps = {
  callBack: () => void;
  callBackAnyClick?: () => void;
};

export const useClickOutside = ({ callBack, callBackAnyClick }: UseClickOutsideProps) => {
  const refClickOutside = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const isInsideDateCalendar = fixOutsideClick(event);

    if (
      callBackAnyClick &&
      refClickOutside.current &&
      !refClickOutside.current.contains(event.target as Node)
    ) {
      callBackAnyClick?.();
    }

    if (
      refClickOutside.current &&
      !refClickOutside.current.contains(event.target as Node) &&
      !isInsideDateCalendar
    ) {
      callBack();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callBack, callBackAnyClick]);

  return { refClickOutside };
};

const fixOutsideClick = (event: MouseEvent) => {
  const path = event.composedPath();
  const isInsideDateCalendar = path.some((el) => {
    return (
      el instanceof Element && el.classList.contains(OutsideClickFlagType.outsideClickFlagDefault)
    );
  });
  return isInsideDateCalendar;
};
