import { Stack, Typography, useTheme } from '@mui/material';
import { MessageChatType, RepliedToMessage } from '../../../../../types/types';
import { TypographyStyle, ImageStyle, StackStyle } from './style';
import { createAttachmentImage, userNameCalc } from '../../../../../utils';
import { useChatContext } from '../../../context/ChatContext';

type ReplyType = {
  reply?: MessageChatType | RepliedToMessage | null;
};

export const Reply = ({ reply }: ReplyType) => {
  const { setReplayId } = useChatContext();

  const attachFile = reply?.attachments[0];

  const { imageUrl } = createAttachmentImage({
    path: attachFile?.path,
    mimeType: attachFile?.mimeType,
  });

  const scrollToElement = () => {
    setReplayId(reply?.id as number);
  };

  const theme = useTheme();

  return (
    <StackStyle onClick={scrollToElement}>
      {attachFile && <ImageStyle src={imageUrl} alt={attachFile?.fileName} />}
      <Stack width={'99%'}>
        <Typography variant='customDefault' sx={{ color: theme.palette.textPrimary.main }}>
          {userNameCalc({
            email: reply?.author?.email,
            firstName: reply?.author?.firstName,
            lastName: reply?.author?.lastName,
          })}
        </Typography>
        <TypographyStyle
          sx={{
            width: `calc(100% - ${attachFile ? 48 : 0}px)`,
          }}
        >
          {reply?.content ? reply?.content : attachFile?.fileName}
        </TypographyStyle>
      </Stack>
    </StackStyle>
  );
};
