import { Stack, styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';

export const StackStyle = styled(Stack)(({ theme }) => ({
  padding: '0 24px',
  '& .MuiInputBase-root': { borderRadius: '8px!important' },
}));

export const GroupUserWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 8px 4px 0',
}));
export const AvatarStyle = styled(Avatar)({
  marginRight: '8px',
  width: '32px',
  height: '32px',
  fontSize: '14px',
});
