import { styled, Stack, Box } from '@mui/material';

export const StackStyle = styled(Stack)(() => ({
  whiteSpace: 'nowrap',
  flexDirection: 'row',
  alignItems: 'center',
}));
export const BoxPickerStyle = styled(Box)(({ theme }) => ({
  boxShadow: theme.palette.boxShadowPopper.main,
  borderRadius: '6px ',
  border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
}));
