import React, { createContext, useContext, useState } from 'react';
import { Alert, Box, Snackbar, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ModalStatuses } from '../types/enum';
import { ModalLayout } from '../components/ModalLayout/ModalLayout';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ModalParametersTypes, ModalParameterValue, AlertDataType } from '../types/types';

type ModalContextType = {
  handleChangeModalParameters: (params: ModalParameter[]) => void;
  handleCloseModal: () => void;
  modalParameters: ModalParametersTypes;
  handleCloseAlert: () => void;
  handleAlertChangeParameter: (value: AlertDataType) => void;
};

const ModalContext = createContext<ModalContextType | null>(null);
const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useMenuContext must be used within a MenuProvider');
  }
  return context;
};

type AuthContextProviderType = {
  children: React.ReactNode;
};

type ModalParameter = {
  key: string;
  value: ModalParameterValue;
};

const ModalProvider = ({ children }: AuthContextProviderType) => {
  const [modalParameters, setModalParameters] = useState<ModalParametersTypes>({
    type: ModalStatuses.CLOSE,
  });
  const handleChangeModalParameters = (params: ModalParameter[]) => {
    params.forEach(({ key, value }) => {
      setModalParameters((prevModalParameters) => ({
        ...prevModalParameters,
        [key]: value,
      }));
    });
  };

  const handleCloseModal = () => {
    setModalParameters({
      type: ModalStatuses.CLOSE,
    });
  };
  const [alertData, setAlertData] = useState<AlertDataType>({
    state: false,
    text: '',
    severity: 'info',
  });

  const handleCloseAlert = () => {
    setAlertData((prev) => {
      return { ...prev, state: false };
    });
  };

  const handleAlertChangeParameter = ({ state, text, severity }: AlertDataType) => {
    setAlertData({ state: state, text: text, severity: severity });
  };

  return (
    <ModalContext.Provider
      value={{
        handleChangeModalParameters,
        handleCloseModal,
        modalParameters,
        handleCloseAlert,
        handleAlertChangeParameter,
      }}
    >
      <ModalLayout modalParameters={modalParameters} handleCloseModal={handleCloseModal}>
        {children}
      </ModalLayout>
      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={alertData.state}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Stack
          flexDirection='row'
          alignItems='center'
          p='8px'
          sx={{
            backgroundColor: '#fff',
            borderRadius: '8px',
          }}
        >
          <CheckCircleOutlineIcon fontSize='inherit' sx={{ marginRight: '8px', color: 'green' }} />
          <Typography fontWeight='500' pr='8px' color='black600'>
            {alertData.text}
          </Typography>
          <CloseIcon onClick={handleCloseAlert} />
        </Stack>
      </Snackbar>
    </ModalContext.Provider>
  );
};

export { ModalProvider, useModalContext };
