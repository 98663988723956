import { SelectChangeEvent } from '@mui/material/Select';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

import { Stack, SxProps, Select, MenuItem, useTheme } from '@mui/material';
import { TimelansOutlinedOrange, CheckCircleRounden } from '../../icons';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import { useFormContext, useController } from 'react-hook-form';
import { StatusThreadTypes } from '../../../types/enum';

type StatusThreadRHFType = {
  sx?: SxProps;
  name: string;
};

export const StatusThreadRHF = ({ name, sx }: StatusThreadRHFType) => {
  const methods = useFormContext();

  const {
    field: { value, onChange },
  } = useController({
    name,
    control: methods.control,
  });

  return <StatusThread sx={sx} value={value} onChange={onChange} />;
};

type StatusThreadType = {
  sx?: SxProps;
  value: number;
  onChange: (value: number) => void;
};

export const StatusThread = ({ sx, value, onChange }: StatusThreadType) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(Number(event.target.value));
  };

  const theme = useTheme();

  return (
    <Select
      value={`${value}`}
      onChange={handleChange}
      sx={{ '& .MuiInputBase-input': { borderRadius: '6px' }, ...sx }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          sx: {
            marginTop: '5px',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
            background: theme.palette.backgroundSurface.main,
            boxShadow: theme.palette.boxShadowPopper.main,
            '& .MuiList-root': {
              padding: '6px 0px',
              width: '180px',
            },
          },
        },
      }}
      variant='outlined'
      size='small'
    >
      {statusOptions?.map((e) => {
        return (
          <MenuItem value={e.value} key={e.value} sx={{ padding: '4px 8px !important' }}>
            <Stack
              direction='row'
              alignItems={'center'}
              sx={{
                color: value === e.value ? theme.palette.black600 : theme.palette.textTertiary.main,
                '& svg': {
                  margin: '0 6px 0 0',
                },
              }}
            >
              {e.icons}
              {e.title}
            </Stack>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const statusOptions = [
  {
    value: StatusThreadTypes.open,
    title: 'Открыто',
    icons: <CircleOutlinedIcon fontSize='small' sx={{ marginRight: '8px', color: '#CDD7E1' }} />,
  },
  {
    value: StatusThreadTypes.in_progress,
    title: 'В процессе',
    icons: <TimelansOutlinedOrange sx={{ marginRight: '8px', color: '#EA9A3E' }} />,
  },
  {
    value: StatusThreadTypes.postponed,
    title: 'Отложено',
    icons: (
      <PauseCircleOutlineOutlinedIcon
        fontSize='small'
        sx={{ marginRight: '8px', color: '#9FA6AD' }}
      />
    ),
  },
  {
    value: StatusThreadTypes.done,
    title: 'Выполнено',
    icons: <CheckCircleRounden sx={{ marginRight: '8px', color: '#51BC51' }} />,
  },
];
