import { gql } from '@apollo/client';

export const WORKSPACE = gql`
  query workspace($workspaceId: Int!) {
    workspace(id: $workspaceId) {
      __typename
      avatar {
        path
        id
      }
      members {
        role
        user {
          email
          firstName
          lastName
          id
          avatar {
            path
            id
          }
        }
      }
      isInviteLinkAccess
      invites {
        email
        id
      }
      inviteLink
      id
      myRole
      title
    }
  }
`;
