import { Stack } from '@mui/material';
import { StatusThreadTableRHF } from '../../../../index';
import { StyledTableCell } from '../../../style';
import { TextFieldRHF } from '../../../../uiComponents';
import { useForm, FormProvider, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThreadSchema } from '../../../../../validation';
import {
  SizeOptions,
  StatusThreadTypes,
  TypeTableRow,
  OutsideClickFlagType,
} from '../../../../../types/enum';
import { BoxOutherStyle, StackInnerStyle, TypographyStyle } from './style';
import { useClickOutside } from '../../../../../hooks';
import { useWorkspaceContext } from '../../../../../context';
import { useCreateThreadMutation } from '../../../../../graphql/generated/graphql';
import { THREADS_AND_COUNT } from '../../../../../graphql/queries/threadsAndCount.gql';
import { FOLDER } from '../../../../../graphql/queries/folder.gql';

type NameCellType = {
  titleCellWidth: string;
  setCreateThread: (value: boolean) => void;
  type?: TypeTableRow;
  goalId?: number;
};

export const NameAndStatusCellCreate = ({
  titleCellWidth,
  setCreateThread,
  type = TypeTableRow.thread,
  goalId,
}: NameCellType) => {
  const { workspaceId } = useWorkspaceContext();

  const [createThreadMutation] = useCreateThreadMutation({
    refetchQueries: TypeTableRow.thread === type ? [THREADS_AND_COUNT] : [FOLDER],
  });

  const { refClickOutside } = useClickOutside({
    callBack: () => {
      const validateAndSubmit = async () => {
        const isValid = await trigger();
        if (isValid) {
          const currentValues = getValues();
          createThreadMutation({
            variables: {
              data: {
                workspaceId: Number(workspaceId),
                title: currentValues.title,
                statusId: currentValues.status,
                goalId: goalId,
              },
            },
          });
        } else {
          reset({ title: '' });
        }
        setCreateThread(false);
      };

      validateAndSubmit();
    },
  });

  type FormData = {
    title: string;
    status: number;
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(ThreadSchema) as Resolver<FormData>,
    defaultValues: {
      title: '',
      status: StatusThreadTypes.open,
    },
  });
  const { getValues, trigger, reset } = methods;

  return (
    <StyledTableCell width={titleCellWidth} sx={{ maxWidth: '300px' }}>
      <FormProvider {...methods}>
        <Stack direction={'row'} alignItems={'center'} ref={refClickOutside}>
          <BoxOutherStyle className={OutsideClickFlagType.outsideClickFlagDefault}>
            <StackInnerStyle direction={'row'}>
              <StatusThreadTableRHF type='table' name='status' />
              <TextFieldRHF
                fullWidth
                placeholder='Введите название'
                minRows={1}
                name='title'
                type='text'
                multiline
                size={SizeOptions.small}
              />
            </StackInnerStyle>
          </BoxOutherStyle>
          <TypographyStyle sx={{ opacity: '0' }}>fix</TypographyStyle>
        </Stack>
      </FormProvider>
    </StyledTableCell>
  );
};
