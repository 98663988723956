import * as yup from 'yup';

import { REQUIRE_MESSAGE, WRONG_WORD } from './messages';
import { KEY_WORD_DELETE_WORKSPACE } from '../constants/constants';

export const WorkspaceSchema = yup.object().shape({
  title: yup.string().required(),
});

export const DeleteWorkspaceSchema = yup.object().shape({
  keyWord: yup.string().equals([KEY_WORD_DELETE_WORKSPACE], WRONG_WORD).required(REQUIRE_MESSAGE),
});
