import { ListItemButtonStyle } from '../../../style';
import { FolderTwoTone } from '../../../../../icons/index';
import { ListItemIcon, useTheme, Box } from '@mui/material';
import { SizeOptions, ModalStatuses } from '../../../../../../types/enum';
import {
  useAutoFocusLineEnd,
  useClickOutside,
  useDefineAcess,
  useNavigateSearch,
} from '../../../../../../hooks';
import { useEditFolder } from '../../../hooks/index';
import { FormProvider } from 'react-hook-form';
import { PositionedPopper, TextFieldRHF } from '../../../../../index';
import { FolderPopperContent } from '../FolderPopperContent/FolderPopperContent';
import { useModalContext, useWorkspaceContext } from '../../../../../../context/index';
import { ListItemTextStyle } from './style';
import { ROUTES } from '../../../../../../constants/constants';
import { VisibilityStatusEnum } from '../../../../../../graphql/generated/graphql';
import { ShowRightIcons } from '../../ShowRightIcons/ShowRightIcons';
import { memo, useState } from 'react';

type EditableFolderLineType = {
  projectId: number;
  isEditable: boolean;
  setIsEditable: (value: boolean) => void;
  isAddFolder?: boolean;
  title: string;
  folderId?: number;
  visibilityStatus: VisibilityStatusEnum;
  authorId?: number;
};

export const EditableFolderLineMemo = ({
  projectId,
  isEditable,
  setIsEditable,
  isAddFolder = false,
  title,
  folderId,
  visibilityStatus,
  authorId,
}: EditableFolderLineType) => {
  const { handleChangeModalParameters } = useModalContext();

  const { refClickOutside } = useClickOutside({
    callBack: () => {
      setIsEditable(false);
    },
  });

  useAutoFocusLineEnd({
    ref: refClickOutside,
    trigger: isEditable,
  });

  const { selectFolderId, setSelectFolderId } = useWorkspaceContext();
  const { methods, removeFolderMutation } = useEditFolder({
    nameFolder: title,
    isEditable,
    projectId,
    isAddFolder,
    folderId,
    setSelectFolderId,
  });

  const handleDeleteFolder = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.FOLDER_DELETE_MODAL,
      },
      {
        key: 'removeFolder',
        value: removeFolderMutation,
      },
    ]);
  };

  const theme = useTheme();

  const { navigateSearchParams } = useNavigateSearch();

  const handleClickFolder = () => {
    folderId && setSelectFolderId(folderId);
    navigateSearchParams({ path: ROUTES.home });
  };
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  const { isEditFolderAcess } = useDefineAcess({ authorId: authorId });

  return (
    <ListItemButtonStyle
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={
        isAddFolder
          ? {
              paddingLeft: '44px',
              display: isEditable ? 'flex' : 'none',
              height: isEditable ? 'auto' : '32px',
            }
          : {
              paddingLeft: '44px',
              backgroundColor:
                folderId === selectFolderId && !isEditable
                  ? `${theme.palette.backgroundLevel1.main} !important`
                  : '',
              '&:hover': {
                backgroundColor: isEditable ? 'white !important' : '',
              },
              height: isEditable ? 'auto' : '32px',
            }
      }
      className={isEditable ? 'editable' : ''}
      ref={refClickOutside}
      onClick={handleClickFolder}
    >
      <FormProvider {...methods}>
        <ListItemIcon
          sx={{
            minWidth: '24px',
            paddingRight: '8px',
            marginBottom: isEditable ? 'auto' : '',
          }}
        >
          <FolderTwoTone sx={{ width: '20px', height: '20px' }} />
        </ListItemIcon>
        {isEditable ? (
          <TextFieldRHF
            fullWidth
            placeholder='Заголовок папки'
            minRows={1}
            name='title'
            type='text'
            multiline
            size={SizeOptions.small}
            autoFocus={true}
          />
        ) : (
          <>
            <ListItemTextStyle
              primary={title}
              sx={
                folderId === selectFolderId
                  ? {
                      '& .MuiTypography-root': {
                        color: folderId === selectFolderId ? theme.palette.black600 : '',
                        fontWeight: '500',
                      },
                    }
                  : {}
              }
            />

            <Box
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {isEditFolderAcess && (
                <PositionedPopper
                  ClickComponent={
                    <ShowRightIcons visibilityStatus={visibilityStatus} isHover={isHover} />
                  }
                  PopperContent={
                    <FolderPopperContent
                      handleSetIsEditable={setIsEditable}
                      handleDeleteFolder={handleDeleteFolder}
                    />
                  }
                />
              )}
            </Box>
          </>
        )}
      </FormProvider>
    </ListItemButtonStyle>
  );
};

export const EditableFolderLine = memo(EditableFolderLineMemo);
