import { ListItemText, ListItemIcon, useTheme } from '@mui/material';
import { ListItemButtonStyle, ListStyle, TypographyMessageCount, ListItemStyle } from './style';
import { NavigationsData, ROUTES } from '../../../constants/constants';
import { useLocation } from 'react-router-dom';
import { useWorkspaceContext } from '../../../context';
import { useNavigateSearch } from '../../../hooks';

export const NavigationList = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const theme = useTheme();

  const { setSelectProjectId, selectProjectId, selectFolderId } = useWorkspaceContext();

  const { navigateSearchParams } = useNavigateSearch();

  return (
    <ListStyle>
      {NavigationsData.map((item) => {
        const { text, path, Icon } = item;

        const calcIsSelected = () => {
          if (path === ROUTES.home && (selectProjectId || selectFolderId)) return false;
          return currentPath === item.path;
        };

        const isSeleced = calcIsSelected();

        return (
          <ListItemStyle
            key={text}
            disablePadding
            sx={{
              backgroundColor: isSeleced ? theme.palette.backgroundLevel1.main : '',
            }}
            onClick={() => {
              if (item.path === ROUTES.home) {
                setSelectProjectId(null);
                navigateSearchParams({
                  path: ROUTES.home,
                });
              }
            }}
          >
            <ListItemButtonStyle>
              <ListItemIcon
                sx={{
                  minWidth: '24px',
                  paddingRight: '8px',
                }}
              >
                <Icon
                  sx={{
                    color: isSeleced ? theme.palette.black600 : theme.palette.neutral500.main,
                    width: '18px',
                    height: '18px',
                    margin: '0px',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={text}
                sx={{
                  '& .MuiTypography-root': {
                    color: isSeleced ? theme.palette.black600 : '',
                  },
                }}
              />
              {item?.messageCount && (
                <TypographyMessageCount>{item?.messageCount}</TypographyMessageCount>
              )}
            </ListItemButtonStyle>
          </ListItemStyle>
        );
      })}
    </ListStyle>
  );
};
