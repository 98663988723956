import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TextFieldRHF } from '../../../../components';
import { SizeOptions } from '../../../../types/enum';
import { FormWrapper } from '../../../../components/AuthFormElements/form.style';
import { LoadingButton } from '@mui/lab';
import { forgotPasswordSchemaEmail } from '../../../../validation/forgotPasswordSchemaEmail';
import { useSendPasswordResetMutation } from '../../../../graphql/generated/graphql';
import { useForgotPasswordContext } from '../../context/context';
import { FORGOT_PASSWORD_STEP, RECOVERY_INSTRUCTIONS_QUERY_KEY } from '../../const';
import { useQueryParams } from '../../../../hooks/useQueryParams';

interface IFormData {
  email: string;
}
enum ServerError {
  USER_NOT_FOUND = 'User not found!',
}
export const AtTheBeginningForm = () => {
  const queryParams = useQueryParams();
  const { handleGoToStep, updateData } = useForgotPasswordContext();

  const methods = useForm<IFormData>({
    resolver: yupResolver(forgotPasswordSchemaEmail),
    defaultValues: {
      email: '',
    },
  });
  const { setError } = methods;
  const [sendPasswordResetMutation, { loading: isLoading }] = useSendPasswordResetMutation({
    onCompleted: () => {
      handleGoToStep(FORGOT_PASSWORD_STEP.recoveryInstructions);
    },
    onError: (error) => {
      if (error.message === ServerError.USER_NOT_FOUND) {
        setError('email', {
          type: 'server',
          message: 'Пользователь с такой почтой не найден!',
        });
      }
    },
  });

  const onSubmit: SubmitHandler<IFormData> = (data) => {
    sendPasswordResetMutation({
      variables: {
        email: data.email,
      },
    });
    updateData({ email: data.email });
  };
  useEffect(() => {
    const token = queryParams.get(RECOVERY_INSTRUCTIONS_QUERY_KEY);
    if (token) {
      handleGoToStep(FORGOT_PASSWORD_STEP.createNewPassword);
    }
  }, []);
  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
        <TextFieldRHF
          name='email'
          type='text'
          placeholder='Почта'
          size={SizeOptions.extraLarge}
          sx={{
            marginTop: '32px',
          }}
        />

        <LoadingButton
          loading={isLoading}
          variant='contained'
          type='submit'
          sx={{ marginTop: '24px', width: '100%' }}
        >
          Отправить инструкцию
        </LoadingButton>
      </FormWrapper>
    </FormProvider>
  );
};
