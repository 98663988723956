import React, { useState } from 'react';
import { Stack, Button, useTheme, IconButton } from '@mui/material';
import { useWorkspace } from '../../../../../../hooks';
import Switch from '@mui/material/Switch';
import {
  useHandleInviteLinkAccessMutation,
  useResetWorkspaceInviteLinkMutation,
} from '../../../../../../graphql/generated/graphql';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormWrapper, SubTitle } from '../../style';
import { TextFieldRHFStyle } from '../../../../../WorkspaceHubElements/style';
import { SizeOptions } from '../../../../../../types/enum';
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import { useModalContext } from '../../../../../../context';
import { DefaultModal } from '../../../DefaultModal/DefaultModal';
import { ResetInvitationLinkModal } from '../../../ResetInvitationLinkModal/ResetInvitationLinkModal';
import { WORKSPACE } from '../../../../../../graphql/queries/workspace';

interface IFormData {
  inviteLink: string;
  showInviteLink: boolean;
}
export const InviteLinkForm = () => {
  const theme = useTheme();
  const { currentWorkspace } = useWorkspace();
  const { handleAlertChangeParameter } = useModalContext();
  const methods = useForm<IFormData>({
    defaultValues: {
      inviteLink: currentWorkspace?.inviteLink,
      showInviteLink: currentWorkspace?.isInviteLinkAccess,
    },
  });
  const { watch, setValue } = methods;
  const linkWatch = watch('inviteLink');
  const showInviteLineWatch = watch('showInviteLink');
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleShowModal = () => setShowModal((prevState) => !prevState);
  const [resetWorkspaceInviteLinkMutation] = useResetWorkspaceInviteLinkMutation({
    onCompleted: (data) => {
      setValue('inviteLink', data.resetWorkspaceInviteLink);
    },
    onError: () => {
      handleAlertChangeParameter({
        severity: 'error',
        state: true,
        text: 'Возникла ошибка',
      });
    },
  });
  const resetInviteLink = () => {
    resetWorkspaceInviteLinkMutation({
      variables: {
        workspaceId: currentWorkspace!.id,
      },
    });
  };
  const [handleInviteLinkAccessMutation] = useHandleInviteLinkAccessMutation({
    variables: {
      workspaceId: Number(currentWorkspace?.id),
    },
    refetchQueries: [WORKSPACE],
  });
  const handleShowLink = () => {
    setValue('showInviteLink', !showInviteLineWatch);
    handleInviteLinkAccessMutation();
  };
  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    await navigator.clipboard.writeText(data.inviteLink);
    handleAlertChangeParameter({
      severity: 'success',
      state: true,
      text: 'Ссылка скопирована',
    });
  };
  return (
    <>
      <FormProvider {...methods}>
        <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack>
            <Stack flexDirection='row' justifyContent='space-between'>
              <SubTitle variant='h4'>Или отправьте ссылку-приглашение</SubTitle>
              <Switch
                inputProps={{ 'aria-label': 'controlled' }}
                name='showInviteLink'
                onChange={handleShowLink}
                checked={currentWorkspace?.isInviteLinkAccess}
              />
            </Stack>
            {showInviteLineWatch && (
              <Stack flexDirection='row' mt='10px'>
                <TextFieldRHFStyle
                  size={SizeOptions.small}
                  name='link'
                  value={linkWatch}
                  sx={{
                    borderRadius: '6px',
                    border: `1px solid ${theme.palette.neutralOutlinedBorder.main}`,
                    '& .MuiInputBase-input': {
                      border: 'none',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleShowModal} sx={{ padding: 0, marginRight: '8px' }}>
                        <RefreshSharpIcon />
                      </IconButton>
                    ),
                  }}
                />
                <Button
                  size='small'
                  variant='outlined'
                  type='submit'
                  sx={{ marginLeft: '8px', minWidth: '170px' }}
                >
                  Скопировать ссылку
                </Button>
              </Stack>
            )}
          </Stack>
        </FormWrapper>
      </FormProvider>
      <DefaultModal open={showModal} handleCloseModal={handleShowModal} sx={{ width: '360px' }}>
        <ResetInvitationLinkModal
          handleCloseModal={handleShowModal}
          handleSubmitModal={resetInviteLink}
        />
      </DefaultModal>
    </>
  );
};
