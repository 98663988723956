import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { TabType } from '../../../const';
import { InviteByEmailForm, InviteLinkForm } from '../../../../InviteWorkspaceModal/components';
import { WorkspaceMembers } from './components/WorkspaceMembers/Members';
import { WorkspaceInvitations } from './components/WorkspaceInvitations/WorkspaceInvitations';

export const Users = ({ handleCloseModal }: TabType) => {
  const theme = useTheme();
  return (
    <Stack width='100%' p='24px'>
      <Typography variant='h3'>Пользователи</Typography>
      <InviteByEmailForm />
      <Divider
        sx={{
          backgroundColor: theme.palette.neutralOutlinedBorder.main,
          margin: '24px 0',
        }}
      />
      <InviteLinkForm />
      <WorkspaceMembers />
      <WorkspaceInvitations />
    </Stack>
  );
};
