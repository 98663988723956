import 'dayjs/locale/ru';

import { useFormContext, useController } from 'react-hook-form';
import { DatePickerWrapper } from './DatePickerWrapper/DatePickerWrapper';
import { usePickerConrtoll } from './hooks/usePickerConrtoll';

type TableDatePickerRHFType = {
  startDateName: string;
  endDateName: string;
};

export const TableDatePickerRHF = ({ startDateName, endDateName }: TableDatePickerRHFType) => {
  const methods = useFormContext();

  const {
    field: { value: startDateValue, onChange: onStartDateChange },
  } = useController({
    name: startDateName,
    control: methods.control,
  });

  const {
    field: { value: endDateValue, onChange: onEndDateChange },
  } = useController({
    name: endDateName,
    control: methods.control,
  });

  return (
    <TableDatePicker
      startDate={startDateValue}
      onStartDateChange={onStartDateChange}
      endDate={endDateValue}
      onEndDateChange={onEndDateChange}
    />
  );
};

type TableDatePickerType = {
  startDate: string;
  onStartDateChange: (value: string) => void;
  endDate: string;
  onEndDateChange: (value: string) => void;
};

export const TableDatePicker = ({
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
}: TableDatePickerType) => {
  const { pickerValue, setPickerValue } = usePickerConrtoll({
    endDate,
    onEndDateChange,
    startDate,
    onStartDateChange,
  });

  return <DatePickerWrapper pickerValue={pickerValue} setPickerValue={setPickerValue} />;
};
