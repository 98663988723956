import { Stack, Typography } from '@mui/material';
import { TypographyStyle, StackAddStyle } from './style';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useState } from 'react';
import { useRemoveChecklistItemMutation } from '../../../graphql/generated/graphql';
import { ItemCheck } from './ItemCheck/ItemCheck';
import { ChecklistItemType } from '../../../types/types';
import { THREAD } from '../../../graphql/queries/thread.gql';

type EditCheckboxType = {
  threadId?: number;
  checklistItems?: Array<ChecklistItemType>;
};

export const EditCheckbox = ({ threadId, checklistItems }: EditCheckboxType) => {
  const [isAddItem, setIsAddItem] = useState(false);

  const [removeChecklistItemMutation] = useRemoveChecklistItemMutation({
    refetchQueries: [THREAD],
  });

  return (
    <Stack sx={{ padding: '24px 0px' }}>
      <TypographyStyle>Чеклист</TypographyStyle>
      {checklistItems?.map((e) => {
        return (
          <ItemCheck
            key={e.id}
            onRemove={() => {
              removeChecklistItemMutation({
                variables: {
                  id: e.id,
                },
              });
            }}
            title={e.title}
            checklistItemId={e.id}
            isChecked={e.isChecked}
            setIsAddItem={setIsAddItem}
            threadId={Number(threadId)}
          />
        );
      })}
      {isAddItem && (
        <ItemCheck
          onRemove={() => {
            setIsAddItem(false);
          }}
          isAddItem={isAddItem}
          title=''
          isChecked={false}
          setIsAddItem={setIsAddItem}
          threadId={Number(threadId)}
        />
      )}

      <StackAddStyle
        onClick={() => {
          setIsAddItem(true);
        }}
      >
        <AddRoundedIcon sx={{ margin: '0 6px 0 0' }} />
        <Typography>Добавить пункт</Typography>
      </StackAddStyle>
    </Stack>
  );
};
