import { useForm, FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { UpdateThreadMutationFn } from '../../../graphql/generated/graphql';
import { PathThreadBreadCrumbs, PathThreadWrapperRHF } from '../../index';
import { useClickCellPopup } from '../../../hooks';
import { BoxProjectStyle, StackStyle, StackWrapperStyle } from './style';
import { useTheme, Popover, Stack, Typography } from '@mui/material';
import { useWorkspaceContext } from '../../../context';
import { PathType } from '../../../types/types';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { OutsideClickFlagType } from '../../../types/enum';

type EditThreadResponsibleType = {
  updateThreadMutation: UpdateThreadMutationFn;
  threadId?: number;
  path: PathType;
};

export const EditProject = ({
  threadId,
  updateThreadMutation,
  path,
}: EditThreadResponsibleType) => {
  type FormDataType = {
    path?: PathType;
  };
  const { workspaceId } = useWorkspaceContext();

  const { anchorEl, open, handleClose, refClickOutside, handleClick, setOpen, isDisable } =
    useClickCellPopup({ isSetDisableProject: true });
  const methods = useForm<FormDataType>({
    defaultValues: {
      path: path,
    },
  });

  const { getValues, reset, watch } = methods;

  const [isInitDisable, setIsInitDisable] = useState(true);

  const pathWath = watch('path');

  useEffect(() => {
    setIsInitDisable(false);
    if (!open || isInitDisable || !threadId || !pathWath?.goal?.id) return;

    const currentValues = getValues();
    updateThreadMutation({
      variables: {
        data: {
          id: threadId,
          goalId: currentValues.path?.goal?.id,
        },
      },
    });
    setOpen(false);
  }, [pathWath]);

  useEffect(() => {
    if (path) {
      reset({
        path: path,
      });
    }
  }, [path]);

  const theme = useTheme();

  return (
    <FormProvider {...methods}>
      <StackWrapperStyle ref={refClickOutside} onClick={handleClick}>
        <StackStyle>
          {!open &&
            (path ? (
              <Stack direction={'row'} ref={refClickOutside} width={'100%'}>
                {path?.goal?.title && (
                  <BusinessCenterRoundedIcon
                    fontSize='small'
                    sx={{ marginRight: '6px', color: theme.palette.neutral500.main }}
                  />
                )}
                {path?.goal?.title && (
                  <Stack justifyContent={'center'} width={'100%'}>
                    <PathThreadBreadCrumbs
                      value={path}
                      sxText={{ color: theme.palette.textTertiary.main }}
                    />
                  </Stack>
                )}
              </Stack>
            ) : (
              <>
                <BusinessCenterIcon
                  sx={{
                    color: theme.palette.neutralPlainDisabledColor.main,
                    marginRight: '6px',
                  }}
                />
                <Typography
                  color={isDisable ? 'neutralPlainDisabledColor.main' : 'textTertiary.main'}
                >
                  Указать
                </Typography>
              </>
            ))}
        </StackStyle>
      </StackWrapperStyle>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <BoxProjectStyle className={OutsideClickFlagType.outsideClickFlagDefault}>
          <PathThreadWrapperRHF name='path' workspaceId={workspaceId!} placement='right-start' />
        </BoxProjectStyle>
      </Popover>
    </FormProvider>
  );
};
