import { Stack } from '@mui/material';
import { StyledTableCell } from '../../../style';
import { TextFieldRHF } from '../../../../uiComponents';
import { useForm, FormProvider, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThreadSchema } from '../../../../../validation';
import { SizeOptions } from '../../../../../types/enum';
import { BoxOutherStyle, StackInnerStyle, TypographyStyle } from './style';
import { useClickOutside } from '../../../../../hooks';
import { useCreateFolderMutation } from '../../../../../graphql/generated/graphql';
import { FolderTwoTone } from '../../../../icons';
import { useWorkspaceContext } from '../../../../../context';
import { PROJECT_FOLDER } from '../../../../../graphql/queries/projectFolders.gql';

type NameCellType = {
  nameWidth: string;
  setIsAddFolder: (value: boolean) => void;
};

export const NameCreateCell = ({ nameWidth, setIsAddFolder }: NameCellType) => {
  const { selectProjectId } = useWorkspaceContext();

  const [createFolderMutation] = useCreateFolderMutation({
    refetchQueries: [PROJECT_FOLDER],
  });
  const { refClickOutside } = useClickOutside({
    callBack: () => {
      const validateAndSubmit = async () => {
        const isValid = await trigger();
        if (isValid) {
          const currentValues = getValues();
          createFolderMutation({
            variables: {
              createFolderInput: {
                projectId: Number(selectProjectId),
                title: currentValues.title,
              },
            },
          });
        } else {
          reset({ title: '' });
        }
      };

      validateAndSubmit();
      setIsAddFolder(false);
    },
  });

  type FormData = {
    title: string;
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(ThreadSchema) as Resolver<FormData>,
    defaultValues: {
      title: '',
    },
  });
  const { getValues, trigger, reset } = methods;

  return (
    <StyledTableCell width={nameWidth}>
      <FormProvider {...methods}>
        <Stack direction={'row'} alignItems={'center'} ref={refClickOutside}>
          <BoxOutherStyle>
            <StackInnerStyle direction={'row'}>
              <FolderTwoTone
                sx={{
                  marginRight: '5px',
                  marginBottom: 'auto',
                }}
              />
              <TextFieldRHF
                fullWidth
                placeholder='Введите название'
                minRows={1}
                name='title'
                multiline
                size={SizeOptions.small}
              />
            </StackInnerStyle>
          </BoxOutherStyle>
          <TypographyStyle sx={{ opacity: '0' }}>fix</TypographyStyle>
        </Stack>
      </FormProvider>
    </StyledTableCell>
  );
};
