type UserNameCalcType = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  title?: string | null;
  isOneLether?: boolean;
};

export const userNameCalc = ({
  firstName,
  lastName,
  email,
  title,
  isOneLether,
}: UserNameCalcType) => {
  if (!isOneLether && firstName) {
    return `${firstName} ${lastName || ''}`;
  }
  if (firstName) {
    return `${firstName} ${lastName?.slice(0, 1) || ''}.`;
  }
  if (email) {
    return email;
  }
  if (title) {
    return title;
  }
  return '';
};
