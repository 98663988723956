import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, SxProps } from '@mui/material';
import { measureTextSize } from '../../utils/index';
import { PositionedPopper } from '../uiComponents';
import { TypographyStyle, StackStyle, BreadcrumbsStyle } from './style';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

type BreadcampWrapperType = {
  options?: Array<{ text?: string; id?: number; onClallback: () => void }>;
  sx?: SxProps;
};

export const BreadcrumbsWrapper: React.FC<BreadcampWrapperType> = ({ options, sx }) => {
  const [visibleItems, setVisibleItems] = useState(options || []);
  const breadcrumbsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateVisibleItems = () => {
      const breadcrumbsElement = breadcrumbsRef.current;
      if (!breadcrumbsElement || !options) return;

      const itemsToFit = [...options];
      let totalWidth = 105;
      const singleLineHeight = breadcrumbsElement.firstChild
        ? (breadcrumbsElement.firstChild as HTMLElement).clientHeight
        : 0;

      itemsToFit.forEach((item) => {
        const { width } = measureTextSize(item.text || '');
        totalWidth += width;
      });
      while (
        itemsToFit.length > 0 &&
        (totalWidth > breadcrumbsElement.clientWidth ||
          breadcrumbsElement.clientHeight > singleLineHeight)
      ) {
        totalWidth -= measureTextSize(itemsToFit[0].text || '').width;

        itemsToFit.shift();
      }

      setVisibleItems(itemsToFit);
    };
    setTimeout(updateVisibleItems, 0);
    window.addEventListener('resize', updateVisibleItems);

    return () => {
      window.removeEventListener('resize', updateVisibleItems);
    };
  }, [options]);

  if (!options || options.length === 0) return null;

  const isLastOption = visibleItems.length - 1;

  return (
    <>
      <Box ref={breadcrumbsRef} sx={{ width: '100%', overflow: 'hidden' }}>
        <BreadcrumbsStyle
          sx={{
            ...sx,
          }}
        >
          {visibleItems.length < options.length && (
            <PositionedPopper
              sxPopper={{
                padding: '4px 0px',
              }}
              ClickComponent={<MoreHorizIcon sx={{ cursor: 'pointer' }} />}
              PopperContent={options
                .filter((option) => !visibleItems.includes(option))
                .map((option) => (
                  <StackStyle onClick={option.onClallback} key={option.text}>
                    <TypographyStyle>{option.text}</TypographyStyle>
                  </StackStyle>
                ))}
            />
          )}
          {visibleItems.map((option, index) => (
            <Typography
              key={option.text}
              sx={{
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                color: isLastOption === index ? 'black600' : 'neutralPlainDisabledColor.main',
              }}
              onClick={option.onClallback}
            >
              {option.text}
            </Typography>
          ))}
        </BreadcrumbsStyle>
      </Box>
    </>
  );
};
