import { Box, Stack, useTheme } from '@mui/material';
import { MessageChatFileType, EditindnameDataType } from '../../../../../types/types';
import { BASE_URL } from '../../../../../constants/constants';
import {
  ImageStyle,
  StackWrapperStyle,
  TypographyStyleName,
  TypographyStyleSize,
  IconButtonStyle,
  ImageSmallStyle,
  ButtonWrapperStyle,
} from './style';
import {
  getFileSizeInKilobytes,
  downloadFile,
  createAttachmentImage,
} from '../../../../../utils/index';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { ModalStatuses, AttachmentsClickType } from '../../../../../types/enum';
import { useAuthContext, useModalContext } from '../../../../../context';
import {
  useUpdateFileMutation,
  useRemoveFileMutation,
} from '../../../../../graphql/generated/graphql';

type FilesType = { attachments?: Array<MessageChatFileType>; authorId?: number };

export const Files = ({ attachments, authorId }: FilesType) => {
  if (!attachments) return <></>;

  const theme = useTheme();

  const { currentUser } = useAuthContext();

  const [updateFileMutation] = useUpdateFileMutation();

  const [removeFileMutation] = useRemoveFileMutation();

  const { handleChangeModalParameters } = useModalContext();

  const isAllowEditing = currentUser?.id === authorId;

  const handleClickImage = (file: MessageChatFileType) => {
    const { isImage } = createAttachmentImage({ mimeType: file?.mimeType });

    if (!isImage) return;
    handleChangeModalParameters([
      {
        key: 'type',
        value: ModalStatuses.SINGLE_IMAGE_MODAL,
      },
      {
        key: 'singleImagePath',
        value: `${BASE_URL}${file.path}`,
      },
    ]);
  };
  const renderActionButtons = (file: MessageChatFileType, isImage?: boolean) => {
    return [
      {
        Icon: ModeOutlinedIcon,
        isAllowEditing: !isAllowEditing,
        handleClick: () => {
          handleChangeModalParameters([
            {
              key: 'type',
              value: ModalStatuses.RENAME_FILE_MODAL,
            },
            {
              key: 'editindnameData',
              value: { editingName: false, index: file.id, newName: file.fileName },
            },
            {
              key: 'renameFileModalType',
              value: AttachmentsClickType.Comment,
            },
            {
              key: 'setEditindnameData',
              value: (value: EditindnameDataType) => {
                updateFileMutation({
                  variables: {
                    id: value.index as number,
                    data: {
                      fileName: value.newName,
                    },
                  },
                });
              },
            },
          ]);
        },
      },
      {
        Icon: FileDownloadOutlinedIcon,
        handleClick: () => {
          downloadFile({ path: file.path, fileName: file.fileName });
        },
      },
      {
        Icon: CloseIcon,
        handleClick: () => {
          removeFileMutation({
            variables: {
              id: file.id,
            },
          });
        },
        isAllowEditing: !isAllowEditing,
      },
    ].map((option, index) => {
      const { handleClick, Icon, isAllowEditing } = option;
      if ((isImage && Icon === FileDownloadOutlinedIcon) || isAllowEditing) return;

      return (
        <IconButtonStyle
          key={index}
          onClick={handleClick}
          sx={{
            paddingLeft: '2px',
            ...(isImage
              ? {
                  backgroundColor: theme.palette.backgroundLevel1.main,
                  marginLeft: '2px',
                  '&:hover': {
                    backgroundColor: theme.palette.neutral200.main,
                  },
                }
              : {}),
          }}
        >
          <Icon sx={{ color: theme.palette.black600 }} />
        </IconButtonStyle>
      );
    });
  };

  if (!attachments.length) return <></>;

  const imageAttachments = attachments?.filter((e) => {
    const { isImage } = createAttachmentImage({ mimeType: e.mimeType });
    return isImage;
  });

  const nonImageAttachments = attachments?.filter((e) => {
    const { isImage } = createAttachmentImage({ mimeType: e.mimeType });
    return !isImage;
  });

  return (
    <Box sx={{ maxWidth: '345px', marginTop: '8px' }}>
      <Stack flexDirection={'row'} flexWrap={'wrap'}>
        {imageAttachments.map((e) => {
          return (
            <Stack
              key={e.id}
              sx={{
                position: 'relative',
                '&:hover .hidden': {
                  visibility: 'visible',
                },
              }}
            >
              <ButtonWrapperStyle className='hidden'>
                {renderActionButtons(e, true)}
              </ButtonWrapperStyle>
              <ImageStyle
                onClick={() => {
                  handleClickImage(e);
                }}
                width={172}
                height={188}
                src={`${BASE_URL}${e.path}`}
                alt='img'
              />
            </Stack>
          );
        })}
      </Stack>

      {nonImageAttachments?.map((e) => {
        const { isImage, imageUrl } = createAttachmentImage({
          path: e.path,
          mimeType: e?.mimeType,
        });

        return (
          <StackWrapperStyle key={e.id}>
            <ImageSmallStyle
              sx={{ cursor: isImage ? 'pointer' : '' }}
              src={imageUrl}
              alt='img'
              onClick={() => {
                handleClickImage(e);
              }}
            />
            <Stack sx={{ width: '160px', marginRight: '12px' }}>
              <TypographyStyleName>{e.fileName}</TypographyStyleName>
              <TypographyStyleSize>{getFileSizeInKilobytes(Number(e.size))} кб</TypographyStyleSize>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} sx={{ marginLeft: 'auto' }}>
              {renderActionButtons(e)}
            </Stack>
          </StackWrapperStyle>
        );
      })}
    </Box>
  );
};
