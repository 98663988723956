import {
  useUserGroupsQuery,
  useUsersQuery,
  UserGroup,
  User,
  useProjectQuery,
  VisibilityStatusEnum,
  useFolderQuery,
  ProjectMemberUserGroup,
  ProjectMemberUser,
  FolderMemberUserGroup,
  FolderMemberUser,
} from '../../../graphql/generated/graphql';
import { useWorkspaceContext } from '../../../context';
import { createTextUserAndGroup } from '../../../utils/index';
import { MemberTypes } from '../../../types/enum';

export const useInitDate = () => {
  const { selectProjectId, selectFolderId, currentWorkspace } = useWorkspaceContext();

  const { data: usersGroup } = useUserGroupsQuery({
    variables: {
      data: {
        workspaceId: Number(currentWorkspace?.id),
      },
    },
  });

  const { data: users } = useUsersQuery({
    variables: {
      options: {
        workspaceId: Number(currentWorkspace?.id),
      },
    },
  });

  const createOptionsUsers = () => {
    const groupsOptions =
      usersGroup?.userGroups?.map((e, index) => {
        return {
          value: e.id,
          title: createTextUserAndGroup({ userGroup: e as UserGroup }),
          type: MemberTypes.groups,
          last: usersGroup?.userGroups?.length === index + 1,
          avatar: e.avatar,
          users: e.users?.map((e) => {
            return {
              value: e.id,
              title: createTextUserAndGroup({ user: e as User }),
              avatar: e.avatar,
            };
          }),
        };
      }) || [];
    const usersOptions =
      users?.users?.map((e) => {
        return {
          value: e.id,
          title: createTextUserAndGroup({ user: e as User }),
          type: MemberTypes.user,
          avatar: e.avatar,
        };
      }) || [];

    return [...groupsOptions, ...usersOptions];
  };

  const { data: projectData } = useProjectQuery({
    variables: {
      id: Number(selectProjectId),
    },
    skip: !selectProjectId,
    fetchPolicy: 'cache-and-network',
  });

  const { data: folderData } = useFolderQuery({
    variables: {
      id: Number(selectFolderId),
    },
    skip: !selectFolderId,
    fetchPolicy: 'cache-and-network',
  });

  const visibilityStatus =
    (folderData?.folder?.visibilityStatus || projectData?.project.visibilityStatus) ===
    VisibilityStatusEnum.Public;

  type MemberUserGroup = ProjectMemberUserGroup | FolderMemberUserGroup;
  type MemberUser = ProjectMemberUser | FolderMemberUser;

  const createInitValue = () => {
    const combinedData = ({
      membersUserGroups,
      membersUsers,
    }: {
      membersUserGroups?: Array<MemberUserGroup>;
      membersUsers?: Array<MemberUser>;
    }) => {
      return [
        ...(membersUserGroups?.map((group) => ({
          ...group.userGroup,
          title: createTextUserAndGroup({ userGroup: group.userGroup as UserGroup }),
          type: MemberTypes.groups,
        })) || []),
        ...(membersUsers?.map((user) => ({
          ...user.user,
          title: createTextUserAndGroup({ user: user.user as User }),
          type: MemberTypes.user,
        })) || []),
      ].map((item) => ({
        title: item.title,
        value: Number(item.id),
        type: item.type,
        avatar: item.avatar,
      }));
    };
    const activeData = folderData?.folder ?? projectData?.project;

    return combinedData({
      membersUserGroups: activeData?.membersUserGroups as Array<MemberUserGroup>,
      membersUsers: activeData?.membersUsers as Array<MemberUser>,
    });
  };

  const initValue = createInitValue();

  const initValueIds = initValue.map((iv) => iv.value);

  const options = createOptionsUsers().filter((option) => !initValueIds.includes(option.value));

  const calcTitle = () => {
    if (selectFolderId && projectData?.project?.visibilityStatus === VisibilityStatusEnum.Team) {
      return projectData?.project?.title;
    }
    return currentWorkspace?.title;
  };

  const title = calcTitle();

  return { options, initValue, visibilityStatus, title };
};
