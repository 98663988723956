import { Stack, Typography, Box } from '@mui/material';
import { StackReactionStyle } from '../style';
import { PositionedPopper, AvatarCustom } from '../../../../index';
import { CommentReactionType } from '../../../../../types/types';
import { User, useHandleReactionMutation } from '../../../../../graphql/generated/graphql';
import { userNameCalc } from '../../../../../utils';
import { REACTION_EMOJI } from '../../../../../constants/constants';

type EmojiSelectType = { reactions?: Array<CommentReactionType>; messageId?: number };

export const EmojiSelect = ({ reactions, messageId }: EmojiSelectType) => {
  const [handleReactionMutation] = useHandleReactionMutation();

  const handleClickReaction = ({ emojiId }: { emojiId: number }) => {
    handleReactionMutation({
      variables: {
        data: { emojiId: emojiId, messageId: Number(messageId) },
      },
    });
  };

  if (!reactions?.length) return <></>;

  return (
    <Stack sx={{ paddingTop: '4px' }} direction={'row'}>
      {reactions?.map((reation) => {
        const reactionSrc = REACTION_EMOJI.find((e) => e.id === reation.emojiId)?.src;

        return (
          <PositionedPopper
            hover
            key={reation.emojiId}
            placement='bottom-start'
            ClickComponent={
              <Box component={'span'} onClick={(e) => e.stopPropagation()}>
                <StackReactionStyle
                  key={reactionSrc}
                  onClick={() => {
                    handleClickReaction({ emojiId: reation.emojiId });
                  }}
                >
                  <img src={reactionSrc} alt='reaction' width={16} height={16} />
                  <Typography color='black600' sx={{ fontWeight: 500, marginLeft: '6px' }}>
                    {reation.count}
                  </Typography>
                </StackReactionStyle>
              </Box>
            }
            PopperContent={
              <Stack sx={{ padding: '4px 0px', width: '225px' }}>
                {reation.users?.map((user) => {
                  return <EmojiUserContent user={user} key={user.id} />;
                })}
              </Stack>
            }
          />
        );
      })}
    </Stack>
  );
};

type EmojiUserContentType = {
  user: CommentReactionType['users'][number];
};

const EmojiUserContent = ({ user }: EmojiUserContentType) => {
  return (
    <Stack sx={{ padding: '4px 8px' }} direction={'row'}>
      <AvatarCustom user={user as User} />
      <Typography pl={1} color='black600'>
        {userNameCalc({
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        })}
      </Typography>
    </Stack>
  );
};
